/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { NgModule } from '@angular/core';
import {
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
  DialogService as PrimeNgDialogService,
} from 'primeng/dynamicdialog';
import { DialogService } from './services/dialog.service';

@NgModule({
  imports: [DynamicDialogModule],
  providers: [
    DialogService,
    PrimeNgDialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
  ],
})
export class DialogModule {}
