/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, from, of } from 'rxjs';
import { dbService } from '../services/offline-db.service';
import { AppState, AppStateModel } from '../states/app.state';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
  isOffline = false;

  prepareOffline = false;

  @Select(AppState) appState$: Observable<AppStateModel>;
  constructor() {
    this.appState$.subscribe((appState) => {
      this.isOffline = appState.isOffline;
      this.prepareOffline = appState.prepareOffline;
      if (appState.isOffline) {
        console.log(
          'OfflineInterceptor is set to ' +
            (appState.isOffline ? 'Offline' : 'Online')
        );
      }
    });
  }

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    req: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    if (this.isOffline && req.url.indexOf('https') > -1) {
      console.log('OFFLINE Request!', req);
      if (req.url.indexOf('eva-wks-dev.eu.auth0.com') > -1) {
        throw new Error('Attempt to authenticate while in offline status');
      }
      if (req.method !== 'GET') {
        const key = req.body?.operationName;
        if (key === 'loadAuthenticatedUser') {
          return from(
            dbService
              .table('appState')
              .get('appState')
              .then((state: AppState) => {
                return new HttpResponse({
                  body: {
                    data: { authenticatedUser: state.authenticatedUser },
                  },
                });
              })
          );
        }
        if (key === 'listCertifications') {
          return from(
            dbService
              .table('certifications')
              .toArray()
              .then((certifications) => {
                return new HttpResponse({
                  body: { data: { certifications } },
                });
              })
          );
        }
        if (key === 'loadCertification' && req.body?.variables?.id) {
          console.log(
            'OFFLINE: Request intercepted with response from local DB!'
          );
          return from(
            dbService
              .table('certifications')
              .get(req.body?.variables?.id)
              .then((certification) => {
                return new HttpResponse({
                  body: { data: { certification } },
                });
              })
          );
        }
        if (key === 'listProjects') {
          return from(
            dbService
              .table('appState')
              .get('appState')
              .then((state: AppState) => {
                return new HttpResponse({
                  body: { data: { projects: state.projects } },
                });
              })
          );
        }
        if (key === 'loadStandardDefinitions') {
          return from(
            dbService
              .table('certificationState')
              .get('certificationState')
              .then((state: AppState) => {
                return new HttpResponse({
                  body: {
                    data: { standardDefinitions: state.standardDefinitions },
                  },
                });
              })
          );
        }

        if (key === 'loadProject' && req.body?.variables?.id) {
          console.log(
            'OFFLINE: Request intercepted with response from local DB!'
          );
          return from(
            dbService
              .table('appState')
              .get('appState')
              .then((state: AppState) => {
                const project = state.projects.find(
                  (p) => p.id === req.body?.variables?.id
                );
                return new HttpResponse({
                  body: {
                    data: {
                      project,
                    },
                  },
                });
              })
          );
        }
        // we return empty response since all data should be in store already
        console.log('OFFLINE: Request intercepted with empty response!');
        return of(
          new HttpResponse({
            body: { data: null },
          })
        );
      } else {
        console.log('GET request!', req);
      }
    } else {
      const i = req.url.indexOf('auth0.com');
      if (i === -1) {
        const modifiedReq = req.clone({
          headers: req.headers.set('ngsw-bypass', 'true'),
        });
        return next.handle(modifiedReq);
      }
    }
    return next.handle(req);
  }
}
