/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import {
  BaseInterface,
  CertificationPartialInterface,
  DocumentInterface,
  GroupCertificationInterface,
  IndicationInterface,
  MessageInterface,
  ScenarioInterface,
  SiteInterface,
  VerificationStatus,
} from './certification.interfaces';

export enum AuditorType {
  AUDITOR = 'AUDITOR',
  WKS = 'WKS',
  SOFTWARE = 'SOFTWARE',
  NA = 'NA',
  MRV = 'MRV',
  LEGAL = 'LEGAL',
}

export enum EvidenceType {
  ADDITIONALITY_TOOL = 'ADDITIONALITY_TOOL',
  AGB = 'AGB',
  CONFIRM = 'CONFIRM',
  DATE = 'DATE',
  DEFAULTS_MANDATORY_TEXT = 'DEFAULTS_MANDATORY_TEXT', // confirm + text + documents
  DEFAULTS_OPTIONAL_TEXT = 'DEFAULTS_OPTIONAL_TEXT', // confirm + text + documents
  DOCUMENT = 'DOCUMENT',
  MANDATORY_TEXT = 'MANDATORY_TEXT', // version 0.4 only
  NA = 'NA',
  PRE_CERTIFICATION = 'PRE_CERTIFICATION',
  TEXT = 'TEXT', // version 0.4 only
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
}

export enum InputType {
  INDICATION = 'INDICATION',
  MAP = 'MAP',
  BASELINE = 'BASELINE',
  GROWTHMODEL = 'GROWTHMODEL',
  PROJECT = 'PROJECT',
  CERTIFICATION = 'CERTIFICATION',
  USER = 'USER',
  ORGANISATION = 'ORGANISATION',
  NA = 'NA',
  NEWS = 'NEWS',
  AGB = 'AGB',
}

export enum ReferenceType {
  SITES = 'SITES',
  CERTIFICATION_AREA = 'CERTIFICATION_AREA',
  LAND_OWNER = 'LAND_OWNER',
  PROJECT = 'PROJECT',
  CERTIFIER = 'CERTIFIER',
  CERTIFICATION_PROCESS = 'CERTIFICATION_PROCESS',
  EVA = 'EVA',
  REGISTRY_USER = 'REGISTRY_USER',
  METHOD_DEVELOPER = 'METHOD_DEVELOPER',
  PROJECT_SCENARIO = 'PROJECT_SCENARIO',
  BASELINE_SCENARIO = 'BASELINE_SCENARIO',
  NA = '',
}

export interface StandardDefinitionInterface {
  createdAt?: Date;

  version: string;

  revision: string;

  bufferShare: number;

  feeShare: number;

  principles: PrincipleInterface[];

  clarificationRequests: ClarificationRequestInterface[];
}

export interface PrincipleInterface {
  createdAt?: Date;

  version: string;

  nodeIndex: string;

  identifier: string;

  title: string;

  description: string;

  info: string;

  criterias: CriteriaInterface[];
}

export interface CriteriaInterface {
  createdAt?: Date;

  version: string;

  nodeIndex: string;

  identifier: string;

  title: string;

  description: string;

  info: string;

  parent: string;

  indicators: IndicatorInterface[];
}

export interface IndicatorInterface {
  createdAt?: Date;

  identifier: string;

  nodeIndex: string;

  title: string;

  description: string;

  info?: string;

  scopes: string[];

  evidenceProvidedBy?: string[];

  auditorType?: AuditorType;

  evidenceTypeInfo?: string;

  clarificationRequests?: string;
  clarificationRequestUids?: number[];

  inputType?: InputType;

  parent: string;

  reference?: ReferenceType;
}

export interface AuditorRequestInterface extends BaseInterface {
  certification?: CertificationPartialInterface;

  certificationId?: string | null;

  groupCertification?: GroupCertificationInterface;

  groupCertificationId?: string | null;

  auditorComment: string;

  internalAuditorRemarks: string;

  documents?: DocumentInterface[];

  messages?: MessageInterface[];

  reminderCount: number;

  sample: boolean;

  /**
   * input is valid
   */
  valid: boolean;

  /**
   * certifier reviewed the verificationStatus
   * the status itself is in property verificationStatus
   */
  reviewed: boolean;

  verificationStatus: VerificationStatus;

  initialVerificationStatus: VerificationStatus;

  sites?: SiteInterface[];

  growthModels?: ScenarioInterface[];

  baselines?: ScenarioInterface[];

  indication?: IndicationInterface | null;

  indicatorId?: string | null;
}

export interface ClarificationRequestInterface {
  uid: number;

  clarificationId: string;

  topic: string;

  date: Date | string;

  content: string;

  responses: ResponseInterface[];
}

export interface ResponseInterface {
  uid: number;

  topic: string;

  date: Date | string;

  content: string;

  status: string;

  isPublic: 1;
}
