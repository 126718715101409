<div class="relation-item-wrapper border-1 border-black-alpha-20 p-2">
  <div class="flex flex-row flex-wrap gap-2">
    <div
      class="relation-item-container w-20rem h-5rem border-1 border-black-alpha-20 p-2"
      [class.deleting]="relation === deletingRelation"
      *ngFor="let relation of relations">
      <eva-relation-item
        [showDeleteButton]="showDeleteButton && relation.type !== relationTypeEnumn.INDICATION && relation.type !== relationTypeEnumn.INDICATOR"
        [relation]="relation"
        (deleteClick)="onDeleteClicked($event)"></eva-relation-item>
    </div>
  </div>
  <p *ngIf="(relations?.length??0)===0" style="font-style: italic">
    Keine Bezug vorhanden
  </p>
</div>
