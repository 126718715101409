<p-pickList
  [sourceStyle]="{ height: '30rem' }"
  [targetStyle]="{ height: '30rem' }"
  breakpoint="1000px"
  [responsive]="true"
  [source]="filteredRelations"
  [target]="selectedRelations"
  sourceHeader="Bezug wählen"
  targetHeader="Zugeordnet"
  [dragdrop]="true"
  filterBy="label"
  [showSourceControls]="false"
  [showTargetControls]="false"
  [showTargetFilter]="false"
  (onMoveToTarget)="onItemAdded($event.items)"
  (onMoveToSource)="onItemRemoved($event.items)"
  (onMoveAllToSource)="onItemRemoved($event.items)"
  (onMoveAllToTarget)="onItemAdded($event.items)">
  <ng-template let-item pTemplate="sourceHeader">
    <p-tabMenu
      [scrollable]="true"
      [model]="relationTypes"
      [(activeItem)]="typeFilterSingle"
      (activeItemChange)="filterRelationTypes($event)"></p-tabMenu>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <eva-relation-item [relation]="item"></eva-relation-item>
  </ng-template>
</p-pickList>

<div *ngIf="dialogRef">
  <p-button
    label="OK"
    styleClass="p-button"
    (click)="closeClicked()"></p-button>
</div>
