/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { GeometryType } from '@eva/certification/api';
import * as turf from '@turf/turf';
import { Feature, FeatureCollection, Point } from 'geojson';
import { LngLat, LngLatBounds } from 'maplibre-gl';
import { environment } from '../../environments/environment';

export type MapProperties = {
  center: Point;
  zoom: number;
  bounds: LngLatBounds;
};

export const defaultFillColor = 'rgba(64, 192, 25, 0.2)';

export const defaultLineColor = 'rgba(71, 226, 48, 0.5)';

export const highlightedFillColor = 'rgba(32, 108, 158, 0.6)';

export const highlightedLineColor = 'rgba(243, 123, 17, 1)';

export const selectedLineColor = 'rgba(243, 220, 17, 1)';

export const excludedFillColor = 'rgba(90, 90, 90, 0.6)';

export const excludedLineColor = 'rgba(120, 120, 120, 1)';

export const lowZoomFillColor = 'rgba(113, 10, 217, 1)';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  getBounds(f: FeatureCollection) {
    if (f.features?.length) {
      const bounds = this.boundsFromFeature(f.features[0]);
      if (f.features.length > 0) {
        f.features.forEach((f: Feature) => this.boundsFromFeature(f, bounds));
      }
      return bounds;
    } else {
      return null;
    }
  }

  getStaticImageUrl(geometry: {
    coordinates: number[][] | number[][][];
    type: GeometryType;
  }): string {
    if (
      geometry.coordinates?.length > 0 &&
      geometry.coordinates[0]?.length > 0
    ) {
      const g = turf.simplify(geometry, {
        tolerance: 0.00001,
        highQuality: true,
      });
      const coordinates = g.coordinates[0];
      let c = '';
      if (!Array.isArray((coordinates as any)[0][0])) {
        c = coordinates.map((c) => (c as any)[1] + ',' + (c as any)[0]).join();
      } else if (Array.isArray(coordinates[0]) && coordinates[0]?.length > 0) {
        c = coordinates[0]
          .map((c) => (c as any)[1] + ',' + (c as any)[0])
          .join();
        // other coordinates represent holes in the polygon
        if (Array.isArray(coordinates[1])) {
          /**          this is not understand by typescript!         */
          // for(let i = 1; i < coordinates.length; i++) {
          //   if(Array.isArray(coordinates[i]) && coordinates[i].map) {
          //     c += ',' + coordinates[i].map((c) => c[1] + ',' + c[0]).join();
          //   }
          // }
          c +=
            ',' +
            coordinates[1]
              .map((c) => (c as any)[1] + ',' + (c as any)[0])
              .join();
        }
        if (Array.isArray(coordinates[2])) {
          c +=
            ',' +
            coordinates[2]
              .map((c) => (c as any)[1] + ',' + (c as any)[0])
              .join();
        }
      }
      return (
        'https://image.maps.ls.hereapi.com/mia/1.6/region?apiKey=' +
        environment.mapApiToken +
        '&a0=' +
        c +
        '&fc0=80ff8800&t=3'
      );
    } else {
      return '';
    }
  }

  getCenterForBounds(bounds: LngLatBounds) {
    return bounds.getCenter();
  }

  boundsFromFeature(
    f: Feature,
    bounds: LngLatBounds | null = null
  ): LngLatBounds | null {
    const [lng1, lat1, lng2, lat2] = turf.bbox(f);
    const validRange = (n: number) => n >= -90 && n <= 90;
    if (
      validRange(lng1) &&
      validRange(lat1) &&
      validRange(lng2) &&
      validRange(lat2)
    ) {
      if (!bounds) {
        return new LngLatBounds([
          new LngLat(lng1, lat1),
          new LngLat(lng2, lat2),
        ]);
      } else {
        bounds.extend(new LngLat(lng1, lat1));
        bounds.extend(new LngLat(lng2, lat2));
      }
    }
    return bounds;
  }

  isValidLocation(coordinates: string[]) {
    const germany = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            coordinates: [
              [
                [8.192515933538488, 55.25132499303538],
                [8.670765805718844, 53.93877436238955],
                [6.8201395442399075, 53.45899967634193],
                [5.76355896308641, 51.85423003861368],
                [5.671645425190604, 50.9844775266329],
                [5.996870506351513, 50.06808429935205],
                [6.563589829304817, 49.144039403957436],
                [7.9370808551738605, 48.97203730772421],
                [7.08328618234691, 47.97096424465124],
                [7.5933225186583115, 47.49552593044092],
                [8.720803622090557, 47.46327136956256],
                [9.794595149169652, 47.44310221329823],
                [10.209762193302652, 47.20857455622027],
                [12.114306946761388, 47.54060557004823],
                [13.056857287196664, 47.3994668362337],
                [13.635511610121995, 48.39533309275245],
                [14.01424875221528, 48.71051419318141],
                [12.809216038494327, 49.55360730751923],
                [12.463216546436229, 50.17653518827052],
                [14.497454939400711, 50.821604560835794],
                [15.028385194456575, 50.76504084650105],
                [15.159626381099372, 50.91948622731081],
                [15.147695364132943, 51.343986706454984],
                [14.789764855106569, 51.78896569306795],
                [14.789764855106569, 52.546347133309325],
                [14.240938074599711, 52.90042760464263],
                [14.533247990309292, 53.28871354256174],
                [14.288662142474692, 53.9819620379383],
                [13.51911154806885, 54.78444272323125],
                [12.087389511964375, 54.40426573421351],
                [10.019510821167728, 54.86348616367604],
                [8.192515933538488, 55.25132499303538],
              ],
            ],
            type: 'Polygon',
          },
        },
      ],
    };

    const point = turf.point(coordinates.map(Number));
    const polygon = turf.polygon(germany.features[0].geometry.coordinates);

    const res = turf.booleanPointInPolygon(point, polygon);
    return res;
  }
}
