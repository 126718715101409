<div class="field col-12 ml-auto" style="width: min-content; height: 3rem">
  @if (auditorRequestGroupId) {
  <p-button
    data-cy="save-button"
    [disabled]="disabled"
    (click)="saveClick(true)"
    >Speichern</p-button
  >
  } @else if(!groupCertificationId || !certificationId) {
  <p-button
    data-cy="save-button"
    [disabled]="disabled"
    (click)="saveClick(!!groupCertificationId)"
    >Speichern</p-button
  >
  }@else{
  <p-splitButton
    data-cy="save-group-button"
    [disabled]="disabled"
    label="Speichern"
    (onClick)="saveClick(!!groupCertificationId)"
    [model]="saveButtons"
    [appendTo]="'body'" />
  }
</div>
