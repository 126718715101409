/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import {
  CreateSystemMessageGQL,
  LoadSystemDataGQL,
  SystemDataFragment,
  SystemMessageFragment,
  SystemMessageInput,
  UpdateSystemMessageGQL,
} from '@eva/data-access/graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SystemService {
  constructor(
    private loadSystemDataQuery: LoadSystemDataGQL,
    private createSystemMessageMutation: CreateSystemMessageGQL,
    private updateSystemMessageMutation: UpdateSystemMessageGQL
  ) {}

  loadSystemData(): Observable<{ getSystemData: SystemDataFragment } | null> {
    return this.loadSystemDataQuery
      .watch()
      .valueChanges.pipe(map((result) => this.extractData(result)));
  }

  createSystemMessage(
    input: SystemMessageInput
  ): Observable<{ createSystemMessage: SystemMessageFragment } | null> {
    return this.createSystemMessageMutation
      .mutate({ input })
      .pipe(map((result) => this.extractData(result)));
  }

  updateSystemMessage(
    id: string,
    input: SystemMessageInput
  ): Observable<{ updateSystemMessage: SystemMessageFragment } | null> {
    return this.updateSystemMessageMutation
      .mutate({ id, input })
      .pipe(map((result) => this.extractData(result)));
  }

  private extractData<T>(result: ApolloQueryResult<T> | FetchResult<T>) {
    if (result.data === undefined || result.errors?.length) {
      if (result.errors) {
        console.log('error', result);
      }
      return null;
    } else {
      return result.data;
    }
  }
}
