/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization.service';

@Injectable({ providedIn: 'root' })
export class AfterLoginPreloadingStrategy implements PreloadingStrategy {
  constructor(private authorizationService: AuthorizationService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // If the route was opt-out by the preload property of the route data object, we skip the preloading
    if (!route.data?.preload === false) {
      return of(null);
    }

    return this.authorizationService
      .isCurrentUserAuthorized(route?.data?.roles)
      .pipe(
        switchMap((authorized) => {
          if (!authorized) {
            return of(null);
          }
          return load().pipe(catchError(() => of(null)));
        })
      );
  }
}
