/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Component } from '@angular/core';
import { DocumentSimpleFragment } from '@eva/data-access/shared';
import { DialogData } from '@eva/ng-base';
import { Store } from '@ngxs/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription, combineLatest, map } from 'rxjs';
import { FileService } from '../../../services/api/file.service';
import { AppState } from '../../../states/app.state';

export interface LinkDocumentDialogData {
  existingRelations: DocumentSimpleFragment[];
  auditorRequestGroupId: string | boolean | undefined;
}

@Component({
  selector: 'eva-link-documents-dialog',
  templateUrl: './link-documents-dialog.component.html',
  styleUrls: ['./link-documents-dialog.component.scss'],
})
export class LinkDocumentsDialogComponent {
  documents: DocumentSimpleFragment[] = [];
  selectedDocuments: DocumentSimpleFragment[] = [];
  _subscriptions = new Subscription();

  constructor(
    private store: Store,
    private fileService: FileService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig<
      DialogData<LinkDocumentDialogData>
    >
  ) {
    this._subscriptions.add(
      combineLatest([
        this.store.select(AppState.currentGroupCertification),
        this.store.select(AppState.currentCertification),
      ])
        .pipe(
          map(([groupCertification, singleCertification]) =>
            this.distinct(
              [
                ...(groupCertification?.documents ?? []),
                ...(singleCertification?.documents ?? []),
              ],
              (a, b) => a.id === b.id
            )
          )
        )
        .subscribe((docs) => {
          this.documents = docs.filter((doc) => {
            if (this.dialogConfig.data?.auditorRequestGroupId) {
              return !!doc.groupCertificationId;
            }
            return true;
          });
          this.selectedDocuments = this.documents.filter((doc) =>
            (this.dialogConfig?.data?.existingRelations ?? [])
              .map((existingDoc) => existingDoc.id)
              .includes(doc.id)
          );
        })
    );

    if (dialogConfig.data) {
      dialogConfig.data.actions = [
        {
          text: 'Übernehmen',
          icon: {
            name: 'pi pi-check',
          },
          action: () => {
            this.submit();
            return Subscription.EMPTY;
          },
          dataCy: 'assume-document',
        },
      ];
    }
  }

  distinct<T>(data: T[], compareableFunction: (a: T, b: T) => boolean): T[] {
    return data?.filter((thing, i, arr) => {
      const found = arr.find((t) => compareableFunction(t, thing));
      return (found ? arr?.indexOf(found) : -1) === i;
    });
  }

  submit() {
    this.dialogRef.close(this.selectedDocuments);
  }

  formatSize(size: number) {
    return this.fileService.formatSize(size);
  }

  public static joinDocumentsForm = (
    initialDocuments: DocumentSimpleFragment[],
    editDocuments: DocumentSimpleFragment[]
  ): (DocumentSimpleFragment & {
    relationDelete?: boolean;
    relationNew?: boolean;
  })[] => {
    // removed relations
    const removedRelations = initialDocuments.filter(
      (initialDocument) =>
        !editDocuments.find((ed) => initialDocument.id === ed.id)
    );

    // new relations
    const newRelations = editDocuments
      .filter(
        (editDocument) =>
          !initialDocuments.find((id) => id.id === editDocument.id)
      )
      .map((newRelation) => {
        return {
          ...newRelation,
          relationNew: true,
        } as DocumentSimpleFragment & {
          relationDelete?: boolean;
          relationNew?: boolean;
        };
      });

    const resultRelations = [
      ...removedRelations.map((rr) => {
        return { ...rr, relationDelete: true };
      }),
      ...newRelations,
    ];
    return resultRelations;
  };
}
