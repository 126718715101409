/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'eva-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnInit, OnDestroy {
  loading = false;
  subscription = new Subscription();

  constructor(
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.subscription.add(
        this.loadingService.loadingStatus.subscribe((value) => {
          this.loading = value.some((ls) => !ls.loaded);
          this.changeDetectorRef.detectChanges();
        })
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
