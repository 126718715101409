/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
  DialogService as PrimeNgDialogService,
} from 'primeng/dynamicdialog';
import { Severity } from '../../model';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { DialogFooterComponent } from '../components/footer/dialog-footer.component';
import { DialogData } from '../model/dialog-data';

@Injectable()
export class DialogService {
  constructor(private dialogService: PrimeNgDialogService) {}

  public openDialogWithComponent<R, T = unknown>(
    component: ComponentType<T>,
    config: DynamicDialogConfig<DialogData<R>>
  ): DynamicDialogRef<T> {
    // merge configurations and set default options
    config = {
      focusOnShow: false,
      ...config,
    };

    // set default footer, if no template is given
    if (!config.templates) {
      config.templates = {};
    }

    if (!config.templates.footer) {
      config.templates.footer = DialogFooterComponent;
    }

    if (!config.data) {
      config.data = {} as DialogData<R>;
    }

    // open the dialog and return the reference
    return this.dialogService.open(component, config);
  }

  public openConfirmDialog(
    config: DynamicDialogConfig<
      DialogData<{ message: string; severity?: Severity; cancel?: boolean }>
    >
  ): DynamicDialogRef {
    // merge configurations and set default options
    config = {
      ...config,
      focusOnShow: false,
      contentStyle: { 'max-width': '50rem' },
    };

    // set default footer, if no template is given
    if (!config.templates) {
      config.templates = {};
    }

    config.templates.footer = DialogFooterComponent;

    return this.openDialogWithComponent<
      DialogData<{ message: string; severity?: Severity; cancel?: boolean }>
    >(ConfirmDialogComponent, config);
  }
}
