<p-selectButton
  [options]="VerificationStatusArray"
  [(ngModel)]="verificationStatus"
  (ngModelChange)="vsChange($event)"
  optionLabel="label"
  [allowEmpty]="false"
  optionValue="value">
  <ng-template let-item pTemplate>
    <ng-container
      *ngTemplateOutlet="vsStatusTemplate; context{$implicit: item}">
    </ng-container>
  </ng-template>
</p-selectButton>

<ng-template #vsStatusTemplate let-item>
  <div
    class="verification-status status-item"
    [ngClass]="item.value"
    [pTooltip]="getFullName(item.label)">
    {{auditorRequestHelper.getShortName(item.value)}}
  </div>
</ng-template>
