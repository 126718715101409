/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import {
  CreateCertificationInput,
  GroupCertification,
  ProjectSimpleFragment,
  UpdateAuditDataInput,
  UpdateCertificationInput,
  UpdateCertificationStatusInput,
  UpdateCertifierInput,
  UpdateIndicationInput,
} from '@eva/data-access/shared';

import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { attachAction } from '../attach-actions';

import { catchError, of, take, tap, throwError } from 'rxjs';
import { CertificationApiService } from '../../services/api/certification.api.service';
import {
  AppState,
  mergeGroupdataToChildCertifications,
  updateCertificationInStateContext,
  updatePartialGroupCertificationInState,
} from '../app.state';

/**
 * helper function called in state constructor
 * attach all actions from this file
 */
export const loadCertificationActions = (
  certificationService: CertificationApiService
) => {
  attachAction(
    AppState,
    LoadCertificationAction,
    loadCertificationAction(certificationService)
  );
  attachAction(
    AppState,
    LoadGroupCertificationAction,
    loadGroupCertificationAction(certificationService)
  );
  attachAction(
    AppState,
    UnsetCurrentCertificationAction,
    unsetCurrentCertificationAction()
  );
  attachAction(
    AppState,
    ListCertificationsAction,
    listCertificationsAction(certificationService)
  );
  attachAction(
    AppState,
    CreateCertificationAction,
    createCertificationAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateCertificationAction,
    updateCertificationAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateAuditDataAction,
    updateAuditDataAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateCertificationStatusAction,
    updateCertificationStatusAction(certificationService)
  );
  attachAction(
    AppState,
    DeleteCertificationAction,
    deleteCertificationAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateIndicationAction,
    updateIndicationAction(certificationService)
  );
  attachAction(
    AppState,
    LoadTreesAction,
    loadTreesAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateCertifierAction,
    updateCertifierAction(certificationService)
  );
};

export class LoadCertificationsAction {
  static readonly type = '[Certification] LoadCertifications';
}

export class ListCertificationsAction {
  static readonly type = '[Certification] ListCertifications';
}

export const listCertificationsAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>) => {
    return certificationService.listCertifications().pipe(
      tap((data) => {
        if (data?.certifications) {
          stateContext.patchState({ certifications: data.certifications });
        }
      })
    );
  };

export class LoadCertificationAction {
  static readonly type = '[Certification] Load';
  constructor(public payload: string) {}
}

export const loadCertificationAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: LoadCertificationAction) => {
    const state = stateContext.getState();
    if (state.treeSpecies.length === 0) {
      stateContext.dispatch(new LoadTreesAction());
    }
    if (
      state.currentGroupCertification &&
      state.currentGroupCertification.certifications
    ) {
      // if the certification belongs to a loaded group certification
      // set the already loaded certification as current certification
      const certification = state.currentGroupCertification.certifications.find(
        (c) => c.id === action.payload
      );
      if (certification) {
        stateContext.patchState({ currentCertification: certification });
        return of(certification);
      }
    }
    return certificationService.loadCertification(action.payload).pipe(
      take(1), // needed to complete Observable
      tap((data) => {
        if (data?.certification) {
          updateCertificationInStateContext(stateContext, data.certification);
          if (data.certification.groupId && !state.currentGroupCertification) {
            stateContext.dispatch(
              new LoadGroupCertificationAction(data.certification.groupId)
            );
          }
        }
      })
    );
  };

export class LoadGroupCertificationAction {
  static readonly type = '[GroupCertification] Load';
  constructor(public payload: string | null | undefined) {}
}

export const loadGroupCertificationAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: LoadGroupCertificationAction
  ) => {
    if (action.payload) {
      return certificationService.loadGroupCertification(action.payload).pipe(
        tap((data) => {
          const loadedGroupCertification =
            data?.groupCertification as GroupCertification;
          if (loadedGroupCertification) {
            stateContext.setState(
              patch({
                currentGroupCertification:
                  data?.groupCertification as GroupCertification,
              })
            );
            mergeGroupdataToChildCertifications(
              stateContext,
              loadedGroupCertification
            );
          }
        })
      );
    } else {
      stateContext.patchState({ currentGroupCertification: undefined });
    }
  };

export class ReLoadCurrentCertificationAction {
  static readonly type = '[Certification] ReloadCurrent';
}

export class UnsetCurrentCertificationAction {
  static readonly type = '[Certification] UnsetCurrent';
}

export const unsetCurrentCertificationAction =
  () => (stateContext: StateContext<AppState>) => {
    stateContext.patchState({ currentCertification: undefined });
  };

export class CreateCertificationAction {
  static readonly type = '[Certification] Create';
  constructor(public payload: CreateCertificationInput) {}
}

export const createCertificationAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: CreateCertificationAction) => {
    return certificationService.createCertification(action.payload).pipe(
      tap((newCertification) => {
        if (newCertification !== null && newCertification?.id) {
          stateContext.patchState({
            currentCertification: newCertification,
          });
          const state = stateContext.getState();
          const relatedProject = state.projects.find(
            (p) => p.id === newCertification.project?.id
          );
          if (relatedProject) {
            const updatedProject = {
              ...relatedProject,
              certifications: relatedProject.certifications
                ? [...relatedProject.certifications, newCertification]
                : [newCertification],
            };
            stateContext.setState(
              patch({
                projects: updateItem<ProjectSimpleFragment>(
                  (project) => project.id === newCertification.project?.id,
                  updatedProject
                ),
              })
            );
          }
        }
      }),
      catchError((error: string) => {
        console.log('catchError', error);
        return of(null);
      })
    );
  };

export class UpdateCertificationAction {
  static readonly type = '[Certification] Update';
  constructor(public payload: UpdateCertificationInput) {}
}

export const updateCertificationAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: UpdateCertificationAction) => {
    return certificationService.updateCertification(action.payload).pipe(
      tap((updatedCertification) => {
        if (updatedCertification) {
          updateCertificationInStateContext(stateContext, updatedCertification);
          if (
            stateContext.getState().currentGroupCertification?.id ===
            updatedCertification.groupId
          ) {
            updatePartialGroupCertificationInState(
              stateContext,
              stateContext.getState().currentGroupCertification
            );
          }
        }
      })
    );
  };

export class UpdateAuditDataAction {
  static readonly type = '[Certification] AuditUpdate';
  constructor(public payload: UpdateAuditDataInput) {}
}

export const updateAuditDataAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: UpdateCertificationAction) => {
    return certificationService.updateAuditData(action.payload).pipe(
      tap((updatedCertification) => {
        if (updatedCertification) {
          updateCertificationInStateContext(stateContext, updatedCertification);
          if (
            stateContext.getState().currentGroupCertification?.id ===
            updatedCertification.groupId
          ) {
            updatePartialGroupCertificationInState(
              stateContext,
              stateContext.getState().currentGroupCertification
            );
          }
        }
      })
    );
  };

export class DeleteCertificationAction {
  static readonly type = '[Certification] Delete';
  constructor(public id: string) {}
}

export const deleteCertificationAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: DeleteCertificationAction) => {
    return certificationService.deleteCertification(action.id).pipe(
      take(1), // needed to complete Observable
      tap((data) => {
        if (data?.deleteCertification) {
          // remove certification from group certification
          const currentGroupCertification =
            stateContext.getState().currentGroupCertification;
          if (currentGroupCertification) {
            stateContext.setState(
              patch({
                currentGroupCertification: {
                  ...currentGroupCertification,
                  certifications:
                    currentGroupCertification.certifications.filter(
                      (c) => c.id !== action.id
                    ),
                },
              })
            );
          }

          // remove certification from all projects
          const allProjects = stateContext.getState().projects;
          const projects = allProjects.filter((p) =>
            p.certifications?.find((c) => c.id === action.id)
          );
          projects.forEach((project) => {
            const updatedProject = {
              ...project,
              certifications: project.certifications?.filter(
                (c) => c.id !== action.id
              ),
            };
            stateContext.setState(
              patch({
                projects: updateItem<ProjectSimpleFragment>(
                  (p) => p.id === project.id,
                  updatedProject
                ),
              })
            );
          });

          // remove certification from current project
          const currentProject = stateContext.getState().currentProject;
          if (
            currentProject &&
            currentProject.certifications?.find((c) => c.id === action.id)
          ) {
            stateContext.setState(
              patch({
                currentProject: {
                  ...currentProject,
                  certifications: currentProject.certifications?.filter(
                    (c) => c.id !== action.id
                  ),
                },
              })
            );
          }

          // remove certification from certifications
          // and unset current certification
          stateContext.setState(
            patch({
              currentCertification: undefined,
              certifications: stateContext
                .getState()
                .certifications.filter((c) => c.id !== action.id),
            })
          );
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  };

export class UpdateIndicationAction {
  static readonly type = '[Indication] Update';
  constructor(public input: UpdateIndicationInput) {}
}

export const updateIndicationAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: UpdateIndicationAction) => {
    return certificationService.updateIndication(action.input).pipe(
      tap((f) => console.log('UpdateIndicationAction', f)),
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
          if (certification.group) {
            updatePartialGroupCertificationInState(
              stateContext,
              certification.group
            );
          }
        }
      })
    );
  };

export class UpdateCertifierAction {
  static readonly type = '[Certification] UpdateCertifier';
  constructor(public payload: UpdateCertifierInput) {}
}

export const updateCertifierAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: UpdateCertifierAction) => {
    return certificationService.updateCertifier(action.payload).pipe(
      tap((data) => {
        if (data?.certification) {
          updateCertificationInStateContext(stateContext, data.certification);
        }
      })
    );
  };

export class UpdateCertificationStatusAction {
  static readonly type = '[Certification] UpdateStatus';
  constructor(public input: UpdateCertificationStatusInput) {}
}

export const updateCertificationStatusAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: UpdateCertificationStatusAction
  ) => {
    return certificationService.updateCertificationStatus(action.input).pipe(
      tap((data) => {
        if (data.updateCertificationStatus) {
          updateCertificationInStateContext(
            stateContext,
            data.updateCertificationStatus
          );
        }
      })
    );
  };

/**
 * TODO: move these actions to a own file
 */
export class LoadTreesAction {
  static readonly type = '[Certification] LoadTrees';
}

export const loadTreesAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>) => {
    return certificationService.loadTrees().pipe(
      tap((data) => {
        if (data?.getTreeSpecies) {
          stateContext.patchState({ treeSpecies: data.getTreeSpecies });
        }
      })
    );
  };
