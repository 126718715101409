/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { UpdateOrganisationInput } from '@eva/data-access/shared';

export class UpdateOrganisationAction {
  static readonly type = '[Organisation] Update';
  constructor(public input: UpdateOrganisationInput) {}
}
