/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Component, OnDestroy } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { Subscription } from 'rxjs';
import { NewDocumentsDialogData } from '../new-documents-dialog.component';

@Component({
  selector: 'eva-new-documents-dialog-footer',
  templateUrl: './new-documents-dialog-footer.component.html',
  standalone: true,
  imports: [ButtonModule, SplitButtonModule],
})
export class NewDocumentsDialogFooterComponent implements OnDestroy {
  disabled = false;

  groupCertificationId: string | undefined;
  certificationId: string | undefined;
  auditorRequestGroupId: string | boolean | undefined;

  private subscription: Subscription | undefined;

  constructor(
    private dialogConfig: DynamicDialogConfig<NewDocumentsDialogData>
  ) {
    this.groupCertificationId = this.dialogConfig.data?.groupCertificationId;
    this.certificationId = this.dialogConfig.data?.certificationId;
    this.auditorRequestGroupId = this.dialogConfig.data?.auditorRequestGroupId;

    this.subscription = this.dialogConfig.data?.disabled?.subscribe(
      (disabled) => {
        this.disabled = disabled;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  saveButtons = [
    {
      label: 'Nur für diese Zertifizierung speichern',
      command: () => {
        this.saveClick(false);
      },
    },
  ];

  async saveClick(useGroupCertificationId = false) {
    if (this.dialogConfig.data?.onSave) {
      this.dialogConfig.data?.onSave(useGroupCertificationId);
    }
  }
}
