<div class="waiting" *ngIf="showRedirectScreen && !showTimeout">
  <h5 class="text-primary" i18n="@@redirect-to-login">
    Weiterleitung zum Login...
  </h5>
</div>
<div class="waiting" *ngIf="showLoading && !showTimeout">
  <div class="center-container">
    <div class="logo"></div>
    <div class="text">Daten werden geladen...</div>
  </div>
</div>
<div class="waiting" *ngIf="showTimeout">
  <div class="logo logo-error"></div>
  <div class="center-container">
    <h1 class="text-7xl text-white" i18n="@@server-timeout.oops">Ooops!</h1>
    <div class="text text-error" i18n="@@server-timeout.text">
      Aktuell können wir den Server leider nicht erreichen. Versuchen Sie es
      später nochmal oder kontaktieren Sie den Support.
    </div>
  </div>
</div>
<div *ngIf="!showLoading && !showRedirectScreen" id="loadedIndicator"></div>

<div class="h-full w-full">
  <router-outlet></router-outlet>
</div>

<p-confirmDialog></p-confirmDialog>
<p-confirmDialog #infoDialog key="info" styleClass="info-confirm-dialog">
  <ng-template pTemplate="header">
    <span class="p-dialog-title">
      <i [ngClass]="infoDialog.confirmation?.icon"> </i>{{
      infoDialog.confirmation?.header }}</span
    >
  </ng-template>
</p-confirmDialog>

<p-confirmDialog #warnDialog key="warn" styleClass="warn-confirm-dialog">
  <ng-template pTemplate="header">
    <span class="p-dialog-title">
      <i [ngClass]="warnDialog.confirmation?.icon"> </i>{{
      warnDialog.confirmation?.header }}</span
    >
  </ng-template>
</p-confirmDialog>

<p-confirmDialog #deleteDialog key="delete" styleClass="delete-confirm-dialog">
  <ng-template pTemplate="header">
    <span class="p-dialog-title">
      <i [ngClass]="deleteDialog.confirmation?.icon"> </i>{{
      deleteDialog.confirmation?.header }}</span
    >
  </ng-template>
</p-confirmDialog>

<div id="primaryColorHelper"></div>
<div id="accentColorHelper"></div>

<div id="errorRedColorHelper"></div>
<div id="color1GrayHelper"></div>
<div id="color2GreenHelper"></div>
<div id="color2GreenLightHelper"></div>
<div id="color3TurkisHelper"></div>
<div id="color4BlueHelper"></div>
<div id="color5PinkHelper"></div>
