/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentCategory } from '@eva/certification/api';
import {
  AuditorRequest,
  Certification,
  DocumentSimpleFragment,
  MessageFragment,
  Role,
} from '@eva/data-access/shared';
import { Store } from '@ngxs/store';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { MessagingService } from '../../../../services/messaging.service';
import { UpdateAuditorRequestDocumentsAction } from '../../../../states/actions';
import { AppState } from '../../../../states/app.state';
import { LinkDocumentsDialogComponent } from '../../link-documents-dialog/link-documents-dialog.component';
import {
  auditorRequestRelation,
  AuditorRequestService,
} from '../auditor-request.service';
import { AuditorRequestMessagesComponent } from '../messages/auditor-request-messages.component';

const DEFAULT_DURATION = 300;

export interface AuditorRequestDisplayDialogData {
  certification: Certification;
  auditorRequest: AuditorRequest | undefined;
  showRelationSelection: boolean;
  collapsable: boolean;
  editMode: boolean;
  collapsed: boolean;
  submitMessage?: EventEmitter<MessageFragment>;
}

/**
 * component to display a single AuditorRequest in a
 * list of items, showing a form to append messages
 * if appropriate
 */
@Component({
  selector: 'eva-auditor-request-display',
  templateUrl: './auditor-request-display.component.html',
  styleUrls: ['./auditor-request-display.component.scss'],
  animations: [
    trigger('collapse', [
      state(
        'false',
        style({
          height: AUTO_STYLE,
          visibility: AUTO_STYLE,
          margin: '0.25rem 0.25rem',
        })
      ),
      state(
        'true',
        style({ height: '0', visibility: 'hidden', margin: '0 0.25rem' })
      ),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
})
export class AuditorRequestDisplayComponent implements OnDestroy, OnChanges {
  @Input() certification: Certification;
  @Input() auditorRequest: AuditorRequest | undefined;
  @Input() showRelationSelection = true;
  @Input() collapsable = true;
  @Input() editMode = false;
  @Input() collapsed = true;
  @Output() editClicked = new EventEmitter<AuditorRequest>();
  @Output() submitMessage = new EventEmitter<MessageFragment>();
  @Output() messageFormDirty = new EventEmitter<boolean>();

  @ViewChild('messageComponent')
  messageComponent: AuditorRequestMessagesComponent;

  selectedRelations: auditorRequestRelation[] = [];
  isAuditorView = false;
  isAdminView = false;
  documentCategoryEnum = DocumentCategory;
  documentCategories: DocumentCategory[] = [];
  indicationId: string | null;

  private _subscriptions: Subscription = new Subscription();

  constructor(
    private store: Store,
    public auditorRequestHelper: AuditorRequestService,
    public config: DynamicDialogConfig<AuditorRequestDisplayDialogData>,
    private messagingService: MessagingService
  ) {
    this._subscriptions.add(
      this.store.select(AppState.authenticatedUser).subscribe((user) => {
        if (user?.role === Role.AUDITOR) {
          this.isAuditorView = true;
        } else if (user?.role === Role.ADMIN) {
          this.isAdminView = true;
        }
      })
    );

    if (config.data) {
      this.auditorRequest = config.data.auditorRequest;
      this.certification = config.data.certification;
      this.collapsable = config.data.collapsable;
      this.getAllAuditorRequestRelation(this.auditorRequest);
      this.collapsed = config.data.collapsed;
      this.showRelationSelection = config.data.showRelationSelection;

      this.editMode = config.data.editMode;

      if (config.data.submitMessage) {
        this.submitMessage = config.data.submitMessage;
      }
    }
  }

  getAllAuditorRequestRelation(auditorRequest: AuditorRequest | undefined) {
    if (auditorRequest) {
      this.selectedRelations =
        this.auditorRequestHelper.setupData(this.certification, auditorRequest)
          ?.selectedItems ?? [];
    } else {
      this.selectedRelations = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.auditorRequest) {
      this.getAllAuditorRequestRelation(this.auditorRequest);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onEditClicked() {
    this.editClicked.emit(this.auditorRequest);
  }

  saveMessage(event: MessageFragment) {
    this.submitMessage.emit(event);
  }

  setWizardStatus(isDirty: boolean) {
    this.messageFormDirty.emit(isDirty);
  }

  protected onDocumentsLinked(linkedDocuments: DocumentSimpleFragment[]) {
    if (this.certification?.id && this.auditorRequest?.id) {
      const action = new UpdateAuditorRequestDocumentsAction({
        certificationId: this.certification?.id,
        auditorRequestId: this.auditorRequest?.id,
        documentRelations: LinkDocumentsDialogComponent.joinDocumentsForm(
          this.auditorRequest?.documents ?? [],
          linkedDocuments
        )
          .filter((doc) => doc.relationDelete || doc.relationNew)
          .map((dr) => ({
            documentId: dr.id,
            delete: dr.relationDelete,
          })),
      });

      this.store.dispatch(action).subscribe(() => {
        this.messagingService.success(
          '',
          $localize`:@@documents.saved_and_linked: Die Dokumente wurden gespeichert und verlinkt`
        );
      });
    }
  }

  protected unLinkDocument(document: DocumentSimpleFragment) {
    if (this.certification?.id && this.auditorRequest?.id) {
      const action = new UpdateAuditorRequestDocumentsAction({
        certificationId: this.certification?.id,
        auditorRequestId: this.auditorRequest?.id,
        documentRelations: LinkDocumentsDialogComponent.joinDocumentsForm(
          [document],
          []
        )
          .filter((doc) => doc.relationDelete || doc.relationNew)
          .map((dr) => ({
            documentId: dr.id,
            delete: dr.relationDelete,
          })),
      });

      this.store.dispatch(action).subscribe(() => {
        this.messagingService.success(
          '',
          $localize`:@@documents.link_removed: Die Verlinkung für das Dokument ${document.name} wurde entfernt`
        );
      });
    }
  }
}
