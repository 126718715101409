/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { of } from 'rxjs';
import { Severity } from '../../../model';
import { DialogData } from '../../model/dialog-data';

@Component({
  selector: 'eva-confirm-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss',
})
export class ConfirmDialogComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<
      DialogData<{
        message: string;
        severity: Severity;
        cancel: boolean;
      }>
    >
  ) {
    if (this.config.data) {
      this.config.data.actions = [
        {
          text: this.config.data.acceptLabel ?? 'Ok',
          i18nLabel: '@@ok',
          icon: {
            name: 'pi pi-check',
          },
          severity: this.config.data.severity,
          dataCy: 'confirm',
          action: () => {
            return of(true).subscribe((confimed) => this.ref.close(confimed));
          },
        },
      ];

      if (this.config.data.cancel === true) {
        this.config.data.actions.unshift({
          text: this.config.data.rejectLabel ?? 'Abbrechen',
          i18nLabel: '@@cancel',
          type: 'outlined',
          icon: {
            name: 'pi pi-times',
          },
          dataCy: 'reject',
          action: () => {
            return of(false).subscribe((confimed) => this.ref.close(confimed));
          },
        });
      }
    }
  }
}
