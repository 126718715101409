/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, concat, map, of } from 'rxjs';
import { ButtonComponent } from '../../../button';
import { DialogAction } from '../../model/dialog-action';

@Component({
  selector: 'eva-dialog-action',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './dialog-action.component.html',
})
export class DialogActionComponent implements OnInit {
  @Input({ required: true }) action: DialogAction | undefined;

  /**
   * Disabled Stream for button disabled state
   */
  public disabled$!: Observable<boolean>;

  ngOnInit(): void {
    this.disabled$ = this.action?.disable
      ? this.getDisabledStream(this.action.disable())
      : of(false);
  }

  private getDisabledStream(
    dialogDisable: Observable<boolean> | FormGroup | FormControl
  ): Observable<boolean> {
    if (
      dialogDisable instanceof FormGroup ||
      dialogDisable instanceof FormControl
    ) {
      // set inital value
      const initialValue = of(dialogDisable.pristine || dialogDisable.invalid);

      // setting the status change stream and map the result
      const statusChange = dialogDisable.statusChanges.pipe(
        map((status) => {
          return dialogDisable.pristine ? true : status === 'INVALID';
        })
      );

      // emit the initial value first and afterwards emit the status changes from the group / control
      return concat(initialValue, statusChange);
    }

    return dialogDisable;
  }
}
