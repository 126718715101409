/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { CertificationProcessService } from '@eva/certification/service';
import {
  AuditorRequest,
  AuditorRequestInput,
  AuditorRequestUpdateInput,
  Baseline,
  Certification,
  CertificationFragment,
  Document,
  DocumentCategory,
  DocumentRelationInput,
  DocumentSimpleFragment,
  GrowthModel,
  Indication,
  Role,
  Site,
  User,
  VerificationStatus,
} from '@eva/data-access/shared';
import { DialogData, DialogService } from '@eva/ng-base';
import { Store } from '@ngxs/store';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { debounceTime, Subscription } from 'rxjs';
import { AuthorizationService } from '../../../../services/authorization.service';
import { MessagingService } from '../../../../services/messaging.service';
import {
  CreateAuditorRequestAction,
  DeleteAuditorRequestAction,
  UpdateAuditorRequestAction,
} from '../../../../states/actions/auditor-actions';
import { AppState } from '../../../../states/app.state';
import { LinkDocumentsDialogComponent } from '../../link-documents-dialog/link-documents-dialog.component';
import {
  auditorRequestRelation,
  AuditorRequestService,
  RELATION_TYPE,
} from '../auditor-request.service';
import { AuditorRequestMessagesComponent } from '../messages/auditor-request-messages.component';
import {
  RelationSelectionComponent,
  RelationSelectionDialogData,
} from '../relation-selection/relation-selection.component';

type AuditorRequestForm = {
  sample: FormControl<boolean>;
  auditorComment: FormControl<string | null | undefined>;
  internalAuditorRemarks: FormControl<string | null | undefined>;
  valid: FormControl<boolean | null | undefined>;
  reviewed: FormControl<boolean | null | undefined>;
  documents: FormControl<DocumentSimpleFragment[]>;
  applyToGroup: FormControl<boolean | null | undefined>;
};

export interface AuditorRequestEditorDialogData {
  certification: CertificationFragment;
  indication: Indication | undefined;
  showRelationSelection: boolean;
  showVerificationSelection: boolean;
  sampleMode: boolean;
  restrictedStatusSelection: boolean;
  allowEmptyTexts: boolean;
  auditorRequest: AuditorRequest;
  editMode: boolean;
}

/**
 * component to edit AuditorRequests, used in dialogs or
 * as inline component in indication wizard
 */
@Component({
  selector: 'eva-auditor-request-editor',
  templateUrl: './auditor-request-editor.component.html',
  styleUrls: ['./auditor-request-editor.component.scss'],
})
export class AuditorRequestEditorComponent
  implements OnDestroy, OnChanges, AfterViewInit, OnInit
{
  _subscriptions = new Subscription();
  documentCategoryEnum = DocumentCategory;

  VerificationStatusEnum = VerificationStatus;
  VerificationStatusArray = Object.entries(VerificationStatus).map(
    ([k, v]) => ({
      value: v,
      label: v,
    })
  );

  @Input() certification: Certification | undefined;
  @Input() auditorRequest: AuditorRequest = {} as AuditorRequest;
  @Input() indication: Indication | undefined;
  @Input() showRelationSelection = true;
  @Input() showVerificationSelection = true;
  @Input() sampleMode = false;
  @Input() restrictedStatusSelection = false;
  @Input() allowEmptyTexts = false;
  @Input() editMode = false;
  @Input() verificationStatus = VerificationStatus.NONE;
  @Input() showInline = false;
  @Output() formDirty = new EventEmitter<boolean>();

  @ViewChild('messageComponent')
  messageComponent: AuditorRequestMessagesComponent;

  isAuditorView = false;

  auditorRequestForm: FormGroup<AuditorRequestForm>;
  selectedRelations: auditorRequestRelation[] = [];
  deletingRelation: auditorRequestRelation | null;
  setValidationStatus = false;
  currentUser: User | undefined;

  showApplyToGroup = false;
  showReviewCheckbox = true;

  _skipEmitChanges = true;

  constructor(
    private store: Store,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<
      DialogData<AuditorRequestEditorDialogData>
    >,
    private formBuilder: FormBuilder,
    private nnFormBuilder: NonNullableFormBuilder,
    private messagingService: MessagingService,
    private dialogService: DialogService,
    public auditorRequestService: AuditorRequestService,
    public aurthorizationService: AuthorizationService
  ) {
    // set data from from the dialog config, if there is one
    if (this.config.data) {
      this.certification = this.config.data?.certification;
      this.auditorRequest = this.config.data?.auditorRequest;
      this.indication = this.config.data?.indication;
      this.showRelationSelection = this.config.data?.showRelationSelection;
      this.verificationStatus =
        this.config.data.auditorRequest.verificationStatus;
      this.showVerificationSelection =
        this.config.data?.showVerificationSelection;
      this.sampleMode = this.config.data?.sampleMode;
      this.restrictedStatusSelection =
        this.config.data?.restrictedStatusSelection;
      this.allowEmptyTexts = this.config.data?.allowEmptyTexts;
      this.editMode = this.config.data?.editMode;

      this.config.data.actions = [
        {
          text: 'Schließen',
          type: 'outlined',
          i18nLabel: '@@close',
          icon: {
            name: 'pi pi-times',
          },
          action: () => {
            this.cancel();
            return Subscription.EMPTY;
          },
        },
        {
          text: 'Speichern',
          i18nLabel: '@@save',
          icon: {
            name: 'pi pi-check',
          },
          dataCy: 'save',
          disable: () => {
            return this.auditorRequestForm;
          },
          action: () => {
            return this.submitForm();
          },
        },
      ];
    }

    this.initForm();

    if (this.certification && this.auditorRequest) {
      const sortedData = this.auditorRequestService.setupData(
        this.certification,
        this.auditorRequest
      );
      this.selectedRelations = sortedData?.selectedItems ?? [];

      if (this.certification.groupId) {
        this._checkApplyToGroup();
      }

      this.checkShowReview(this.auditorRequest.verificationStatus);
    }
  }

  ngOnInit(): void {
    this._subscriptions.add(
      this.store
        .select(AppState.authenticatedUser)
        .subscribe((user: User | undefined) => {
          this.currentUser = user;
          this.isAuditorView = user?.role === Role.AUDITOR;

          if (
            !this.auditorRequest.messages?.length &&
            this.auditorRequest?.id &&
            this.isAuditorView &&
            !this.config.data?.actions?.some(
              (a) => a.text === 'Rückfrage löschen'
            )
          ) {
            this.config.data?.actions?.unshift({
              text: 'Rückfrage löschen',
              i18nLabel: '@@delete',
              icon: {
                name: 'pi pi pi-trash',
              },
              dataCy: 'delete',
              severity: 'danger',
              action: () => {
                return this.deleteAuditorRequest();
              },
            });
          }
        })
    );
  }

  ngAfterViewInit(): void {
    if (this.messageComponent) {
      this._subscriptions.add(
        this.messageComponent.messageForm.statusChanges.subscribe((sc) => {
          this.auditorRequestForm.markAsDirty();
          this.auditorRequestForm.updateValueAndValidity();
          this._setWizardDirtyStatus(true);
        })
      );

      this.auditorRequestForm.addValidators(
        (c: AbstractControl): ValidationErrors | null => {
          return this.messageComponent?.messageForm.invalid
            ? { messageformInvalid: true }
            : null;
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.auditorRequest && this.auditorRequest.verificationStatus) {
      this._setHeader(this.auditorRequest.verificationStatus);
    }

    if (changes.certification) {
      if (
        changes.certification?.currentValue?.id !==
        changes.certification?.previousValue?.id
      ) {
        this.verificationStatus = VerificationStatus.NONE;
        if (
          this.auditorRequest &&
          changes.certification &&
          this.certification
        ) {
          this.auditorRequest.certification = this.certification;
          this.auditorRequest.certificationId = this.certification?.id;
        }
        if (this.certification) {
          const sortedData = this.auditorRequestService.setupData(
            this.certification,
            this.auditorRequest
          );
          this.verificationStatus =
            this.auditorRequest.verificationStatus || VerificationStatus.NONE;
          this.selectedRelations = sortedData?.selectedItems ?? [];
          this._skipEmitChanges = true;
          this.auditorRequestForm.reset();
          this.auditorRequestForm.patchValue({
            ...this.auditorRequest,
            documents: this.auditorRequest.documents ?? [],
          });
          this._skipEmitChanges = false;
          this._checkApplyToGroup();
        }
      }
    }
    if (changes.indication || changes.indicator) {
      if (
        this.auditorRequest &&
        (changes.indication || changes.indicator) &&
        this.indication
      ) {
        //Nur Überschreiben wenn auch gefüllt
        this.auditorRequest.indication = this.indication;
      }
      if (this.certification?.standardVersion && this.indication?.indicatorId) {
        if (this.certification?.groupId) {
          this._checkApplyToGroup();
        } else {
          this.showApplyToGroup = false;
        }
      }
    }

    this.checkShowReview(this.auditorRequest.verificationStatus);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  initForm() {
    this.auditorRequestForm = this.formBuilder.group<AuditorRequestForm>({
      sample: new FormControl<boolean>(this.sampleMode, { nonNullable: true }),
      auditorComment: new FormControl<string>(
        this.auditorRequest?.auditorComment ?? '',
        this.getAuditorCommentRequired()
      ),
      internalAuditorRemarks: new FormControl<string>(
        this.auditorRequest?.internalAuditorRemarks ?? '',
        this.getInternalAuditorRemarksRequired()
      ),
      valid: new FormControl<boolean>(this.auditorRequest?.valid ?? false),
      reviewed: new FormControl<boolean>(
        this.auditorRequest?.reviewed ?? false
      ),
      documents: this.nnFormBuilder.control(
        (this.auditorRequest?.documents as DocumentSimpleFragment[]) ?? []
      ),
      applyToGroup: new FormControl<boolean>(false),
    });

    this._subscriptions.add(
      this.auditorRequestForm.get('sample')?.valueChanges.subscribe((s) => {
        this.auditorRequestForm
          .get('internalAuditorRemarks')
          ?.updateValueAndValidity();
      })
    );
    this._subscriptions.add(
      this.auditorRequestForm.valueChanges
        .pipe(debounceTime(50))
        .subscribe((s) => {
          if (!this._skipEmitChanges) {
            this._setWizardDirtyStatus(this.auditorRequestForm.dirty);
          }
        })
    );
  }

  getAuditorCommentRequired(): ValidatorFn {
    return (form: AbstractControl) => {
      if (this.allowEmptyTexts) {
        return null;
      }
      if (!(form.parent?.value as AuditorRequestForm | null)?.sample) {
        if ((form.value ?? '').length === 0) {
          return {
            required: true,
          };
        }
      }
      return null;
    };
  }

  getInternalAuditorRemarksRequired(): ValidatorFn {
    return (form: AbstractControl) => {
      if (this.allowEmptyTexts) {
        return null;
      }
      if ((form.parent?.value as AuditorRequestForm | null)?.sample) {
        if ((form.value ?? '').length === 0) {
          return {
            required: true,
          };
        }
      }
      return null;
    };
  }

  convertToAr() {
    this.auditorRequestForm.get('sample')?.setValue(false);
    this.sampleMode = false;
    this.auditorRequest.sample = this.sampleMode;
    this.auditorRequestForm.markAsDirty();
    this.auditorRequestForm.updateValueAndValidity();
    this._setHeader(this.verificationStatus);
  }

  sampleChange($event: { option?: { label: string } }) {
    if ($event.option) {
      this.config.header = $event.option.label;
      this.auditorRequestForm
        .get('internalAuditorRemarks')
        ?.updateValueAndValidity();
      this.auditorRequestForm.get('auditorComment')?.updateValueAndValidity();
    }
  }

  deleteAuditorRequest(): Subscription {
    if (this.certification?.id) {
      return this.store
        .dispatch(
          new DeleteAuditorRequestAction({
            id: this.auditorRequest.id,
            certificationId: this.certification.id,
            groupCertificationId: this.certification.groupId ?? undefined,
          })
        )
        .subscribe((response) => {
          this.ref.close(false);
          this.messagingService.success('', `AuditorRequest deleted`);
        });
    }

    return Subscription.EMPTY;
  }

  cancel() {
    if (this.showInline) {
      this._setWizardDirtyStatus(false);
    }
    this.ref.close(false);
  }

  onApplyToGroup(event: CheckboxChangeEvent): void {
    // if this is an group certification AR or the user wants to generate a group certificaiton AR
    if (
      event.checked &&
      (this.auditorRequest.groupCertificationId ||
        this.auditorRequestForm.controls.applyToGroup.value)
    ) {
      // if we have a group certification AR, we filter out all relations except of INDICATION
      this.selectedRelations = this.selectedRelations.filter(
        (r) => r.type === RELATION_TYPE.INDICATION
      );
    }
  }

  submitForm() {
    this.auditorRequestForm.markAllAsTouched();
    this.auditorRequestForm.markAsDirty();
    if (
      this.certification &&
      this.auditorRequestForm.valid &&
      (!this.messageComponent || this.messageComponent.messageForm.valid)
    ) {
      const formValue = { ...this.auditorRequestForm.value };
      this.auditorRequestForm.disable();
      let args: Partial<AuditorRequestUpdateInput & AuditorRequestInput> = {
        certificationId: this.certification.id,
        sample: formValue.sample,
        auditorComment: formValue.auditorComment ?? '',
        internalAuditorRemarks: formValue.internalAuditorRemarks ?? '',
        verificationStatus: this.verificationStatus,
        baselines: this.selectedRelations
          .filter((r) => r.type === RELATION_TYPE.BASELINE)
          .map((r) => r.data as Baseline),
        growthModels: this.selectedRelations
          .filter((r) => r.type === RELATION_TYPE.GROWTHMODEL)
          .map((r) => r.data as GrowthModel),
        sites: this.selectedRelations
          .filter((r) => r.type === RELATION_TYPE.SITE)
          .map((r) => r.data as Site),
        indication: this.auditorRequest.indication,
        message: formValue.sample
          ? null
          : this.messageComponent?.getCurrentMessage() ?? null,
        documentRelations: LinkDocumentsDialogComponent.joinDocumentsForm(
          this.auditorRequest.documents ?? [],
          this.auditorRequestForm.controls.documents.value
        )
          .filter((doc) => doc.relationDelete || doc.relationNew)
          .map(
            (dr) =>
              ({
                documentId: dr.id,
                delete: dr.relationDelete,
              } as DocumentRelationInput)
          ),
      };
      args = {
        ...args,
        reviewed: formValue.reviewed ?? false,
        valid: formValue.valid ?? false,
        groupCertificationId: this.certification.groupId,
      };
      if (this.auditorRequest.id) {
        args = {
          ...args,
          id: this.auditorRequest?.id ?? undefined,
        };

        if (
          !this.auditorRequest.messages?.length &&
          ![Role.PROJECT_MANAGER, Role.CONSULTANT].includes(
            this.currentUser?.role ?? Role.ANONYMOUS
          )
        ) {
          delete args.message;
        }

        return this.store
          .dispatch(
            new UpdateAuditorRequestAction(args as AuditorRequestUpdateInput)
          )
          .subscribe({
            next: () => {
              this.messagingService.success(
                '',
                AuditorRequestService.getAppropriateMessage(
                  {
                    ...this.auditorRequest,
                    verificationStatus: this.verificationStatus,
                  },
                  this.sampleMode,
                  'UPDATE'
                )
              );
              this.auditorRequestForm.enable();
              this.ref.close(true);
            },
            error: () => {
              this.auditorRequestForm.enable();
            },
          });
      } else {
        delete args.message;

        args = {
          ...args,
          applyToGroup: formValue.applyToGroup ?? false,
          indicatorId:
            formValue.applyToGroup && this.indication?.indicatorId
              ? this.indication.indicatorId
              : undefined,
        };
        return this.store
          .dispatch(new CreateAuditorRequestAction(args as AuditorRequestInput))
          .subscribe({
            next: () => {
              this.messagingService.success(
                '',
                AuditorRequestService.getAppropriateMessage(
                  {
                    ...this.auditorRequest,
                    verificationStatus: this.verificationStatus,
                  },
                  this.sampleMode,
                  'SAVE'
                )
              );
              this.auditorRequestForm.enable();
              this.ref.close(true);
            },
            error: () => {
              this.auditorRequestForm.enable();
            },
          });
      }
    }

    return Subscription.EMPTY;
  }

  vsChanged(status: VerificationStatus) {
    this.auditorRequestForm.markAsDirty();
    this.auditorRequestForm.updateValueAndValidity();
    this._setHeader(status);
    this.checkShowReview(status);
  }

  addRelationClicked(event: MouseEvent) {
    if (this.auditorRequest.groupCertificationId) {
      this.messagingService.confirmOnly(
        'Achtung',
        $localize`:@@auditor-request.editor.relations-in-group:Gruppen-Rückfragen
          können keine Bezüge zu Daten einer spezifischen Zertifizierung haben.
          Wenn Sie solche Bezüge setzen wollen, müssen Sie diese Rückfrage zuerst in eine
          spezifischen Rückfrage umwandeln, indem Sie das Häkchen "Auf Gruppe anwenden" entfernen
          und die Rückfrage abspeichern.`,
        () => null
      );
      return;
    }

    const dialogRef =
      this.dialogService.openDialogWithComponent<RelationSelectionDialogData>(
        RelationSelectionComponent,
        {
          header: 'Neue Relation',
          modal: true,
          width: '80rem',
          height: '50rem',
          data: {
            certification: this.certification,
            excludedRelations: this.selectedRelations,
          },
        }
      );

    dialogRef.onClose.subscribe((newItems: auditorRequestRelation[]) => {
      this.selectedRelations = [...this.selectedRelations, ...newItems];
      this._checkApplyToGroup();
      this.auditorRequestForm.markAsDirty();
    });
  }

  onRelationDeleteClicked(relation: auditorRequestRelation) {
    const relationIndex = this.selectedRelations.findIndex(
      (sr) => sr.data?.id === relation.data.id
    );

    this.selectedRelations.splice(relationIndex, 1);
    this._checkApplyToGroup();
    this.deletingRelation = null;
    this.auditorRequestForm.markAsDirty();
  }

  /**
   * Group AuditorRequests may not have
   * relations to a certification specific data set
   */
  private _checkApplyToGroup() {
    const hasNonGroupDouments =
      this.auditorRequestForm.controls.documents.value.find(
        (d) => d.groupCertificationId === null
      ) !== undefined;

    this.showApplyToGroup =
      this.auditorRequest.id === undefined && // only new auditorRequests may become group requests
      this.indication !== undefined && // only auditor requests with an indication may become group requests
      this.selectedRelations
        .map((v) => v.type)
        .filter((type) =>
          [
            RELATION_TYPE.SITE,
            RELATION_TYPE.BASELINE,
            RELATION_TYPE.GROWTHMODEL,
          ].includes(type)
        ).length === 0 &&
      !hasNonGroupDouments &&
      !this.sampleMode;
  }

  private _setHeader(status: VerificationStatus) {
    const header = AuditorRequestService.getAuditorRequestHeader(
      { ...this.auditorRequest, verificationStatus: status },
      this.sampleMode
    );
    this.config.header = header;
  }

  private _setWizardDirtyStatus(isDirty: boolean) {
    setTimeout(() => {
      this.formDirty.emit(isDirty);
    }, 200);
  }

  onDocumentsLinked(linkedDocuments: DocumentSimpleFragment[]) {
    if (linkedDocuments) {
      this.auditorRequestForm.controls.documents.setValue(linkedDocuments);
      this.auditorRequestForm.markAsDirty();
      if (
        this.auditorRequest.groupCertificationId ||
        this.auditorRequestForm.controls.applyToGroup.value
      ) {
        const hasNonGroupDouments = linkedDocuments.find(
          (d) => d.groupCertificationId === null
        );
        if (hasNonGroupDouments) {
          // TODO: move this warning to the upload/link dialog and show it BEFORE the
          // user saves the non group document
          this.messagingService.confirmOnly(
            '',
            $localize`:Toast@@auditor-request.documents.non-group-warning:
                        Es können nur Gruppendokumente mit Gruppen-Rückfragen verlinkt werden, daher müssen alle
                        verknüpften Dokumente Gruppendokumente sein! Sie haben mindestens ein Dokument
                        verlinkt das kein Gruppendokument ist. Daher wird der
                        Bezug zur Gruppe automatisch gelöst!`,
            () => null
          );
          this.auditorRequestForm.patchValue({ applyToGroup: false });
        }
      }
      this._checkApplyToGroup();
    }
  }

  onDocumentUnLinked(document: Document) {
    const documents = this.auditorRequestForm.controls.documents.value;
    const newDocuments = documents.filter((d) => d.id !== document.id);
    this.auditorRequestForm.controls.documents.setValue(newDocuments);
    this.auditorRequestForm.markAsDirty();
  }

  /**
   * Sets the review checkbox visibility based on the verification code and last message role
   *
   * @param status Verification Status
   */
  private checkShowReview(status: VerificationStatus): void {
    // only for CARs so far
    if (status === VerificationStatus.CORRECTIVE_ACTION_REQUEST) {
      // get last message role and set visibility
      const lastMessageRole = CertificationProcessService.getLastMessageRole(
        this.auditorRequest.messages
      );
      this.showReviewCheckbox =
        !!lastMessageRole && lastMessageRole !== this.currentUser?.role;
    } else {
      this.showReviewCheckbox = true;
    }

    // set false to control when review checkbox is hidden
    if (!this.showReviewCheckbox) {
      this.auditorRequestForm.controls.reviewed.setValue(false);
    }
  }
}
