/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

// see comment in certifcation.state.ts

import { Injectable } from '@angular/core';
import { HistoryFragment } from '@eva/data-access/shared';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { LoadHistoriesAction } from '../actions';
import { HistoryService } from '../services/api/history.service';

export interface HistoryStateModel {
  histories: HistoryFragment[];
}

export const DefaultHistoryState = {
  histories: [],
};

@State<HistoryStateModel>({
  name: 'historyState',
  defaults: DefaultHistoryState,
})
@Injectable()
export class HistoryState implements HistoryStateModel {
  histories: HistoryFragment[];
  projects: HistoryFragment[] = [];

  constructor(private historyService: HistoryService) {}

  @Selector()
  static histories(state: HistoryStateModel) {
    return state.histories;
  }

  parseError(err: { error: { errors: { [key: string]: unknown } } }) {
    console.log(err);
    return Object.keys(err.error.errors).map(
      (key) => `${key} ${err.error.errors[key]}`
    );
  }

  @Action(LoadHistoriesAction)
  loadHistories(
    stateContext: StateContext<HistoryStateModel>,
    action: LoadHistoriesAction
  ) {
    return this.historyService.loadHistories(action.payload).pipe(
      tap((data) => {
        if (data?.histories) {
          stateContext.patchState({ histories: data.histories });
        }
      })
    );
  }
}
