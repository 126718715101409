/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Editor, EditorModule, EditorTextChangeEvent } from 'primeng/editor';
import { ButtonComponent } from '../../button';
import { DialogService } from '../../dialog';
import {
  EditorDialogComponent,
  EditorDialogData,
} from './editor-dialog/editor-dialog.component';

@Component({
  selector: 'eva-editor',
  standalone: true,
  imports: [
    CommonModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
  ],
  templateUrl: './editor.component.html',
  styleUrl: './editor.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    },
  ],
})
/**
 * This Component implements a control value accessor to act as a form control
 * for the parent component
 */
export class EditorComponent implements ControlValueAccessor {
  /**
   * Name of the form control
   */
  @Input({ required: true }) formControlName!: string;

  /**
   * List of allowed formats
   */
  @Input() formats: string[] = [
    'italic',
    'bold',
    'underline',
    'link',
    'list',
    'indent',
  ];

  /**
   * Style object, which should be applied to the editor
   */
  @Input() editorStyle?: { [key: string]: string };

  /**
   * Boolean to control the maximize functionality
   */
  @Input() maximize = true;

  /**
   * (Optional)
   */
  @Input() i18nTooltip?: string;

  /**
   * (Optional)
   */
  @Input() title?: string;

  /**
   * (Optional)
   */
  @Input() titleI18n?: string;

  /**
   * Primeng editor instance, which can be accessed by the parent
   */
  @ViewChild(Editor, { static: true }) editor!: Editor;

  /**
   * ControlValueAccessor methods
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChange: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onTouched: any;

  // local copy of the value
  public value!: string;

  constructor(private dialogService: DialogService) {}

  // Write the value from the form to the editor
  writeValue(value: string): void {
    this.value = value;
    if (this.editor) {
      this.editor.writeValue(value);
    }
  }

  // Register the onChange function for form control changes
  registerOnChange(fn: unknown): void {
    this.onChange = fn;
  }

  // Register the onTouched function
  registerOnTouched(fn: unknown): void {
    this.onTouched = fn;
  }

  /**
   * Handle editor text changes and propagate them to the form control
   *
   * @param event Text change event of the PrimgNg editor
   */
  onEditorContentChange(event: EditorTextChangeEvent | string) {
    let newValue: string;

    if (typeof event === 'string') {
      newValue = event;
    } else {
      newValue = event.htmlValue;
    }

    // Save local value for the editor
    this.value = newValue;

    // propagte value to the form control
    this.onChange(newValue);
    this.onTouched();
  }

  /**
   * Open the editor in a full screen dialog
   */
  openFullscreenEditor(): void {
    this.dialogService
      .openDialogWithComponent<EditorDialogData>(EditorDialogComponent, {
        header: this.title,
        width: '100vw',
        height: '100vh',
        data: {
          content: this.value,
        },
      })
      .onClose.subscribe((content: string) => {
        if (content !== undefined) {
          this.onEditorContentChange(content);
        }
      });
  }
}
