/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DocumentCategoryLocalization, Role } from '@eva/certification/api';
import {
  DocumentCategory,
  DocumentSimpleFragment,
  DocumentVisibility,
} from '@eva/data-access/shared';
import { DialogData } from '@eva/ng-base';
import { Store } from '@ngxs/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';
import { LoadSystemDataAction } from '../../../actions/systemActions';
import { FileService } from '../../../services/api/file.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { UpdateDocumentAction } from '../../../states/actions/document-actions';
import { FileUploadComponent } from '../document-list/file-upload/file-upload.component';

interface documentForm {
  fileName: FormControl<string | null>;
  file: FormControl<string | null>;
  remarks: FormControl<string | null>;
  visibility: FormControl<DocumentVisibility | null>;
  category: FormControl<DocumentCategory | null>;
  pddAppendix: FormControl<boolean | null>;
}

export interface DocumentEditDialogData {
  doc: DocumentSimpleFragment | undefined;
  category: DocumentCategory | undefined;
  categories: (DocumentCategory | null | undefined)[] | undefined;
}

@Component({
  selector: 'eva-document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: ['./document-edit.component.scss'],
})
export class DocumentEditComponent implements OnInit, OnDestroy {
  @ViewChild('evaFileUpload', { static: false })
  evaFileUpload: FileUploadComponent;

  currentUserRole: Role = Role.ANONYMOUS;
  allowedCategories: (DocumentCategory | null)[] = [];
  file: File;
  loading = false;
  documentForm: FormGroup<documentForm>;
  documentVisibilityEnum = DocumentVisibility;
  documentCategoryList: ({
    key: DocumentCategory | null;
    value: string;
  } | null)[] = [];

  private _subscriptions = new Subscription();
  newDocument = false;
  constructor(
    private formbuilder: FormBuilder,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig<
      DialogData<DocumentEditDialogData>
    >,
    private store: Store,
    private authorizationService: AuthorizationService,
    public fileService: FileService
  ) {
    this.newDocument = !this.dialogConfig.data?.doc;
    this.documentForm = this.formbuilder.group<documentForm>({
      file: this.formbuilder.control<string | null>(
        this.dialogConfig.data?.doc?.name ?? null
      ),
      fileName: this.formbuilder.control<string | null>(
        this.dialogConfig.data?.doc?.name ?? ''
      ),
      remarks: this.formbuilder.control<string>(
        this.dialogConfig.data?.doc?.remarks ?? ''
      ),
      pddAppendix: this.formbuilder.control<boolean>({
        value: !!(this.dialogConfig.data?.doc?.pddAppendix ?? false),
        disabled: !this.isPDFFile(),
      }),
      visibility: this.formbuilder.control<DocumentVisibility>({
        value:
          this.dialogConfig.data?.doc?.visibility ?? DocumentVisibility.PUBLIC,
        disabled:
          !!this.fileService.getDefaultVisibility(
            this.dialogConfig.data?.doc?.category ??
              this.dialogConfig.data?.category ??
              null
          ) || !this.dialogConfig.data?.doc?.remarks,
      }),

      category: this.formbuilder.control<DocumentCategory | null>({
        value:
          this.dialogConfig.data?.doc?.category ??
          this.dialogConfig.data?.category ??
          null,
        disabled: !!this.dialogConfig.data?.category,
      }),
    });
    this.documentForm.updateValueAndValidity();

    this._subscriptions.add(
      this.documentForm.controls.category.valueChanges.subscribe((cat) => {
        if (this.fileService.getDefaultVisibility(cat)) {
          this.documentForm.controls.visibility.setValue(
            this.fileService.getDefaultVisibility(cat)
          );
        }
      })
    );

    this._subscriptions.add(
      this.documentForm.valueChanges.subscribe((cat) =>
        this.handleAppendixVisibility()
      )
    );

    this.handleAppendixVisibility(false);

    if (this.dialogConfig.data) {
      this.dialogConfig.data.actions = [
        {
          text: 'Speichern',
          i18nLabel: '@@save',
          icon: {
            name: 'pi pi-check',
          },
          action: () => {
            return this.saveClick().then((subscription) => {
              return subscription;
            });
          },
        },
      ];
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  handleAppendixVisibility(onlySelf = true) {
    if (!this.isPDFFile()) {
      this.documentForm.controls.pddAppendix.disable({ onlySelf });
    } else {
      this.documentForm.controls.pddAppendix.enable({ onlySelf });
    }
  }

  isPDFFile(): boolean {
    const retVal =
      ((this.file?.type?.toLowerCase().indexOf('pdf') ?? 0) > -1 &&
        this.dialogConfig.data?.doc?.name.toLowerCase().endsWith('.pdf')) ??
      false;
    return retVal;
  }

  async ngOnInit() {
    this.currentUserRole = await this.authorizationService.getRole();
    this.allowedCategories =
      (this.dialogConfig?.data?.doc?.relations ?? []).length > 0
        ? [this.dialogConfig?.data?.doc?.category ?? null]
        : this.fileService.getAllowedCategories(this.currentUserRole);

    this.documentCategoryList = [
      ...this.allowedCategories.map((c) => ({
        key: c,
        value: c ? DocumentCategoryLocalization[c] : 'Allgemein',
      })),
      // { key: null, value: 'Allgemein' },
    ];
  }

  getLocalization(category: DocumentCategory) {
    return DocumentCategoryLocalization[category];
  }

  fileChangeEvent(e: File) {
    this.file = e;
    this.documentForm.get('file')?.setValue(e.name);
    this.documentForm.get('fileName')?.setValue(e.name);
  }

  async saveClick(): Promise<Subscription> {
    const newDoc = !this.dialogConfig.data?.doc?.id;

    let loadingTask: Observable<DocumentSimpleFragment[]> | undefined;
    this.loading = true;
    if (newDoc && this.documentForm.get('fileName')?.value) {
      this.evaFileUpload.setFile([
        {
          file: this.file,
          fileName: this.documentForm.get('fileName')?.value ?? '',
          remarks: this.documentForm.get('remarks')?.value ?? '',
          visibility:
            this.documentForm.get('visibility')?.value ??
            DocumentVisibility.PRIVATE,
          category: this.documentForm.get('category')?.value ?? null,
          pddAppendix: this.documentForm.controls.pddAppendix?.value ?? false,
        },
      ]);
      loadingTask = await this.evaFileUpload.startUpload();
    } else {
      // Trigger action for update
      loadingTask = this.store.dispatch(
        new UpdateDocumentAction(
          {
            input: {
              id: this.dialogConfig.data?.doc?.id ?? '',
              name: this.documentForm.get('fileName')?.value ?? '',
              remarks: this.documentForm.get('remarks')?.value ?? '',
              visibility:
                this.documentForm.get('visibility')?.value ??
                DocumentVisibility.PRIVATE,
              category: this.getCategory(),
              // if new category is other type than auditor-request or indication: remove potentially exist relation-ids
              // auditorRequestId:
              //   this.getCategory() === DocumentCategory.AUDITOR_REQUEST
              //     ? this.dialogConfig.data?.auditorRequestId
              //     : null,
              // indicationId:
              //   this.getCategory() === DocumentCategory.INDICATION
              //     ? this.dialogConfig.data?.indicationId
              //     : null,
              pddAppendix:
                this.documentForm.controls.pddAppendix?.value ?? false,
            },
          },
          (doc) => {
            if (
              doc?.category &&
              [
                DocumentCategory.AGB_PLATFORM,
                DocumentCategory.AGB_STANDARD,
              ].includes(doc?.category)
            ) {
              this.store.dispatch(new LoadSystemDataAction());
            }
          }
        )
      );
    }
    return loadingTask.subscribe({
      next: (e) => {
        this.dialogRef.close(e);
        this.loading = false;
      },
      error: (e) => {
        console.log(e);
        this.dialogRef.close(false);
        this.loading = false;
      },
    });
  }

  getCategory(): DocumentCategory | null {
    return (
      this.dialogConfig.data?.category ??
      this.documentForm.get('category')?.value ??
      null
    );
  }
}
