/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

/**
 * types shared between backend & frontend
 */

import {
  DocumentCategory,
  DocumentVisibility,
  IndicatorInterface,
} from '@eva/certification/api';

import {
  AuditorRequestFragment,
  CertificationFragment,
  CertificationPartialFragment,
  DocumentSimpleFragment,
  GroupCertificationFragment,
  ImageSimpleFragment,
  IndicationFragment,
  ProjectFragment,
  ProjectSimpleFragment,
  SiteFragment,
  SiteSimpleFragment,
  UserFragment,
  UserSimpleFragment,
} from './generated/types';

export type Project = ProjectFragment;

export type Site = SiteFragment;

export type Certification = CertificationFragment;

export type GroupCertification = GroupCertificationFragment;

export type CertificationPartial = CertificationPartialFragment;

export type Indication = IndicationFragment & {
  indicator?: IndicatorInterface;
};

export type Image = ImageSimpleFragment;

export type Document = DocumentSimpleFragment;

export type User = UserFragment;

export type UserSimple = UserSimpleFragment;

export type AuditorRequest = AuditorRequestFragment;

/**
 * reduced types for lists
 */
export type ListProject = ProjectSimpleFragment;

export type ListSite = SiteSimpleFragment;

export type CreateDocumentInput = {
  name: string;
  file: File;
  md5FileSum?: string;
  fileSize?: number;
  // indicationId?: string;
  certificationId?: string;
  groupCertificationId?: string;
  projectId?: string;
  remarks?: string;
  category?: DocumentCategory;
  visibility?: DocumentVisibility;
  index?: number;
  total?: number;
  subPath?: string;
  mainImage?: boolean;
  pddAppendix?: boolean;
};

/**
 * needed since type generator messes up the order
 * which is used in frontend to generate the right order of buttons
 */
export enum VerificationStatus {
  CLARIFICATION_REQUEST = 'CLARIFICATION_REQUEST',
  COMPLIANT = 'COMPLIANT',
  CORRECTIVE_ACTION_REQUEST = 'CORRECTIVE_ACTION_REQUEST',
  ENDANGERED = 'ENDANGERED',
  FORWARD_ACTION_REQUEST = 'FORWARD_ACTION_REQUEST',
  NON_COMPLIANT = 'NON_COMPLIANT',
  NONE = 'NONE',
}
