<div *ngIf="auditorRequest" class="flex">
  <div
    *ngIf="auditorRequest.initialVerificationStatus !== auditorRequest.verificationStatus"
    class="verification-status vs-status-icon initial-status initial-status"
    pTooltip="Ursprünglicher Status"
    [ngClass]="auditorRequest.initialVerificationStatus">
    {{auditorRequestHelper.getShortName(auditorRequest.initialVerificationStatus)}}
  </div>
  <div
    class="verification-status vs-status-icon"
    [ngClass]="auditorRequest.verificationStatus"
    [class.current-status]="auditorRequest.initialVerificationStatus !== auditorRequest.verificationStatus">
    {{auditorRequestHelper.getShortName(auditorRequest.verificationStatus)}}
  </div>
</div>
