/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import {
  CreateProjectInput,
  ListProject,
  UpdateProjectInput,
} from '@eva/data-access/shared';
import { StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { catchError, of, take, tap, throwError } from 'rxjs';
import { ProjectService } from '../../services/api/project.service';
import { AppState } from '../app.state';
import { attachAction } from '../attach-actions';

/**
 * helper function called in state constructor
 * attach all actions from this file
 */
export const loadProjectActions = (projectService: ProjectService) => {
  attachAction(AppState, LoadProjectAction, loadProjectAction(projectService));
  attachAction(
    AppState,
    LoadProjectsAction,
    loadProjectsAction(projectService)
  );
  attachAction(
    AppState,
    ReLoadCurrentProjectAction,
    reLoadCurrentProjectAction(projectService)
  );
  attachAction(
    AppState,
    UnsetCurrentProjectAction,
    unsetCurrentProjectAction()
  );
  attachAction(
    AppState,
    CreateProjectAction,
    createProjectAction(projectService)
  );
  attachAction(
    AppState,
    UpdateProjectAction,
    updateProjectAction(projectService)
  );
  attachAction(
    AppState,
    DeleteProjectAction,
    deleteProjectAction(projectService)
  );
};

export class LoadProjectsAction {
  static readonly type = '[Project] LoadProjects';
}

export const loadProjectsAction =
  (projectService: ProjectService) =>
  (stateContext: StateContext<AppState>, action: LoadProjectsAction) => {
    return projectService.loadProjects().pipe(
      tap((data) => {
        if (data?.projects) {
          stateContext.patchState({ projects: data.projects });
        }
      })
    );
  };

export class LoadProjectAction {
  static readonly type = '[Project] Load';
  constructor(public payload: string) {}
}

export const loadProjectAction =
  (projectService: ProjectService) =>
  (stateContext: StateContext<AppState>, action: LoadProjectAction) => {
    const state = stateContext.getState();
    if (state.projects?.length) {
      const project = state.projects.find((p) => p.id === action.payload);
      if (project) {
        stateContext.patchState({ currentProject: project });
        return of(project);
      }
    }
    return projectService.loadProject(action.payload).pipe(
      take(1), // needed to complete Observable
      tap((data) => {
        if (data?.project) {
          stateContext.patchState({ currentProject: data.project });
        }
      })
    );
  };

export class ReLoadCurrentProjectAction {
  static readonly type = '[Project] Reload';
}

export const reLoadCurrentProjectAction =
  (projectService: ProjectService) =>
  (stateContext: StateContext<AppState>, action: LoadProjectAction) => {
    return projectService.loadProject(action.payload).pipe(
      take(1), // needed to complete Observable
      tap((data) => {
        if (data?.project) {
          stateContext.setState(
            patch({
              currentProject: data.project,
              projects: updateItem<ListProject>(
                (projectItem) => projectItem.id === data.project.id,
                data.project
              ),
            })
          );
          return of(data.project);
        }
      })
    );
  };

export class UnsetCurrentProjectAction {
  static readonly type = '[Project] UnsetCurrent';
}

export const unsetCurrentProjectAction =
  () =>
  (stateContext: StateContext<AppState>, action: UnsetCurrentProjectAction) => {
    stateContext.patchState({ currentProject: undefined });
  };

export class CreateProjectAction {
  static readonly type = '[Project] Create';
  constructor(public payload: CreateProjectInput) {}
}

export const createProjectAction =
  (projectService: ProjectService) =>
  (stateContext: StateContext<AppState>, action: CreateProjectAction) => {
    return projectService.createProject(action.payload).pipe(
      take(1), // needed to complete Observable
      tap((newProject) => {
        if (newProject?.id) {
          stateContext.setState(
            patch({
              currentProject: newProject,
              projects: append<ListProject>([newProject]),
            })
          );
        }
      }),
      catchError((error) => {
        console.log('catchError', error);
        return of(null);
      })
    );
  };

export class UpdateProjectAction {
  static readonly type = '[Project] Update';
  constructor(public payload: UpdateProjectInput) {}
}

export const updateProjectAction =
  (projectService: ProjectService) =>
  (stateContext: StateContext<AppState>, action: UpdateProjectAction) => {
    return projectService.updateProject(action.payload).pipe(
      tap((updatedProject) => {
        if (updatedProject) {
          stateContext.setState(
            patch({
              currentProject: updatedProject,
              projects: updateItem<ListProject>(
                (projectItem) => projectItem?.id === updatedProject?.id,
                updatedProject
              ),
            })
          );
        }
      })
    );
  };

export const deleteProjectAction =
  (projectService: ProjectService) =>
  (stateContext: StateContext<AppState>, action: DeleteProjectAction) => {
    return projectService.deleteProject(action.payload).pipe(
      take(1), // needed to complete Observable
      tap((result) => {
        stateContext.setState(
          patch({
            currentProject: undefined,
            projects: removeItem<ListProject>(
              (projectItem) => projectItem.id === result?.id
            ),
          })
        );
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  };

export class DeleteProjectAction {
  static readonly type = '[Project] Delete';
  constructor(public payload: string) {}
}
