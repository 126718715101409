/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eva-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  ngOnInit(): void {
    console.debug('Init help');
  }
}
