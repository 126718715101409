/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Certification } from '@eva/data-access/shared';
import { auditorRequestRelation } from '../auditor-request.service';

@Component({
  selector: 'eva-relation-item',
  templateUrl: './relation-item.component.html',
  styleUrls: ['./relation-item.component.scss'],
})
export class RelationItemComponent {
  @Input() relation: auditorRequestRelation;
  @Input() certification: Certification;
  @Output() deleteClick = new EventEmitter<auditorRequestRelation>();
  @Input() showDeleteButton = false;
  onDeleteClicked() {
    this.deleteClick.emit(this.relation);
  }
}
