/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { User } from '@eva/data-access/shared';

/**
 * login action to patch authenticatedUser
 * not a real action since the
 * login happens outside the app
 */
export class LoginAction {
  static readonly type = '[Auth] Login';
  constructor(public payload: User) {}
}

export class LoadAuthenticatedUserAction {
  static readonly type = '[Auth] AuthenticatedUser';
}

export class LogoutAction {
  static readonly type = '[Auth] Logout';
}
