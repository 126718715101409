/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CertificationProcessService } from '@eva/certification/service';
import {
  AuditorRequest,
  Role,
  UserFragment,
  VerificationStatus,
} from '@eva/data-access/shared';
import { Store } from '@ngxs/store';
import { TooltipModule } from 'primeng/tooltip';
import { AppState } from '../../../../states/app.state';

@Component({
  selector: 'eva-auditor-request-display-action-status',
  templateUrl: './auditor-request-display-action-status.component.html',
  standalone: true,
  imports: [CommonModule, TooltipModule],
  styleUrl: './auditor-request-display-action-status.component.scss',
})
export class AuditorRequestDisplayActionStatusComponent implements OnChanges {
  @Input() auditorRequest: AuditorRequest | undefined;

  public actionNeeded: boolean;

  public actionMessage: string;

  public authenticatedUser: UserFragment | undefined;

  public roles = Role;

  constructor(private store: Store) {
    this.store.select(AppState.authenticatedUser).subscribe((user) => {
      this.authenticatedUser = user;
      this.updateTemplateState();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.auditorRequest) {
      this.updateTemplateState();
    }
  }

  /**
   * Updates the template specific properties to show the correct data
   */
  private updateTemplateState(): void {
    if (this.auditorRequest) {
      if (this.authenticatedUser?.role === this.roles.AUDITOR) {
        this.actionNeeded =
          CertificationProcessService.auditorRequestNeedsAction(
            this.auditorRequest,
            this.authenticatedUser?.role
          );
        if (this.actionNeeded) {
          if (
            this.auditorRequest.verificationStatus ===
            VerificationStatus.CORRECTIVE_ACTION_REQUEST
          ) {
            this.actionMessage = 'Status Nachricht fehlt noch';
          } else if (!this.auditorRequest.reviewed) {
            this.actionMessage = 'Prüfung muss noch bestätigt werden';
          }
        } else {
          this.actionMessage = '';
        }
      }
      this.actionNeeded =
        this.authenticatedUser?.role === this.roles.AUDITOR
          ? CertificationProcessService.auditorRequestNeedsAction(
              this.auditorRequest,
              this.authenticatedUser?.role
            )
          : !this.auditorRequest.reviewed;
    }
  }
}
