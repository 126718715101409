/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import { CertificationStatus, Role } from '@eva/certification/api';
import { CertificationProcessService } from '@eva/certification/service';
import {
  Certification,
  CertificationPartial,
  Project,
  Site,
  User,
} from '@eva/data-access/shared';
import { Select } from '@ngxs/store';
import {
  Observable,
  combineLatest,
  filter,
  firstValueFrom,
  map,
  shareReplay,
  skipWhile,
  tap,
} from 'rxjs';
import { AppState } from '../states/app.state';

/**
 * initialize Authentication Service based on
 * passed configuration argument
 */
@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  hasPermissionToUseApp = false;
  hasBearerToken = false;

  @Select(AppState.authenticatedUser) authenticatedUser$: Observable<User>;

  @Select(AppState.currentCertification)
  certification$: Observable<Certification>;

  currentUser: User;

  currentCertificationStatus: CertificationStatus | null | undefined;

  constructor() {
    this.authenticatedUser$
      .pipe(
        skipWhile((u) => u === null),
        tap((u) => {
          if (this.currentUser?.id !== u?.id) {
            // clear filter if user changes
            console.log('clear filter if user changes');
            localStorage.removeItem('groupCertificationFilter');
          }
        })
      )

      .subscribe((u) => (this.currentUser = u));
    this.certification$.subscribe((c) => {
      if (c) {
        this.currentCertificationStatus = c.status;
      }
    });
  }

  /**
   * Checks if the current user is authorized for a given roles set
   *
   * @param roles Array of roles
   * @returns Returns a boolean stream, if the current user is authorized for a given roles set
   */
  isCurrentUserAuthorized(roles: Role[]): Observable<boolean> {
    return this.authenticatedUser$.pipe(
      skipWhile((user) => user === null || user === undefined),
      map((user) => {
        if (roles && roles.indexOf(user.role) === -1) {
          return false;
        } else {
          return true;
        }
      })
    );
  }

  /**
   */
  getCurrentEditMode(
    certification?: Certification
  ): Promise<boolean | undefined> {
    const combined = combineLatest({
      user: this.authenticatedUser$,
      certification: this.certification$,
    }).pipe(
      skipWhile((res) => !res.user || !res.certification),
      map((res) => {
        const role = res.user?.role;
        const status = certification
          ? certification.status
          : res.certification?.status;
        if (status) {
          return this.getEditMode(status, role);
        }
      }),
      // tap((editMode) => console.log('editMode', editMode)),
      shareReplay()
      // tap((editMode) => console.log('shareReplay editMode', editMode))
    );
    // combined.subscribe((r) => console.log('Subscribe', r));
    return firstValueFrom(combined);
  }

  getEditMode(status: CertificationStatus, role: Role) {
    if (
      !status ||
      !role ||
      status === CertificationStatus.PUBLISHED ||
      status === CertificationStatus.WITHDRAWAL
    ) {
      return false;
    }
    if ([Role.PROJECT_MANAGER, Role.CONSULTANT].includes(role)) {
      return CertificationProcessService.ProjectManagerStates.includes(status);
    } else if (role === Role.AUDITOR) {
      // auditor has only access to indicators
      return false;
    } else if (role === Role.ADMIN) {
      return [
        CertificationStatus.DRAFT,
        CertificationStatus.VALID_FOR_REVIEW,
        CertificationStatus.PRECHECK_REQUESTED,
        CertificationStatus.VALID_FOR_RE_REVIEW,
        CertificationStatus.REVIEW_REQUESTED,
        CertificationStatus.COMPLIANT,
      ].includes(status);
    }
  }

  getAuditorEditMode(status: CertificationStatus) {
    return CertificationProcessService.AuditorStates.includes(status);
  }

  isProjectOperationAllowed(project: Project, operation = 'delete') {
    if (operation === 'delete') {
      if (project.certifications?.length) {
        return false;
      }
      return (
        [Role.PROJECT_MANAGER, Role.CONSULTANT].includes(
          this.currentUser.role
        ) || this.currentUser.role === Role.ADMIN
      );
    } else if (operation === 'changeOwner') {
      if (!project.certifications?.length) {
        return true;
      }
      let ownerChangePossible = true;
      project.certifications.forEach((certification) => {
        if (
          ![
            CertificationStatus.DRAFT,
            CertificationStatus.VALID_FOR_REVIEW,
          ].includes(certification.status)
        ) {
          ownerChangePossible = false;
        }
      });
      return ownerChangePossible;
    } else {
      return false;
    }
  }

  isCertificationOperationAllowed(
    certification: CertificationPartial | Certification | null,
    operation: 'delete' | 'update' | 'requestReview'
  ) {
    if (!certification) {
      return false;
    }
    if (operation === 'delete') {
      return (
        this.currentUser &&
        ([Role.PROJECT_MANAGER, Role.CONSULTANT].includes(
          this.currentUser.role
        ) ||
          this.currentUser.role === Role.ADMIN) &&
        [
          CertificationStatus.DRAFT,
          CertificationStatus.VALID_FOR_REVIEW,
        ].includes(certification.status)
      );
    } else if (operation === 'requestReview') {
      return (
        [
          CertificationStatus.VALID_FOR_REVIEW,
          CertificationStatus.VALID_FOR_RE_REVIEW,
        ].includes(certification.status) &&
        this.currentUser &&
        !certification.group &&
        [Role.PROJECT_MANAGER, Role.CONSULTANT].includes(this.currentUser.role)
      );
    } else if (operation === 'update') {
      return (
        this.currentUser.role === Role.ADMIN ||
        ([Role.PROJECT_MANAGER, Role.CONSULTANT].includes(
          this.currentUser.role
        ) &&
          CertificationProcessService.ProjectManagerStates.includes(
            certification.status
          )) ||
        (this.currentUser.role === Role.AUDITOR &&
          CertificationProcessService.AuditorStates.includes(
            certification.status
          ))
      );
    }
    return false;
  }

  isSiteOperationAllowed(site: Site, operation: 'delete' | 'update') {
    if (operation === 'delete' || operation === 'update') {
      return (
        this.currentUser &&
        [Role.PROJECT_MANAGER, Role.CONSULTANT].includes(
          this.currentUser.role
        ) &&
        this.currentUser.organisation?.id === site.owner.organisation?.id &&
        (!site?.certification ||
          [
            CertificationStatus.DRAFT,
            CertificationStatus.VALID_FOR_REVIEW,
          ].includes(site.certification?.status))
      );
    } else {
      return false;
    }
  }

  async getRole() {
    const roleObservable = this.authenticatedUser$.pipe(
      filter((u) => u?.role?.toString()?.length > 0),
      map((u) => u.role)
    );
    return firstValueFrom(roleObservable);
  }

  getCurrentUser() {
    return this.currentUser;
  }

  isOrganisationOwner(user: User) {
    return user.organisation?.ownerId === user.id;
  }
}
