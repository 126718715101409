/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  primaryColor = 'red';
  primaryColorText = 'white';

  accentColor = 'green';
  accentColorText = 'white';

  errorRed = 'red';
  // Colors: [key: string];
  color1Gray = 'gray';
  color2Green = 'green';
  color2GreenLight = 'lemon';
  color3Turkis = 'greenblue';
  color4Blue = 'blue';
  color5Pink = 'pink';
  /**
   *
   */
  constructor() {
    this.load();
  }

  load(): void {
    const primaryColorHelper = document.getElementById(
      'primaryColorHelper'
    ) as Element;
    const accentColorHelper = document.getElementById(
      'accentColorHelper'
    ) as Element;
    const errorRedColorHelper = document.getElementById(
      'errorRedColorHelper'
    ) as Element;

    const color1GrayHelper = document.getElementById(
      'color1GrayHelper'
    ) as Element;
    const color2GreenHelper = document.getElementById(
      'color2GreenHelper'
    ) as Element;
    const color2GreenLightHelper = document.getElementById(
      'color2GreenLightHelper'
    ) as Element;

    const color3TurkisHelper = document.getElementById(
      'color3TurkisHelper'
    ) as Element;
    const color4BlueHelper = document.getElementById(
      'color4BlueHelper'
    ) as Element;
    const color5PinkHelper = document.getElementById(
      'color5PinkHelper'
    ) as Element;

    if (primaryColorHelper) {
      this.primaryColor =
        window.getComputedStyle(primaryColorHelper).backgroundColor;
    }
    if (primaryColorHelper) {
      this.primaryColorText = window.getComputedStyle(primaryColorHelper).color;
    }

    if (accentColorHelper) {
      this.accentColor =
        window.getComputedStyle(accentColorHelper).backgroundColor;
    }
    if (accentColorHelper) {
      this.accentColorText = window.getComputedStyle(accentColorHelper).color;
    }

    if (errorRedColorHelper) {
      this.errorRed =
        window.getComputedStyle(errorRedColorHelper).backgroundColor;
    }
    if (color1GrayHelper) {
      this.color1Gray =
        window.getComputedStyle(color1GrayHelper).backgroundColor;
    }

    if (color2GreenHelper) {
      this.color2Green =
        window.getComputedStyle(color2GreenHelper).backgroundColor;
    }
    if (color2GreenLightHelper) {
      this.color2GreenLight = window.getComputedStyle(
        color2GreenLightHelper
      ).backgroundColor;
    }

    if (color3TurkisHelper) {
      this.color3Turkis =
        window.getComputedStyle(color3TurkisHelper).backgroundColor;
    }
    if (color4BlueHelper) {
      this.color4Blue =
        window.getComputedStyle(color4BlueHelper).backgroundColor;
    }
    if (color5PinkHelper) {
      this.color5Pink =
        window.getComputedStyle(color5PinkHelper).backgroundColor;
    }

    // --color-1-gray: rgba(0.60,0.65,0.75);
    // --color-2-green: rgba(0.50,0.70,0);
    // --color-3-turkis: rgba(0,0.60,0.65);
    // --color-4-blue: rgba(0,0.5,0.8);
    // --color-5-pink: rgba(1.2,0.3,0.75);
  }
}
