<div
  class="layout-container"
  [ngClass]="{
    'layout-menu-active': menuActive,
    'layout-menu-mobile-active': mobileMenuActive,
    'layout-static': true,
    'layout-overlay-active_': menuActive,
    'layout-mobile-active': mobileMenuActive,
    'layout-sidebar-active': menuActive,
    'layout-topbar-menu-active': mobileTopbarActive,
    'layout-static-inactive':
               staticMenuDesktopInactive

  }">
  <!-- 'layout-overlay-active': this.layoutService.state.overlayMenuActive,
'layout-mobile-active':
    this.layoutService.state.staticMenuMobileActive,
'layout-topbar-menu-active':
    this.layoutService.state.topbarMenuActive,
'layout-menu-profile-active':
    this.layoutService.state.menuProfileActive,
'layout-sidebar-active': this.layoutService.state.sidebarActive,
'layout-sidebar-anchored': this.layoutService.state.anchored, -->

  <!-- 'layout-topbar-menu-active':
 menuActive, -->
  <eva-topbar [buildInfo]="buildInfo"></eva-topbar>

  <eva-menu></eva-menu>

  <div class="layout-content-wrapper">
    <ng-container *ngIf="oldLayout">
      <div class="header">
        <div class="content"></div>
      </div>
      <div class="background-image-container" [class.no-content]="false"></div>
    </ng-container>

    <div class="layout-content" [class.content-padding]="oldLayout">
      <eva-loading-bar></eva-loading-bar>

      <router-outlet></router-outlet>
    </div>
    <div class="layout-footer justify-content-end p-1">
      <div class="text-500 pr-3" *ngIf="buildInfo" i18n="@@main.version-build">
        Version: {{ buildInfo.version }}, Build: {{ buildInfo.build }} vom {{
        buildInfo.date | date : 'short' }}
      </div>
    </div>
  </div>

  <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>
</div>

<p-toast
  position="top-center"
  [showTransformOptions]="'translateY(-100%)'"></p-toast>
<p-toast
  position="center"
  key="c"
  (onClose)="messageService.clear()"
  [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="center mb-3">
        <i
          *ngIf="message.severity === 'info'"
          class="pi pi-info-circle"
          style="font-size: 3rem"></i>
        <i
          *ngIf="message.severity === 'warning'"
          class="pi pi-exclamation-triangle"
          style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p class="message-content" [innerHTML]="message.detail"></p>
        <!-- <div *ngIf="showAGB">
          Hiermit akzeptiere ich die neuen AGB und Nutzungsbedingungen
          <input type="checkbox" (change)="" #accepted value="true" />
        </div> -->
      </div>
      <div class="grid fluid">
        <div class="col-6">
          <button
            *ngIf="!message?.data?.noReject"
            type="button"
            pButton
            (click)="message?.data?.callback(false); messageService.clear()"
            i18n="@@no"
            label="Nein"
            class="p-button-secondary w-full"></button>
        </div>
        <div class="col-6">
          <button
            type="button"
            pButton
            (click)="message?.data?.callback(true); messageService.clear()"
            i18n="@@yes"
            [label]="message?.data?.noReject ? 'Ok' : 'Ja'"
            class="p-button-success w-full"
            autofocus></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
