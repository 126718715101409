/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Document,
  DocumentCategory,
  Role,
  User,
} from '@eva/data-access/shared';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { OfflineService } from '../../../../services/offline.service';
import { ROUTES } from '../../../../shared/routes';
import { AppState } from '../../../../states/app.state';

type MenuItem = {
  label: string;
  icon: string;
  title?: string;
  target?: string;
  class?: string;
  restricted?: Role[];
  additionalRestriction?: string;
  routerLink?: string[];
  url?: string[];
};

@Component({
  selector: 'eva-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any[];

  showOfflineLink = false;

  isOffline = false;

  onlineStatus = 'Offline';

  private _subscriptions: Subscription;

  agbLink = '';

  termsOfUseLink = '';

  offlineAllowed = false;

  private availableMenuItems: MenuItem[] = [
    {
      label: $localize`:Menu@@home:Home`,
      icon: 'pi pi-fw pi-home',
      routerLink: ['/'],
    },
    {
      label: $localize`:Menu@@certification-auditors:Auditor-Zuordnung`,
      icon: 'pi pi-fw pi-check-square',
      class: 'cy-assign-auditors',
      routerLink: ['/' + ROUTES.ASSIGN_AUDITORS],
      restricted: [Role.AUDITOR, Role.ADMIN],
    },
    {
      label: $localize`:Menu@@system-administration:System-Einstellungen`,
      icon: 'pi pi-fw pi-cog',
      routerLink: ['/' + ROUTES.SYSTEM_ADMINISTRATION],
      restricted: [Role.ADMIN],
    },
    {
      label: $localize`:Menu@@users:Benutzer`,
      icon: 'pi pi-fw pi-user',
      routerLink: ['/' + ROUTES.USERS_LIST],
      restricted: [Role.PROJECT_MANAGER, Role.CONSULTANT, Role.AUDITOR],
      // for these roles only the owner may create new users
      additionalRestriction: 'ORGANIZATION_OWNER',
    },
    {
      label: $localize`:Menu@@users:Benutzer`,
      icon: 'pi pi-fw pi-user',
      routerLink: ['/' + ROUTES.USERS_LIST],
      restricted: [Role.ADMIN],
    },
    // {
    //   label: $localize`:Menu@@help:Hilfe`,
    //   icon: 'pi pi-fw pi-question-circle',
    //   routerLink: ['/help'],
    // },
    {
      label: $localize`:Menu@@contact:Kontakt`,
      icon: 'pi pi-fw pi-pencil',
      routerLink: ['/contact'],
    },
  ];

  private filteredMenuItems: MenuItem[] = [];

  @Select(AppState.authenticatedUser) authenticatedUser$: Observable<User>;

  @Select(AppState.getIsOffline) isOffline$: Observable<boolean>;

  @Select(AppState.getIsOfflineReady) getOfflineReady$: Observable<boolean>;

  @Select(AppState.systemDocuments) systemDocuments$: Observable<Document[]>;

  constructor(private offlineService: OfflineService) {
    this._subscriptions = new Subscription();
  }

  ngOnInit() {
    this._subscriptions.add(
      this.authenticatedUser$.subscribe((user) => {
        if (user?.role) {
          if (user.role === Role.AUDITOR || user.role === Role.ADMIN) {
            this.offlineAllowed = true;
          }
          this.filteredMenuItems = this.availableMenuItems.filter(
            (item) =>
              (!item.restricted || item.restricted.includes(Role[user.role])) &&
              (!item.additionalRestriction ||
                (item.additionalRestriction === 'ORGANIZATION_OWNER' &&
                  user.organisation?.ownerId === user.id))
          );
          this.model = [
            {
              items: this.filteredMenuItems,
            },
          ];
          if (this.offlineAllowed && this._isPwa()) {
            this.showOfflineLink = true;
          }
        }
      })
    );
    window.addEventListener('appinstalled', () => {
      console.log('App installed');
      if (this.offlineAllowed) {
        this.showOfflineLink = true;
      }
    });
    this._subscriptions.add(
      this.isOffline$.subscribe((offline) => {
        if (this.showOfflineLink) {
          this.isOffline = offline;
        }
      })
    );
    this._subscriptions.add(
      this.getOfflineReady$.subscribe((ready) => {
        if (ready) {
          this.isOffline = true;
          this.showOfflineLink = false;
        }
      })
    );
    this._subscriptions.add(
      this.systemDocuments$.subscribe((documents) => {
        if (!documents || documents.length) {
          return;
        }
        const agbs = documents.filter(
          (d) => d.category === DocumentCategory.AGB_STANDARD
        );
        if (agbs) {
          const agb = agbs.pop();
          if (agb) {
            const agbIndex = this.filteredMenuItems.findIndex(
              (item) => item.title === 'AGB Waldklimastandard'
            );
            if (agbIndex > -1) {
              this.filteredMenuItems.splice(agbIndex, 1);
            }
            const agbLink = 'document/' + agb.id + '/' + agb.file.fileName;
            this.filteredMenuItems.push({
              label: $localize`:Menu@@agb:AGB`,
              title: 'AGB Waldklimastandard',
              icon: 'pi pi-fw pi-file-pdf',
              target: '_blank',
              url: [agbLink],
            });
          }
        }

        const termsOfUseDocs = documents.filter(
          (d) => d.category === DocumentCategory.AGB_PLATFORM
        );
        if (termsOfUseDocs) {
          const termsOfUseDoc = termsOfUseDocs.pop();
          if (termsOfUseDoc) {
            const termsOfUseLink =
              'document/' +
              termsOfUseDoc.id +
              '/' +
              termsOfUseDoc.file.fileName;

            const agbIndex = this.filteredMenuItems.findIndex(
              (item) => item.title === 'Nutzungsbedingungen eva Onlineplattform'
            );
            if (agbIndex > -1) {
              this.filteredMenuItems.splice(agbIndex, 1);
            }

            this.filteredMenuItems.push({
              label: $localize`:Menu@@terms-of-use: Nutzungsbedingungen eva Online-Plattform `,
              title: 'Nutzungsbedingungen eva Onlineplattform',
              icon: 'pi pi-fw pi-file-pdf',
              target: '_blank',
              url: [termsOfUseLink],
            });
          }
        }

        this.model = [
          {
            items: this.filteredMenuItems,
          },
        ];
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  toggleOffline(offline: boolean) {
    if (this._isPwa()) {
      this.offlineService.setOfflineMode(offline);
    }
    return false;
  }

  private _isPwa() {
    return (
      (navigator as any)['standalone'] ||
      window.matchMedia('(display-mode: standalone)').matches
    );
  }
}
