<form
  [formGroup]="auditorRequestForm"
  (ngSubmit)="submitForm()"
  *ngIf="auditorRequest?.certificationId || auditorRequest?.groupCertificationId">
  <div class="fluid grid">
    <div
      class="col-12"
      *ngIf="!auditorRequest?.id && sampleMode && isAuditorView">
      <p-selectButton
        [options]="[{label: 'Stichprobe', value: true}, {label: 'Rückfrage', value: false}]"
        formControlName="sample"
        [allowEmpty]="false"
        (onOptionClick)="sampleChange($event)"
        optionLabel="label"
        optionValue="value"></p-selectButton>
    </div>

    <div
      class="col-12"
      *ngIf="auditorRequest?.id && auditorRequestForm.value.sample && isAuditorView">
      <button
        i18n="@@auditor.request.sample-convert"
        pButton
        type="button"
        (click)="convertToAr()">
        In Rückfrage umwandeln
      </button>
    </div>
    <div class="col-12" *ngIf="!auditorRequestForm.get('sample')?.value">
      <eva-editor
        id="auditor-comment"
        [editorStyle]="{ height: '136px', 'font-size': '1.1rem' }"
        [formControlName]="'auditorComment'"
        [i18nTooltip]="'@@auditor.request.comment.tooltip'"
        [title]="'Anmerkungen/Kommentar/Hinweise'"
        [titleI18n]="'@@auditor.request.comment'">
      </eva-editor>
      <ng-container
        *ngTemplateOutlet="formErrorsTemplate; context:{$implicit: auditorRequestForm.get('auditorComment')}"></ng-container>
    </div>
    <div class="col-12" *ngIf="isAuditorView">
      <eva-editor
        id="internalAuditorRemarks"
        class="red"
        [editorStyle]="{ height: '100px', 'font-size': '1.1rem' }"
        [formControlName]="'internalAuditorRemarks'"
        [i18nTooltip]="'@@auditor.request.internal-auditor-remarks.tooltip'"
        [title]="'Interne Anmerkung'"
        [titleI18n]="'@@auditor.request.internal-auditor-remarks'">
      </eva-editor>
      <ng-container
        *ngTemplateOutlet="formErrorsTemplate; context:{$implicit: auditorRequestForm.get('internalAuditorRemarks')}"></ng-container>
    </div>
    <div class="col-12" *ngIf="(auditorRequest.messages??[]).length === 0">
      <eva-verification-status-selector
        *ngIf="showVerificationSelection"
        [sampleMode]="auditorRequestForm.get('sample')?.value || restrictedStatusSelection"
        [(verificationStatus)]="verificationStatus"
        (verificationStatusChange)="vsChanged($event)"></eva-verification-status-selector>
    </div>
    <div
      class="col-12"
      *ngIf="!auditorRequestForm.get('sample')?.value && auditorRequest?.id">
      <div class="messages-wrapper border-1 border-black-alpha-20 p-2">
        <eva-auditor-request-messages
          #messageComponent
          [editMode]="editMode"
          [showSaveButton]="false"
          [auditorRequest]="auditorRequest"></eva-auditor-request-messages>
      </div>
    </div>
    <div class="col-12" *ngIf="showRelationSelection">
      <eva-button
        [disabled]="!!this.auditorRequest.groupCertificationId || !!auditorRequestForm.controls.applyToGroup.value"
        label="Neuer Bezug"
        [icon]="{name: 'pi pi-plus'}"
        styleClass="p-button"
        (click)="addRelationClicked($event)"></eva-button>
    </div>
    <div class="col-12" *ngIf="showRelationSelection">
      <div class="relations-wrapper">
        <eva-display-relations
          [relations]="selectedRelations"
          [showDeleteButton]="true"
          (deleteClick)="onRelationDeleteClicked($event)"></eva-display-relations>
      </div>
    </div>
    <div class="col-12">
      <div class="border-1 border-black-alpha-20 p-2">
        <eva-document-group-list
          *ngIf="certification"
          [certificationId]="certification.id"
          [groupCertificationId]="certification.groupId ?? undefined"
          [auditorRequestGroupId]="auditorRequestForm.controls.applyToGroup.value ? true : auditorRequest.groupCertificationId ?? undefined "
          [documents]="auditorRequestForm.controls.documents.value"
          (documentsLinked)="onDocumentsLinked($event)"
          (documentUnLinked)="onDocumentUnLinked($event)"
          [showNoDocumentsMessage]="true"
          [showGroupHeader]="true"
          [showUnlinkButton]="true"
          [showTableHeader]="true"
          [editMode]="aurthorizationService.getAuditorEditMode(certification.status)"></eva-document-group-list>
      </div>
    </div>
    <div *ngIf="showApplyToGroup" class="col-12 flex justify-content-end">
      <div class="mr-2 ml-2">
        <p-checkbox
          class="mr-1"
          data-pw="applyToGroup-button"
          #applyToGroup
          formControlName="applyToGroup"
          [binary]="true"
          [trueValue]="true"
          (onChange)="onApplyToGroup($event)"></p-checkbox>
        <label for="applyToGroup">für Gruppe übernehmen</label>
      </div>
    </div>
    <div *ngIf="showReviewCheckbox" class="col-12 flex justify-content-end">
      <div class="mr-2 ml-2">
        <p-checkbox
          class="mr-1"
          #isReviewedCB
          [binary]="true"
          formControlName="reviewed"
          [trueValue]="true"></p-checkbox>
        <label for="isReviewedCB">wurde überprüft</label>
      </div>
    </div>
    <div *ngIf="!config?.data?.actions" class="flex col-12">
      <div
        class="flex-1"
        *ngIf="!auditorRequest.messages?.length && auditorRequest?.id && isAuditorView">
        <p-button
          styleClass="p-button-danger"
          data-cy="delete-button"
          (click)="deleteAuditorRequest()"
          >{{auditorRequestForm.get('sample')?.value ? 'Stichprobe' :
          'Rückfrage'}} löschen
        </p-button>
      </div>
      <div class="flex-none"></div>
      <div class="flex-1">
        <eva-form-buttons
          [form]="auditorRequestForm"
          loadingKey="auditorRequest"
          [showCloseButton]="!showInline"
          (formClosed)="cancel()"
          (formCanceled)="cancel()"></eva-form-buttons>
      </div>
    </div>
  </div>
</form>

<ng-template #formErrorsTemplate let-formControl>
  <div
    *ngIf="formControl.invalid && (formControl.dirty || formControl.touched)"
    class="error-messages">
    <div *ngIf="formControl.errors?.['required']">Feld ist erforderlich</div>
    <div *ngIf="formControl.errors?.['minlength']">Feldinhalt zu kurz</div>
  </div>
</ng-template>
