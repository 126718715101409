/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import { Site } from '@eva/data-access/shared';
import { Store } from '@ngxs/store';
import { SEVERITY } from '../model/message';
import { DeleteSiteAction } from '../states/actions';
import { MessagingService } from './messaging.service';

@Injectable({
  providedIn: 'root',
})
export class EditSiteService {
  constructor(
    private messagingService: MessagingService,
    private store: Store
  ) {}

  deleteSite(site: Site, label: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (site.baselineId !== null || site.growthModelId !== null) {
        this.messagingService.warn(
          'Fläche ist bereits zugeordnet',
          'Diese Fläche ist bereits einem Szenario ' +
            'zugeordnet.Bitte entfernen Sie diese Seite ' +
            'aus den zugeordneten Szenarien. Erst dann kann sie gelöscht werden.'
        );
        reject();
      } else {
        this.messagingService.confirm(
          'Fläche löschen',
          'Sind Sie sicher dass Sie die Fläche "' + label + '" löschen wollen?',
          (confirmed: boolean) => {
            if (confirmed) {
              this.store
                .dispatch(new DeleteSiteAction(site.id))
                .subscribe(() => {
                  this.messagingService.success(
                    '',
                    'Fläche "' + label + '" wurde gelöscht'
                  );
                  resolve(true);
                });
            } else {
              console.log('Rejected');
              resolve(false);
            }
          },
          SEVERITY.DELETE,
          'Löschen',
          'Abbrechen',
          'pi pi-map'
        );
      }
    });
  }
}
