/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Role, User } from '@eva/data-access/shared';
import { Select } from '@ngxs/store';
import { MegaMenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { LoadingService } from '../../../../services/loading.service';
import { MessagingService } from '../../../../services/messaging.service';
import { AppState } from '../../../../states/app.state';
import { MainComponent } from '../../main.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'eva-topbar',
  templateUrl: './topbar.component.html',
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate(
          '.12s cubic-bezier(0, 0, 0.2, 1)',
          style({ opacity: 1, transform: '*' })
        ),
      ]),
      transition(':leave', [animate('.1s linear', style({ opacity: 0 }))]),
    ]),
  ],
  styleUrls: ['./topbar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy, OnChanges {
  @Select(AppState.authenticatedUser) authenticatedUser$: Observable<User>;

  @Input() buildInfo: {
    build: string;
    version: string;
    date: Date;
  };

  model: MegaMenuItem[] = [];

  authenticatedUser: User;
  subscription = new Subscription();
  environment: string;

  constructor(
    public appMain: MainComponent,
    private messagingService: MessagingService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.subscribeToLoader();

    this.subscription.add(
      this.authenticatedUser$.subscribe((user) => {
        this.authenticatedUser = user;
        this.model = this.buildMenu();
      })
    );

    this.model = this.buildMenu();
  }

  buildMenu() {
    return [
      {
        id: 'userProfile',
        label: this.appMain.userName,
        items: [
          [
            {
              id: 'user',
              label: 'Benutzerprofil',
              icon: 'pi pi-user',
              items: [
                {
                  id: 'user',
                  label: this.appMain.userName,
                  icon: 'pi pi-user',
                  styleClass: 'user-item',
                  command: (e: any) => this.appMain.openProfile(),
                },
                {
                  id: 'organisation',
                  styleClass: 'organisation-item',
                  label: this.appMain.organisationName,
                  icon: 'pi pi-globe',
                  command: (e: any) => this.appMain.openOrganisation(),
                },
              ],
            },
          ],
        ],
      },
      {
        id: 'logout',
        label: 'Logout',
        icon: 'pi pi-fw pi-power-off',
        command: (e: any) => this.appMain.onTopbarItemClick(e, 'logout'),
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buildInfo && this.buildInfo) {
      if (environment.name && ['staging', 'dev'].includes(environment.name)) {
        this.environment =
          environment.name === 'dev'
            ? 'Entwicklungs-Instanz'
            : 'Staging-Instanz';
      } else if (this.authenticatedUser?.role === Role.ADMIN) {
        if (this.buildInfo && environment.name === 'prod') {
          this.environment = 'LIVE-Instanz!';
        }
      } else {
        this.environment = 'Zertifizierungs-Plattform';
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  subscribeToLoader() {
    const timeoutMessages: { [key: string]: number } = {};
    this.subscription.add(
      this.loadingService.loadingStatus.subscribe((s) => {
        if (s.some((ls) => ls.loaded && !ls.success)) {
          let errorMessage = '';
          s.forEach((ls) => {
            if (
              ls.loaded &&
              !ls.success &&
              !timeoutMessages[ls.key] &&
              environment.name !== 'dev'
            ) {
              errorMessage += 'Fehler: ' + ls.label;
              timeoutMessages[ls.key] = new Date().getTime();
              setTimeout(() => {
                delete timeoutMessages[ls.key];
              }, 10000);
            }
          });
          if (errorMessage !== '') {
            this.messagingService.error(
              'Daten Ladefehler',
              errorMessage,
              false
            );
          }
        }
      })
    );
  }
}
