<div class="h-full" *ngIf="organisationForm">
  <form
    [formGroup]="organisationForm"
    id="edit-organisation-form"
    (ngSubmit)="submitForm()"
    style="
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    ">
    <p-tabView>
      <p-tabPanel formGroupName="general">
        <ng-template pTemplate="header">
          <i class="pi pi-building mr-2"></i>
          <span
            i18n="@@organisation-edit.Allgemein"
            data-cy="name"
            [style.color]="this.organisationForm.controls.general.invalid?'red':null"
            >Name<span *ngIf="this.organisationForm.controls.general.invalid"
              >!</span
            >
            <span *ngIf="this.organisationForm.controls.general.dirty"
              >*</span
            ></span
          >
        </ng-template>
        <div class="fluid formgrid grid">
          <div class="field col">
            <span class="p-float-label required">
              <input
                id="name"
                class="w-full"
                pInputText
                formControlName="name" />
              <label for="name" i18n="@@name">Name</label>
            </span>
          </div>
        </div>
        <div class="field mb-2 mt-0">
          <label
            i18n-pTooltip="@@organisation.description.ttip"
            pTooltip="Beschreiben Sie Ihre Organisation in 100 Wörtern.">
            <span i18n="@@description">Beschreibung</span>
          </label>
          <textarea
            id="description"
            class="w-full"
            pInputText
            formControlName="description"
            rows="5"
            placeholder="Beschreibung"
            i18n-placeholder="@@description"></textarea>
        </div>
        <div
          class="p-dropdown-group w-full"
          *ngIf="currentRole === roleEnum.ADMIN && (consultancyOptions??[]).length">
          <p-dropdown
            [options]="consultancyOptions"
            [showClear]="true"
            placeholder="Berater (optional)"
            formControlName="consultancy"
            optionLabel="name"
            optionDisabled="disabled"
            optionValue="id"
            appendTo="body"
            class="cy-auditor-select">
          </p-dropdown>
        </div>
      </p-tabPanel>
      <p-tabPanel formGroupName="address">
        <ng-template pTemplate="header">
          <i class="pi pi-map mr-2"></i>
          <span
            i18n="@@organisation-edit.address"
            data-cy="address"
            [style.color]="this.organisationForm.controls.address.invalid?'red':null"
            >Addresse
            <span *ngIf="this.organisationForm.controls.address.invalid"
              >!</span
            >
            <span *ngIf="this.organisationForm.controls.address.dirty"
              >*</span
            ></span
          >
        </ng-template>
        <div class="fluid">
          <div class="fluid formgrid grid">
            <div class="field col-3">
              <span class="p-float-label required">
                <input
                  id="zip"
                  class="w-full"
                  pInputText
                  formControlName="zip" />
                <label for="zip" i18n="@@plz">PLZ</label>
              </span>
            </div>
            <div class="field col-9">
              <span class="p-float-label required">
                <input
                  id="city"
                  class="w-full"
                  pInputText
                  formControlName="city" />
                <label for="city" i18n="@@city">Ort</label>
              </span>
            </div>
          </div>
          <div class="fluid formgrid grid">
            <div class="field col">
              <span class="p-float-label required">
                <input
                  id="address"
                  class="w-full"
                  pInputText
                  formControlName="address"
                  placeholder="Straße Nr." />
                <label for="address" i18n="@@address">Addresse</label>
              </span>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel formGroupName="contact">
        <ng-template pTemplate="header">
          <i class="pi pi-id-card mr-2"></i>
          <span
            i18n="@@organisation-edit.contact"
            data-cy="contact"
            [style.color]="this.organisationForm.controls.contact.invalid?'red':null"
            >Kontakt
            <span *ngIf="this.organisationForm.controls.contact.invalid"
              >!</span
            >
            <span *ngIf="this.organisationForm.controls.contact.dirty">*</span>
          </span>
        </ng-template>

        <div class="fluid formgrid grid">
          <div class="field col">
            <span class="p-float-label">
              <input
                id="website"
                class="w-full"
                pInputText
                formControlName="website" />
              <label for="website" i18n="@@website">Website</label>
            </span>
          </div>
        </div>

        <hr />
        <div class="fluid formgrid grid">
          <div class="field col">
            <span class="p-float-label required">
              <input
                id="contact"
                class="w-full"
                pInputText
                formControlName="contact"
                placeholder="Name" />
              <label for="contact" i18n="@@organisation.contact"
                >Ansprechpartner</label
              >
            </span>
          </div>
        </div>

        <div class="fluid formgrid grid">
          <div class="field col-12 lg:col-6">
            <span class="p-float-label required">
              <input
                id="contactMail"
                class="w-full"
                pInputText
                formControlName="contactMail" />
              <label for="contactMail" i18n="@@contactMail"
                >Mail Ansprechpartner</label
              >
            </span>
          </div>
          <div class="field col-12 lg:col-6">
            <span class="p-float-label">
              <input
                id="contactPhone"
                class="w-full"
                pInputText
                formControlName="contactPhone" />
              <label for="contactPhone" i18n="@@contactPhone"
                >Tel. Ansprechpartner</label
              >
            </span>
          </div>
        </div>

        <hr />

        <div class="fluid formgrid grid">
          <div class="field col-12">
            <span class="p-float-label">
              <input
                id="internalContact"
                class="w-full"
                pInputText
                formControlName="internalContact" />
              <label for="internalContact" i18n="@@internalContact"
                >Interner Ansprechpartner</label
              >
            </span>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel
        *ngIf="![roleEnum.PROJECT_MANAGER, roleEnum.CONSULTANT].includes(currentRole ?? roleEnum.ANONYMOUS)">
        <ng-template pTemplate="header">
          <i class="pi pi-user mr-2"></i>
          <span
            i18n="@@organisation-edit.templates"
            data-cy="templates"
            [style.color]="this.organisationForm.controls.textTemplates.invalid?'red':null"
            >Text-Vorlagen<span
              *ngIf="this.organisationForm.controls.textTemplates.invalid"
              >!</span
            >
            <span *ngIf="this.organisationForm.controls.textTemplates.dirty"
              >*</span
            ></span
          >
        </ng-template>

        <p-button
          class="mb-2"
          (click)="newTextTemplateClicked()"
          label="Neue Vorlage..."></p-button>
        <p-scrollPanel [style]="{ width: '100%', height: '373px' }">
          <div>
            <div
              *ngFor="let textTemplateForm of organisationForm.controls.textTemplates.controls">
              <form [formGroup]="textTemplateForm" class="fluid formgrid grid">
                <div
                  class="field col-11 mb-0"
                  *ngIf="textTemplateForm?.controls?.name as namefb">
                  <span class="p-float-label required">
                    <input
                      id="name"
                      class="w-full"
                      pInputText
                      [formControl]="namefb" />
                    <label for="name" i18n="@@name">Name</label>
                  </span>
                </div>
                <div class="field col-1 mb-0 pl-0 flex align-items-center">
                  <p-button
                    [style]="{'width': '2rem', 'height':'2rem'}"
                    [rounded]="true"
                    [text]="false"
                    [outlined]="true"
                    icon="pi pi-times"
                    (click)="deleteTextTemplate(textTemplateForm)"></p-button>
                </div>
                <div
                  class="field col-12 mb-0"
                  *ngIf="textTemplateForm?.controls?.text">
                  <eva-editor
                    #conclusionEditor
                    [editorStyle]="{ 'font-size': '1.1rem' }"
                    [formControlName]="'text'">
                    <span class="ql-formats">
                      <button
                        type="button"
                        (click)="variablesOverlay.toggle($event)">
                        <i class="pi pi-key"></i>
                      </button>
                    </span>
                  </eva-editor>
                  <p-overlayPanel
                    [style]="{'z-index': '9999'}"
                    #variablesOverlay>
                    <div class="variable-list">
                      <div
                        class="variable-item"
                        *ngFor="let ttVar of textTemplateService.Variables; trackBy: trackByFn;"
                        (click)="insertVariable(variablesOverlay, conclusionEditor.editor, ttVar.variable)">
                        <div class="name">{{ttVar.name}}</div>
                        <div class="value" *ngIf="sampleCertification$">
                          {{
                          textTemplateService.replaceVariablesInTextTemplate(ttVar.variable,
                          (sampleCertification$ | async))}}
                        </div>
                      </div>
                    </div>
                  </p-overlayPanel>
                </div>
              </form>

              <hr style="border-top-color: var(--primary-color)" />
            </div>
          </div>
        </p-scrollPanel>
      </p-tabPanel>
    </p-tabView>
  </form>
</div>
