/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import '@angular/localize/init';
import {
  Document,
  DocumentCategory,
  UpdateUserInput,
  User,
} from '@eva/data-access/shared';
import { DialogData } from '@eva/ng-base';
import { Select, Store } from '@ngxs/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription } from 'rxjs';
import { UpdateAuthenticatedUserAction } from '../../../actions/userActions';
import { MessagingService } from '../../../services/messaging.service';
import { AppState } from '../../../states/app.state';

export interface AgbDialogData {
  agbMustBeAccepted?: boolean;
  user?: User;
}

@Component({
  selector: 'eva-agb-popup',
  templateUrl: './agb.component.html',
})
export class AgbComponent implements OnDestroy {
  @Select(AppState.systemDocuments) systemDocuments$: Observable<Document[]>;
  @Output() done = new EventEmitter<boolean>();

  private _subscriptions: Subscription;

  userForm: FormGroup<{ agbAccepted: FormControl<boolean | null> }>;

  userId: string;

  agbDocumentLink: string;

  termsOfUsageLink: string;

  agbFirstView = true;

  private agbDocId = '';

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    public conf: DynamicDialogConfig<DialogData<AgbDialogData>>,
    private messagingService: MessagingService
  ) {
    this._subscriptions = new Subscription();
    const user = this.conf?.data?.user;
    if (user) {
      this.userId = user.id;
      this.agbFirstView = user.agbAcceptedDate === '';
    }
    this._subscriptions.add(
      this.systemDocuments$.subscribe((docs) => {
        docs.forEach((d) => {
          if (d.category === DocumentCategory.AGB_STANDARD) {
            this.agbDocumentLink = 'document/' + d.id + '/' + d.file.fileName;
            this.agbDocId = d.id;
          } else if (d.category === DocumentCategory.AGB_PLATFORM) {
            this.termsOfUsageLink = 'document/' + d.id + '/' + d.file.fileName;
          }
        });
      })
    );
    this.initForm();
    if (this.conf.data) {
      this.conf.data.actions = [
        {
          text: 'Speichern',
          i18nLabel: '@@save',
          disable: () => {
            return this.userForm;
          },
          action: () => {
            return this.submitForm();
          },
        },
      ];
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  cancel() {
    this.userForm.reset();
    this.ref.close(false);
  }

  initForm() {
    this.userForm = this.formBuilder.group<{
      agbAccepted: FormControl<boolean | null>;
    }>({
      agbAccepted: new FormControl(false, Validators.requiredTrue),
    });
  }

  submitForm(): Subscription {
    const v = this.userForm.getRawValue();
    if (!v.agbAccepted) {
      this.messagingService.error(
        'AGB müssen akzeptiert werden',
        'Sie müssen die AGB akzeptieren um eine Zertifizierung zu beantragen',
        false
      );
      return Subscription.EMPTY;
    }
    // Set update data
    const updateUserInput: UpdateUserInput = {
      agbAccepted: true,
      agbAcceptedDate: new Date(),
      agbVersionAccepted: this.agbDocId,
      id: this.userId,
    } as UpdateUserInput;

    const sub = this.store
      .dispatch(new UpdateAuthenticatedUserAction(updateUserInput))
      .subscribe(() => {
        this.ref.close(true);
      });
    this._subscriptions.add(sub);

    return sub;
  }
}
