/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrimengModule } from './primeng.module';
import { SharedComponentsModule } from './shared-components.module';

import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';

import { AgbComponent } from '../user/agb/agb.component';

import { MethodTranslationPipe } from '../../feature/methods/pipes/method-translation.pipe';
import { TrimWhitespacePipe } from '../../pipes/trimWhitespace.pipe';
import { ProjectDatatableComponent } from '../project/datatable/project-datatable.component';
import { HelpComponent } from '../static/help/help.component';

/**
 * Holds all components which are used once only
 *
 * used in tests & to reduce AppModule length
 */

@NgModule({
  declarations: [
    AgbComponent,
    ProjectDatatableComponent,
    HelpComponent,
    TrimWhitespacePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengModule,
    SharedComponentsModule,
    RouterModule,
    NgxMapLibreGLModule,
    MethodTranslationPipe,
  ],
  exports: [
    PrimengModule,
    NgxMapLibreGLModule,
    ProjectDatatableComponent,
    AgbComponent,
    TrimWhitespacePipe,
  ],
})
export class ComponentsModule {}
