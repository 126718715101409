/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  RELATION_TYPE,
  auditorRequestRelation,
} from '../auditor-request.service';

@Component({
  selector: 'eva-display-relations',
  templateUrl: './display-relations.component.html',
  styleUrls: ['./display-relations.component.scss'],
})
export class DisplayRelationsComponent {
  @Output() deleteClick = new EventEmitter<auditorRequestRelation>();
  @Input() relations: auditorRequestRelation[];
  deletingRelation: auditorRequestRelation | null;
  @Input() showDeleteButton = false;
  relationTypeEnumn = RELATION_TYPE;

  onDeleteClicked(arr: auditorRequestRelation) {
    if (arr.type !== RELATION_TYPE.INDICATION) {
      this.deletingRelation = arr;
      setTimeout(() => {
        this.deleteClick.emit(arr);
      }, 300);
    }
  }
}
