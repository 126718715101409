/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

export enum ROUTES {
  PROJECT = 'project/:projectId',
  PROJECT_EDIT = 'project/:projectId/edit',
  PROJECT_NEW = 'project/new',
  PROJECT_SHOW = 'project/:projectId/show',
  SITE_EDIT = 'site/:siteId/edit',
  SITE_NEW = 'site/new',
  SITE_SHOW = 'site/:siteId/show',
  USERS_LIST = 'users',
  USER_NEW = 'user/new',
  CERTIFICATION = 'certification/:certificationId',
  GROUP_CERTIFICATION = 'groupCertification/:groupCertificationId',
  ASSIGN_AUDITORS = 'assignAuditors',
  SYSTEM_ADMINISTRATION = 'systemAdministration',
  DASHBOARD = '',
}

export const getRoute = (
  routeId: string,
  ids: { [key: string]: string }
): string => {
  const replaceIds = (path: string, ids: { [key: string]: string }) => {
    let dynamicPath = path;
    Object.keys(ids).forEach(
      (key) => (dynamicPath = dynamicPath.replace(':' + key, ids[key]))
    );
    return dynamicPath;
  };
  let result = '';
  switch (routeId) {
    case 'listProjects':
      result = '/projects';
      break;

    case 'showProject':
      result = replaceIds(ROUTES.PROJECT, ids) + '/info';
      break;

    case 'newProject':
      result = ROUTES.PROJECT_NEW;
      break;

    case 'editProject':
      result = replaceIds(ROUTES.PROJECT, ids) + '/edit';
      break;
    case 'infoProject':
      result = replaceIds(ROUTES.PROJECT, ids) + '/info';
      break;

    case 'listSites':
      result = replaceIds(ROUTES.PROJECT, ids) + '/sites';
      break;

    case 'showSite':
      result = replaceIds(ROUTES.PROJECT + '/' + ROUTES.SITE_SHOW, ids);
      break;

    case 'newSite':
      if (ids['certificationId']) {
        result = replaceIds(ROUTES.CERTIFICATION + '/' + ROUTES.SITE_NEW, ids);
      } else {
        result = replaceIds(ROUTES.PROJECT + '/' + ROUTES.SITE_EDIT, ids);
      }
      break;

    case 'editSite':
      if (ids['certificationId']) {
        result = replaceIds(ROUTES.CERTIFICATION + '/' + ROUTES.SITE_NEW, ids);
      } else {
        result = replaceIds(ROUTES.PROJECT + '/' + ROUTES.SITE_EDIT, ids);
      }
      break;

    case 'listUsers':
      result = '/users';
      break;

    case 'newUser':
      result = ROUTES.USER_NEW;
      break;

    case 'listCertifications':
      result = '/certifications';
      break;

    case 'editCertification':
      result = replaceIds(ROUTES.CERTIFICATION, ids) + '/edit';
      break;

    case 'groupCertification':
      result = replaceIds(ROUTES.GROUP_CERTIFICATION, ids);
      break;

    default:
      result = 'not found:';
  }

  if (result.indexOf(':') > -1) {
    throw new Error('ID not replaced: ' + result);
  }
  return '/' + result;
};
