/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

/**
 * API Service
 *
 * TBD: move all GraphQL specific stuff to data-access/graphql lib?
 *
 */
import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MessagingService } from '../messaging.service';

import {
  CreateUserGQL,
  DeleteUserGQL,
  ListUsersGQL,
  LoadAuthenticatedUserGQL,
  LoadUserGQL,
  UpdateOrganisationGQL,
  UpdateUserGQL,
} from '@eva/data-access/graphql';

import {
  CreateUserInput,
  Organisation,
  UpdateOrganisationInput,
  UpdateUserInput,
  User,
  UserSimple,
} from '@eva/data-access/shared';
import { GraphQLError } from 'graphql';

@Injectable()
export class UserService {
  constructor(
    private loadAuthenticatedUserQuery: LoadAuthenticatedUserGQL,
    private listUsers: ListUsersGQL,
    private createUserMutation: CreateUserGQL,
    private deleteUserMutation: DeleteUserGQL,
    private updateUserMutation: UpdateUserGQL,
    private updateOrganisationMutation: UpdateOrganisationGQL,
    private loadUserQuery: LoadUserGQL,
    private messageService: MessagingService
  ) {}

  /**
   * this error handlers shows an error message via MessagingService
   * and returns an empty array which means dispatched actions and subscribers
   * will not get the error anymore
   *
   * This is mainly for convenience (reducing error handling in components)
   * but there might be use cases where this is not wanted, then this
   * error handler should not be used
   */
  private errorHandler(err: any) {
    if (environment.debug && (err.graphQLErrors || err.networkError)) {
      err.graphQLErrors.forEach((error: GraphQLError) => {
        console.debug('graphQLError', error);
        if (error.message) {
          this.showError('Fehler', error.message);
        }
      });
      if (err.networkError?.statusText) {
        this.showError('Fehler', err.networkError.statusText);
      }
      if (err.networkError?.error?.errors?.length > 0) {
        err.networkError.error.errors.forEach((e: Error) => {
          console.debug('Network Error:' + e.message);
        });
      }
    } else if (environment.debug && err.message) {
      this.showError('Client-Fehler', err.message);
    } else {
      throw Error(
        'Fehler: die gewünschte Aktion konnte nicht durchgeführt werden. Bitte informieren Sie das WKS Sekreteriat!'
      );
    }
    return of(undefined);
  }

  private extractData<T>(result: ApolloQueryResult<T> | FetchResult<T>) {
    if (!result.data || result.errors?.length) {
      if (result.errors) {
        this.showError(
          'Fehler: keine Daten verfügbar!',
          result.errors[0].message
        );
      }
      return null;
    } else {
      return result.data;
    }
  }

  private showError(headline: string, message: string) {
    this.messageService.error(headline, message);
  }

  loadUser(id: string): Observable<User | undefined> {
    return this.loadUserQuery.watch({ id }).valueChanges.pipe(
      map((result) => this.extractData(result)),
      map((data) => data?.user),
      catchError((error) => this.errorHandler(error))
    );
  }

  loadUsers(): Observable<UserSimple[] | undefined> {
    return this.listUsers.watch().valueChanges.pipe(
      map((result) => this.extractData(result)),
      map((data) => data?.users)
    );
  }

  /**
   *
   * @param input
   * @returns
   */
  createUser(input: CreateUserInput): Observable<User | undefined> {
    return this.createUserMutation.mutate({ input }).pipe(
      tap((result) => console.debug(result)),
      map((result) => result.data?.createUser),
      catchError((error) => this.errorHandler(error))
    );
  }

  /**
   *
   * @param input
   * @returns
   */
  updateUser(input: UpdateUserInput): Observable<User | undefined> {
    return this.updateUserMutation.mutate({ input }).pipe(
      map((result) => result.data?.updateUser),
      catchError((error) => this.errorHandler(error))
    );
  }

  /**
   *
   * @param input
   * @returns
   */
  updateOrganisation(
    input: UpdateOrganisationInput
  ): Observable<Organisation | undefined> {
    return this.updateOrganisationMutation.mutate({ input }).pipe(
      map(
        (result) =>
          (result.data?.updateOrganisation as Organisation) ?? undefined
      ),
      catchError((error) => this.errorHandler(error))
    );
  }

  loadAuthenticatedUser(): Observable<User | null | undefined> {
    return this.loadAuthenticatedUserQuery.watch().valueChanges.pipe(
      map((result) => {
        if (
          !result.data ||
          !result.data.authenticatedUser ||
          result.errors?.length
        ) {
          if (result.errors) {
            this.showError(
              'Fehler: keine Daten verfügbar!',
              result.errors[0].message
            );
          }

          return null;
        }
        return result.data.authenticatedUser;
      }),
      catchError((error) => this.errorHandler(error))
    );
  }

  /**
   * Delete user
   * @param id
   * @returns
   */
  delete(id: string) {
    return this.deleteUserMutation
      .mutate({ id })
      .pipe(map((result) => result.data?.deleteUser));
  }
}
