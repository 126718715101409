<div class="layout-topbar">
  <div class="layout-topbar-start">
    <a class="layout-topbar-logo aplogo" routerLink="/"> </a>
    <div style="width: 1rem"></div>
    <a
      #menuButton
      class="layout-menu-button"
      (click)="appMain.onMenuButtonClick($event)"
      pRipple>
      <i class="pi pi-chevron-right"></i>
    </a>
    <a
      #mobileMenuButton
      class="layout-topbar-mobile-button"
      [class.layout-topbar-mobile-active]="appMain.mobileTopbarActive"
      (click)="appMain.onTopbarMobileButtonClick($event)"
      pRipple>
      <i class="pi pi-ellipsis-v"></i>
    </a>
  </div>

  <div
    class="layout-topbar-end"
    [class.layout-topbar-mobile-active]="appMain.mobileTopbarActive">
    <div class="layout-topbar-actions-start">
      <span
        *ngIf="environment"
        class="text-left text-2xl font-bold environment">
        {{ environment }}
      </span>
    </div>
    <div class="layout-topbar-actions-end">
      <ul class="layout-topbar-items">
        <p-megaMenu [model]="model" styleClass="layout-megamenu"></p-megaMenu>
      </ul>
    </div>
  </div>
</div>
