/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// eslint-disable-next-line @nx/enforce-module-boundaries
import pkg from '../../../package.json';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://17b988d88631f2f4059b07372cdb5189@sentry.eva-dev.net/3',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.name === 'staging' ? 1.0 : 0.5,

  // set release variable
  release: pkg.version,

  // set environment
  environment: environment.name,

  // only enabled for prod and staging
  enabled: environment.name === 'prod' || environment.name === 'staging',

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    'https://staging.eva-dev.net',
    'https://projekte.waldklimastandard.de/',
  ],

  // Capture Replay for 0% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
