/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import { Inject } from '@angular/core';
import { Certification } from '@eva/data-access/shared';
import Dexie, { Table } from 'dexie';
import { AppState } from '../states/app.state';

@Inject({
  providedIn: 'root',
})
export class OfflineDbService extends Dexie {
  lastState!: Table<{ offline: boolean; version: string }>;
  certifications!: Table<Certification>;
  appState!: Table<AppState>;
  certificationState!: Table<AppState>;

  constructor() {
    super('wksdb');
    this.version(31).stores({
      lastState: 'id',
      certifications: 'id',
      appState: 'id',
      certificationState: 'id',
    });
    this.open()
      .then((data) => console.log('DB Opened', data))
      .catch((err) => console.log(err.message));
  }
}

export const dbService = new OfflineDbService();
