<p-fileUpload
  #fileUpload
  class="mt-3 block"
  name="demo[]"
  [disabled]="disabled"
  (onSelect)="onSelect($event, fileUpload)"
  (onClear)="selectionCleared($event, fileUpload)"
  (onRemove)="fileRemoved($event, fileUpload)"
  (uploadHandler)="uploadFile($event, fileUpload)"
  (onProgress)="onProgress($event)"
  [multiple]="multiple"
  [chooseLabel]="chooseLabel"
  [showUploadButton]="!externalControlled"
  [showCancelButton]="!externalControlled"
  uploadLabel="Upload"
  cancelLabel="Cancel"
  autofocus
  [maxFileSize]="maxFileSize"
  [customUpload]="true"
  [mode]="_selectedFiles.length ? 'advanced' : 'basic'">
  <ng-template pTemplate="toolbar" *ngIf="useInternalCategorySelection">
    <div style="max-width: 100%" class="ml-auto p-1">
      <span i18n="@@category">Kategorie</span><span class="mr-1">: </span>

      <p-dropdown
        (ngModelChange)="categoryChanged($event)"
        [appendTo]="'body'"
        [style]="{ 'min-width': '185px' }"
        data-cy="category-dropdown"
        inputId="category-select1"
        [options]="documentCategoryList"
        optionLabel="value"
        optionValue="key"
        emptyMessage="Allgemein"
        [(ngModel)]="category">
        <ng-template let-item pTemplate="item">
          <span [attr.id]="item.key">{{item.value}}</span>
        </ng-template>
      </p-dropdown>
    </div>
  </ng-template>

  <ng-template let-file let-i pTemplate="file">
    <div
      *ngIf="currentFileInfos[file.name + file.lastModified + file.size] as fileData"
      class="flex justify-content-between gap-2 align-items-center mb-3 flex-wrap p-3"
      style="background-color: var(--gray-200); border-radius: 4px">
      <div *ngIf="file && fileUpload.isImage(file)">
        <img [src]="file.objectURL" [width]="fileUpload.previewWidth" />
      </div>
      <div style="flex-grow: 100">
        <span class="p-float-label w-full">
          <input
            class="w-full"
            type="text"
            id="fileName"
            [(ngModel)]="
              fileData.name
            "
            pInputText />
          <label for="fileName" i18n="@@name">Name</label>
        </span>
      </div>

      <div style="flex-grow: 100">
        <span class="p-float-label w-full">
          <textarea
            class="w-full"
            pInputTextarea
            [rows]="1"
            [autoResize]="true"
            [(ngModel)]="
              fileData.remark
            "
            (ngModelChange)="fileData.visibility = !$event?.length ? DocumentVisibility.PUBLIC: fileData.visibility"
            id="remark"></textarea>
          <label for="remark" i18n="@@annotation">Anmerkung</label>
        </span>
      </div>
      <div style="min-width: fit-content">
        <p-selectButton
          [pTooltip]="!fileData.remark ? 'Bitte geben Sie eine Anmerkung ein, wenn Sie das Dokument nicht öffentlich machen wollen.' : ''"
          [disabled]="!visiblilitySelection"
          optionDisabled="inactive"
          [allowEmpty]="false"
          [options]="[{value:DocumentVisibility.PRIVATE, inactive: !fileData.remark, icon: 'pi-eye-slash', tooltip:'Nicht Öffentlich'}, {value:DocumentVisibility.PUBLIC, inactive: false, icon: 'pi-eye', tooltip:'Öffentlich'}]"
          [(ngModel)]="
            fileData
              .visibility
          "
          optionValue="value">
          <ng-template let-item pTemplate>
            <div style="width: 100%" [pTooltip]="item.tooltip">
              <i class="pi" [class]="item.icon"></i>
            </div>
          </ng-template>
        </p-selectButton>
      </div>
      <div style="min-width: fit-content">{{ formatSize(file.size) }}</div>

      <p-checkbox
        *ngIf="fileData.isPDF && fileData.visibility === DocumentVisibility.PUBLIC"
        [trueValue]="true"
        [binary]="true"
        [(ngModel)]="fileData.pddAppendix"
        i18n-label="@@pddAppendix"
        i18n-pTooltip="@@pddAppendix.ttip"
        pTooltip="Dieses Dokument als Anhang im PDD anzeigen"
        label="PDD-Anhang"></p-checkbox>

      <div>
        <button
          type="button"
          icon="pi pi-times"
          pButton
          (click)="fileUpload.remove($event, i)"
          [disabled]="fileUpload.uploading"></button>
      </div>
    </div>
  </ng-template>
</p-fileUpload>
