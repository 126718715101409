/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PrimeNGConfig } from 'primeng/api';

import * as translations from '../assets/i18n/primeng-translations.json';

import { LoadProjectsAction } from './states/actions/project-actions';

import { Observable } from 'rxjs';
import { LoadSystemDataAction } from './actions/systemActions';
import { AuthorizationService } from './services/authorization.service';
import { AppState } from './states/app.state';

@Component({
  selector: 'eva-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(AppState.getIsOffline) isOffline$: Observable<boolean>;
  @Select(AppState.getIsTimeout) isTimeout$: Observable<boolean>;

  showRedirectScreen = false;
  showLoading = true;
  showTimeout = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    private authorizationService: AuthorizationService,
    private store: Store
  ) {}

  ngOnInit() {
    this.isTimeout$.subscribe({
      next: (val) => {
        this.showTimeout = val;
      },
    });
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(translations);
    this.authorizationService.getRole().then(() => {
      this.showRedirectScreen = false;
      this.showLoading = false;
      this.store.dispatch(new LoadProjectsAction());
      this.store.dispatch(new LoadSystemDataAction());
    });
    if (window.location.pathname.indexOf('unauthorized') === 1) {
      // this is the only component which should be visible for not authenticated users
      this.showRedirectScreen = false;
      this.showLoading = false;
    }
    if (window.location.hash === '#login') {
      this.showRedirectScreen = true;
      this.showLoading = false;
    }
  }
}
