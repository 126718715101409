<ng-container *ngIf="relation">
  <ng-container [ngSwitch]="relation.type">
    <div class="relation-container">
      <ng-container *ngSwitchCase="'Site'">
        <ng-container
          *ngTemplateOutlet="siteTemplate; context:{$implicit: relation}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'GrowthModel'">
        <ng-container
          *ngTemplateOutlet="growthModelTemplate; context:{$implicit: relation}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'Baseline'">
        <ng-container
          *ngTemplateOutlet="baselineTemplate; context:{$implicit: relation}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'Indication'">
        <ng-container
          *ngTemplateOutlet="indicationTemplate; context:{$implicit: relation}"></ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault="">
        <i class="pi pi-circle m-2"></i>{{relation.label}}</ng-container
      >
      <div class="relation-delete-button" *ngIf="showDeleteButton">
        <p-button
          icon="pi pi-times"
          styleClass="p-button-danger"
          (click)="onDeleteClicked()"></p-button>
      </div>
      <!-- <i class="pi pi-chart-line"></i>
          {{ baseline.name }} ({{ baseline.sites?.length }} Flächen,
          {{baseline.totalArea / 10000 | number : '1.0-0'}} ha) -->
    </div>
  </ng-container>
</ng-container>

<ng-template #siteTemplate let-site>
  <div class="item-grid site-item-grid">
    <div class="icon">
      <i class="pi pi-map"></i>
    </div>
    <div class="label">
      <span>{{site.label}}</span>
    </div>
    <div class="size">
      <span>{{site.data.totalArea / 10000 | number : '1.1-1' }} ha</span>
    </div>
  </div>
</ng-template>

<ng-template #baselineTemplate let-baseline>
  <div class="item-grid ar-model-item-grid">
    <div class="icon">
      <i class="pi pi-chart-bar"></i>
    </div>
    <div class="label">
      <span>{{baseline.label}}</span>
    </div>
    <div class="size">
      <span>{{baseline.data.totalArea / 10000 | number : '1.1-1' }} ha</span>
    </div>

    <div class="co2a data-pair headline" *ngIf="certification">
      <span>CO2/{{ certification.creditingPeriod}}</span>
      <span i18n="@@unit.year">J.</span>
    </div>
    <div class="co2a data-pair value" *ngIf="certification">
      <span> {{ baseline.data.co2Storage | number : '1.0-0' }} t</span>
    </div>
    <div class="co2b data-pair headline">
      <span>CO2 t/ha</span>
    </div>
    <div class="co2b data-pair value">
      <span>
        {{ (baseline.data.co2Storage / baseline.data.totalArea) * 10000 | number
        : '1.0-1' }}</span
      >
    </div>
  </div>
</ng-template>

<ng-template #growthModelTemplate let-growthmodel>
  <div class="item-grid ar-model-item-grid">
    <div class="icon">
      <i class="pi pi-chart-line"></i>
    </div>
    <div class="label">
      <span>{{growthmodel.label}}</span>
    </div>
    <div class="size">
      <span>{{growthmodel.data.totalArea / 10000 | number : '1.1-1' }} ha</span>
    </div>

    <div class="co2a data-pair headline" *ngIf="certification">
      <span>CO2/{{ certification.creditingPeriod}}</span>
      <span i18n="@@unit.year">J.</span>
    </div>
    <div class="co2a data-pair value" *ngIf="certification">
      <span> {{ growthmodel.data.co2Storage | number : '1.0-0' }} t</span>
    </div>
    <div class="co2b data-pair headline">
      <span>CO2 t/ha</span>
    </div>
    <div class="co2b data-pair value">
      <span>
        {{ (growthmodel.data.co2Storage / growthmodel.data.totalArea) * 10000 |
        number : '1.0-1' }}</span
      >
    </div>
  </div>
</ng-template>

<ng-template #indicationTemplate let-indication>
  <div class="item-grid indication-item-grid">
    <div class="icon">
      <i class="pi pi-check-square"></i>
    </div>
    <div class="label" *ngIf="indication.data2">
      <span>{{indication.data2.nodeIndex}}</span>
    </div>
    <div class="title" *ngIf="indication.data2">
      <span>{{indication.data2.title}}</span>
    </div>

    <!-- <div
      class="status"
      *ngIf="indication.data.verificationStatus !== VerificationStatusEnum.NONE">
      <span
        class="verification-status"
        [ngClass]="indication.data.verificationStatus">
        {{getShortName(indication.data.verificationStatus)}}</span
      >
    </div> -->
  </div>
</ng-template>
