/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { SystemMessageInput } from '@eva/data-access/shared';

export class LoadSystemDataAction {
  static readonly type = '[SystemData] LoadSystemData';
}

export class CreateSystemMessageAction {
  static readonly type = '[SystemMessage] Create';
  constructor(public input: SystemMessageInput) {}
}

export class UpdateSystemMessageAction {
  static readonly type = '[SystemMessage] Update';
  constructor(public payload: { id: string; input: SystemMessageInput }) {}
}

export class DeleteSystemMessageAction {
  static readonly type = '[SystemMessage] Delete';
  constructor(public id: string) {}
}
