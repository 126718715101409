/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import { StateContext } from '@ngxs/store';
import { AppState, updateCertificationInStateContext } from '../app.state';
import { attachAction } from '../attach-actions';

import { take, tap } from 'rxjs/operators';
import { CertificationApiService } from '../../services/api/certification.api.service';

import { CreateSiteInput, UpdateSiteInput } from '@eva/data-access/shared';

/**
 * helper function called in state constructor
 * attach all actions from this file
 */
export const loadSiteActions = (
  certificationService: CertificationApiService
) => {
  attachAction(
    AppState,
    CreateSiteAction,
    createSiteAction(certificationService)
  );

  attachAction(AppState, LoadSiteAction, loadSiteAction(certificationService));

  attachAction(
    AppState,
    DeleteSiteAction,
    deleteSiteAction(certificationService)
  );

  attachAction(
    AppState,
    UpdateSiteAction,
    updateSiteAction(certificationService)
  );
};

export class CreateSiteAction {
  static readonly type = '[Site] Create';
  constructor(public payload: CreateSiteInput[]) {}
}

/**
 *
 * Action execution in state
 *
 */
export const createSiteAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: CreateSiteAction) => {
    return certificationService.createSite(action.payload).pipe(
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
        }
      })
    );
  };

export class UpdateSiteAction {
  static readonly type = '[Site] Update';
  constructor(public payload: UpdateSiteInput) {}
}

/**
 *
 * Action execution in state
 *
 */
export const updateSiteAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: UpdateSiteAction) => {
    return certificationService.updateSite(action.payload).pipe(
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
        }
      })
    );
  };

export class DeleteSiteAction {
  static readonly type = '[Site] Delete';
  constructor(public id: string) {}
}

/**
 *
 * Action execution in state
 *
 */
export const deleteSiteAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: DeleteSiteAction) => {
    return certificationService.deleteSite(action.id).pipe(
      take(1), // needed to complete Observable
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
        }
      })
    );
  };

export class LoadSiteAction {
  static readonly type = '[Site] GetSite';
  constructor(public id: string) {}
}

/**
 *
 * Action execution in state
 *
 */
export const loadSiteAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: LoadSiteAction) => {
    // TODO: refresh whole certification??
    return certificationService.loadSite(action.id).pipe(
      take(1), // needed to complete Observable
      tap((site) => {
        if (site) {
          stateContext.patchState({ currentSite: site });
        }
      })
    );
  };
