/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import { Point } from 'geojson';

import {
  ArBaselineDataInterface,
  ArGrowthModelDataInterface,
  ArTreeSpeciesInterface,
} from '@eva/ar-services';

import {
  IfmBaselineDataInterface,
  IfmGrowthModelDataInterface,
} from '@eva/ifm-services';

import {
  AuditorRequestInterface,
  IndicatorInterface,
} from './standard.interfaces';

export enum Role {
  ADMIN = 'ADMIN',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  CONSULTANT = 'CONSULTANT',
  AUDITOR = 'AUDITOR',
  ANONYMOUS = 'ANONYMOUS',
}

export enum Language {
  DE = 'de-DE',
  EN_GB = 'en-GB',
  EN_US = 'en-US',
}

export enum CertificationStatus {
  DRAFT = 'DRAFT', // in Vorbereitung (Default)
  VALID_FOR_REVIEW = 'VALID_FOR_REVIEW', // bereit zur Prüfung (Software)
  PRECHECK_REQUESTED = 'PRECHECK_REQUESTED', // Precheck beantragt, (PM)
  REVIEW_REQUESTED = 'REVIEW_REQUESTED', // Auditor beantragt, (EVA)
  REVIEW_IN_PROGRESS = 'REVIEW_IN_PROGRESS', // Auditor prüft, (Software)
  REVIEW_INQUIRY = 'REVIEW_INQUIRY', // Auditor hat Rückfragen (Auditor)
  VALID_FOR_RE_REVIEW = 'VALID_FOR_RE_REVIEW', // bereit zur erneuten Prüfung (Software)
  RE_REVIEW_REQUESTED = 'RE_REVIEW_REQUESTED', // erneute Prüfung beantragt, (PM)
  RE_REVIEW_IN_PROGRESS = 'RE_REVIEW_IN_PROGRESS', // Auditor prüft erneut, (Software)
  INTERNAL_REVIEW = 'INTERNAL_REVIEW', // Interne Prüfung beim Auditor (Auditor) TODO!
  REVIEW_FINISHED = 'REVIEW_FINISHED', // Prüfung vollständig (Software)
  NON_COMPLIANT = 'NON_COMPLIANT', // Zertifizierung ist nicht compliant (Auditor)
  COMPLIANT = 'COMPLIANT', // Zertifizierung ist compliant (Auditor)
  WITHDRAWAL = 'WITHDRAWAL', // Zertifizierung wurde zurückgezogen (PM) TODO!
  PUBLISHED = 'PUBLISHED', // Transfer in Registry (EVA)
}

export const CertificationStatusLocalization = {
  DRAFT: 'Entwurf',
  VALID_FOR_REVIEW: 'Bereit zur Prüfung',
  PRECHECK_REQUESTED: 'Audit angefragt',
  REVIEW_REQUESTED: 'Audit angefragt',
  REVIEW_IN_PROGRESS: 'Auditor prüft',
  REVIEW_INQUIRY: 'Auditor hat Rückfragen',
  VALID_FOR_RE_REVIEW: 'Bereit zur erneuten Prüfung',
  RE_REVIEW_REQUESTED: 'Erneute Prüfung beantragt',
  RE_REVIEW_IN_PROGRESS: 'Auditor prüft erneut',
  INTERNAL_REVIEW: 'Interne Review beim Auditor',
  REVIEW_FINISHED: 'Prüfung vollständig',
  COMPLIANT: 'Zertifizierung erfolgreich abgeschlossen',
  NON_COMPLIANT: 'Zertifizierung wurde abgewiesen',
  WITHDRAWAL: 'Zertifizierung wurde zurückgezogen',
  PUBLISHED: 'Zertifizierung ist veröffentlicht', // Zertifizierung ist abgeschlossen (wird vom Sekretariat gesetzt)
};

export enum VerificationStatus {
  NONE = 'NONE',
  COMPLIANT = 'COMPLIANT',
  CORRECTIVE_ACTION_REQUEST = 'CORRECTIVE_ACTION_REQUEST', // Corrective Action Request
  CLARIFICATION_REQUEST = 'CLARIFICATION_REQUEST', // Clarification Request
  FORWARD_ACTION_REQUEST = 'FORWARD_ACTION_REQUEST', //
  NON_COMPLIANT = 'NON_COMPLIANT',
  ENDANGERED = 'ENDANGERED',
}

export enum StandardMethod {
  AR = 'AR', // Afforestation/Reforestation
  IFM = 'IFM', // Improved Forest Management
}

export type StandardVersion = '1.1' | '1.0' | '0.4';

export enum OrganisationType {
  COMPANY = 'COMPANY',
  CONSULTANCY = 'CONSULTANCY',
  ORGANISATION = 'ORGANISATION',
  TOWNSHIP = 'TOWNSHIP',
  STATE = 'STATE',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  PERSON = 'PERSON',
}

export enum GeometryType {
  Point = 'Point',
  MultiPoint = 'MultiPoint',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  Polygon = 'Polygon',
  MultiPolygon = 'MultiPolygon',
  GeometryCollection = 'GeometryCollection',
}

export enum StorageAccessType {
  PUBLIC = 'PUBLIC',
  SECURE = 'SECURE',
}

/**
 * PUBLIC or PRIVATE visibility (maybe more?)
 */
export enum DocumentVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum DocumentCategory {
  ADDITIONALITY = 'ADDITIONALITY',
  AGB_STANDARD = 'AGB_STANDARD',
  AGB_PLATFORM = 'AGB_PLATFORM',

  ALL_CERTIFICATION_DOCS_ZIP = 'ALL_CERTIFICATION_DOCS_ZIP',

  FOREST_MANAGEMENT_PLAN = 'FOREST_MANAGEMENT_PLAN',
  FSC_CERTIFICATION = 'FSC_CERTIFICATION',

  PEFC_CERTIFICATION = 'PEFC_CERTIFICATION',
  PRE_CERTIFICATION = 'PRE_CERTIFICATION',

  SIGNED_PDD = 'SIGNED_PDD',
}

export const DocumentCategoryLocalization = {
  ADDITIONALITY: 'Nachweise zur finanz. Additionalität',
  AGB_PLATFORM: 'Nutzungsbedingungen',
  AGB_STANDARD: 'AGB',
  ALL_CERTIFICATION_DOCS_ZIP: 'Zip aus allen Dokumenten',

  FSC_CERTIFICATION: 'FSC Zertifizierung',
  FOREST_MANAGEMENT_PLAN: 'Forsteinrichtungsplan',

  PEFC_CERTIFICATION: 'PEFC Zertifizierung',
  PRE_CERTIFICATION: 'Pre-Zertifizierung',
  SIGNED_PDD: 'PDD (Signiert)',
};

export interface Geometry {
  type: GeometryType;
  coordinates: JSON;
}

export interface OrganisationInterface {
  id: string;

  readonly createdAt?: Date;

  updatedAt?: Date | null;

  updatedBy?: UserInterface | undefined;

  internalContact?: string | null;

  owner: UserInterface | null;

  ownerId: string;

  name: string;

  description?: string | null;

  users?: UserInterface[];

  clients?: OrganisationInterface[];

  consultancy?: OrganisationInterface;

  type: OrganisationType;

  zip?: string | null;

  city?: string | null;

  address?: string | null;

  website?: string | null;

  contact?: string | null;

  contactMail?: string | null;

  contactPhone?: string | null;
}

export interface UserInterface {
  id: string;

  createdAt?: Date;

  updatedAt?: Date;

  createdBy?: UserInterface | undefined;

  created_by?: string;

  organisation?: OrganisationInterface;

  title?: string;

  firstName: string;

  lastName: string;

  email: string;

  phone?: string;

  remarks?: string;

  oauthId: string;

  role: Role;

  agbAccepted: boolean;

  agbVersionAccepted: string;

  agbAcceptedDate?: Date;

  isActive: boolean;

  isBlocked?: boolean;

  documents?: DocumentInterface[];

  organisations?: OrganisationInterface[];
}

export interface BaseInterface {
  id: string;

  createdAt?: Date;

  updatedAt?: Date;

  deletedAt?: Date;

  createdBy?: UserInterface | undefined;

  created_by?: string;

  updatedBy?: UserInterface | undefined;

  updated_by?: string;

  /**
   * is set to true once the document is sent to review
   * visibility and name stay editable but document can't be deleted!
   */
  readonly?: boolean;
}

export interface ImageInterface extends BaseInterface, FileInterface {
  owner: UserInterface | null;

  ownerId: string;

  name: string;

  copyright?: string;

  project: ProjectPartialInterface;

  projectId: string;

  data: Uint8Array;
}

export interface FileInterface {
  id: string;

  createdAt?: Date;

  fileName: string;

  fileType: string;

  fileSize: number;

  md5: string;

  path: string;

  data: Uint8Array;
}

export interface DocumentInterface extends BaseInterface {
  owner: UserInterface | null;

  ownerId: string;

  name: string;

  visibility?: DocumentVisibility;

  author?: string;

  remarks: string;

  source?: string;

  file: FileInterface;

  fileId: string;

  project?: ProjectInterface;

  projectId?: string;

  readonly?: boolean;

  certificationId?: string;
  groupCertificationId?: string;

  user?: UserInterface;

  userId?: string;

  pddAppendix?: boolean;

  category?: DocumentCategory;
}

export interface DocumentRelationInterface extends BaseInterface {
  document: DocumentInterface;

  indication?: IndicationInterface;
  auditorRequest?: AuditorRequestInterface;
  baseline?: ScenarioInterface;
  growthModel?: ScenarioInterface;
}

export interface ProjectInterface extends ProjectPartialInterface {
  subtitle?: string;

  teaser?: string;

  description?: string;

  regioInfo?: string;

  totalArea?: number;

  latitude?: string;

  longitude?: string;

  certifications?: CertificationPartialInterface[];

  documents?: DocumentInterface[];

  images?: ImageInterface[];
}

export interface ProjectPartialInterface extends BaseInterface {
  startDate?: Date;

  name: string;

  owner: UserInterface;

  ownerId: string;

  projectCode?: string;
}

export interface SiteInterface extends BaseInterface {
  ownerId: string;

  label: string;

  description?: string;

  totalArea?: number;

  certificationId: string;

  baseline?: ScenarioInterface;

  baselineId?: string;

  growthModel?: ScenarioInterface;

  growthModelId?: string;

  auditorRequests?: AuditorRequestInterface[];

  region?: GrowthRegionInterface;

  regionId?: number;

  geometry?: Geometry;

  unstockedArea?: number;

  owner: UserInterface;
}

export interface GrowthRegionInterface {
  id: number;

  name: string;

  kwb50: number;

  kwb100: number;
}

export interface Co2PerYearInterface {
  id: string;

  co2: number;

  year: number;

  yearDiff?: number | null;

  avoidance?: number | null;

  baseline?: ScenarioInterface | null;

  growthModel?: ScenarioInterface | null;

  certificationId?: string | null;

  species?: ArTreeSpeciesInterface | null;

  speciesNumber?: number | null;
}

export interface ScenarioInterface extends BaseInterface {
  name: string;

  inputId?: string;

  input?:
    | ArBaselineDataInterface
    | ArGrowthModelDataInterface
    | IfmBaselineDataInterface
    | IfmGrowthModelDataInterface
    | null;

  totalArea?: number;

  co2Storage?: number;

  co2Avoidance?: number;

  co2Years?: Co2PerYearInterface[];

  sites?: SiteInterface[];

  certification?: CertificationPartialInterface;

  certificationId: string;

  auditorRequests?: AuditorRequestInterface[];

  documents?: DocumentInterface[];

  valid: boolean;

  reviewed: boolean;

  verificationStatus: VerificationStatus;
}

export interface IndicationInterface extends BaseInterface {
  indicator: IndicatorInterface;

  indicatorId: string;

  certification?: CertificationPartialInterface;

  certificationId: string;

  text: string;

  documents?: DocumentInterface[];

  messages?: MessageInterface[];

  auditorRequests?: AuditorRequestInterface[];

  confirmed: boolean;

  valid: boolean;

  reviewed: boolean;

  verificationStatus: VerificationStatus;
}

export interface CertificationPartialInterface extends BaseInterface {
  certifier?: UserInterface;

  certifierId?: string;

  certificationCompleteDate?: Date;

  certificationStartDate?: Date;

  certificationConclusion?: string;

  certificationOpinion?: string;

  consultant?: UserInterface;

  co2Storage?: number;

  co2Avoidance?: number;

  creditingPeriod?: number;

  creditingPeriodStart?: Date;

  growthRegion?: GrowthRegionInterface;

  group?: GroupCertificationInterface;

  groupId?: string;

  internalContact?: string;

  internalRemarks?: string;

  involvedPersons?: string;

  location?: Point;

  method: StandardMethod;

  name: string;

  owner: UserInterface;

  ownerId: string;

  project: ProjectPartialInterface;

  sample: boolean;

  standardVersion: string;

  startDate?: Date;

  status: CertificationStatus;

  testCertification?: boolean;

  timeSchedule?: string;

  totalArea?: number;
}

export interface IndicationWithCertification extends IndicationInterface {
  certification: CertificationPartialInterface;
}

export interface MessageInterface extends BaseInterface {
  text: string;

  indication: IndicationInterface;

  auditorRequest?: AuditorRequestInterface;

  parentMessage?: MessageInterface;
  parentMessageId?: string;
  unread: boolean;

  hasAnswer: boolean;

  notified: boolean;

  verificationStatus?: VerificationStatus;
}

export interface CertificationInterface extends CertificationPartialInterface {
  auditorRequests: AuditorRequestInterface[];

  baselines: ScenarioInterface[];

  co2Years: Co2PerYearInterface[];

  documents: DocumentInterface[];

  growthModels: ScenarioInterface[];

  indications: IndicationInterface[];

  sites: SiteInterface[];
}

export interface CertificationWithProjectInterface
  extends CertificationInterface {
  project: ProjectInterface;
}

export interface GroupCertificationInterface extends BaseInterface {
  name: string;
  code: string;
  status: CertificationStatus;
  certifier?: UserInterface | null;
  certifierId?: string;
  owner: UserInterface;
  ownerId: string;
  auditorRequests?: AuditorRequestInterface[];
  certifications?: CertificationInterface[];
  documents: DocumentInterface[];
}

export interface HistoryInterface extends BaseInterface {
  operation: string;
  resourceType: string;
  diff: object | null;
  user?: UserInterface | null;
  certification?: CertificationPartialInterface | null;
  certificationId?: string | null;
}

export interface SystemMessageInterface {
  title: string;

  text: string;

  severity: string;

  expireDate: Date;

  readByUser?: UserInterface[];

  targetRole?: Role;
}

export interface TextTemplateInterface {
  name: string;
  text: string;

  organisation: OrganisationInterface | null;

  id: string;

  readonly createdAt?: Date;

  updatedAt?: Date;

  updatedBy?: UserInterface | undefined;
}
