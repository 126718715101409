<div style="height: calc(100% - 3rem); overflow: auto">
  <eva-file-upload
    #fileUpload
    [certificationId]="certificationId"
    [groupCertificationId]="groupCertificationId"
    [useInternalCategorySelection]="showCategory"
    [externalControlled]="true"
    [categories]="categories"
    [category]="selectedCategory"
    (selectionChanged)="selectionChanged()">
  </eva-file-upload>
</div>
<div
  *ngIf="loading"
  class="busy-indicator-container flex justify-content-center align-items-center">
  <p-progressSpinner></p-progressSpinner>
</div>
