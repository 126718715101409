/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { VerificationStatus } from '@eva/data-access/shared';
import { AuditorRequestService } from '../auditor-request.service';

@Component({
  selector: 'eva-verification-status-selector',
  templateUrl: './verification-status-selector.component.html',
  styleUrls: ['./verification-status-selector.component.scss'],
})
export class VerificationStatusSelectorComponent implements OnChanges {
  @Input() verificationStatus: VerificationStatus | undefined =
    VerificationStatus.NONE;

  @Output() verificationStatusChange = new EventEmitter<VerificationStatus>();
  @Input() showCompliant = true;
  @Input() sampleMode: boolean | undefined | null = false;
  VerificationStatusArray: { value: string; label: string }[] = [];

  constructor(public auditorRequestHelper: AuditorRequestService) {
    this.setArrayOfVerificationStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sampleMode']) {
      this.setArrayOfVerificationStatus();
    }
  }

  setArrayOfVerificationStatus() {
    this.VerificationStatusArray = Object.entries(VerificationStatus)
      .map(([k, v]) => ({
        value: v,
        label: v,
      }))
      .filter(
        (vs) => vs.value !== VerificationStatus.COMPLIANT || this.showCompliant
      )
      .filter((vs) => {
        if (vs.value === VerificationStatus.ENDANGERED) {
          return false;
        }
        if (this.sampleMode) {
          return (
            vs.value === VerificationStatus.COMPLIANT ||
            vs.value === VerificationStatus.NONE
          );
        } else {
          return true;
        }
      });
  }

  vsChange(vs: VerificationStatus) {
    this.verificationStatusChange.emit(vs);
  }

  getFullName(vs: VerificationStatus) {
    return this.auditorRequestHelper.getFullName(vs);
  }
}
