<p *ngIf="(sortedMessages?.length??0)===0" style="font-style: italic">
  Keine Nachrichten vorhanden
</p>
<div class="messages">
  <ng-container *ngFor="let msg of sortedMessages; let i = index">
    <ng-container *ngIf="msg && message">
      <ng-container
        *ngIf="msg.id !== message.id || msg.createdBy?.id !== message.createdBy?.id">
        <ng-container
          *ngTemplateOutlet="messageTemplate; context:{$implicit: msg, newMessage: false}"></ng-container
      ></ng-container>
    </ng-container>
  </ng-container>
  <hr *ngIf="(sortedMessages?.length??0)!==0 && editMode" />

  <ng-container
    *ngIf="showNewMessageForm && (!isAuditorView || (sortedMessages?.length??0) > 0)">
    <ng-container
      *ngTemplateOutlet="messageTemplate; context:{$implicit: message, newMessage: true}"></ng-container>
  </ng-container>
</div>

<ng-template let-message let-isNewMessage="newMessage" #messageTemplate>
  <div
    class="message-container"
    *ngIf="message"
    [class.isNewMessage]="isNewMessage">
    <div
      class="message flex flex-column"
      style="overflow: hidden"
      [ngClass]="messageForm.get('verificationStatus')?.value">
      <form [formGroup]="messageForm">
        <div class="flex flex-row justify-content-between header">
          <div *ngIf="isNewMessage">
            <p-dropdown
              *ngIf="isAuditorView"
              [options]="VerificationStatusArray"
              formControlName="verificationStatus"
              appendTo="body"
              optionValue="value"
              optionLabel="label"
              appendTo="body">
              <ng-template pTemplate="selectedItem">
                <div
                  class="verification-status"
                  [ngClass]="messageForm.get('verificationStatus')?.value">
                  {{auditorRequestHelper.getShortName(messageForm.get('verificationStatus')?.value)}}
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="verification-status" [ngClass]="item.value">
                  {{auditorRequestHelper.getShortName(item.value)}}
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div
            *ngIf="!isNewMessage && message.createdBy?.role && ![RoleEnum.PROJECT_MANAGER, RoleEnum.CONSULTANT].includes(message.createdBy?.role)"
            class="verification-status m-1"
            [ngClass]="message.verificationStatus ??  getLastStatus(message)">
            {{auditorRequestHelper.getShortName(message.verificationStatus ??
            getLastStatus(message))}}
          </div>
          <div class="name m-1">
            {{message.createdBy?.firstName}} {{message.createdBy?.lastName}}
            ({{message.createdBy?.role}})
          </div>
          <div class="flex date-time">
            <div
              class="m-1 buttons"
              style="align-items: center; display: flex; white-space: nowrap">
              <ng-container *ngIf="!isNewMessage">
                {{message.createdAt| date : 'short' }} Uhr
              </ng-container>
              <ng-container *ngIf="isNewMessage && editMode && showSaveButton"
                ><button
                  data-cy="cancel-button"
                  *ngIf="messageForm.dirty"
                  label="Abbrechen"
                  pButton
                  type="reset"
                  class="mr-2 cancel-button"
                  (click)="resetToInitialValues()"></button>
                <p-button
                  data-cy="save-button"
                  [disabled]="!messageForm.dirty || !messageForm.valid"
                  label="Speichern"
                  (click)="submit()"></p-button>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
      <div class="message-text m-2">
        <div
          [outerHTML]="message.text"
          *ngIf="!isNewMessage || !editMode"></div>
        <form [formGroup]="messageForm" *ngIf="isNewMessage && editMode">
          <eva-editor
            id="messageEditor"
            [editorStyle]="{ height: '150px', 'font-size': '1.1rem' }"
            [formControlName]="'text'">
          </eva-editor>
          <p
            *ngIf="messageForm.controls.text.errors"
            class="invalid small text-right mt-1">
            Bitte mindestens {{minMessageTextLength}} Zeichen eingeben!
          </p>
          <input type="hidden" formControlName="id" />
          <input type="hidden" formControlName="parentMessageId" />
        </form>
      </div>
    </div>
  </div>
</ng-template>
