/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import { Baseline, BaselineFragment } from '@eva/data-access/shared';
import { StateContext } from '@ngxs/store';
import { StateOperator, patch, updateItem } from '@ngxs/store/operators';
import { AppState, updateCertificationInStateContext } from '../app.state';
import { attachAction } from '../attach-actions';

import { throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { CertificationApiService } from '../../services/api/certification.api.service';

import {
  ArBaselineDataInput,
  CreateBaselineInput,
  StandardMethod,
  UpdateBaselineInput,
} from '@eva/data-access/shared';

/**
 * helper function called in state constructor
 * attach all actions from this file
 */
export const loadBaselineActions = (
  certificationService: CertificationApiService
) => {
  attachAction(
    AppState,
    CreateBaselineAction,
    createBaselineAction(certificationService)
  );

  attachAction(
    AppState,
    GetBaselineAction,
    getBaselineAction(certificationService)
  );

  attachAction(
    AppState,
    DeleteBaselineAction,
    deleteBaselineAction(certificationService)
  );

  attachAction(
    AppState,
    UpdateBaselineAction,
    updateBaselineAction(certificationService)
  );
};

export class CreateBaselineAction {
  static readonly type = '[Baseline] Create';
  constructor(public input: CreateBaselineInput) {}
}

/**
 *
 * Action execution in state
 *
 */
export const createBaselineAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: CreateBaselineAction) => {
    return certificationService.createBaseline(action.input).pipe(
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  };

export class UpdateBaselineAction {
  static readonly type = '[Baseline] Update';
  constructor(
    public input: UpdateBaselineInput,
    public methodInput?: ArBaselineDataInput
  ) {}
}

/**
 *
 * Action execution in state
 *
 */
export const updateBaselineAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: UpdateBaselineAction) => {
    return certificationService
      .updateBaseline(action.input, action.methodInput)
      .pipe(
        tap((certification) => {
          if (certification) {
            updateCertificationInStateContext(stateContext, certification);
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  };

export class DeleteBaselineAction {
  static readonly type = '[Baseline] Delete';
  constructor(public input: UpdateBaselineInput) {}
}

/**
 *
 * Action execution in state
 *
 */
export const deleteBaselineAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: DeleteBaselineAction) => {
    return certificationService.deleteBaseline(action.input).pipe(
      take(1), // needed to complete Observable
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  };

export class GetBaselineAction {
  static readonly type = '[Baseline] GetBaseline';
  constructor(public method: StandardMethod, public id: string) {}
}

/**
 *
 * Action execution in state
 *
 */
export const getBaselineAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: GetBaselineAction) => {
    // TODO: refresh whole certification??
    return certificationService.getBaseline(action.method, action.id).pipe(
      take(1), // needed to complete Observable
      tap((data) => {
        if (data?.baseline) {
          const currentCertification =
            stateContext.getState().currentCertification;
          if (currentCertification) {
            stateContext.setState(
              patch({
                currentCertification: patch({
                  baselines: updateItem<Baseline>(
                    (baselineItem) =>
                      baselineItem && baselineItem.id === data.baseline.id,
                    data.baseline as Baseline
                  ) as StateOperator<BaselineFragment[]>,
                }),
              })
            );
          }
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  };
