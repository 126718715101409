/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import {
  AuditorRequestDocumentsInput,
  AuditorRequestInput,
  AuditorRequestMessageInput,
  AuditorRequestUpdateInput,
  DeleteAuditorRequestInput,
} from '@eva/data-access/shared';
import { StateContext } from '@ngxs/store';
import { attachAction } from '../attach-actions';

import { tap } from 'rxjs';
import { CertificationApiService } from '../../services/api/certification.api.service';
import {
  AppState,
  updateCertificationInStateContext,
  updatePartialGroupCertificationInState,
} from '../app.state';

/**
 * helper function called in state constructor
 * attach all actions from this file
 */
export const loadAuditorActions = (
  certificationService: CertificationApiService
) => {
  attachAction(
    AppState,
    CreateAuditorRequestAction,
    createAuditorRequestAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateAuditorRequestAction,
    updateAuditorRequestAction(certificationService)
  );
  attachAction(
    AppState,
    DeleteAuditorRequestAction,
    deleteAuditorRequestAction(certificationService)
  );
  attachAction(
    AppState,
    CreateAuditorRequestMessageAction,
    createAuditorRequestMessageAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateAuditorRequestMessageAction,
    updateAuditorRequestMessageAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateAuditorRequestDocumentsAction,
    updateAuditorRequestDocumentsAction(certificationService)
  );
};

/**
 * Action definition
 */
export class CreateAuditorRequestAction {
  static readonly type = '[AuditorRequest] Create';
  constructor(public input: AuditorRequestInput) {}
}

export const createAuditorRequestAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: CreateAuditorRequestAction
  ) => {
    return certificationService.createAuditorRequest(action.input).pipe(
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
          if (certification.group) {
            updatePartialGroupCertificationInState(
              stateContext,
              certification.group
            );
          }
        }
      })
    );
  };

export class UpdateAuditorRequestAction {
  static readonly type = '[AuditorRequest] Update';
  constructor(public input: AuditorRequestUpdateInput) {}
}

export const updateAuditorRequestAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: UpdateAuditorRequestAction
  ) => {
    return certificationService.updateAuditorRequest(action.input).pipe(
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
          if (certification.group) {
            updatePartialGroupCertificationInState(
              stateContext,
              certification.group
            );
          }
        }
      })
    );
  };

export class DeleteAuditorRequestAction {
  static readonly type = '[AuditorRequest] Delete';
  constructor(public input: DeleteAuditorRequestInput) {}
}

export const deleteAuditorRequestAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: DeleteAuditorRequestAction
  ) => {
    return certificationService.deleteAuditorRequest(action.input).pipe(
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
          if (certification.group) {
            updatePartialGroupCertificationInState(
              stateContext,
              certification.group
            );
          }
        }
      })
    );
  };
export class CreateAuditorRequestMessageAction {
  static readonly type = '[AuditorRequest] Message';
  constructor(public input: AuditorRequestMessageInput) {}
}

export const createAuditorRequestMessageAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: CreateAuditorRequestMessageAction
  ) => {
    return certificationService.createAuditorRequestMessage(action.input).pipe(
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
          if (certification.group) {
            updatePartialGroupCertificationInState(
              stateContext,
              certification.group
            );
          }
        }
      })
    );
  };

export class UpdateAuditorRequestMessageAction {
  static readonly type = '[AuditorRequest] UpdateMessage';
  constructor(public input: AuditorRequestMessageInput) {}
}

export const updateAuditorRequestMessageAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: UpdateAuditorRequestMessageAction
  ) => {
    return certificationService.updateAuditorRequestMessage(action.input).pipe(
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
          if (certification.group) {
            updatePartialGroupCertificationInState(
              stateContext,
              certification.group
            );
          }
        }
      })
    );
  };

export class UpdateAuditorRequestDocumentsAction {
  static readonly type = '[AuditorRequest] UpdateDocuments';
  constructor(public input: AuditorRequestDocumentsInput) {}
}

export const updateAuditorRequestDocumentsAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: UpdateAuditorRequestDocumentsAction
  ) => {
    return certificationService
      .updateAuditorRequestDocuments(action.input)
      .pipe(
        tap((certification) => {
          if (certification) {
            updateCertificationInStateContext(stateContext, certification);
            if (certification.group) {
              updatePartialGroupCertificationInState(
                stateContext,
                certification.group
              );
            }
          }
        })
      );
  };
