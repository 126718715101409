/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DocumentCategory } from '@eva/data-access/shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileUploadComponent } from '../document-list/file-upload/file-upload.component';
import { NewDocumentsDialogFooterComponent } from './new-documents-dialog-footer/new-documents-dialog-footer.component';

export interface NewDocumentsDialogData {
  category: DocumentCategory | undefined | null;
  showCategory?: boolean;
  categories: (DocumentCategory | null | undefined)[] | undefined;
  groupCertificationId: string | undefined;
  certificationId: string | undefined;
  auditorRequestGroupId: string | boolean | undefined;
  onSave?: (useGroupCertificationId: boolean) => void;
  disabled?: Observable<boolean>;
}

@Component({
  selector: 'eva-new-documents-dialog',
  templateUrl: './new-documents-dialog.component.html',
  styleUrls: ['./new-documents-dialog.component.scss'],
})
export class NewDocumentsDialogComponent implements AfterViewInit {
  @ViewChild('fileUpload') fileUpload: FileUploadComponent;
  @ViewChild('dialogFooter') dialogFooter: TemplateRef<any>;

  loading = false;
  selectedCategory: DocumentCategory | null;
  showCategory = true;
  categories: DocumentCategory[] | undefined;

  groupCertificationId: string | undefined;
  certificationId: string | undefined;

  private disabled: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig<NewDocumentsDialogData>
  ) {
    this.groupCertificationId = this.dialogConfig.data?.groupCertificationId;
    this.certificationId = this.dialogConfig.data?.certificationId;

    this.selectedCategory = this.dialogConfig.data?.category ?? null;
    if (this.dialogConfig.data?.showCategory === false) {
      this.showCategory = false;
    }
    if (this.dialogConfig.templates) {
      this.dialogConfig.templates.footer = NewDocumentsDialogFooterComponent;

      if (this.dialogConfig.data) {
        this.dialogConfig.data.onSave = (useGroupCertificationId: boolean) => {
          this.saveClick(useGroupCertificationId);
        };
        this.dialogConfig.data.disabled = this.disabled.asObservable();
      }
    }
  }

  ngAfterViewInit(): void {
    this.fileUpload.choose();
  }

  public selectionChanged() {
    this.disabled.next(
      this.loading || !((this.fileUpload?._selectedFiles ?? []).length > 0)
    );
  }

  async saveClick(useGroupCertificationId = false) {
    if (this.fileUpload?._selectedFiles?.length > 0) {
      this.loading = true;
      this.disabled.next(this.loading);
      const loadingTask = await this.fileUpload.startUpload(
        useGroupCertificationId
      );
      loadingTask.subscribe({
        next: (e) => {
          this.dialogRef.close(e);
          this.loading = false;
          this.disabled.next(this.loading);
        },
        error: (e) => {
          console.log(e);
          this.dialogRef.close(false);
          this.loading = false;
          this.disabled.next(this.loading);
        },
      });
    }
  }
}
