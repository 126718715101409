<p-editor
  [(ngModel)]="content"
  class="w-full"
  [formats]="['italic', 'bold', 'underline', 'link', 'list', 'indent']"
  (onTextChange)="setContent($event)">
  <ng-template pTemplate="header">
    <span class="ql-formats">
      <button type="button" class="ql-bold" aria-label="Bold"></button>
      <button type="button" class="ql-italic" aria-label="Italic"></button>
      <button
        type="button"
        class="ql-underline"
        aria-label="Underline"></button>
    </span>
    <span class="ql-formats">
      <button type="button" class="ql-list" value="ordered"></button>
      <button type="button" class="ql-list" value="bullet"></button>
    </span>
    <span class="ql-formats">
      <button type="button" class="ql-link"></button>
    </span>
  </ng-template>
</p-editor>
