@switch (type) { @case ('raised') {
<ng-container
  [ngTemplateOutlet]="button"
  [ngTemplateOutletContext]="{disabled, severity, icon, label, i18nLabel, nativeType, raised: true}"></ng-container>
} @case ('outlined') {
<ng-container
  [ngTemplateOutlet]="button"
  [ngTemplateOutletContext]="{disabled, severity, icon, label, i18nLabel, nativeType, outlined: true}"></ng-container>
} @case ('rounded') {
<ng-container
  [ngTemplateOutlet]="button"
  [ngTemplateOutletContext]="{disabled, severity, icon, label, i18nLabel, nativeType, rounded: true}"></ng-container>
} @case ('text') {
<ng-container
  [ngTemplateOutlet]="button"
  [ngTemplateOutletContext]="{disabled, severity, icon, label, i18nLabel, nativeType, text: true}"></ng-container>
} @case ('raised-text') {
<ng-container
  [ngTemplateOutlet]="button"
  [ngTemplateOutletContext]="{disabled, severity, icon, label, i18nLabel, nativeType, raised: true, text: true}"></ng-container>
} @case ('icon-only') {
<ng-container
  [ngTemplateOutlet]="button"
  [ngTemplateOutletContext]="{disabled, severity, icon, label, i18nLabel, nativeType, text: true, rounded: true }"></ng-container>
} @case ('icon-only-raised') {
<ng-container
  [ngTemplateOutlet]="button"
  [ngTemplateOutletContext]="{disabled, severity, icon, label, i18nLabel, nativeType, raised: true, text: true, rounded: true }"></ng-container>
} @default {
<ng-container
  [ngTemplateOutlet]="button"
  [ngTemplateOutletContext]="{disabled, severity, icon, label, i18nLabel, nativeType, raised: true}"></ng-container>
} }

<ng-template
  #button
  let-disabled="disabled"
  let-severity="severity"
  let-outlined="outlined"
  let-text="text"
  let-raised="raised"
  let-rounded="rounded"
  let-label="label"
  let-i18nLabel="i18nLabel"
  let-icon="icon"
  let-nativeType="nativeType">
  <p-button
    [icon]="icon?.name"
    [iconPos]="icon?.position ?? 'left'"
    [severity]="severity"
    [outlined]="outlined"
    [raised]="raised"
    [rounded]="rounded"
    [disabled]="disabled"
    [text]="text"
    label="{{ label }}"
    i18n-label="{{ i18nLabel }}"
    [type]="nativeType"
    [style]="fullWidth ? {width: '100%'} : {}">
  </p-button>
</ng-template>
