/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * enables testing with mocked environment values
 */

import { InjectionToken, Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../model/environment';
import { tap, Observable } from 'rxjs';

export const ENVIRONMENT = new InjectionToken<{ [key: string]: any }>(
  'environment'
);

export type BuildInfo = {
  build: string;
  version: string;
  date: Date;
};

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private readonly environment: any;

  // We need @Optional to be able start app without providing environment file
  constructor(
    private http: HttpClient,
    @Optional() @Inject(ENVIRONMENT) environment: Environment
  ) {
    this.environment = environment !== null ? environment : {};
  }

  getValue(key: string, defaultValue?: any): any {
    return this.environment[key] || defaultValue;
  }

  getBuildInfo(): Observable<BuildInfo> {
    return this.http
      .get<BuildInfo>('/assets/build.json')
      .pipe(tap((r) => console.log(r)));
  }
}
