/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

/**
 * This service enables showing the status of multiple async requests
 * or the loading status of all running requests
 *
 * Usage example:
 *
 * setLoadingStatus('users', 'Loading users', false, false)
 *
 * when loaded call (in subscriber) setLoadingStatus('users', 'Users loaded', true, true)
 *
 * when failed call setLoadingStatus('users', 'Loading users', true, false)
 *
 * or short version (with auto generated key):
 *
 * const key = start(label)
 *
 * loaded(key)
 *
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type LoadingStatus = {
  key: string;
  loaded: boolean;
  label: string;
  success: boolean;
  timestamp?: Date;
};

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  isOffline = false;

  private _loadingUpdate: BehaviorSubject<LoadingStatus[]> =
    new BehaviorSubject<LoadingStatus[]>([]);

  private _loadingStatus: LoadingStatus[] = [];
  private waiting!: ReturnType<typeof setInterval>;

  // Min show duration of loader
  private minDuration = 800;

  get loadingStatus() {
    return this._loadingUpdate;
  }

  /**
   * Start loader with random key
   */
  start(label: string): string {
    const key = (Math.random() * 100).toFixed(4);
    this.setLoadingStatus(key, label, false, false);
    return key;
  }

  /**
   * Set loader item to loaded by key
   */
  loaded(key: string) {
    if (this._loadingStatus.some((status) => status.key === key)) {
      const status = this._loadingStatus.find((s) => s.key === key);
      if (status) {
        this.setLoadingStatus(key, status.label, true, true);
      }
    }
  }

  /**
   * Set loader item by key
   */
  setLoadingStatus(
    key: string,
    label: string,
    loaded = false,
    success = true,
    timeout = true
  ) {
    if (this.isOffline) {
      return;
    }
    if (this._loadingStatus.some((status) => status.key === key)) {
      const status = this._loadingStatus.find((s) => s.key === key);
      if (status?.timestamp) {
        const currentTime = new Date().getTime();
        const statusTime = status.timestamp.getTime();
        const diff = currentTime - statusTime;

        // console.log(key, currentTime, statusTime, diff, diff < this.minDuration);

        if (diff < this.minDuration) {
          setTimeout(() => {
            status.label = label;
            status.loaded = loaded;
            status.success = success;
            status.timestamp = new Date();
            this.emitStatus();
          }, this.minDuration - diff);
        } else {
          status.label = label;
          status.loaded = loaded;
          status.success = success;
          status.timestamp = new Date();
          this.emitStatus();
        }
      }
    } else {
      this._loadingStatus.push({
        key,
        label,
        loaded,
        success,
        timestamp: new Date(),
      });
      this.emitStatus();
    }

    if (this.waiting || !timeout) {
      clearInterval(this.waiting);
    }

    if (timeout) {
      this.waiting = setInterval(() => {
        this._loadingStatus.map((status) => {
          if (!status.loaded) {
            status.success = false;
            status.label += ' Timeout!';
            status.loaded = true;
          }
          this._loadingUpdate.next(this._loadingStatus);
          return status;
        });
      }, 20000);
    }
  }

  setOfflineStatus(offline: boolean) {
    this.isOffline = offline;
  }

  resetLoadingStatus() {
    this._loadingStatus = [];
    this._loadingUpdate.next(this._loadingStatus);
    this.isOffline = true;
  }

  /**
   * Emit status to loadingUpdate
   */
  emitStatus() {
    setTimeout(() => this._loadingUpdate.next(this._loadingStatus), 10);
  }
}
