<ng-container *ngIf="auditorRequest">
  <div
    [attr.data-py]="auditorRequest.id"
    [attr.data-pw]="auditorRequest.number | auditorRequestNumber:!!auditorRequest.groupCertificationId"
    class="auditor-request-display-container verification-status border"
    [ngClass]="auditorRequest.verificationStatus">
    <div class="flex flex-row justify-content-between header">
      <div class="flex">
        <eva-auditor-request-display-status
          [auditorRequest]="auditorRequest"></eva-auditor-request-display-status>

        <div
          data-cy="ar-number"
          *ngIf="auditorRequest.number"
          class="font-bold ml-2 flex align-items-center">
          {{auditorRequest.number |
          auditorRequestNumber:!!auditorRequest.groupCertificationId}}
        </div>
        <div class="ml-2 flex align-items-center" *ngIf="auditorRequest.sample">
          (Stichpobe)
        </div>
        <eva-auditor-request-display-action-status
          [auditorRequest]="auditorRequest"></eva-auditor-request-display-action-status>
        <div
          data-pw="group-related"
          class="ml-2 flex align-items-center"
          i18n-pTooltip="@@auditor-request.group-related"
          pTooltip="Diese Rückfrage bezieht sich auf alle Zertifizierungen der Gruppe"
          *ngIf="auditorRequest.indicatorId">
          (Gruppe)
        </div>
      </div>

      <div class="flex">
        <i
          *ngIf="(auditorRequest.messages??[]).length && collapsable"
          class="pi pi-comments m-1 align-items-center flex text-2xl"
          i18n-pTooltip="@@auditor-request.messages-exists"
          pTooltip="Nachrichten vorhanden"></i>
        <i
          *ngIf="(auditorRequest.messages??[]).length === 0 && collapsable"
          class="pi pi-comments m-1 align-items-center flex text-2xl"
          i18n-pTooltip="@@auditor-request.no-messages-exists"
          pTooltip="Keine Nachrichten vorhanden"></i>
        <i
          *ngIf="(auditorRequest.documents??[]).length && collapsable"
          i18n-pTooltip="@@auditor-request.documents-exists"
          pTooltip="Dokumente vorhanden"
          class="pi pi-copy m-1 align-items-center flex text-2xl"></i>
        <div class="p-1 m-1 align-items-center flex">
          {{auditorRequest.createdAt| date : 'short' }} Uhr
        </div>

        <p-button
          data-pw="edit-button"
          *ngIf="isAuditorView && editMode"
          icon="pi pi-pencil"
          [text]="true"
          [rounded]="true"
          (click)="onEditClicked()"></p-button>
        <p-button
          *ngIf="collapsable"
          class="collpase-button"
          icon="pi pi-angle-right"
          [text]="true"
          [rounded]="true"
          [class.expanded]="!collapsed"
          (click)="collapsed=!collapsed"></p-button>
      </div>
    </div>

    <div
      class="p-1 m-1"
      *ngIf="auditorRequest.auditorComment"
      [innerHTML]="auditorRequest.auditorComment"></div>

    <div
      *ngIf="auditorRequest.internalAuditorRemarks && (isAuditorView || isAdminView)"
      class="p-1 m-1">
      <span class="font-bold">Interne Anmerkungen</span>
      <span
        data-cy="internal-text"
        [innerHTML]="auditorRequest.internalAuditorRemarks"></span>
    </div>
    <div
      style="margin: 0.25rem 0.25rem; overflow: hidden"
      [@collapse]="collapsed">
      <p-tabView>
        <!-- <p-tabPanel header="Kommentar">
          <div
            class="p-1 m-1"
            [innerHTML]="auditorRequest.auditorComment"></div>
        </p-tabPanel> -->
        <p-tabPanel
          header="Nachrichten"
          *ngIf="(editMode || auditorRequest.messages?.length) && !auditorRequest.sample">
          <div class="min-height">
            <eva-auditor-request-messages
              #messageComponent
              (messageChange)="saveMessage($event)"
              (messageFormDirty)="setWizardStatus($event)"
              [editMode]="editMode"
              [showSaveButton]="editMode"
              [auditorRequest]="auditorRequest"></eva-auditor-request-messages>
          </div>
        </p-tabPanel>
        <p-tabPanel
          header="Keine Nachrichten"
          *ngIf="!editMode && auditorRequest.messages?.length === 0 && !auditorRequest.sample">
          <div class="min-height"></div>
        </p-tabPanel>

        <p-tabPanel header="Dokumente">
          <div class="min-height">
            <eva-document-group-list
              [certificationId]="certification.id"
              [groupCertificationId]="certification.groupId ?? undefined"
              [auditorRequestGroupId]="auditorRequest.groupCertificationId ?? undefined"
              [documents]="auditorRequest.documents ?? []"
              [showNoDocumentsMessage]="false"
              [showGroupHeader]="true"
              [showTableHeader]="editMode"
              [categories]="documentCategories"
              [preDefinedCategories]="documentCategories"
              [editMode]="editMode"
              [showUnlinkButton]="true"
              (documentUnLinked)="unLinkDocument($event)"
              (documentsLinked)="onDocumentsLinked($event)"></eva-document-group-list>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Beziehungen">
          <div class="min-height">
            <eva-display-relations
              [relations]="selectedRelations"
              [showDeleteButton]="false"></eva-display-relations>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</ng-container>
