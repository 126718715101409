/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

enum PHONE_TYPES {
  germanPhone = 'germanPhone',
}

// Password policy levels from auth0
// see https://auth0.com/docs/authenticate/database-connections/password-strength#password-policies
export enum PASSWORD_POLICY_LEVEL {
  // At least 1 character of any type
  NONE = 'None',

  // At least 6 characters
  LOW = 'Low',

  // At least 8 characters including a lower-case letter, an upper-case letter, and a number
  FAIR = 'Fair',

  // At least 8 characters including at least 3 of the following 4 types of characters:
  // a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)
  GOOD = 'Good',

  // At least 10 characters including at least 3 of the following 4 types of characters:
  // a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)
  // Not more than 2 identical characters in a row (for example, 111 is not allowed)
  EXCELLENT = 'Excellent',
}

type validationErrors = {
  [key in keyof typeof PHONE_TYPES]?: { valid: boolean };
};

export class CustomValidators {
  static germanPhone(control: AbstractControl): validationErrors | null {
    return CustomValidators.phone(PHONE_TYPES.germanPhone)(control);
  }

  static phone(phoneType: PHONE_TYPES): ValidatorFn {
    return (control: AbstractControl): validationErrors | null => {
      let PHONE_REGEX = /^(\([0-9]{3}\)\s*|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/; // american format (with dashes)
      if (phoneType === PHONE_TYPES.germanPhone) {
        // german format
        PHONE_REGEX =
          /^([+][0-9]{1,3}[ .-])?([(]{1}[0-9]{1,6}[)])?([0-9 .\-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/;
      }
      if (!control.value || control.value?.length === 0) {
        return null;
      } else {
        return PHONE_REGEX.test(control.value) && control.value?.length > 5
          ? null
          : {
              [phoneType]: {
                valid: false,
              },
            };
      }
    };
  }

  static passwordStrength(options?: { policyLevel?: PASSWORD_POLICY_LEVEL }) {
    const config = {
      policyLevel: PASSWORD_POLICY_LEVEL.GOOD,
      ...options,
    };
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;
      const error = { passwordStrength: config.policyLevel };

      // Check for value
      if (!value || /\s/.test(value)) {
        return error;
      }

      // Statistics
      // Count of different types
      const counts = {
        lower: value.length - value.replace(/[a-z]/g, '').length,
        upper: value.length - value.replace(/[A-Z]/g, '').length,
        number: value.length - value.replace(/\d/g, '').length,
        special: value.length - value.replace(/[^a-zA-Z\d]/g, '').length,
      };
      // Count of characters
      const characters = value.length;
      // One point per count property > 0
      const differentTypes = Object.values(counts).reduce(
        (prev, cur) => prev + (cur ? 1 : 0),
        0
      );
      // More than 2 identical characters in a row
      const hasTripple = /(.)\1\1/.test(value);

      // see https://auth0.com/docs/authenticate/database-connections/password-strength#password-policies
      switch (config.policyLevel) {
        case PASSWORD_POLICY_LEVEL.NONE:
          // At least 1 character of any type
          return characters > 0 ? null : error;
        case PASSWORD_POLICY_LEVEL.LOW:
          // At least 6 characters
          return characters >= 6 ? null : error;
        case PASSWORD_POLICY_LEVEL.FAIR:
          // At least 8 characters including a lower-case letter, an upper-case letter, and a number
          return characters >= 8 &&
            !!counts.lower &&
            !!counts.upper &&
            !!counts.number
            ? null
            : error;
        case PASSWORD_POLICY_LEVEL.GOOD:
          // At least 8 characters including at least 3 of the following 4 types of characters:
          // a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)
          return characters >= 8 && differentTypes >= 3 ? null : error;
        case PASSWORD_POLICY_LEVEL.EXCELLENT:
          // At least 10 characters including at least 3 of the following 4 types of characters:
          // a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*)
          // Not more than 2 identical characters in a row (for example, 111 is not allowed)
          return length >= 10 && differentTypes >= 3 && !hasTripple
            ? null
            : error;
        default:
          // See PASSWORD_POLICY_LEVEL.EXCELLENT
          return length >= 10 && differentTypes >= 3 && !hasTripple
            ? null
            : error;
      }
    };
  }

  static passwordConfirmation(options?: {
    passwordField?: string;
    confirmationField?: string;
  }): ValidatorFn {
    const config = {
      passwordField: 'password',
      confirmationField: 'passwordConfirmation',
      ...options,
    };
    return ((formGroup: FormGroup): ValidationErrors | null => {
      const password =
        formGroup?.get(config.passwordField) &&
        formGroup.get(config.passwordField)?.value;
      const passwordConfirmationControl = formGroup.get(
        config.confirmationField
      );
      const passwordConfirmation =
        passwordConfirmationControl && passwordConfirmationControl.value;
      if (password !== passwordConfirmation && passwordConfirmationControl) {
        passwordConfirmationControl.setErrors({ noMatch: true });
      }
      return null;
    }) as ValidatorFn;
  }
}
