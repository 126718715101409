<div
  class="max-text-width"
  [ngClass]="{
    grid: !editAuthenticatedUser
  }">
  <div>
    <div class="card-body">
      <form [formGroup]="userForm" (ngSubmit)="submitForm()">
        <div class="fluid">
          <div class="field">
            <span class="p-float-label title">
              <input
                id="title"
                class="w-full"
                pInputText
                formControlName="title" />
              <label for="title" i18n="@@user.title">Titel oder Funktion</label>
            </span>
          </div>
          <div class="fluid formgrid grid">
            <div class="field col">
              <span class="p-float-label required">
                <input
                  id="firstname"
                  class="w-full"
                  pInputText
                  formControlName="firstName" />
                <label for="firstname" i18n="@@user.firstName">Vorname</label>
              </span>
            </div>
            <div class="field col">
              <span class="p-float-label required">
                <input
                  id="lastname"
                  class="w-full"
                  pInputText
                  formControlName="lastName" />
                <label for="lastname" i18n="@@user.lastName">Nachname</label>
              </span>
            </div>
          </div>
          <div class="fluid formgrid grid">
            <div
              class="field col"
              i18n-pTooltip="@@user.email-change.ttip"
              pTooltip="Die Email kann hier nicht geändert werden">
              <span class="p-float-label">
                <input
                  id="email"
                  class="w-full"
                  pInputText
                  formControlName="email" />
                <label for="email" i18n="@@user.email">Email</label>
              </span>
            </div>

            <div class="field col">
              <span class="p-float-label">
                <input
                  id="phone"
                  class="w-full"
                  pInputText
                  formControlName="phone" />
                <label for="phone" i18n="@@user.phone">Telefon</label>
              </span>
            </div>
          </div>
          <div *ngIf="showPasswordFields">
            <h5 i18n="@@user.change-password">Passwort ändern</h5>
            <div class="fluid formgrid">
              <div class="field">
                <span class="p-float-label">
                  <input
                    id="password"
                    type="password"
                    class="w-full"
                    pInputText
                    formControlName="password"
                    aria-describedby="password-help" />
                  <label for="password" i18n="@@user.new-password"
                    >Neues Passwort</label
                  >
                </span>
                <small
                  *ngIf="userForm.get('password')?.invalid"
                  id="password-help"
                  class="p-invalid hidden-hint"
                  [ngClass]="{ invalid: userForm.get('password')?.invalid }"
                  >{{ passwordHint }}</small
                >
              </div>
              <div class="field">
                <span
                  class="p-float-label"
                  [ngClass]="{ required: passwordConfirmationRequired }">
                  <input
                    id="password-confirmation"
                    type="password"
                    class="w-full"
                    pInputText
                    formControlName="passwordConfirmation" />
                  <label for="password" i18n="@@user.confirm-password"
                    >Passwort bestätigen</label
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="field" *ngIf="isAdmin">
            <span class="p-float-label required">
              <p-dropdown
                inputId="role"
                [style]="{'width':'100%'}"
                [options]="roles"
                formControlName="role"
                optionLabel="role"
                optionValue="value"></p-dropdown>
              <label for="role" i18n="@@user.role">Rolle</label>
            </span>
          </div>
          <div
            class="fluid formgrid"
            *ngIf="
              (authenticatedUser$ | async)?.role === 'ADMIN'
            ">
            <div class="field">
              <span class="p-float-label">
                <textarea
                  id="remarks"
                  class="w-full"
                  pInputText
                  formControlName="remarks"
                  rows="5"
                  i18n-pTooltip="@@user.remarks.tooltip"
                  pTooltip="Internes Feld">
                ></textarea
                >
                <label for="remarks">Anmerkungen zu diesem User</label>
              </span>
            </div>
          </div>
          <div class="fluid formgrid" *ngIf="editAuthenticatedUser">
            <p i18n="@@user.agb">
              Bitte beachten Sie auch unsere<br />
              <a href="/assets/documents/agb/{{ agbFile }}" target="_blank"
                >Allgemeinen Geschäftsbedingungen</a
              >.
            </p>
            <div class="field">
              <div class="field-checkbox">
                <p-checkbox
                  formControlName="agbAccepted"
                  [binary]="true"
                  inputId="agb"></p-checkbox>
                <label class="text-color" for="agb" i18n="@@user.agb-label"
                  >Ich habe die AGB gelesen und akzeptiere sie</label
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
