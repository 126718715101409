/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

export class RefreshTimerAction {
  static readonly type = '[UI] RefreshTimer';
}

export class SetOfflineAction {
  static readonly type = '[APP] SetOffline';
  constructor(public offline: boolean) {}
}

export class SetTimeoutAction {
  static readonly type = '[APP] SetTimeout';
  constructor(public isTimeout: boolean) {}
}

export class SetOfflineReadyAction {
  static readonly type = '[APP] SetOfflineReady';
}

export class PrepareOfflineStatusAction {
  static readonly type = '[APP] PrepareOfflineStatus';
}
