/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

/**
 * API Service
 *
 * TBD: move all GraphQL specific stuff to data-access/graphql lib?
 *
 */
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpProgressEvent,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { Role } from '@eva/certification/api';
import {
  CreateDocumentDocument,
  CreateImageDocument,
  DeleteDocumentGQL,
  DeleteImageGQL,
  Document,
  DocumentCategory,
  DocumentVisibility,
  Image,
  UpdateDocumentGQL,
  UpdateDocumentInput,
  UpdateImageGQL,
  UpdateImageInput,
} from '@eva/data-access/graphql';
import { CreateDocumentInput } from '@eva/data-access/shared';
import Compressor from 'compressorjs';
import { GraphQLError } from 'graphql';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, skipWhile, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CompressOptions } from '../../model/compress-options.interface';
import { FileProgress } from '../../model/file-progress.interface';
import { MessagingService } from '../messaging.service';

@Injectable()
export class FileService {
  _progressList = new BehaviorSubject<FileProgress[]>([]);

  constructor(
    private updateImageMutation: UpdateImageGQL,
    private deleteImageMutation: DeleteImageGQL,
    private updateDocumentMutation: UpdateDocumentGQL,
    private deleteDocumentMutation: DeleteDocumentGQL,
    private messageService: MessagingService,
    private http: HttpClient
  ) {}

  /**
   * this error handlers shows an error message via MessagingService
   * and returns an empty array which means dispatched actions and subscribers
   * will not get the error anymore
   *
   * This is mainly for convenience (reducing error handling in components)
   * but there might be use cases where this is not wanted, then this
   * error handler should not be used
   */
  private errorHandler(err: any) {
    if (environment.debug && (err.graphQLErrors || err.networkError)) {
      err.graphQLErrors.forEach((error: GraphQLError) => {
        console.debug('graphQLError', error);
        if (error.message) {
          this.showError('Fehler', error.message);
        }
      });
      if (err.networkError?.statusText) {
        this.showError('Fehler', err.networkError.statusText);
      }
      if (err.networkError?.error?.errors?.length > 0) {
        err.networkError.error.errors.forEach((e: Error) => {
          console.debug('Network Error:' + e.message);
        });
      }
    } else if (environment.debug && err.message) {
      this.showError('Client-Fehler', err.message);
    } else {
      throw Error(
        'Fehler: die gewünschte Aktion konnte nicht durchgeführt werden. Bitte informieren Sie das WKS Sekreteriat!'
      );
    }
    return of(null);
  }

  private extractData<T>(result: ApolloQueryResult<T> | FetchResult<T>) {
    if (!result.data) {
      if (result.errors && result.errors?.length > 0) {
        this.showError(
          'Fehler: keine Daten verfügbar!',
          result.errors[0].message
        );
      }
      return null;
    } else {
      return result.data;
    }
  }

  private showError(headline: string, message: string) {
    this.messageService.error(headline, message);
  }

  createDocument(input: CreateDocumentInput): Observable<Document | null> {
    return this.uploadDocument(input);
  }

  updateDocument(input: UpdateDocumentInput): Observable<Document | null> {
    return this.updateDocumentMutation.mutate({ input }).pipe(
      map((result) => (result.data?.updateDocument as Document) ?? null),
      catchError((error) => this.errorHandler(error))
    );
  }

  deleteDocument(input: { id: string }): Observable<Document | null> {
    return this.deleteDocumentMutation.mutate(input).pipe(
      tap((r) => console.log(r)),
      map((result) => (result.data?.deleteDocumentResult as Document) ?? null),
      catchError((error) => this.errorHandler(error))
    );
  }

  createImage(input: {
    name: string;
    file: File;
    projectId: string;
    mainImage?: boolean;
  }): Observable<Image | null> {
    return this.uploadImage(input);
  }

  updateImage(input: UpdateImageInput) {
    return this.updateImageMutation.mutate({ input }).pipe(
      tap((r) => console.log(r)),
      map((result) => result.data),
      catchError((error) => this.errorHandler(error))
    );
  }

  deleteImage(input: { id: string; projectId?: string }) {
    return this.deleteImageMutation.mutate(input).pipe(
      tap((r) => console.log(r)),
      map((result) => result.data),
      catchError((error) => this.errorHandler(error))
    );
  }

  uploadDocument(input: CreateDocumentInput): Observable<Document | null> {
    const queryJsonString = CreateDocumentDocument.loc?.source.body;
    if (!queryJsonString) {
      return of(null);
    }
    return this.uploadFileWithName<{ data: { createdDocument: Document } }>(
      input,
      queryJsonString
    ).pipe(
      map((response) => {
        if (response?.data?.createdDocument) {
          return response.data.createdDocument;
        } else {
          this.messageService.error(
            'Upload fehlgeschlagen',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (response as any).error
          );
        }
      })
    ) as Observable<Document>;
  }

  uploadImage(input: {
    name: string;
    file: File;
    projectId: string;
    mainImage?: boolean;
  }): Observable<Image | null> {
    const queryJsonString = CreateImageDocument.loc?.source.body;
    if (!queryJsonString) {
      return of(null);
    }
    return this.uploadFileWithName<{ data: { createdImage: Image } }>(
      input,
      queryJsonString
    ).pipe(
      tap((r) => console.log(r)),
      map((response) => {
        if (response?.data?.createdImage) {
          return response.data.createdImage;
        } else {
          this.messageService.error(
            'Upload fehlgeschlagen',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (response as any).error
          );
        }
      })
    ) as Observable<Image>;
  }

  /**
   *
   * as a workaround for missing file upload in code generator
   * we have to use a direct http post with FormData to send the data
   * see https://github.com/jaydenseric/graphql-multipart-request-spec
   *
   * md5FileSum & fileSize only for document uploads needed
   *
   */
  uploadFileWithName<T>(
    input: {
      name: string;
      file: File;
      remarks?: string;
      md5FileSum?: string;
      fileSize?: number;
      certificationId?: string;
      groupCertificationId?: string;
      indicationId?: string;
      auditorRequestId?: string;
      baselineId?: string;
      growthModelId?: string;
      projectId?: string;
      subPath?: string;
      category?: DocumentCategory;
      visibility?: DocumentVisibility;
      index?: number;
      total?: number;
      mainImage?: boolean;
      pddAppendix?: boolean;
    },
    query: string
  ) {
    const operations = {
      query,
      variables: {
        name: input.name,
        remarks: input.remarks ?? '',
        fileSize: input.fileSize,
        md5FileSum: input.md5FileSum ?? 'n.a.',
        certificationId: input.certificationId,
        groupCertificationId: input.groupCertificationId,
        subPath: input.subPath,
        category: input.category ?? null,
        visibility: input.visibility ?? DocumentVisibility.PRIVATE,
        index: input.index ?? 0,
        total: input.total ?? 0,
        mainImage: input.mainImage ?? false,
        pddAppendix: input.pddAppendix ?? false,
        '0': null,
        projectId: input.projectId,
      },
    } as { query: string; variables: Partial<CreateDocumentInput> };
    const _map = { '0': ['variables.file'] };
    const formData = new FormData();
    formData.append('operations', JSON.stringify(operations));
    formData.append('map', JSON.stringify(_map));
    formData.append('0', input.file, input.file.name);

    const isHttpProgressEvent = (
      event: HttpEvent<unknown>
    ): event is HttpProgressEvent => {
      return (
        event.type === HttpEventType.DownloadProgress ||
        event.type === HttpEventType.UploadProgress
      );
    };

    // set dummy-apollo-operation-name header to post multipart-http-request to graphql
    const headers = new HttpHeaders().set(
      'x-apollo-operation-name',
      'dummy-operation-file-upload-multipart'
    );

    const req = new HttpRequest('POST', environment.apiUrl, formData, {
      reportProgress: true,
      responseType: 'json',
      headers,
    });

    return this.http.request<T>(req).pipe(
      skipWhile((event) => {
        if (isHttpProgressEvent(event) && event.total) {
          this.refreshProgressList(
            input.name,
            Number(((event.loaded / event.total) * 100).toFixed(0)),
            event.loaded,
            event.total
          );
        }
        if (event.type === HttpEventType.Response) {
          return false;
        }
        return true;
      }),
      map((event) => {
        if (event.type === HttpEventType.Response) {
          return event.body;
        }
      }),
      catchError((err) => {
        console.error(err);
        alert('Fehler beim Upload');
        return [];
      })
    );
  }

  refreshProgressList(
    name: string,
    progress: number,
    loaded: number,
    total: number
  ) {
    let currentList = this._progressList.value;

    if (!currentList) {
      currentList = [];
    }

    const itemInList = currentList.find((e) => e.name === name);

    if (itemInList) {
      itemInList.progress = progress;
      itemInList.loaded = loaded;
      itemInList.total = total;
    } else {
      currentList.push({ name, progress, loaded, total });
    }

    this._progressList.next(currentList);
  }

  getDomain(): string {
    return window.location.protocol + '//' + window.location.host;
  }

  formatSize(size: number) {
    return size > 1000000
      ? (size / 1000000).toFixed(2) + ' MB'
      : (size / 1000).toFixed(0) + ' kB';
  }

  download(
    url: string,
    fileName: string,
    options?: {
      progress?: { id: string; progressObject: Record<string, string | null> };
    }
  ) {
    if (options?.progress) {
      options.progress.progressObject[options.progress.id] = '...';
    }
    this.http
      .get(url, {
        reportProgress: true,
        observe: 'events',
        responseType: 'blob',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          return of(null);
        })
      )
      .subscribe((event: HttpEvent<Blob> | null) => {
        if (
          event?.type === HttpEventType.DownloadProgress &&
          options?.progress
        ) {
          options.progress.progressObject[options.progress.id] =
            this.formatSize(event.loaded);
        }
        if (event?.type === HttpEventType.Response && event?.body) {
          const a = window.document.createElement('a');
          const objectUrl = URL.createObjectURL(event.body);
          a.href = objectUrl;
          a.download = fileName;
          a.click();
          URL.revokeObjectURL(objectUrl);
          if (options?.progress) {
            options.progress.progressObject[options.progress.id] = null;
          }
        }
      });
  }

  downloadObservable(
    url: string,
    fileName: string,
    options?: {
      progress?: { id: string; progressObject: Record<string, string> };
    }
  ): Observable<void> {
    if (options?.progress) {
      options.progress.progressObject[options.progress.id] = '...';
    }
    return this.http
      .get(url, {
        reportProgress: false,
        responseType: 'blob',
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          return of(null);
        }),
        map((event: Blob | null) => {
          if (event) {
            const a = window.document.createElement('a');
            const objectUrl = URL.createObjectURL(event);
            a.href = objectUrl;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(objectUrl);
          } else {
            throw throwError(() => 'Download Error');
          }
        })
      );
  }

  getImageFromFile(file: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target?.result as string;
        image.onload = () => {
          resolve(image);
        };
      };
    });
  }

  compress(file: File, options?: CompressOptions): Promise<File> {
    const config: CompressOptions = {
      quality: 1,
      checkOrientation: false,
      maxWidth: 1920, // full HD
      maxHeight: 1440, // full HD 4:3
      ...options,
    };

    return new Promise((resolve, reject) => {
      new Compressor(file, {
        success(result: Blob | File) {
          // Check if result is blob for convert to file
          if (result instanceof Blob) {
            resolve(
              new File([result], (result as any).name, {
                type: (result as any).type,
                lastModified: file.lastModified,
              })
            );
          } else {
            resolve(result);
          }
        },
        error(err) {
          reject(err.message);
        },
        ...config,
      });
    });
  }

  getAllowedCategories(role: Role): (DocumentCategory | null)[] {
    switch (role) {
      case Role.CONSULTANT:
      case Role.PROJECT_MANAGER:
        return [
          DocumentCategory.FSC_CERTIFICATION,
          DocumentCategory.PEFC_CERTIFICATION,
          DocumentCategory.PRE_CERTIFICATION,
          DocumentCategory.FOREST_MANAGEMENT_PLAN,
          DocumentCategory.ADDITIONALITY,
          null,
        ];
      case Role.AUDITOR:
        return [DocumentCategory.SIGNED_PDD, null];
      case Role.ADMIN:
        return [DocumentCategory.AGB_PLATFORM, DocumentCategory.AGB_STANDARD];
      default:
        return [];
    }
  }

  // return null if no visibility restrictions (user can select)
  getDefaultVisibility(
    category: DocumentCategory | null
  ): DocumentVisibility | null {
    switch (category) {
      case DocumentCategory.FSC_CERTIFICATION:
      case DocumentCategory.PEFC_CERTIFICATION:
      case DocumentCategory.PRE_CERTIFICATION:
      case DocumentCategory.SIGNED_PDD:
      case DocumentCategory.AGB_PLATFORM:
      case DocumentCategory.AGB_STANDARD:
        return DocumentVisibility.PUBLIC;
      default:
        return null;
    }
  }
}
