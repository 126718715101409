/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

/**
 * API Service
 *
 * TBD: move all GraphQL specific stuff to data-access/graphql lib?
 *
 */
import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MessagingService } from '../messaging.service';

import {
  CreateCertificationGQL,
  CreateCertificationInput,
  CreateProjectGQL,
  CreateProjectInput,
  DeleteProjectGQL,
  ListProjectsGQL,
  LoadProjectGQL,
  UpdateProjectGQL,
  UpdateProjectInput,
} from '@eva/data-access/graphql';

import { Certification, ListProject, Project } from '@eva/data-access/shared';

import { GraphQLError } from 'graphql';
import { LoadingService } from '../loading.service';

@Injectable()
export class ProjectService {
  constructor(
    private loadProjectQuery: LoadProjectGQL,
    private listProjects: ListProjectsGQL,
    private createProjectMutation: CreateProjectGQL,
    private updateProjectMutation: UpdateProjectGQL,
    private deleteProjectMutation: DeleteProjectGQL,
    private createCertificationMutation: CreateCertificationGQL,
    private messageService: MessagingService,
    private loadingService: LoadingService
  ) {}

  /**
   * this error handlers shows an error message via MessagingService
   * and returns an empty array which means dispatched actions and subscribers
   * will not get the error anymore
   *
   * This is mainly for convenience (reducing error handling in components)
   * but there might be use cases where this is not wanted, then this
   * error handler should not be used
   */
  private errorHandler(err: any) {
    if (environment.debug && (err.graphQLErrors || err.networkError)) {
      err.graphQLErrors.forEach((error: GraphQLError) => {
        console.debug('graphQLError', error);
        if (error.message) {
          this.showError('Fehler', error.message);
        }
      });
      if (err.networkError?.statusText) {
        this.showError('Fehler', err.networkError.statusText);
      }
      if (err.networkError?.error?.errors?.length > 0) {
        err.networkError.error.errors.forEach((e: Error) => {
          console.debug('Network Error:' + e.message);
        });
      }
    } else if (environment.debug && err.message) {
      this.showError('Client-Fehler', err.message);
    } else {
      throw Error(
        'Fehler: die gewünschte Aktion konnte nicht durchgeführt werden. Bitte informieren Sie das WKS Sekreteriat!'
      );
    }
    return of(null);
  }

  private extractData<T, K = any>(
    result: ApolloQueryResult<K> | FetchResult<K>
  ): T | null {
    if (result.data === undefined || result.errors?.length) {
      if (result.errors) {
        this.showError(
          'Fehler: keine Daten verfügbar!',
          result.errors[0].message
        );
      }
      return null;
    } else {
      return result.data as T;
    }
  }

  private showError(headline: string, message: string) {
    this.messageService.error(headline, message);
  }

  loadProjects(): Observable<{ projects: ListProject[] } | null> {
    return this.listProjects.watch().valueChanges.pipe(
      map((result) => this.extractData<{ projects: ListProject[] }>(result)),
      catchError((error) => this.errorHandler(error))
    );
  }

  loadProject(id: string): Observable<{ project: Project } | null> {
    return this.loadProjectQuery.watch({ id }).valueChanges.pipe(
      map((result) => this.extractData<{ project: Project }>(result)),
      catchError((error) => this.errorHandler(error))
    );
  }

  /**
   *
   * @param input
   * @returns
   */
  createProject(input: CreateProjectInput): Observable<Project | null> {
    return this.createProjectMutation.mutate({ input }).pipe(
      map((result) => result.data?.createProject ?? null),
      catchError((error) => this.errorHandler(error))
    );
  }

  /**
   *
   * @param input
   * @returns
   */
  updateProject(input: UpdateProjectInput): Observable<Project | null> {
    console.log('updateProject', input);
    return this.updateProjectMutation.mutate({ input }).pipe(
      map((result) => result.data?.updateProject ?? null),
      catchError((error) => this.errorHandler(error))
    );
  }

  deleteProject(id: string): Observable<{ id: string } | null> {
    return this.deleteProjectMutation.mutate({ id }).pipe(
      map((result) => result.data?.deleteProject ?? null),
      catchError((error) => this.errorHandler(error))
    );
  }

  /**
   *
   * @param input
   * @returns
   */
  createCertification(
    input: CreateCertificationInput
  ): Observable<Certification | null> {
    return this.createCertificationMutation.mutate({ input }).pipe(
      map((result) => result.data?.createCertification ?? null),
      catchError((error) => this.errorHandler(error))
    );
  }
}
