/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import {
  AuditorRequest,
  MessageFragment,
  MessageInput,
  Role,
  User,
  VerificationStatus,
} from '@eva/data-access/shared';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '../../../../states/app.state';
import { AuditorRequestService } from '../auditor-request.service';

interface MessageForm {
  id: FormControl<string | null>;
  text: FormControl<string | null>;
  parentMessageId: FormControl<string | null>;
  verificationStatus: FormControl<VerificationStatus | null | undefined>;
}

@Component({
  selector: 'eva-auditor-request-messages',
  templateUrl: './auditor-request-messages.component.html',
  styleUrls: ['./auditor-request-messages.component.scss'],
})
export class AuditorRequestMessagesComponent implements OnChanges, OnDestroy {
  @Select(AppState.authenticatedUser) authenticatedUser$: Observable<User>;

  @Input() auditorRequest: AuditorRequest | null | undefined;
  @Output() messageChange = new EventEmitter<MessageFragment>();
  @Output() messageFormDirty = new EventEmitter<boolean>();

  @Input() editMode: boolean;

  /**
   * don't show save message button for auditors
   * since they see message form only inline in auditor
   * request form
   */
  @Input() showSaveButton = true;

  /**
   * various use cases:
   * the reference to a new message used in the message form
   * if the current user has not yet created a new message
   * or the reference to the last message if the user is in editMode
   */
  message: MessageFragment | undefined;

  showNewMessageForm = false;

  messageForm: FormGroup<MessageForm>;
  sortedMessages: MessageFragment[] | undefined;
  isDraft = true;
  RoleEnum = Role;
  isAuditorView = false;
  VerificationStatusArray = Object.entries(VerificationStatus)
    .map(([k, v]) => ({
      value: v,
      label: v,
    }))
    .filter((v) => v.value !== VerificationStatus.ENDANGERED);

  minMessageTextLength = 30;

  subscription = new Subscription();

  private _currentUser: User;
  /**
   * holds the initial Indication values
   * needed to enable resetting the form after changes
   */
  private _initialMessage: Partial<MessageInput>;

  private _skipEmitDirty = true;

  constructor(
    private formBuilder: FormBuilder,
    public auditorRequestHelper: AuditorRequestService
  ) {
    this.initForm();
    this.subscription.add(
      this.authenticatedUser$.subscribe({
        next: (user) => {
          this._currentUser = user;
          this.isAuditorView = user.role === Role.AUDITOR;
        },
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.auditorRequest || (changes.editMode && this.auditorRequest)) {
      this.sortedMessages = [...(this.auditorRequest?.messages ?? [])];

      this.sortedMessages.sort(
        (objA, objB) =>
          new Date(objA.createdAt).getTime() -
          new Date(objB.createdAt).getTime()
      );
      this.message = {
        id: '',
        text: '',
        createdBy: this._currentUser ?? ({} as User),
        verificationStatus:
          this.auditorRequest?.verificationStatus ?? VerificationStatus.NONE,
      } as MessageFragment;

      this.setCurrentMessage();
      if (this.message) {
        this._skipEmitDirty = true;
        this.messageForm.patchValue(this.message as Partial<MessageInput>);
        this._initialMessage = { ...this.message };
        this.messageForm.markAsPristine();
        this._skipEmitDirty = false;
      } else {
        this.message = {
          id: '',
          text: '',
          createdBy: this._currentUser ?? ({} as User),
          verificationStatus: this.auditorRequest?.verificationStatus,
        } as MessageFragment;
      }
    }

    if (changes.resetForm) {
      if (!changes.resetForm.firstChange) {
        this.resetToInitialValues();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getLastStatus(msg: MessageFragment): VerificationStatus {
    if (this.auditorRequest && this.sortedMessages) {
      const msgIndex = this.sortedMessages?.indexOf(msg) ?? 0;
      if (msgIndex > 0) {
        const lastMessage = this.sortedMessages[msgIndex - 1];
        if (lastMessage.verificationStatus) {
          return lastMessage.verificationStatus;
        } else {
          return this.getLastStatus(lastMessage);
        }
      }
      return this.auditorRequest.verificationStatus;
    }
    return VerificationStatus.NONE;
  }

  setCurrentMessage() {
    this.showNewMessageForm = this.editMode;
    if (!this.editMode) {
      console.log('editMode false', this.auditorRequest);
      return;
    }
    let lastMessage;
    if (this.sortedMessages && this.sortedMessages.length) {
      lastMessage = this.sortedMessages[this.sortedMessages.length - 1];
    }
    if (lastMessage?.createdBy?.role === this._currentUser.role) {
      this.message = lastMessage;
    } else {
      if (
        lastMessage &&
        lastMessage.verificationStatus === VerificationStatus.COMPLIANT &&
        [Role.PROJECT_MANAGER, Role.CONSULTANT].includes(
          this._currentUser?.role ?? Role.ANONYMOUS
        )
      ) {
        // No new message needed!
        this.showNewMessageForm = false;
      } else {
        this.message = {
          id: '',
          text: '',
          createdBy: this._currentUser ?? ({} as User),
          verificationStatus:
            this.auditorRequest?.verificationStatus ?? VerificationStatus.NONE,
        } as MessageFragment;
      }
    }
    if (
      this.auditorRequest?.verificationStatus ===
        VerificationStatus.COMPLIANT &&
      !this.isAuditorView
    ) {
      this.showNewMessageForm = false;
    }
  }

  resetToInitialValues() {
    this.messageForm.patchValue(
      this._initialMessage as Partial<{
        id: string;
        text: string;
        parentMessageId: string;
        verificationStatus: VerificationStatus;
      }>
    );
    this.messageForm.markAsPristine();
    this._setWizardDirtyStatus(false);
  }

  initForm() {
    this.messageForm = this.formBuilder.group<MessageForm>({
      id: new FormControl(null),
      text: new FormControl(null, this.getMessageTextRequired()),
      parentMessageId: new FormControl(null),
      verificationStatus: new FormControl(null),
    });
    this.subscription.add(
      this.messageForm.controls.verificationStatus.valueChanges.subscribe((s) =>
        this.messageForm.controls.text.updateValueAndValidity()
      )
    );
    this.subscription.add(
      this.messageForm.valueChanges.subscribe((s) => {
        if (!this._skipEmitDirty && this.messageForm.dirty) {
          this.messageFormDirty.emit(true);
        }
      })
    );
  }

  public getCurrentMessage(): MessageFragment | null {
    if (this.messageForm.untouched) {
      return null;
    }
    const t = {
      ...this.messageForm.value,
      id:
        this.messageForm.value.id?.length === 0
          ? undefined
          : this.messageForm.value.id,
      text: this.messageForm.value.text?.length
        ? this.messageForm.value.text
        : '',
      parentMessageId:
        this.messageForm.value.parentMessageId?.length === 0
          ? undefined
          : this.messageForm.value.parentMessageId,
    } as MessageFragment;
    return t;
  }

  submit() {
    ///TODO ID.handling + parentMessageId
    const currentMessage = this.getCurrentMessage();
    if (
      !currentMessage?.text ||
      currentMessage?.text.length < this.minMessageTextLength
    ) {
      if (
        ![
          VerificationStatus.NONE,
          VerificationStatus.COMPLIANT,
          null,
          undefined,
        ].includes(currentMessage?.verificationStatus)
      ) {
        return false;
      }
    }
    if (currentMessage) {
      if (!currentMessage.text) {
        currentMessage.text = '';
      }
      this.messageChange.emit(currentMessage);
    }
  }

  getMessageTextRequired(): ValidatorFn {
    return (control: AbstractControl) => {
      if (
        this.isAuditorView &&
        ([
          VerificationStatus.NONE,
          VerificationStatus.COMPLIANT,
          null,
          undefined,
        ].includes(this.messageForm.controls.verificationStatus.value) ||
          !this.showNewMessageForm ||
          (this.sortedMessages?.length ?? 0) === 0)
      ) {
        return null;
      } else {
        if ((control.value ?? '').length === 0) {
          return {
            required: true,
          };
        }
        if ((control.value ?? '').length < this.minMessageTextLength) {
          return {
            minLength: this.minMessageTextLength,
          };
        }
        return null;
      }
    };
  }

  private _setWizardDirtyStatus(isDirty: boolean) {
    setTimeout(() => {
      this.messageFormDirty.emit(isDirty);
    }, 200);
  }
}
