<eva-button
  (click)="action?.action()"
  [disabled]="!!(disabled$ | async)"
  [type]="action?.type ?? 'raised'"
  [severity]="action?.severity ?? 'primary'"
  [icon]="action?.icon"
  [label]="action?.text"
  [i18nLabel]="action?.i18nLabel"
  [attr.data-cy]="action?.dataCy ?? null"
  >{{action?.text}}</eva-button
>
