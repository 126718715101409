/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { CreateUserInput, UpdateUserInput } from '@eva/data-access/shared';

export class LoadUsersAction {
  static readonly type = '[User] LoadUsers';
}

export class LoadUserAction {
  static readonly type = '[User] Load';
  constructor(public userId: string) {}
}

export class CreateUserAction {
  static readonly type = '[User] Create';
  constructor(public user: CreateUserInput) {}
}

export class UpdateUserAction {
  static readonly type = '[User] Update';
  constructor(public user: UpdateUserInput) {}
}

export class UpdateAuthenticatedUserAction {
  static readonly type = '[User] UpdateAuthenticatedUser';
  constructor(public user: UpdateUserInput) {}
}

export class DeleteUserAction {
  static readonly type = '[User] Delete';
  constructor(public id: string) {}
}
