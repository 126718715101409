/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

export enum SEVERITY {
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
  SUCCESS = 'success',
  DELETE = 'delete',
}

export interface Message {
  severity: SEVERITY;
  summary: string;
  sticky: boolean;
  detail: string;
}
