/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import { GrowthModel, GrowthModelFragment } from '@eva/data-access/shared';
import { StateContext } from '@ngxs/store';
import { StateOperator, patch, updateItem } from '@ngxs/store/operators';
import { AppState, updateCertificationInStateContext } from '../app.state';
import { attachAction } from '../attach-actions';

import { throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { CertificationApiService } from '../../services/api/certification.api.service';

import {
  CreateArGrowthModelInput,
  CreateGrowthModelInput,
  StandardMethod,
  UpdateGrowthModelInput,
} from '@eva/data-access/shared';

/**
 * helper function called in state constructor
 * attach all actions from this file
 */
export const loadGrowthModelActions = (
  certificationService: CertificationApiService
) => {
  attachAction(
    AppState,
    CreateGrowthModelAction,
    createGrowthModelAction(certificationService)
  );

  attachAction(
    AppState,
    GetGrowthModelAction,
    getGrowthModelAction(certificationService)
  );

  attachAction(
    AppState,
    DeleteGrowthModelAction,
    deleteGrowthModelAction(certificationService)
  );

  attachAction(
    AppState,
    UpdateGrowthModelAction,
    updateGrowthModelAction(certificationService)
  );
};

/**
 * Action definition
 */
export class CreateGrowthModelAction {
  static readonly type = '[GrowthModel] Create';
  constructor(public input: CreateGrowthModelInput) {}
}

/**
 *
 * Action execution in state
 *
 */
export const createGrowthModelAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: CreateGrowthModelAction) => {
    return certificationService.createGrowthModel(action.input).pipe(
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  };

/**
 * Action definition
 */
export class UpdateGrowthModelAction {
  static readonly type = '[GrowthModel] Update';
  constructor(
    public input: UpdateGrowthModelInput,
    public methodInput?: CreateArGrowthModelInput
  ) {}
}

/**
 *
 * Action execution in state
 *
 */
export const updateGrowthModelAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: UpdateGrowthModelAction) => {
    return certificationService
      .updateGrowthModel(action.input, action.methodInput)
      .pipe(
        tap((certification) => {
          if (certification) {
            updateCertificationInStateContext(stateContext, certification);
          }
        }),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  };

/**
 * Action definition
 */
export class DeleteGrowthModelAction {
  static readonly type = '[GrowthModel] Delete';
  constructor(public input: UpdateGrowthModelInput) {}
}

/**
 *
 * Action execution in state
 *
 */
export const deleteGrowthModelAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: DeleteGrowthModelAction) => {
    return certificationService.deleteGrowthModel(action.input).pipe(
      take(1), // needed to complete Observable
      tap((certification) => {
        if (certification) {
          updateCertificationInStateContext(stateContext, certification);
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  };

/**
 * Action definition
 */
export class GetGrowthModelAction {
  static readonly type = '[GrowthModel] GetGrowthModel';
  constructor(public method: StandardMethod, public id: string) {}
}

/**
 *
 * Action execution in state
 *
 */
export const getGrowthModelAction =
  (certificationService: CertificationApiService) =>
  (stateContext: StateContext<AppState>, action: GetGrowthModelAction) => {
    // TODO: refresh whole certification??
    return certificationService.getGrowthModel(action.method, action.id).pipe(
      take(1), // needed to complete Observable
      tap((data) => {
        if (data?.growthModel) {
          const currentCertification =
            stateContext.getState().currentCertification;
          if (currentCertification) {
            stateContext.setState(
              patch({
                currentCertification: patch({
                  growthModels: updateItem<GrowthModel>(
                    (growthModelItem) =>
                      growthModelItem &&
                      growthModelItem.id === data.growthModel.id,
                    data.growthModel
                  ) as StateOperator<GrowthModelFragment[]>,
                }),
              })
            );
          }
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  };
