/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DialogData } from '../../model/dialog-data';
import { DialogActionComponent } from '../dialog-action/dialog-action.component';

@Component({
  selector: 'eva-dialog-footer',
  standalone: true,
  imports: [CommonModule, ButtonModule, DialogActionComponent],
  templateUrl: './dialog-footer.component.html',
  styleUrl: './dialog-footer.component.scss',
})
export class DialogFooterComponent {
  constructor(public config: DynamicDialogConfig<DialogData>) {}
}
