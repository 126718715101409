/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Icon } from '../../icon';
import { Severity } from '../../model';
import { ButtonType } from '../model/button-type';

@Component({
  selector: 'eva-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  standalone: true,
  imports: [CommonModule, ButtonModule],
})
export class ButtonComponent implements OnChanges {
  @Input() type: ButtonType = 'raised';
  @Input() nativeType: 'submit' | 'reset' | 'button' = 'button';
  @Input() severity: Severity = 'primary';
  @Input() icon?: Icon;
  @Input() label?: string;
  @Input() i18nLabel?: string;
  @Input() disabled? = false;
  @Input() fullWidth? = false;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      const pointerEventsValue = this.disabled ? 'none' : 'unset';
      this.el.nativeElement.setAttribute(
        'style',
        `pointer-events: ${pointerEventsValue}`
      );
    }
  }
}
