/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

/**
 * Validation Service independant from nestjs and persistence access
 *
 * can be used in both sides: client or server
 */
import {
  AuditorType,
  CertificationInterface,
  CertificationStatus,
  ClarificationRequestInterface,
  DocumentCategory,
  GroupCertificationInterface,
  IndicatorInterface,
  InputType,
  Role,
  StandardDefinitionInterface,
  StandardVersion,
  VerificationStatus,
} from '@eva/certification/api';

import { Certification, ValidationService } from './validation.service';

/**
 * Fragments are used in frontend only
 */
import {
  AuditorRequest,
  AuditorRequestFragment,
  CertificationFragment,
  CertificationPartialFragment,
  GroupCertificationFragment,
  Indication,
  MessageFragment,
} from '@eva/data-access/shared';
import { StandardDefinitionService } from './standard-definition.service';

export type DeepRequired<T> = Required<{ [P in keyof T]: Required<T[P]> }>;

export type Co2StorageShares = {
  bufferShare: number;
  feeShare: number;
  totalCo2Storage: number;
  totalCo2PerHa: number;
  bufferTotal: number;
  feeTotal: number;
  netTotal: number;
  netCo2PerHa: number;
  co2Avoidance: number;
  co2AvoidanceRatio: number;
};

export type StatusSummary = {
  invalidIndications: number;
  CARs: number;
  openCARs: number;
  openCLs: number;
  openSamples: number;
  unconfirmedIndications: number;
  unreviewedIndications: number;
  indicationsWithoutStatus: number;
};

/**
 * Service for processing a certification
 */
export class CertificationProcessService {
  static AuditorStates = [
    CertificationStatus.REVIEW_REQUESTED,
    CertificationStatus.REVIEW_IN_PROGRESS,
    CertificationStatus.RE_REVIEW_REQUESTED,
    CertificationStatus.RE_REVIEW_IN_PROGRESS,
    CertificationStatus.INTERNAL_REVIEW,
    CertificationStatus.REVIEW_FINISHED,
  ];

  static ProjectManagerStates = [
    CertificationStatus.DRAFT,
    CertificationStatus.VALID_FOR_REVIEW,
    CertificationStatus.REVIEW_INQUIRY,
    CertificationStatus.VALID_FOR_RE_REVIEW,
  ];

  static ValidStatusForGroupReReview = [
    CertificationStatus.VALID_FOR_REVIEW,
    CertificationStatus.VALID_FOR_RE_REVIEW,
    CertificationStatus.REVIEW_IN_PROGRESS,
    CertificationStatus.RE_REVIEW_IN_PROGRESS,
  ];

  static isReviewCompleted(
    certification: Certification,
    indicatorObject: { [key: string]: IndicatorInterface }
  ) {
    let reviewed = true;
    certification.indications?.forEach((i) => {
      const indicator = indicatorObject[i.indicatorId];
      if (
        indicator.auditorType === AuditorType.AUDITOR &&
        indicator.inputType !== InputType.NA &&
        (!i.reviewed || i.verificationStatus === VerificationStatus.NONE)
      ) {
        reviewed = false;
      }
    });
    return reviewed;
  }

  /**
   * performs a validation and
   * calculates implicit status change
   */
  static getNewCertificationStatusAfterChange(
    certification: Certification,
    standard: StandardDefinitionInterface,
    changedProperty: keyof CertificationInterface
  ): CertificationStatus | null {
    const previousStatus = certification.status;
    const validCertification = ValidationService.isValidCertification(
      certification,
      standard
    );
    return CertificationProcessService.getNewCertificationStatusAfterValidation(
      previousStatus,
      validCertification,
      changedProperty
    );
  }

  /**
   * calulate new status based on validation result
   * and previous status
   */
  static getNewCertificationStatusAfterValidation(
    previousStatus: CertificationStatus,
    isValidCertification: boolean,
    changedProperty: keyof CertificationInterface
  ) {
    let newStatus: CertificationStatus | null = null;
    if (
      [
        CertificationStatus.REVIEW_REQUESTED,
        CertificationStatus.RE_REVIEW_REQUESTED,
      ].includes(previousStatus)
    ) {
      if (
        changedProperty === 'indications' ||
        changedProperty === 'auditorRequests'
      ) {
        const firstReview =
          previousStatus === CertificationStatus.REVIEW_REQUESTED;
        newStatus = firstReview
          ? CertificationStatus.REVIEW_IN_PROGRESS
          : CertificationStatus.RE_REVIEW_IN_PROGRESS;
      }
    } else {
      if (isValidCertification) {
        if (previousStatus === CertificationStatus.DRAFT) {
          newStatus = CertificationStatus.VALID_FOR_REVIEW;
        } else if (previousStatus === CertificationStatus.REVIEW_INQUIRY) {
          newStatus = CertificationStatus.VALID_FOR_RE_REVIEW;
        } else if (
          [
            CertificationStatus.REVIEW_IN_PROGRESS,
            CertificationStatus.RE_REVIEW_IN_PROGRESS,
            CertificationStatus.INTERNAL_REVIEW,
          ].includes(previousStatus)
        ) {
          newStatus = CertificationStatus.REVIEW_FINISHED;
        }
      } else {
        if (previousStatus === CertificationStatus.VALID_FOR_REVIEW) {
          newStatus = CertificationStatus.DRAFT;
        } else if (previousStatus === CertificationStatus.VALID_FOR_RE_REVIEW) {
          newStatus = CertificationStatus.REVIEW_INQUIRY;
        } else if (previousStatus === CertificationStatus.REVIEW_FINISHED) {
          newStatus = CertificationStatus.RE_REVIEW_IN_PROGRESS;
        }
      }
    }
    return newStatus;
  }

  /**
   * calculate new status for a certification belonging
   * to a group after explicit(!) group certification status
   * update by a user
   */
  static getNewStatusInGroup(
    certification: Certification,
    newGroupStatus: CertificationStatus
  ): CertificationStatus {
    if (
      [
        CertificationStatus.VALID_FOR_REVIEW, // can be set explicit by admin user!
        CertificationStatus.PRECHECK_REQUESTED, // set by user
        CertificationStatus.REVIEW_REQUESTED, // set by admin
        CertificationStatus.COMPLIANT, // set by auditor
        CertificationStatus.NON_COMPLIANT, // set by auditor
      ].includes(newGroupStatus)
    ) {
      // all certifications get same status as group
      return newGroupStatus;
    }
    // for other status we need the StatusSummary
    // and a check for open CARs for the next(!) role
    // here only Auditor or PM is possible
    let role = Role.AUDITOR;
    if (
      CertificationProcessService.ProjectManagerStates.includes(newGroupStatus)
    ) {
      // derive role from new status
      role = Role.PROJECT_MANAGER;
    }
    const summary = CertificationProcessService.getStatusSummary(
      certification,
      role
    );
    if (newGroupStatus === CertificationStatus.REVIEW_INQUIRY) {
      if (summary.openCARs > 0) {
        // only if this specific certification has open CARs
        return CertificationStatus.REVIEW_INQUIRY;
      } else if (
        certification.status === CertificationStatus.REVIEW_REQUESTED
      ) {
        // certification was not reviewed since last review request
        return CertificationStatus.VALID_FOR_REVIEW;
      } else if (
        certification.status === CertificationStatus.RE_REVIEW_REQUESTED
      ) {
        // certification was not reviewed since last rereview request
        return CertificationStatus.VALID_FOR_RE_REVIEW;
      }
    } else if (newGroupStatus === CertificationStatus.RE_REVIEW_REQUESTED) {
      if (certification.status === CertificationStatus.VALID_FOR_RE_REVIEW) {
        return CertificationStatus.RE_REVIEW_REQUESTED;
      } else if (
        certification.status === CertificationStatus.VALID_FOR_REVIEW
      ) {
        return CertificationStatus.REVIEW_REQUESTED;
      }
    }
    return certification.status;
  }

  /**
   * provides all possible next status for a single certification depending on
   * current status, role & indications vertification status
   */
  static getPossibleNextCertificationStatus(
    certification: CertificationInterface | CertificationFragment,
    role = Role.AUDITOR
  ): CertificationStatus[] {
    if ([Role.CONSULTANT, Role.PROJECT_MANAGER].includes(role)) {
      if (certification.status === CertificationStatus.VALID_FOR_REVIEW) {
        return [CertificationStatus.PRECHECK_REQUESTED];
      }
      if (certification.status === CertificationStatus.VALID_FOR_RE_REVIEW) {
        return [
          CertificationStatus.RE_REVIEW_REQUESTED,
          CertificationStatus.WITHDRAWAL,
        ];
      }
      if (certification.status === CertificationStatus.REVIEW_INQUIRY) {
        return [CertificationStatus.WITHDRAWAL];
      }
      return [];
    }
    // Admin
    if (role === Role.ADMIN) {
      if (certification.status === CertificationStatus.PRECHECK_REQUESTED) {
        return [
          // reset the status if precheck failed
          CertificationStatus.VALID_FOR_REVIEW,
          // should be set "implicit" by selecting an auditor
          // possibleStatus.push(CertificationStatus.REVIEW_REQUESTED);
        ];
      }
      if (certification.status === CertificationStatus.COMPLIANT) {
        return [CertificationStatus.PUBLISHED];
      }
      return [];
    }
    // Auditor
    if (
      // should always be the case!
      CertificationProcessService.AuditorStates.includes(certification.status)
    ) {
      const possibleStatus = [];
      const indicationStatus = this.getIndicationsStatus(certification);
      if (indicationStatus.NC > 0) {
        // at least one non compliant indication
        return [CertificationStatus.NON_COMPLIANT];
      }

      const statusSummary = this.getStatusSummary(certification, Role.AUDITOR);
      if (
        [
          CertificationStatus.REVIEW_IN_PROGRESS,
          CertificationStatus.RE_REVIEW_IN_PROGRESS,
          CertificationStatus.REVIEW_REQUESTED,
          CertificationStatus.RE_REVIEW_REQUESTED,
        ].includes(certification.status) &&
        certification.status !== CertificationStatus.INTERNAL_REVIEW
      ) {
        possibleStatus.push(CertificationStatus.INTERNAL_REVIEW);
      }
      // openCAR means the auditor has not yet reacted on the PMs message
      if (statusSummary.CARs > 0 && statusSummary.openCARs === 0) {
        possibleStatus.push(CertificationStatus.REVIEW_INQUIRY);
      } else if (certification.status === CertificationStatus.REVIEW_FINISHED) {
        const pddDocument = certification.documents.find(
          (d) => d.category === DocumentCategory.SIGNED_PDD
        );
        if (pddDocument && certification.certificationCompleteDate) {
          return [CertificationStatus.COMPLIANT];
        }
      }
      return possibleStatus;
    }
    return [];
  }

  /**
   * called in all status and by all roles
   * returns a list of possible new GroupStatus
   * depending on the status of all included certifications
   * and the current groupStatus
   */
  static getPossibleNextGroupCertificationStatus(
    groupCertification:
      | GroupCertificationInterface
      | GroupCertificationFragment,
    role = Role.AUDITOR
  ): CertificationStatus[] {
    const possibleStatus: CertificationStatus[] = [];
    const statusSummary = {
      invalidIndications: 0,
      CARs: 0,
      openCARs: 0,
      openCLs: 0,
      openSamples: 0,
      unconfirmedIndications: 0,
      unreviewedIndications: 0,
    };
    if (groupCertification.certifications?.length) {
      groupCertification.certifications.forEach((c) => {
        if (
          groupCertification.auditorRequests &&
          groupCertification.auditorRequests.length > 0
        ) {
          c = {
            ...c,
            auditorRequests: [
              ...c.auditorRequests,
              ...groupCertification.auditorRequests,
            ],
          } as CertificationFragment;
        }
        const summary = CertificationProcessService.getStatusSummary(c, role);
        statusSummary.invalidIndications += summary.invalidIndications;
        statusSummary.CARs += summary.CARs;
        statusSummary.openCARs += summary.openCARs;
        statusSummary.openCLs += summary.openCLs;
        statusSummary.openSamples += summary.openSamples;
        statusSummary.unconfirmedIndications += summary.unconfirmedIndications;
        statusSummary.unreviewedIndications += summary.unreviewedIndications;
      });
      const certificationsStatus = groupCertification.certifications.map(
        (c) => c.status
      );

      const allHaveSameStatus = (
        status: CertificationStatus,
        certStatus?: CertificationStatus[]
      ) => {
        const cs = certStatus ?? certificationsStatus;
        return cs.filter((s) => s === status).length === cs.length;
      };

      if (role === Role.CONSULTANT) {
        // if all certifications have the same status:
        if (allHaveSameStatus(CertificationStatus.VALID_FOR_REVIEW)) {
          possibleStatus.push(CertificationStatus.PRECHECK_REQUESTED);
        } else if (
          allHaveSameStatus(
            CertificationStatus.VALID_FOR_RE_REVIEW,
            certificationsStatus.filter(
              (cs) => cs !== CertificationStatus.REVIEW_FINISHED
            )
          ) &&
          groupCertification.status !==
            CertificationStatus.RE_REVIEW_REQUESTED &&
          groupCertification.status !== CertificationStatus.REVIEW_FINISHED
        ) {
          possibleStatus.push(CertificationStatus.RE_REVIEW_REQUESTED);
        } else {
          // other cases possibly after first review
          // all certifications should have a status in these:

          if (
            groupCertification.certifications.filter(
              (c) =>
                !CertificationProcessService.ValidStatusForGroupReReview.includes(
                  c.status
                )
            ).length === 0
          ) {
            if (
              certificationsStatus.includes(
                CertificationStatus.VALID_FOR_RE_REVIEW
              )
            ) {
              possibleStatus.push(CertificationStatus.RE_REVIEW_REQUESTED);
            } else {
              possibleStatus.push(CertificationStatus.REVIEW_REQUESTED);
            }
          }
        }
      }
      if (role === Role.AUDITOR) {
        if (
          groupCertification.status &&
          [
            CertificationStatus.REVIEW_IN_PROGRESS,
            CertificationStatus.RE_REVIEW_IN_PROGRESS,
            CertificationStatus.REVIEW_REQUESTED,
            CertificationStatus.RE_REVIEW_REQUESTED,
          ].includes(groupCertification.status)
        ) {
          if (
            groupCertification.status !== CertificationStatus.INTERNAL_REVIEW
          ) {
            possibleStatus.push(CertificationStatus.INTERNAL_REVIEW);
          }
          if (statusSummary.CARs > 0 && statusSummary.openCARs === 0) {
            possibleStatus.push(CertificationStatus.REVIEW_INQUIRY);
          }
        }
        if (certificationsStatus.includes(CertificationStatus.NON_COMPLIANT)) {
          return [CertificationStatus.NON_COMPLIANT];
        }

        const allCertificationsHavePddAndCompleteDate =
          groupCertification.certifications.every((certification) => {
            const pddDocument = certification.documents.find(
              (d) => d.category === DocumentCategory.SIGNED_PDD
            );
            // Check if there is a document with category SIGNED_PDD and a complete date
            return pddDocument && certification.certificationCompleteDate;
          });

        if (
          // no open auditor requests!
          !possibleStatus.includes(CertificationStatus.REVIEW_INQUIRY) &&
          allHaveSameStatus(CertificationStatus.REVIEW_FINISHED) &&
          allCertificationsHavePddAndCompleteDate
        ) {
          possibleStatus.push(CertificationStatus.COMPLIANT);
        }
      }
      if (role === Role.ADMIN) {
        if (allHaveSameStatus(CertificationStatus.PRECHECK_REQUESTED)) {
          // reset the status if precheck failed
          possibleStatus.push(CertificationStatus.VALID_FOR_REVIEW);
          // should be set "implicit" by selecting an auditor
          // possibleStatus.push(CertificationStatus.REVIEW_REQUESTED);
        }
        // these conditions are not official legitimized but needed
        // since Auditor & PM "forget" to switch status
        if (allHaveSameStatus(CertificationStatus.VALID_FOR_REVIEW)) {
          possibleStatus.push(CertificationStatus.PRECHECK_REQUESTED);
        }
        if (allHaveSameStatus(CertificationStatus.VALID_FOR_RE_REVIEW)) {
          possibleStatus.push(CertificationStatus.RE_REVIEW_REQUESTED);
        }
        if (allHaveSameStatus(CertificationStatus.REVIEW_IN_PROGRESS)) {
          possibleStatus.push(CertificationStatus.REVIEW_INQUIRY);
        }
        if (allHaveSameStatus(CertificationStatus.COMPLIANT)) {
          possibleStatus.push(CertificationStatus.PUBLISHED);
        }
      }
    }
    return possibleStatus;
  }

  static getAssignableStatus(
    currentStatus: CertificationStatus
  ): CertificationStatus[] {
    let possibleStatus: CertificationStatus[] = [];
    // special case: assignAuditor component - only Admins can change status here
    if (currentStatus === CertificationStatus.PRECHECK_REQUESTED) {
      possibleStatus = [
        CertificationStatus.REVIEW_REQUESTED,
        CertificationStatus.VALID_FOR_REVIEW,
      ];
    } else if (currentStatus === CertificationStatus.COMPLIANT) {
      possibleStatus = [CertificationStatus.PUBLISHED];
    } else if (currentStatus === CertificationStatus.VALID_FOR_REVIEW) {
      // only needed if the PM "forgets" to request review
      possibleStatus = [CertificationStatus.REVIEW_REQUESTED];
    } else if (currentStatus === CertificationStatus.VALID_FOR_RE_REVIEW) {
      // only needed if the PM "forgets" to request rereview
      possibleStatus = [CertificationStatus.RE_REVIEW_REQUESTED];
    } else if (
      [
        CertificationStatus.REVIEW_IN_PROGRESS,
        CertificationStatus.RE_REVIEW_IN_PROGRESS,
      ].includes(currentStatus)
    ) {
      // only needed if the AUDITOR "forgets" to request rereview
      possibleStatus = [CertificationStatus.REVIEW_INQUIRY];
    }
    return possibleStatus;
  }

  /**
   * implicit status updates of group certification
   * processed only after status change of a single certification!
   *
   * explicit status updates are triggered directy
   *
   * status update is needed if:
   * - if last status was changed to VALID_FOR_REVIEW or VALID_FOR_RE_REVIEW
   * - one status was changed to REVIEW_IN_PROGRESS or RE_REVIEW_IN_PROGRESS
   * - if last status was changed to REVIEW_FINISHED
   */
  static calculateGroupCertificationState(
    existingGroupCertification:
      | GroupCertificationInterface
      | GroupCertificationFragment
  ): CertificationStatus | void {
    const certifications = existingGroupCertification.certifications;
    if (!certifications) {
      return;
    }
    // const previousStatus = existingGroupCertification.status;
    const currentRole = CertificationProcessService.isAuditorStatus(
      existingGroupCertification.status
    )
      ? Role.AUDITOR
      : Role.PROJECT_MANAGER;
    let newStatus: CertificationStatus | undefined;
    if (
      existingGroupCertification.certifications?.find(
        (c) => c.status === CertificationStatus.DRAFT
      ) === undefined
    ) {
      const overallStatus = (status: CertificationStatus): boolean =>
        certifications.filter((c) => c.status === status).length ===
        certifications.length;
      const statusChangeIfAllSame = [
        CertificationStatus.VALID_FOR_REVIEW,
        CertificationStatus.VALID_FOR_RE_REVIEW,
        CertificationStatus.REVIEW_FINISHED,
      ];
      const statusChangeIfAtLeastOne = [
        CertificationStatus.REVIEW_IN_PROGRESS,
        CertificationStatus.RE_REVIEW_IN_PROGRESS,
      ];
      // no certification in DRAFT status, so detect new status
      statusChangeIfAllSame.forEach((status) => {
        if (overallStatus(status)) {
          newStatus = status;
        }
      });
      if (!newStatus) {
        statusChangeIfAtLeastOne.forEach((status) => {
          const hasOne = certifications.find((c) => c.status === status);
          if (hasOne && !newStatus) {
            if (
              currentRole === Role.AUDITOR &&
              CertificationProcessService.isAuditorStatus(status)
            ) {
              newStatus = status;
            } else if (
              currentRole === Role.PROJECT_MANAGER &&
              CertificationProcessService.isProjectManagerStatus(status)
            ) {
              newStatus = status;
            }
          }
        });
      }
      if (
        !newStatus &&
        currentRole === Role.PROJECT_MANAGER &&
        existingGroupCertification.certifications
          ?.filter((c) => c.status !== CertificationStatus.REVIEW_FINISHED)
          ?.filter(
            (c) =>
              !CertificationProcessService.ValidStatusForGroupReReview.includes(
                c.status
              )
          ).length === 0
      ) {
        newStatus = CertificationStatus.VALID_FOR_RE_REVIEW;
      }

      // if no new status could be calculated, remain on the existing group certification status
      if (!newStatus) {
        console.log(
          'groupStatus remains on existing group certification status',
          {
            status: existingGroupCertification.status,
            overallStatus: existingGroupCertification.certifications?.map(
              (c) => c.status
            ),
          }
        );
        return existingGroupCertification.status;
      }

      console.log('newGroupStatus', {
        newStatus,
        overallStatus: existingGroupCertification.certifications?.map(
          (c) => c.status
        ),
      });
      return newStatus;
    } else {
      return CertificationStatus.DRAFT;
    }
  }

  static getStatusSummary(
    certification: Certification,
    role: Role
  ): StatusSummary {
    const standardVersion = certification.standardVersion;
    const standard = StandardDefinitionService.findByVersion(standardVersion);
    if (!standard) {
      throw new Error('No standard with version ' + standardVersion);
    }
    let invalidIndications = [];
    const indicatorsNeedingConfirmation =
      StandardDefinitionService.getIndicatorsNeedingConfirmation(
        standardVersion
      )?.length ?? 0;

    const sample = certification.sample;
    const indicatorsNeedingReview = (
      StandardDefinitionService.getIndicatorsNeedingUniqueReview(
        standardVersion
      ) ?? []
    ).map((i) => i.identifier);
    const auditorIndicators = StandardDefinitionService.getAuditorIndications(
      standardVersion,
      certification.method
    ).map((i) => i.identifier);
    if (certification.sample || role !== Role.AUDITOR) {
      const result = ValidationService.validateIndications(
        certification,
        standard,
        role
      );
      if (result && result.invalidIndications) {
        invalidIndications = result.invalidIndications;
      }
    } else {
      certification.indications.forEach((i) => {
        if (indicatorsNeedingReview?.includes(i.indicatorId) && !i.reviewed) {
          invalidIndications.push(i);
        }
      });
    }
    return {
      invalidIndications: invalidIndications.length,
      unconfirmedIndications:
        indicatorsNeedingConfirmation -
        certification.indications.filter((i) => i.confirmed).length,
      CARs: certification.auditorRequests.filter(
        (a) =>
          a.verificationStatus === VerificationStatus.CORRECTIVE_ACTION_REQUEST
      ).length,
      openCARs: certification.auditorRequests.filter(
        (a) =>
          a.verificationStatus ===
            VerificationStatus.CORRECTIVE_ACTION_REQUEST &&
          CertificationProcessService.auditorRequestNeedsAction(a, role)
      ).length,
      openCLs: certification.auditorRequests.filter(
        (a) =>
          a.verificationStatus === VerificationStatus.CLARIFICATION_REQUEST &&
          CertificationProcessService.auditorRequestNeedsAction(a, role)
      ).length,

      openSamples: certification.auditorRequests.filter(
        (i) => i.sample && i.verificationStatus !== VerificationStatus.COMPLIANT
      ).length,
      indicationsWithoutStatus: certification.indications.filter((i) => {
        if (i.verificationStatus !== VerificationStatus.NONE) {
          return false;
        }
        if (sample) {
          return auditorIndicators.includes(i.indicatorId);
        } else {
          return indicatorsNeedingReview.includes(i.indicatorId);
        }
      }).length,
      unreviewedIndications: certification.indications.filter(
        // only if sample!
        (i) => {
          if (sample) {
            return !i.reviewed;
          } else {
            return (
              indicatorsNeedingReview.includes(i.indicatorId) && !i.reviewed
            );
          }
        }
      ).length,
    };
  }

  static getIndicationsStatus(
    certification: CertificationFragment | CertificationInterface
  ) {
    const status = {
      CAR: 0,
      CL: 0,
      NC: 0,
      FAR: 0,
      EN: 0,
      C: 0,
      NONE: 0,
      valid: 0,
    };
    const indicators = StandardDefinitionService.getIndicatorsByVersion(
      certification.standardVersion
    );
    certification.indications?.forEach((i) => {
      if (
        indicators &&
        indicators[i.indicatorId]?.auditorType === AuditorType.AUDITOR &&
        indicators[i.indicatorId]?.inputType !== InputType.NA
      ) {
        if (
          (!certification.groupId &&
            ValidationService.getGroupDependentIndicators(
              certification.standardVersion as StandardVersion
            ).GROUP_CERTIFICATION !== i.indicatorId) ||
          certification.groupId
        ) {
          switch (i.verificationStatus) {
            case VerificationStatus.CORRECTIVE_ACTION_REQUEST:
              status.CAR++;
              break;
            case VerificationStatus.CLARIFICATION_REQUEST:
              status.CL++;
              break;
            case VerificationStatus.NON_COMPLIANT:
              status.NC++;
              break;
            case VerificationStatus.FORWARD_ACTION_REQUEST:
              status.FAR++;
              break;
            case VerificationStatus.ENDANGERED:
              status.EN++;
              break;
            case VerificationStatus.COMPLIANT:
              status.C++;
              break;
            case VerificationStatus.NONE:
              status.NONE++;
              break;
            default: {
              throw new Error(
                'VerificationStatus missing in indication ' + i.id
              );
            }
          }
        }
      }
      if (i.valid) {
        status.valid++;
      }
    });
    return status;
  }

  /**
   * find the most relevant verification status
   * to derive the indication status from the status
   * of all assigned auditor requests
   */
  static getMostRelevantVerificationStatus(
    status: VerificationStatus[]
  ): VerificationStatus {
    const relevantStatus = [
      VerificationStatus.NON_COMPLIANT,
      VerificationStatus.CORRECTIVE_ACTION_REQUEST,
      VerificationStatus.CLARIFICATION_REQUEST,
      VerificationStatus.FORWARD_ACTION_REQUEST,
      VerificationStatus.ENDANGERED,
      VerificationStatus.COMPLIANT,
      VerificationStatus.NONE,
    ].find((s) => status.includes(s));
    return relevantStatus ?? VerificationStatus.NONE;
  }

  static getAuditorRequestsForIndication(
    indicationId: string,
    certification: Certification
  ) {
    if (certification && certification.auditorRequests) {
      const auditorRequests = (certification.auditorRequests ??
        []) as AuditorRequestFragment[];
      return (
        auditorRequests.filter((ar) => ar.indication?.id === indicationId) ?? []
      );
    }
    return [];
  }

  static isProjectManagerStatus(status: CertificationStatus) {
    return CertificationProcessService.ProjectManagerStates.includes(status);
  }

  static isAuditorStatus(status: CertificationStatus) {
    return CertificationProcessService.AuditorStates.includes(status);
  }

  static calculateShares(
    certification?:
      | Certification
      | CertificationFragment
      | CertificationPartialFragment
  ): Co2StorageShares {
    let standardDefinition: StandardDefinitionInterface | undefined;
    if (certification) {
      standardDefinition = StandardDefinitionService.findByVersion(
        certification.standardVersion
      );
    }
    if (
      !certification ||
      !standardDefinition ||
      !certification.co2Storage ||
      !certification.totalArea
    ) {
      return {
        bufferShare: 0,
        feeShare: 0,
        totalCo2Storage: 0,
        totalCo2PerHa: 0,
        bufferTotal: 0,
        feeTotal: 0,
        netTotal: 0,
        netCo2PerHa: 0,
        co2Avoidance: 0,
        co2AvoidanceRatio: 0,
      };
    }
    const { bufferShare, feeShare } = standardDefinition;
    const totalCo2Storage = certification.co2Storage;
    const totalAreaInHa = certification.totalArea / 10000;
    const totalCo2PerHa = Number((totalCo2Storage / totalAreaInHa).toFixed(2));
    const bufferTotal = (totalCo2Storage * bufferShare) / 100;
    const feeTotal = (totalCo2Storage * feeShare) / 100;
    const netTotal = totalCo2Storage - bufferTotal - feeTotal;
    const netCo2PerHa = Number((netTotal / totalAreaInHa).toFixed(2));
    const co2Avoidance = certification.co2Avoidance ?? 0;
    let co2AvoidanceRatio = 0;
    if (totalCo2Storage > 0) {
      co2AvoidanceRatio = (certification.co2Avoidance ?? 0) / totalCo2Storage;
    }
    return {
      bufferShare,
      feeShare,
      totalCo2Storage,
      totalCo2PerHa,
      bufferTotal,
      feeTotal,
      netTotal,
      netCo2PerHa,
      co2Avoidance,
      co2AvoidanceRatio,
    };
  }

  static indicationNeedsAction(
    indication: Indication,
    auditorRequests: AuditorRequest[] = [],
    role: Role
  ) {
    if ([Role.PROJECT_MANAGER, Role.CONSULTANT].includes(role)) {
      indication.valid;
    } else if (role === Role.AUDITOR) {
      if (auditorRequests.length > 0) {
        let needsAction = false;
        auditorRequests.forEach((ar) => {
          if (!needsAction) {
            needsAction = CertificationProcessService.auditorRequestNeedsAction(
              ar,
              role
            );
          }
        });
        return needsAction;
      }
    }
    return false;
  }

  static auditorRequestNeedsAction(auditorRequest: AuditorRequest, role: Role) {
    if ([Role.PROJECT_MANAGER, Role.CONSULTANT].includes(role)) {
      return !auditorRequest.valid;
    } else if (role === Role.AUDITOR) {
      if (
        auditorRequest.verificationStatus ===
        VerificationStatus.CORRECTIVE_ACTION_REQUEST
      ) {
        if (auditorRequest.messages && auditorRequest.messages.length > 0) {
          // action needed if last message is not from auditor!
          return (
            CertificationProcessService.getLastMessageRole(
              auditorRequest.messages
            ) !== Role.AUDITOR
          );
        }
      } else {
        return !auditorRequest.reviewed;
      }
    }
    return false;
  }

  static getLastMessage(
    messages: MessageFragment[] | null | undefined
  ): MessageFragment | null | undefined {
    if (messages && messages.length > 0) {
      const m = [...messages];
      m.sort((a, b) => {
        return a.createdAt && b.createdAt
          ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          : 0;
      });
      return m.pop();
    }
    return null;
  }

  static getLastMessageRole(
    messages: MessageFragment[] | null | undefined
  ): Role | null | undefined {
    return CertificationProcessService.getLastMessage(messages)?.createdBy
      ?.role;
  }

  static getClarificationRequests(
    version: string,
    indicator?: IndicatorInterface
  ): ClarificationRequestInterface[] {
    const standardDefinition = StandardDefinitionService.findByVersion(version);

    const majorVersion = StandardDefinitionService.getMajorVersion(version);
    if (majorVersion === '0.4') {
      const clarificationRequestContent = standardDefinition.principles
        .flatMap((p) => p.criterias)
        .flatMap((c) => c.indicators)
        .find(
          (i) => i.identifier === indicator?.identifier
        )?.clarificationRequests;
      if (clarificationRequestContent) {
        return [
          {
            content: clarificationRequestContent,
          } as ClarificationRequestInterface,
        ];
      } else [];
    }

    return standardDefinition.clarificationRequests.filter((cl) =>
      indicator?.clarificationRequestUids?.includes(cl.uid)
    );
  }
}
