/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import {
  Image,
  ListProject,
  UpdateImageMutationResponse,
} from '@eva/data-access/shared';
import { StateContext } from '@ngxs/store';
import {
  StateOperator,
  append,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { FileService } from '../../services/api/file.service';
import { AppState } from '../app.state';
import { attachAction } from '../attach-actions';

/**
 * helper function called in state constructor
 * attach all actions from this file
 */
export const loadImageActions = (projectService: FileService) => {
  attachAction(AppState, UploadImageAction, uploadImageAction(projectService));
  attachAction(
    AppState,
    UploadMainImageAction,
    uploadMainImageAction(projectService)
  );
  attachAction(AppState, DeleteImageAction, deleteImageAction(projectService));
  attachAction(AppState, UpdateImageAction, updateImageAction(projectService));
};

import { UpdateImageInput } from '@eva/data-access/shared';

export class UploadMainImageAction {
  static readonly type = '[Image] Main Upload';

  constructor(
    public payload: {
      name: string;
      file: File;
      projectId: string;
      mainImage: boolean;
    }
  ) {}
}

const uploadMainImageAction =
  (fileService: FileService) =>
  (stateContext: StateContext<AppState>, action: UploadMainImageAction) => {
    return fileService.createImage(action.payload).pipe(
      tap((image: Image | null) => {
        const state = stateContext.getState();
        const currentProject = state.currentProject;

        if (image && currentProject) {
          stateContext.setState(
            patch({
              currentProject: patch({
                mainImage: image.id,
                images: append<Image>([image]) as StateOperator<Image[] | null>,
              }),
              projects: updateItem<ListProject>(
                (projectItem) => projectItem.id === currentProject.id,
                patch({
                  mainImage: image.id,
                  images: append<Image>([image]) as StateOperator<
                    Image[] | null
                  >,
                })
              ),
            })
          );
        }
      })
    );
  };

export class UploadImageAction {
  static readonly type = '[Image] Upload';

  constructor(
    public payload: { name: string; file: File; projectId: string }
  ) {}
}

const uploadImageAction =
  (fileService: FileService) =>
  (stateContext: StateContext<AppState>, action: UploadImageAction) => {
    return fileService.createImage(action.payload).pipe(
      tap((image: Image | null) => {
        const currentProject = stateContext.getState().currentProject;
        if (image && currentProject) {
          stateContext.setState(
            patch({
              currentProject: patch({
                images: append<Image>([image]) as StateOperator<Image[] | null>,
              }),
              projects: updateItem<ListProject>(
                (projectItem) => projectItem.id === currentProject.id,
                patch({
                  images: append<Image>([image]) as StateOperator<
                    Image[] | null
                  >,
                })
              ),
            })
          );
        }
      })
    );
  };

export class UpdateImageAction {
  static readonly type = '[Image] Update';

  constructor(public payload: { input: UpdateImageInput }) {}
}

const updateImageAction =
  (fileService: FileService) =>
  (stateContext: StateContext<AppState>, action: UpdateImageAction) => {
    return fileService.updateImage(action.payload.input).pipe(
      tap((image: UpdateImageMutationResponse | null | undefined) => {
        const currentProject = stateContext.getState().currentProject;
        if (image && image.updateImage && currentProject) {
          stateContext.setState(
            patch({
              currentProject: patch({
                images: updateItem<Image>(
                  (imageItem) => imageItem.id === image?.updateImage.id,
                  image.updateImage
                ) as StateOperator<Image[] | null>,
              }),
              projects: updateItem<ListProject>(
                (projectItem) => projectItem.id === currentProject.id,
                patch({
                  images: updateItem<Image>(
                    (imageItem) => imageItem.id === image?.updateImage.id,
                    image.updateImage
                  ) as StateOperator<Image[] | null>,
                })
              ),
            })
          );
        }
      })
    );
  };

export class DeleteImageAction {
  static readonly type = '[Image] Delete';

  constructor(public payload: { id: string; projectId?: string }) {}
}

const deleteImageAction =
  (fileService: FileService) =>
  (stateContext: StateContext<AppState>, action: DeleteImageAction) => {
    return fileService.deleteImage(action.payload).pipe(
      tap(() => {
        const currentProject = stateContext.getState().currentProject;
        if (action.payload.projectId) {
          stateContext.setState(
            patch({
              currentProject: patch({
                mainImage: null,
                images: removeItem<Image>(
                  (imageItem) => imageItem.id === action.payload.id
                ) as StateOperator<Image[] | null>,
              }),
              projects: updateItem<ListProject>(
                (projectItem) => projectItem.id === action.payload.projectId,
                patch({
                  mainImage: null,
                  images: removeItem<Image>(
                    (imageItem) => imageItem.id === action.payload.id
                  ) as StateOperator<Image[] | null>,
                })
              ),
            })
          );
        } else {
          stateContext.setState(
            patch({
              currentProject: patch({
                images: removeItem<Image>(
                  (imageItem) => imageItem.id === action.payload.id
                ) as StateOperator<Image[] | null>,
              }),
              projects: updateItem<ListProject>(
                (projectItem) => projectItem.id === currentProject?.id,
                patch({
                  images: removeItem<Image>(
                    (imageItem) => imageItem.id === action.payload.id
                  ) as StateOperator<Image[] | null>,
                })
              ),
            })
          );
        }
      })
    );
  };
