<form
  *ngIf="documentForm"
  class="flex flex-wrap gap-3 p-fluid"
  [formGroup]="documentForm">
  <div style="display: none">
    <eva-file-upload #evaFileUpload></eva-file-upload>
  </div>
  <div class="fluid formgrid grid width-100">
    <div class="field col-8">
      <input
        (change)="fileChangeEvent($any($event.target).files[0]); "
        #fileinput
        type="file"
        pInputText
        style="display: none"
        data-cy="file-upload" />
      <label class="font-bold block mb-2" for="file" *ngIf="!newDocument"
        >Name</label
      >
      <label class="font-bold block mb-2" for="file" *ngIf="newDocument"
        >Datei</label
      >
      <div class="p-inputgroup">
        <input type="text" pInputText name="file" formControlName="fileName" />
        <button
          *ngIf="newDocument"
          [disabled]="  !!dialogConfig.data?.doc?.id"
          type="button"
          pButton
          icon="pi pi-plus"
          (click)="fileinput.click()"></button>
      </div>
    </div>
    <div class="field col-4">
      <label
        class="font-bold block mb-2"
        i18n-pTooltip="@@document.select-visible-tooltip"
        pTooltip="Grundsätzlich sind alle Dokumente nach der Zertifizierung öffentlich einsehbar. In begründeten Ausnahmen können Sie hier einstellen welche Dokumente nicht veröffentlicht werden sollen. Dazu muss aber zunächst eine Begründung in den Anmerkungen eingetragen werden"
        >Sichtbarkeit</label
      >
      <p-selectButton
        [disabled]="!!this.fileService.getDefaultVisibility(
       documentForm.get('category')?.value??null
        ) || !documentForm.get('remarks')?.value"
        [options]="[{value:documentVisibilityEnum.PRIVATE, inactive: !documentForm.get('remarks')?.value, icon: 'pi-eye-slash', tooltip:'Nicht Öffentlich'}, {value:documentVisibilityEnum.PUBLIC, inactive: false, icon: 'pi-eye', tooltip:'Öffentlich'}]"
        formControlName="visibility"
        optionDisabled="inactive"
        [allowEmpty]="false"
        optionValue="value">
        <ng-template let-item pTemplate>
          <div style="width: 100%" [pTooltip]="item.tooltip">
            <i class="pi" [class]="item.icon"></i>
          </div>
        </ng-template>
      </p-selectButton>
    </div>
    <div class="field col-12">
      <label class="font-bold block mb-2" for="remarks">Anmerkungen</label>
      <textarea
        (ngModelChange)="documentForm.get('visibility')?.setValue(!$event?.length ? documentVisibilityEnum.PUBLIC: documentForm.get('visibility')?.value??documentVisibilityEnum.PUBLIC)"
        name="remarks"
        rows="5"
        cols="30"
        pInputTextarea
        formControlName="remarks"></textarea>
    </div>
    <div class="field col-6">
      <label class="font-bold block mb-2" for="categroy">Kategorie</label>

      <p-dropdown
        [style]="{'max-width': '100%'}"
        appendTo="body"
        [options]="documentCategoryList"
        optionLabel="value"
        optionValue="key"
        emptyMessage="Allgemein"
        formControlName="category"></p-dropdown>
    </div>
    <div class="field col-6" *ngIf="this.isPDFFile()">
      <p-checkbox
        [disabled]="!this.isPDFFile()"
        [trueValue]="true"
        [binary]="true"
        formControlName="pddAppendix"
        i18n-label="@@pddAppendix"
        i18n-pTooltip="@@pddAppendix.ttip"
        pTooltip="Dieses Dokument als Anhang im PDD anzeigen"
        label="PDD-Anhang"></p-checkbox>
    </div>
  </div>
</form>
