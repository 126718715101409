/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Editor, EditorModule, EditorTextChangeEvent } from 'primeng/editor';
import { Subscription } from 'rxjs';
import { DialogData } from '../../../dialog';

export interface EditorDialogData {
  content: string;
}

@Component({
  selector: 'eva-editor-dialog',
  templateUrl: './editor-dialog.component.html',
  styleUrl: './editor-dialog.component.scss',
  standalone: true,
  imports: [EditorModule, FormsModule],
})
export class EditorDialogComponent implements OnInit {
  /**
   * Content variable for editor
   */
  public content: string | undefined;

  // private PrimeNg instance
  @ViewChild(Editor, { static: true }) editor!: Editor;

  constructor(
    public config: DynamicDialogConfig<DialogData<EditorDialogData>>,
    private dialogRef: DynamicDialogRef<EditorDialogComponent>
  ) {
    // set initial value
    this.content = this.config.data?.content;

    if (this.config.data) {
      this.config.data.actions = [
        {
          text: 'Übernehmen',
          icon: {
            name: 'pi pi-check',
          },
          action: () => {
            this.dialogRef.close(this.content);
            return Subscription.EMPTY;
          },
        },
      ];
    }
  }

  ngOnInit(): void {
    // set intial value in editor
    this.editor.writeValue(this.content);
  }

  /**
   * Sets the content on each editor text change
   *
   * @param event primeNg editor text change event
   */
  public setContent(event: EditorTextChangeEvent): void {
    this.content = event.htmlValue;
  }
}
