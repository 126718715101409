/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[evaLoading]',
})
export class LoadingDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() public keys: string[] | string;

  private subscription = new Subscription();

  constructor(
    private _el: ElementRef,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.subscribeToLoader();
  }

  ngAfterViewInit() {
    this._el.nativeElement.disabled = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Check in loading list for key
   */
  subscribeToLoader() {
    this.subscription.add(
      this.loadingService.loadingStatus.subscribe({
        next: (value) => {
          let loading = false;
          let keys = [];

          if (!this.keys) {
            return;
          }

          if (!Array.isArray(this.keys)) {
            keys = [this.keys];
          } else {
            keys = this.keys;
          }

          if (keys.length) {
            if (!value.find((e) => this.keys.includes(e.key))) {
              return;
            }

            loading = value.some(
              (ls) => this.keys?.includes(ls.key) && !ls.loaded
            );
          } else {
            loading = value.some((ls) => !ls.loaded);
          }

          this.prepareElement(loading);
        },
      })
    );
  }

  /**
   * Set disable flag on button
   */
  private prepareElement(disable: boolean) {
    this._el.nativeElement.disabled = disable;
  }
}
