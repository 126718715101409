import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type ArBaselineData = {
  backWaterInfluence: BackWaterInfluence;
  baselineId?: Maybe<Scalars['String']['output']>;
  closeSpecies: Array<ArTreeSpeciesShare>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  establishedNatReg: Array<ArTreeSpeciesShare>;
  establishedNatRegArea: Scalars['Float']['output'];
  fireRisk?: Maybe<Scalars['Float']['output']>;
  generalRemarks?: Maybe<Scalars['String']['output']>;
  groundVegetation: Scalars['Float']['output'];
  groundVegetationRemarks?: Maybe<Scalars['String']['output']>;
  groundWaterInfluence: GroundWaterInfluence;
  id: Scalars['String']['output'];
  inclination: Scalars['Boolean']['output'];
  lateFrostRisk: Scalars['Boolean']['output'];
  nfk?: Maybe<Scalars['Float']['output']>;
  noNatRegPossibleArea: Scalars['Float']['output'];
  noNatRegReasons: Scalars['String']['output'];
  nonEstablishedNatReg: Array<ArTreeSpeciesShare>;
  nonEstablishedNatRegArea: Scalars['Float']['output'];
  nonEstablishedNatRegGroundVegetation: Scalars['Float']['output'];
  pioneerSpecies: Array<ArTreeSpeciesShare>;
  preExistingBioMass: Scalars['Float']['output'];
  previousSpecies: Array<ArTreeSpeciesShare>;
  reducedTreeMix?: Maybe<Array<ArTreeSpeciesShare>>;
  remainingSpecies: Array<ArTreeSpeciesShare>;
  siteWaterBalance?: Maybe<Scalars['Float']['output']>;
  siteWaterBalance50?: Maybe<Scalars['Float']['output']>;
  soilTrophyCategory: TrophyCategory;
  soilType?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  waterHoldingCapacity?: Maybe<Scalars['Float']['output']>;
};

export type ArBaselineDataInput = {
  backWaterInfluence: BackWaterInfluence;
  closeSpecies: Array<Scalars['JSON']['input']>;
  establishedNatReg: Array<Scalars['JSON']['input']>;
  establishedNatRegArea: Scalars['Float']['input'];
  fireRisk?: InputMaybe<Scalars['Float']['input']>;
  generalRemarks?: InputMaybe<Scalars['String']['input']>;
  groundVegetation: Scalars['Float']['input'];
  groundVegetationRemarks?: InputMaybe<Scalars['String']['input']>;
  groundWaterInfluence: GroundWaterInfluence;
  id?: InputMaybe<Scalars['String']['input']>;
  inclination: Scalars['Boolean']['input'];
  lateFrostRisk: Scalars['Boolean']['input'];
  nfk?: InputMaybe<Scalars['Float']['input']>;
  noNatRegPossibleArea: Scalars['Float']['input'];
  noNatRegReasons: Scalars['String']['input'];
  nonEstablishedNatReg: Array<Scalars['JSON']['input']>;
  nonEstablishedNatRegArea: Scalars['Float']['input'];
  nonEstablishedNatRegGroundVegetation: Scalars['Float']['input'];
  pioneerSpecies: Array<Scalars['JSON']['input']>;
  preExistingBioMass: Scalars['Float']['input'];
  previousSpecies: Array<Scalars['JSON']['input']>;
  remainingSpecies: Array<Scalars['JSON']['input']>;
  siteWaterBalance?: InputMaybe<Scalars['Float']['input']>;
  siteWaterBalance50?: InputMaybe<Scalars['Float']['input']>;
  soilTrophyCategory: TrophyCategory;
  soilType?: InputMaybe<Scalars['String']['input']>;
  waterHoldingCapacity?: InputMaybe<Scalars['Float']['input']>;
};

export type ArGrowthModelData = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  firePrevention: Scalars['Boolean']['output'];
  forrestManagement: Scalars['Boolean']['output'];
  gameManagement: Scalars['Boolean']['output'];
  generalRemarks?: Maybe<Scalars['String']['output']>;
  groundMaintenance: Scalars['Boolean']['output'];
  growthModelId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  naturalRegeneration: Scalars['Boolean']['output'];
  plannedSpecies: Array<ArTreeSpeciesShare>;
  planting: Scalars['Boolean']['output'];
  projectActivityDescription?: Maybe<Scalars['String']['output']>;
  projectActivitySupportDescription?: Maybe<Scalars['String']['output']>;
  regionId?: Maybe<Scalars['Float']['output']>;
  seeding: Scalars['Boolean']['output'];
};

export type ArGrowthModelInput = {
  firePrevention: Scalars['Boolean']['input'];
  forrestManagement: Scalars['Boolean']['input'];
  gameManagement: Scalars['Boolean']['input'];
  generalRemarks?: InputMaybe<Scalars['String']['input']>;
  groundMaintenance: Scalars['Boolean']['input'];
  naturalRegeneration: Scalars['Boolean']['input'];
  plannedSpecies: Array<Scalars['JSON']['input']>;
  planting: Scalars['Boolean']['input'];
  projectActivityDescription?: InputMaybe<Scalars['String']['input']>;
  projectActivitySupportDescription?: InputMaybe<Scalars['String']['input']>;
  regionId?: InputMaybe<Scalars['Float']['input']>;
  seeding: Scalars['Boolean']['input'];
};

/**
 * synchronize: false
 *
 * because typeorm first analyzes
 */
export type ArTreeSpecies = {
  baseline?: Maybe<Scalars['Boolean']['output']>;
  germanName: Scalars['String']['output'];
  growthGroup?: Maybe<TreeFunctionalGroups>;
  growthModel?: Maybe<Scalars['Boolean']['output']>;
  identifier: Scalars['String']['output'];
  mdd?: Maybe<Scalars['Float']['output']>;
  pioneer: Scalars['Boolean']['output'];
  scientificName: Scalars['String']['output'];
};

export type ArTreeSpeciesShare = {
  closeInBaseline?: Maybe<ArBaselineData>;
  co2?: Maybe<Scalars['Float']['output']>;
  cubicMeter?: Maybe<Scalars['Float']['output']>;
  delayedNatReg: Scalars['Boolean']['output'];
  dryLoss: Scalars['Boolean']['output'];
  establishedNatReg?: Maybe<ArBaselineData>;
  id: Scalars['String']['output'];
  naturalRegeneration: Scalars['Boolean']['output'];
  nonEstablishedNatReg?: Maybe<ArBaselineData>;
  numberOfTrees?: Maybe<Scalars['Float']['output']>;
  pioneerInBaseline?: Maybe<ArBaselineData>;
  plannedInGrowthModel?: Maybe<ArGrowthModelData>;
  previousInBaseline?: Maybe<ArBaselineData>;
  reducedTreeMixInBaseline?: Maybe<ArBaselineData>;
  remainingSpecies?: Maybe<ArBaselineData>;
  share: Scalars['Float']['output'];
  species: ArTreeSpecies;
};

export type AssignSiteInput = {
  id: Scalars['String']['input'];
};

export type AuditorRequest = {
  auditorComment: Scalars['String']['output'];
  baselines?: Maybe<Array<Baseline>>;
  certification?: Maybe<CertificationPartialType>;
  certificationId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  documents?: Maybe<Array<Document>>;
  groupCertification?: Maybe<GroupCertification>;
  groupCertificationId?: Maybe<Scalars['String']['output']>;
  growthModels?: Maybe<Array<GrowthModel>>;
  id: Scalars['String']['output'];
  indication?: Maybe<Indication>;
  indicatorId?: Maybe<Scalars['String']['output']>;
  initialVerificationStatus: VerificationStatus;
  internalAuditorRemarks: Scalars['String']['output'];
  messages?: Maybe<Array<Message>>;
  number?: Maybe<Scalars['Float']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  /** Used by TaskService to send reminders */
  remindedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Used by TaskService to send reminders */
  reminderCount: Scalars['Float']['output'];
  /**
   * needed for auditor requests not related
   * to an indication
   *
   * all auditor requests have to be reviewed before
   * a certification state can be REVIEW_FINISHED
   */
  reviewed: Scalars['Boolean']['output'];
  sample: Scalars['Boolean']['output'];
  sites?: Maybe<Array<Site>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /**
   * input is valid (needed to show the PM if he
   * has answered all CARs)
   */
  valid: Scalars['Boolean']['output'];
  verificationStatus: VerificationStatus;
};

export type AuditorRequestDocumentsInput = {
  auditorRequestId: Scalars['String']['input'];
  certificationId: Scalars['String']['input'];
  documentRelations: Array<DocumentRelationInput>;
};

export type AuditorRequestInput = {
  applyToGroup?: InputMaybe<Scalars['Boolean']['input']>;
  auditorComment: Scalars['String']['input'];
  baselines?: InputMaybe<Array<Scalars['JSON']['input']>>;
  certificationId: Scalars['String']['input'];
  documentRelations?: InputMaybe<Array<DocumentRelationInput>>;
  groupCertificationId?: InputMaybe<Scalars['String']['input']>;
  growthModels?: InputMaybe<Array<Scalars['JSON']['input']>>;
  indication?: InputMaybe<Scalars['JSON']['input']>;
  indicatorId?: InputMaybe<Scalars['String']['input']>;
  internalAuditorRemarks?: InputMaybe<Scalars['String']['input']>;
  messages?: InputMaybe<Array<MessageInput>>;
  reviewed: Scalars['Boolean']['input'];
  sample?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<Array<Scalars['JSON']['input']>>;
  valid: Scalars['Boolean']['input'];
  verificationStatus: VerificationStatus;
};

export type AuditorRequestMessageInput = {
  auditorRequestId: Scalars['String']['input'];
  certificationId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  verificationStatus?: InputMaybe<VerificationStatus>;
};

export type AuditorRequestUpdateInput = {
  auditorComment?: InputMaybe<Scalars['String']['input']>;
  baselines?: InputMaybe<Array<Scalars['JSON']['input']>>;
  certificationId?: InputMaybe<Scalars['String']['input']>;
  documentRelations?: InputMaybe<Array<DocumentRelationInput>>;
  groupCertificationId?: InputMaybe<Scalars['String']['input']>;
  growthModels?: InputMaybe<Array<Scalars['JSON']['input']>>;
  id: Scalars['String']['input'];
  indication?: InputMaybe<Scalars['JSON']['input']>;
  indicatorId?: InputMaybe<Scalars['String']['input']>;
  internalAuditorRemarks?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<MessageInput>;
  reviewed: Scalars['Boolean']['input'];
  sample?: InputMaybe<Scalars['Boolean']['input']>;
  sites?: InputMaybe<Array<Scalars['JSON']['input']>>;
  valid: Scalars['Boolean']['input'];
  verificationStatus: VerificationStatus;
};

/** Level of backwater influence */
export enum BackWaterInfluence {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  STRONG = 'STRONG',
  UNKNOWN = 'UNKNOWN'
}

export type Baseline = {
  auditorRequests?: Maybe<Array<AuditorRequest>>;
  certification?: Maybe<CertificationPartialType>;
  certificationId: Scalars['String']['output'];
  co2Avoidance?: Maybe<Scalars['Float']['output']>;
  co2Storage?: Maybe<Scalars['Float']['output']>;
  co2Years?: Maybe<Array<Co2PerYear>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['String']['output'];
  input?: Maybe<ArBaselineData>;
  /**
   * relation to input
   * depending on related certification method
   */
  inputId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  readonly?: Maybe<Scalars['Boolean']['output']>;
  /**
   * certifier reviewed the verificationStatus
   * the status itself is in property verificationStatus
   */
  reviewed: Scalars['Boolean']['output'];
  sites?: Maybe<Array<Site>>;
  totalArea?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /** input is valid */
  valid: Scalars['Boolean']['output'];
  verificationStatus: VerificationStatus;
};

export type Certification = {
  auditorRequests: Array<AuditorRequest>;
  baselines: Array<Baseline>;
  certificationCompleteDate?: Maybe<Scalars['DateTime']['output']>;
  certificationConclusion?: Maybe<Scalars['String']['output']>;
  certificationOpinion?: Maybe<Scalars['String']['output']>;
  certificationStartDate?: Maybe<Scalars['DateTime']['output']>;
  certifier?: Maybe<User>;
  certifierId?: Maybe<Scalars['String']['output']>;
  chartDataUrl?: Maybe<Scalars['String']['output']>;
  co2Avoidance?: Maybe<Scalars['Float']['output']>;
  co2Storage?: Maybe<Scalars['Float']['output']>;
  co2Years: Array<Co2PerYear>;
  consultant?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  creditingPeriod?: Maybe<Scalars['Float']['output']>;
  creditingPeriodStart?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  documents: Array<Document>;
  group?: Maybe<GroupCertification>;
  groupId?: Maybe<Scalars['String']['output']>;
  growthModels: Array<GrowthModel>;
  growthRegion?: Maybe<GrowthRegion>;
  id: Scalars['String']['output'];
  indications: Array<Indication>;
  internalContact?: Maybe<Scalars['String']['output']>;
  internalRemarks?: Maybe<Scalars['String']['output']>;
  involvedPersons?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['JSON']['output']>;
  method: StandardMethod;
  name: Scalars['String']['output'];
  owner: User;
  ownerId: Scalars['String']['output'];
  project: Project;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  sample: Scalars['Boolean']['output'];
  sites: Array<Site>;
  standardVersion: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: CertificationStatus;
  testCertification?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Zeitlicher Ablauf der Zertifizierung
   * Termine der Feldbegehung etc.
   */
  timeSchedule?: Maybe<Scalars['String']['output']>;
  totalArea?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type CertificationPartialType = {
  certificationCompleteDate?: Maybe<Scalars['DateTime']['output']>;
  certificationStartDate?: Maybe<Scalars['DateTime']['output']>;
  certifier?: Maybe<User>;
  certifierId?: Maybe<Scalars['String']['output']>;
  co2Avoidance?: Maybe<Scalars['Float']['output']>;
  co2Storage?: Maybe<Scalars['Float']['output']>;
  consultant?: Maybe<User>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  creditingPeriod?: Maybe<Scalars['Float']['output']>;
  creditingPeriodStart?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  group?: Maybe<GroupCertification>;
  groupId?: Maybe<Scalars['String']['output']>;
  growthRegion?: Maybe<GrowthRegion>;
  id: Scalars['String']['output'];
  internalContact?: Maybe<Scalars['String']['output']>;
  internalRemarks?: Maybe<Scalars['String']['output']>;
  involvedPersons?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['JSON']['output']>;
  method: StandardMethod;
  name: Scalars['String']['output'];
  owner: User;
  ownerId: Scalars['String']['output'];
  project: ProjectPartial;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  sample: Scalars['Boolean']['output'];
  standardVersion: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: CertificationStatus;
  testCertification?: Maybe<Scalars['Boolean']['output']>;
  timeSchedule?: Maybe<Scalars['String']['output']>;
  totalArea?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

/** Possible certification states */
export enum CertificationStatus {
  COMPLIANT = 'COMPLIANT',
  DRAFT = 'DRAFT',
  INTERNAL_REVIEW = 'INTERNAL_REVIEW',
  NON_COMPLIANT = 'NON_COMPLIANT',
  PRECHECK_REQUESTED = 'PRECHECK_REQUESTED',
  PUBLISHED = 'PUBLISHED',
  REVIEW_FINISHED = 'REVIEW_FINISHED',
  REVIEW_INQUIRY = 'REVIEW_INQUIRY',
  REVIEW_IN_PROGRESS = 'REVIEW_IN_PROGRESS',
  REVIEW_REQUESTED = 'REVIEW_REQUESTED',
  RE_REVIEW_IN_PROGRESS = 'RE_REVIEW_IN_PROGRESS',
  RE_REVIEW_REQUESTED = 'RE_REVIEW_REQUESTED',
  VALID_FOR_REVIEW = 'VALID_FOR_REVIEW',
  VALID_FOR_RE_REVIEW = 'VALID_FOR_RE_REVIEW',
  WITHDRAWAL = 'WITHDRAWAL'
}

export type Co2PerYear = {
  avoidance?: Maybe<Scalars['Float']['output']>;
  baseline?: Maybe<Baseline>;
  baselineId?: Maybe<Scalars['String']['output']>;
  biomass?: Maybe<Scalars['Float']['output']>;
  certification?: Maybe<CertificationPartialType>;
  certificationId?: Maybe<Scalars['String']['output']>;
  co2: Scalars['Float']['output'];
  growthModel?: Maybe<GrowthModel>;
  growthModelId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  species?: Maybe<TreeSpecies>;
  speciesIdentifier?: Maybe<Scalars['String']['output']>;
  year: Scalars['Float']['output'];
  yearDiff?: Maybe<Scalars['Float']['output']>;
};

export type Co2PerYearInput = {
  avoidance?: InputMaybe<Scalars['Float']['input']>;
  baselineId?: InputMaybe<Scalars['String']['input']>;
  certificationId?: InputMaybe<Scalars['String']['input']>;
  co2: Scalars['Float']['input'];
  growthModelId?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Float']['input'];
  yearDiff?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateArGrowthModelInput = {
  firePrevention: Scalars['Boolean']['input'];
  forrestManagement: Scalars['Boolean']['input'];
  gameManagement: Scalars['Boolean']['input'];
  generalRemarks?: InputMaybe<Scalars['String']['input']>;
  groundMaintenance: Scalars['Boolean']['input'];
  growthModelId?: InputMaybe<Scalars['String']['input']>;
  naturalRegeneration: Scalars['Boolean']['input'];
  plannedSpecies: Array<Scalars['JSON']['input']>;
  planting: Scalars['Boolean']['input'];
  projectActivityDescription?: InputMaybe<Scalars['String']['input']>;
  projectActivitySupportDescription?: InputMaybe<Scalars['String']['input']>;
  regionId?: InputMaybe<Scalars['Float']['input']>;
  seeding: Scalars['Boolean']['input'];
};

export type CreateBaselineInput = {
  certificationId: Scalars['String']['input'];
  co2Years?: InputMaybe<Array<Co2PerYearInput>>;
  inputId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sites?: InputMaybe<Array<AssignSiteInput>>;
};

export type CreateCertificationInput = {
  creditingPeriod?: InputMaybe<Scalars['Float']['input']>;
  creditingPeriodStart?: InputMaybe<Scalars['DateTime']['input']>;
  method?: InputMaybe<StandardMethod>;
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  testCertification?: InputMaybe<Scalars['Boolean']['input']>;
  totalArea?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateGroupCertificationInput = {
  certificationIds: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateGrowthModelInput = {
  certificationId: Scalars['String']['input'];
  co2Years?: InputMaybe<Array<Co2PerYearInput>>;
  inputId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sites?: InputMaybe<Array<AssignSiteInput>>;
};

export type CreateProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  mainImage?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  regioInfo?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSiteInput = {
  certificationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<GeometryInput>;
  label: Scalars['String']['input'];
  unstockedArea?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  oauthId?: InputMaybe<Scalars['String']['input']>;
  organisationInternalContact?: InputMaybe<Scalars['String']['input']>;
  organisationName?: InputMaybe<Scalars['String']['input']>;
  organisationType?: InputMaybe<OrganisationType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteAuditorRequestInput = {
  certificationId: Scalars['String']['input'];
  groupCertificationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type Document = {
  author?: Maybe<Scalars['String']['output']>;
  category?: Maybe<DocumentCategory>;
  certification?: Maybe<CertificationPartialType>;
  certificationId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  file: SecureFile;
  fileId: Scalars['String']['output'];
  groupCertification?: Maybe<GroupCertification>;
  groupCertificationId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<Scalars['JSON']['output']>;
  ownerId: Scalars['String']['output'];
  pddAppendix?: Maybe<Scalars['Boolean']['output']>;
  project?: Maybe<Project>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  relations?: Maybe<Array<DocumentRelation>>;
  remarks: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
  visibility?: Maybe<DocumentVisibility>;
};

/** Categories of documents */
export enum DocumentCategory {
  ADDITIONALITY = 'ADDITIONALITY',
  AGB_PLATFORM = 'AGB_PLATFORM',
  AGB_STANDARD = 'AGB_STANDARD',
  ALL_CERTIFICATION_DOCS_ZIP = 'ALL_CERTIFICATION_DOCS_ZIP',
  FOREST_MANAGEMENT_PLAN = 'FOREST_MANAGEMENT_PLAN',
  FSC_CERTIFICATION = 'FSC_CERTIFICATION',
  PEFC_CERTIFICATION = 'PEFC_CERTIFICATION',
  PRE_CERTIFICATION = 'PRE_CERTIFICATION',
  SIGNED_PDD = 'SIGNED_PDD'
}

export type DocumentRelation = {
  auditorRequest?: Maybe<AuditorRequest>;
  auditorRequestId?: Maybe<Scalars['String']['output']>;
  baseline?: Maybe<Baseline>;
  baselineId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  document: Document;
  documentId: Scalars['String']['output'];
  growthModel?: Maybe<GrowthModel>;
  growthModelId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  indication?: Maybe<Indication>;
  indicationId?: Maybe<Scalars['String']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type DocumentRelationInput = {
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  documentId: Scalars['String']['input'];
};

/** Possible visibilitys */
export enum DocumentVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type GenericCertification = {
  certifierId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type Geometry = {
  coordinates: Scalars['JSON']['output'];
  type: GeometryType;
};

export type GeometryInput = {
  coordinates: Scalars['JSON']['input'];
  type: GeometryType;
};

export enum GeometryType {
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon'
}

/** Level of groundwater influence */
export enum GroundWaterInfluence {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  STRONG = 'STRONG',
  STRONG_INCLINED = 'STRONG_INCLINED',
  UNKNOWN = 'UNKNOWN'
}

export type GroupCertification = {
  auditorRequests: Array<AuditorRequest>;
  certifications: Array<Certification>;
  certifier?: Maybe<User>;
  certifierId?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  documents: Array<Document>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: User;
  ownerId: Scalars['String']['output'];
  readonly?: Maybe<Scalars['Boolean']['output']>;
  status: CertificationStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type GrowthModel = {
  auditorRequests?: Maybe<Array<AuditorRequest>>;
  certification?: Maybe<CertificationPartialType>;
  certificationId: Scalars['String']['output'];
  co2Avoidance?: Maybe<Scalars['Float']['output']>;
  co2Storage?: Maybe<Scalars['Float']['output']>;
  co2Years?: Maybe<Array<Co2PerYear>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['String']['output'];
  input?: Maybe<ArGrowthModelData>;
  /**
   * relation to input
   * depending on related certification method
   */
  inputId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  readonly?: Maybe<Scalars['Boolean']['output']>;
  /**
   * certifier reviewed the verificationStatus
   * the status itself is in property verificationStatus
   */
  reviewed: Scalars['Boolean']['output'];
  sites?: Maybe<Array<Site>>;
  totalArea?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /** input is valid */
  valid: Scalars['Boolean']['output'];
  verificationStatus: VerificationStatus;
};

export type GrowthRegion = {
  id: Scalars['Float']['output'];
  /** klimatische Wasserbilanz 2021-2050 */
  kwb50: Scalars['Float']['output'];
  /** klimatische Wasserbilanz 2051-2100 */
  kwb100: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type History = {
  certification?: Maybe<Certification>;
  certificationId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  diff?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  operation: Scalars['String']['output'];
  readonly?: Maybe<Scalars['Boolean']['output']>;
  resourceType: Scalars['String']['output'];
  scope: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  user?: Maybe<User>;
};

export type Image = {
  copyright?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  data: Scalars['JSON']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Float']['output'];
  fileType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  md5: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<User>;
  ownerId: Scalars['String']['output'];
  path: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['String']['output'];
  readonly?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type Indication = {
  auditorRequests?: Maybe<Array<AuditorRequest>>;
  certification?: Maybe<CertificationPartialType>;
  certificationId: Scalars['String']['output'];
  /**
   * PM confirms that the indication
   * requirements are met
   */
  confirmed: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['String']['output'];
  indicator: Scalars['JSON']['output'];
  indicatorId: Scalars['String']['output'];
  messages?: Maybe<Array<Message>>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  /**
   * certifier reviewed the verificationStatus
   * the status itself is in property verificationStatus
   */
  reviewed: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /**
   * flag to show PM if indication is valid:
   * - input is valid (required fields)
   *
   * if the indication has auditor requests:
   * - are there unanswered questions?
   */
  valid: Scalars['Boolean']['output'];
  /**
   * verificationStatus is derived from the associated
   * auditor requests
   * if there are multiple auditor requests with different verification
   * status the most relevant status is derived from
   */
  verificationStatus: VerificationStatus;
};

export type Message = {
  auditorRequest?: Maybe<AuditorRequest>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hasAnswer: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  indication: Indication;
  notified: Scalars['Boolean']['output'];
  parentMessage?: Maybe<Message>;
  parentMessageId?: Maybe<Scalars['String']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  unread: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  /**
   * Status of the related indication at the time when
   * the message was created
   */
  verificationStatus?: Maybe<VerificationStatus>;
};

export type MessageInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  parentMessage?: InputMaybe<Scalars['JSON']['input']>;
  parentMessageId?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  verificationStatus?: InputMaybe<VerificationStatus>;
};

export type Mutation = {
  certification: Certification;
  createArGrowthModel: ArGrowthModelData;
  createAuditorRequest: Certification;
  createAuditorRequestMessage: Certification;
  createBaseline: Certification;
  createCertification: Certification;
  createGroupCertification: GroupCertification;
  createGrowthModel: Certification;
  createProject: Project;
  createSite: Certification;
  createSystemMessage: SystemMessage;
  createUser: User;
  createdDocument: Document;
  createdImage: Image;
  deleteAuditorRequest: Certification;
  deleteBaseline: Certification;
  deleteCertification: Certification;
  deleteDocumentResult: Document;
  deleteGrowthModel: Certification;
  deleteImageResult: Scalars['Int']['output'];
  deleteProject: Project;
  deleteSite: Certification;
  deleteUser: User;
  groupCertification: GroupCertification;
  removeArGrowthModel: ArGrowthModelData;
  updateArGrowthModel: ArGrowthModelData;
  updateAuditData: Certification;
  updateAuditorRequest: Certification;
  updateAuditorRequestDocuments: Certification;
  updateAuditorRequestMessage: Certification;
  updateBaseline: Certification;
  updateCertification: Certification;
  updateCertificationStatus: Certification;
  updateDocument: Document;
  updateGroupCertification: GroupCertification;
  updateGroupCertificationStatus: GroupCertification;
  updateGrowthModel: Certification;
  updateImage: Image;
  updateIndication: Certification;
  updateOrganisation: Organisation;
  updateProject: Project;
  updateSite: Certification;
  updateSystemMessage: SystemMessage;
  updateUser: User;
};


export type MutationCertificationArgs = {
  input: UpdateCertifierInput;
};


export type MutationCreateArGrowthModelArgs = {
  createGrowthModelInput: CreateArGrowthModelInput;
};


export type MutationCreateAuditorRequestArgs = {
  input: AuditorRequestInput;
};


export type MutationCreateAuditorRequestMessageArgs = {
  input: AuditorRequestMessageInput;
};


export type MutationCreateBaselineArgs = {
  input: CreateBaselineInput;
};


export type MutationCreateCertificationArgs = {
  input: CreateCertificationInput;
};


export type MutationCreateGroupCertificationArgs = {
  input: CreateGroupCertificationInput;
};


export type MutationCreateGrowthModelArgs = {
  input: CreateGrowthModelInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreateSiteArgs = {
  input: Array<CreateSiteInput>;
};


export type MutationCreateSystemMessageArgs = {
  input: SystemMessageInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreatedDocumentArgs = {
  category?: InputMaybe<DocumentCategory>;
  certificationId?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  fileSize: Scalars['Int']['input'];
  groupCertificationId?: InputMaybe<Scalars['String']['input']>;
  index: Scalars['Int']['input'];
  md5FileSum: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pddAppendix?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  subPath?: InputMaybe<Scalars['String']['input']>;
  total: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatedImageArgs = {
  file: Scalars['Upload']['input'];
  mainImage: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};


export type MutationDeleteAuditorRequestArgs = {
  input: DeleteAuditorRequestInput;
};


export type MutationDeleteBaselineArgs = {
  input: UpdateBaselineInput;
};


export type MutationDeleteCertificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDocumentResultArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteGrowthModelArgs = {
  input: UpdateGrowthModelInput;
};


export type MutationDeleteImageResultArgs = {
  id: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteProjectArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteSiteArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationGroupCertificationArgs = {
  input: UpdateCertifierInput;
};


export type MutationRemoveArGrowthModelArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUpdateArGrowthModelArgs = {
  updateGrowthModelInput: UpdateArGrowthModelInput;
};


export type MutationUpdateAuditDataArgs = {
  input: UpdateAuditDataInput;
};


export type MutationUpdateAuditorRequestArgs = {
  input: AuditorRequestUpdateInput;
};


export type MutationUpdateAuditorRequestDocumentsArgs = {
  input: AuditorRequestDocumentsInput;
};


export type MutationUpdateAuditorRequestMessageArgs = {
  input: AuditorRequestMessageInput;
};


export type MutationUpdateBaselineArgs = {
  input: UpdateBaselineInput;
  methodInput?: InputMaybe<ArBaselineDataInput>;
};


export type MutationUpdateCertificationArgs = {
  input: UpdateCertificationInput;
};


export type MutationUpdateCertificationStatusArgs = {
  input: UpdateCertificationStatusInput;
};


export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


export type MutationUpdateGroupCertificationArgs = {
  input: UpdateGroupCertificationInput;
};


export type MutationUpdateGroupCertificationStatusArgs = {
  input: UpdateGroupCertificationStatusInput;
};


export type MutationUpdateGrowthModelArgs = {
  input: UpdateGrowthModelInput;
  methodInput?: InputMaybe<ArGrowthModelInput>;
};


export type MutationUpdateImageArgs = {
  input: UpdateImageInput;
};


export type MutationUpdateIndicationArgs = {
  input: UpdateIndicationInput;
};


export type MutationUpdateOrganisationArgs = {
  input: UpdateOrganisationInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};


export type MutationUpdateSiteArgs = {
  input: UpdateSiteInput;
};


export type MutationUpdateSystemMessageArgs = {
  id: Scalars['String']['input'];
  input: SystemMessageInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type Organisation = {
  address?: Maybe<Scalars['String']['output']>;
  certifications?: Maybe<Array<CertificationPartialType>>;
  city?: Maybe<Scalars['String']['output']>;
  clients?: Maybe<Array<Organisation>>;
  consultancy?: Maybe<Organisation>;
  consultancyId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Scalars['String']['output']>;
  contactMail?: Maybe<Scalars['String']['output']>;
  contactPhone?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  internalContact?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner?: Maybe<User>;
  ownerId: Scalars['String']['output'];
  projects?: Maybe<Array<ProjectPartial>>;
  textTemplates?: Maybe<Array<TextTemplate>>;
  type: OrganisationType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  users?: Maybe<Array<User>>;
  website?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/** Possible organisation types */
export enum OrganisationType {
  COMPANY = 'COMPANY',
  CONSULTANCY = 'CONSULTANCY',
  ORGANISATION = 'ORGANISATION',
  PERSON = 'PERSON',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  STATE = 'STATE',
  TOWNSHIP = 'TOWNSHIP'
}

export type Project = {
  certifications?: Maybe<Array<CertificationPartialType>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['String']['output'];
  images?: Maybe<Array<Image>>;
  /** center coordinates of the whole project */
  latitude?: Maybe<Scalars['String']['output']>;
  /** center coordinates of the whole project */
  longitude?: Maybe<Scalars['String']['output']>;
  mainImage?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner: User;
  ownerId: Scalars['String']['output'];
  projectCode?: Maybe<Scalars['String']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  regioInfo?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  teaser?: Maybe<Scalars['String']['output']>;
  totalArea?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type ProjectPartial = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: User;
  ownerId: Scalars['String']['output'];
  projectCode?: Maybe<Scalars['String']['output']>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type Query = {
  authenticatedUser: User;
  baseline: Baseline;
  certification: Certification;
  certifications: Array<CertificationPartialType>;
  getArGrowthModelInput: ArGrowthModelData;
  getRegions: Array<GrowthRegion>;
  getScenarioData: ScenarioData;
  getSystemData: SystemData;
  getTreeSpecies: Array<ArTreeSpecies>;
  groupCertification: GroupCertification;
  growthModel: GrowthModel;
  histories: Array<History>;
  history: History;
  image: Image;
  loadCertifications: Array<Certification>;
  loadSystemData: User;
  loadUser: User;
  project: Project;
  projects: Array<Project>;
  scenarioSummaries: Array<ScenarioSummary>;
  site: Site;
  user: User;
  users: Array<User>;
};


export type QueryBaselineArgs = {
  id: Scalars['String']['input'];
  method: Scalars['String']['input'];
};


export type QueryCertificationArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetArGrowthModelInputArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetScenarioDataArgs = {
  id: Scalars['String']['input'];
};


export type QueryGroupCertificationArgs = {
  id: Scalars['String']['input'];
};


export type QueryGrowthModelArgs = {
  id: Scalars['String']['input'];
  method: Scalars['String']['input'];
};


export type QueryHistoriesArgs = {
  certificationId: Scalars['String']['input'];
};


export type QueryHistoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryImageArgs = {
  id: Scalars['Int']['input'];
};


export type QueryLoadUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryProjectArgs = {
  id: Scalars['String']['input'];
};


export type QueryScenarioSummariesArgs = {
  id: Scalars['String']['input'];
  scenarioType: Scalars['String']['input'];
};


export type QuerySiteArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

/** Possible roles */
export enum Role {
  ADMIN = 'ADMIN',
  ANONYMOUS = 'ANONYMOUS',
  AUDITOR = 'AUDITOR',
  CONSULTANT = 'CONSULTANT',
  PROJECT_MANAGER = 'PROJECT_MANAGER'
}

export type ScenarioData = {
  baselineInput: ArBaselineData;
  growthModelInput: ArGrowthModelData;
  inputId: Scalars['String']['output'];
  scenarioId?: Maybe<Scalars['String']['output']>;
  scenarioName?: Maybe<Scalars['String']['output']>;
};

export type ScenarioSummary = {
  certificationId?: Maybe<Scalars['String']['output']>;
  certificationName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  inputId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type SecureFile = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data: Scalars['JSON']['output'];
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Float']['output'];
  fileType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  md5: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type Site = {
  auditorRequests?: Maybe<Array<AuditorRequest>>;
  baseline?: Maybe<Baseline>;
  baselineId?: Maybe<Scalars['String']['output']>;
  certification?: Maybe<CertificationPartialType>;
  certificationId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  geometry?: Maybe<Geometry>;
  growthModel?: Maybe<GrowthModel>;
  growthModelId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  owner: User;
  ownerId: Scalars['String']['output'];
  readonly?: Maybe<Scalars['Boolean']['output']>;
  region?: Maybe<GrowthRegion>;
  regionId?: Maybe<Scalars['Float']['output']>;
  totalArea?: Maybe<Scalars['Float']['output']>;
  unstockedArea?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

/** Possible certification methods */
export enum StandardMethod {
  AR = 'AR',
  IFM = 'IFM'
}

export type SystemData = {
  documents: Array<Document>;
  systemMessages: Array<SystemMessage>;
};

export type SystemMessage = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expireDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  readByUser?: Maybe<Array<User>>;
  readonly?: Maybe<Scalars['Boolean']['output']>;
  severity: Scalars['String']['output'];
  targetRole?: Maybe<Role>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type SystemMessageInput = {
  expireDate: Scalars['DateTime']['input'];
  severity: Scalars['String']['input'];
  targetRole: Role;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type TextTemplate = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organisation?: Maybe<Organisation>;
  organisationId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
};

export type TextTemplateInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

/** Maps specific species to generic categories */
export enum TreeFunctionalGroups {
  BUCHE = 'BUCHE',
  DOUGLASIE = 'DOUGLASIE',
  EICHE = 'EICHE',
  FICHTE = 'FICHTE',
  KIEFER = 'KIEFER',
  LAERCHE = 'LAERCHE',
  LHL = 'LHL',
  LNL = 'LNL',
  NONE = 'NONE',
  WEISSTANNE = 'WEISSTANNE'
}

export type TreeSpecies = {
  germanName: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
  mdd?: Maybe<Scalars['Float']['output']>;
  pioneer: Scalars['Boolean']['output'];
  scientificName: Scalars['String']['output'];
};

/** Category for trophy */
export enum TrophyCategory {
  EUTROPH1 = 'EUTROPH1',
  EUTROPH2 = 'EUTROPH2',
  MEDIUM_MESOTROPH = 'MEDIUM_MESOTROPH',
  OLIGOTROPH = 'OLIGOTROPH',
  STRONG_MESOTROPH = 'STRONG_MESOTROPH',
  UNKNOWN = 'UNKNOWN',
  WEAK_MESOTROPH = 'WEAK_MESOTROPH'
}

export type UpdateArGrowthModelInput = {
  firePrevention: Scalars['Boolean']['input'];
  forrestManagement: Scalars['Boolean']['input'];
  gameManagement: Scalars['Boolean']['input'];
  generalRemarks?: InputMaybe<Scalars['String']['input']>;
  groundMaintenance: Scalars['Boolean']['input'];
  growthModelId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  naturalRegeneration: Scalars['Boolean']['input'];
  plannedSpecies: Array<Scalars['JSON']['input']>;
  planting: Scalars['Boolean']['input'];
  projectActivityDescription?: InputMaybe<Scalars['String']['input']>;
  projectActivitySupportDescription?: InputMaybe<Scalars['String']['input']>;
  regionId?: InputMaybe<Scalars['Float']['input']>;
  seeding: Scalars['Boolean']['input'];
};

export type UpdateAuditDataInput = {
  certificationCompleteDate?: InputMaybe<Scalars['DateTime']['input']>;
  certificationConclusion?: InputMaybe<Scalars['String']['input']>;
  certificationOpinion?: InputMaybe<Scalars['String']['input']>;
  certificationStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  involvedPersons?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CertificationStatus>;
  timeSchedule?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBaselineInput = {
  certificationId: Scalars['String']['input'];
  co2Years?: InputMaybe<Array<Co2PerYearInput>>;
  documents?: InputMaybe<Array<Scalars['JSON']['input']>>;
  id: Scalars['String']['input'];
  inputId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sites?: InputMaybe<Array<AssignSiteInput>>;
  totalArea?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCertificationInput = {
  co2Years?: InputMaybe<Array<Co2PerYearInput>>;
  creditingPeriod?: InputMaybe<Scalars['Float']['input']>;
  creditingPeriodStart?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  method?: InputMaybe<StandardMethod>;
  name?: InputMaybe<Scalars['String']['input']>;
  sample?: InputMaybe<Scalars['Boolean']['input']>;
  standardVersion?: InputMaybe<Scalars['JSON']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
  testCertification?: InputMaybe<Scalars['Boolean']['input']>;
  totalArea?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCertificationStatusInput = {
  id: Scalars['String']['input'];
  status: CertificationStatus;
};

export type UpdateCertifierInput = {
  certificationId: Scalars['String']['input'];
  certifierId: Scalars['String']['input'];
};

export type UpdateDocumentInput = {
  category?: InputMaybe<DocumentCategory>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pddAppendix?: InputMaybe<Scalars['Boolean']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<DocumentVisibility>;
};

export type UpdateGroupCertificationInput = {
  certificationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateGroupCertificationStatusInput = {
  id: Scalars['String']['input'];
  status: CertificationStatus;
};

export type UpdateGrowthModelInput = {
  certificationId: Scalars['String']['input'];
  co2Years?: InputMaybe<Array<Co2PerYearInput>>;
  documents?: InputMaybe<Array<Scalars['JSON']['input']>>;
  id: Scalars['String']['input'];
  inputId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sites?: InputMaybe<Array<AssignSiteInput>>;
  totalArea?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateImageInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdateIndicationInput = {
  applyToGroup?: InputMaybe<Scalars['Boolean']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  documentRelations?: InputMaybe<Array<DocumentRelationInput>>;
  id: Scalars['String']['input'];
  message?: InputMaybe<MessageInput>;
  text?: InputMaybe<Scalars['String']['input']>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * even required fields have to be optional here
 * since graphql doesn't accept required fields for
 * initial optional fields?
 */
export type UpdateOrganisationInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  consultancyId?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<Scalars['String']['input']>;
  contactMail?: InputMaybe<Scalars['String']['input']>;
  contactPhone?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  internalContact?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  textTemplates?: InputMaybe<Array<TextTemplateInput>>;
  type?: InputMaybe<OrganisationType>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  mainImage?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  regioInfo?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  teaser?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSiteInput = {
  certificationId: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  geometry?: InputMaybe<GeometryInput>;
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  unstockedArea?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateUserInput = {
  agbAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  agbAcceptedDate?: InputMaybe<Scalars['DateTime']['input']>;
  agbVersionAccepted?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<Scalars['JSON']['input']>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  readMessages?: InputMaybe<Array<Scalars['JSON']['input']>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  agbAccepted: Scalars['Boolean']['output'];
  agbAcceptedDate?: Maybe<Scalars['DateTime']['output']>;
  agbVersionAccepted: Scalars['String']['output'];
  certifications?: Maybe<Array<CertificationPartialType>>;
  clientCertifications?: Maybe<Array<CertificationPartialType>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  documents?: Maybe<Array<Document>>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  organisation?: Maybe<Organisation>;
  phone?: Maybe<Scalars['String']['output']>;
  /**
   * avoid circular dependency
   * by using strings here
   * see https://github.com/typeorm/typeorm/issues/4190
   */
  projects?: Maybe<Array<Project>>;
  readMessages?: Maybe<Array<SystemMessage>>;
  remarks?: Maybe<Scalars['String']['output']>;
  role: Role;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Possible verification states */
export enum VerificationStatus {
  CLARIFICATION_REQUEST = 'CLARIFICATION_REQUEST',
  COMPLIANT = 'COMPLIANT',
  CORRECTIVE_ACTION_REQUEST = 'CORRECTIVE_ACTION_REQUEST',
  ENDANGERED = 'ENDANGERED',
  FORWARD_ACTION_REQUEST = 'FORWARD_ACTION_REQUEST',
  NONE = 'NONE',
  NON_COMPLIANT = 'NON_COMPLIANT'
}

export type CertificationPartialFragment = { id: string, certificationStartDate?: any | null, certificationCompleteDate?: any | null, co2Storage?: number | null, co2Avoidance?: number | null, createdAt?: any | null, creditingPeriod?: number | null, creditingPeriodStart?: any | null, groupId?: string | null, internalContact?: string | null, internalRemarks?: string | null, involvedPersons?: string | null, location?: any | null, method: StandardMethod, name: string, ownerId: string, sample: boolean, standardVersion: string, status: CertificationStatus, testCertification?: boolean | null, timeSchedule?: string | null, totalArea?: number | null, updatedAt?: any | null, certifier?: UserSimpleFragment | null, consultant?: UserSimpleFragment | null, createdBy?: UserSimpleFragment | null, group?: { id: string, name: string, code: string, status: CertificationStatus, certifier?: UserSimpleFragment | null } | null, growthRegion?: { id: number, name: string, kwb50: number, kwb100: number } | null, owner: UserSimpleFragment, project: { id: string, name: string, projectCode?: string | null }, updatedBy?: UserSimpleFragment | null };

export type CertificationFragment = { id: string, certificationStartDate?: any | null, certificationCompleteDate?: any | null, certificationConclusion?: string | null, certificationOpinion?: string | null, co2Storage?: number | null, co2Avoidance?: number | null, createdAt?: any | null, creditingPeriod?: number | null, creditingPeriodStart?: any | null, groupId?: string | null, internalContact?: string | null, internalRemarks?: string | null, involvedPersons?: string | null, location?: any | null, method: StandardMethod, name: string, ownerId: string, sample: boolean, status: CertificationStatus, standardVersion: string, testCertification?: boolean | null, timeSchedule?: string | null, totalArea?: number | null, updatedAt?: any | null, auditorRequests: Array<AuditorRequestFragment>, baselines: Array<BaselineFragment>, certifier?: UserSimpleFragment | null, consultant?: UserSimpleFragment | null, co2Years: Array<{ year: number, co2: number, yearDiff?: number | null, avoidance?: number | null }>, createdBy?: UserSimpleFragment | null, documents: Array<DocumentSimpleFragment>, group?: { id: string, name: string, code: string, status: CertificationStatus, documents: Array<DocumentSimpleFragment> } | null, growthModels: Array<GrowthModelFragment>, growthRegion?: { id: number, name: string, kwb50: number, kwb100: number } | null, indications: Array<IndicationFragment>, owner: UserSimpleFragment, project: { id: string, name: string, startDate?: any | null, projectCode?: string | null, description?: string | null, teaser?: string | null, images?: Array<ImageSimpleFragment> | null }, sites: Array<SiteFragment>, updatedBy?: UserSimpleFragment | null };

export type GroupCertificationFragment = { id: string, code: string, name: string, ownerId: string, status: CertificationStatus, certifier?: UserSimpleFragment | null, certifications: Array<CertificationFragment>, owner: UserSimpleFragment, auditorRequests: Array<AuditorRequestFragment>, documents: Array<DocumentSimpleFragment> };

export type GroupCertificationPartialFragment = { id: string, code: string, name: string, ownerId: string, status: CertificationStatus, certifier?: UserSimpleFragment | null, certifications: Array<{ id: string }>, owner: UserSimpleFragment };

export type IndicationSimpleFragment = { id: string, valid: boolean, reviewed: boolean, indicatorId: string, confirmed: boolean, text: string };

export type IndicationFragment = { id: string, updatedAt?: any | null, confirmed: boolean, text: string, valid: boolean, reviewed: boolean, indicatorId: string, verificationStatus: VerificationStatus, documents?: Array<DocumentSimpleFragment> | null, messages?: Array<MessageFragment> | null };

export type TreeSpeciesShareFragment = { id: string, share: number, dryLoss: boolean, naturalRegeneration: boolean, delayedNatReg: boolean, species: TreeSpeciesFragment };

export type GrowthModelFragment = { id: string, name: string, createdAt?: any | null, inputId?: string | null, certificationId: string, totalArea?: number | null, co2Storage?: number | null, reviewed: boolean, valid: boolean, verificationStatus: VerificationStatus, createdBy?: { firstName: string, lastName: string } | null, input?: { naturalRegeneration: boolean, planting: boolean, seeding: boolean, projectActivityDescription?: string | null, gameManagement: boolean, groundMaintenance: boolean, forrestManagement: boolean, firePrevention: boolean, projectActivitySupportDescription?: string | null, generalRemarks?: string | null, plannedSpecies: Array<TreeSpeciesShareFragment> } | null, certification?: CertificationPartialFragment | null, sites?: Array<{ id: string, label: string, totalArea?: number | null }> | null, co2Years?: Array<{ year: number, co2: number, yearDiff?: number | null, avoidance?: number | null }> | null, documents?: Array<DocumentSimpleFragment> | null };

export type BaselineFragment = { id: string, name: string, createdAt?: any | null, inputId?: string | null, certificationId: string, totalArea?: number | null, co2Storage?: number | null, reviewed: boolean, valid: boolean, verificationStatus: VerificationStatus, createdBy?: { firstName: string, lastName: string } | null, input?: ArBaselineDataFragment | null, certification?: CertificationPartialFragment | null, sites?: Array<{ id: string, label: string, totalArea?: number | null }> | null, co2Years?: Array<{ year: number, co2: number, yearDiff?: number | null, avoidance?: number | null }> | null, documents?: Array<DocumentSimpleFragment> | null };

export type ArBaselineDataFragment = { id: string, backWaterInfluence: BackWaterInfluence, establishedNatRegArea: number, fireRisk?: number | null, generalRemarks?: string | null, groundVegetation: number, groundVegetationRemarks?: string | null, groundWaterInfluence: GroundWaterInfluence, inclination: boolean, lateFrostRisk: boolean, nfk?: number | null, noNatRegReasons: string, noNatRegPossibleArea: number, nonEstablishedNatRegArea: number, nonEstablishedNatRegGroundVegetation: number, preExistingBioMass: number, siteWaterBalance50?: number | null, siteWaterBalance?: number | null, soilTrophyCategory: TrophyCategory, soilType?: string | null, waterHoldingCapacity?: number | null, closeSpecies: Array<TreeSpeciesShareFragment>, establishedNatReg: Array<TreeSpeciesShareFragment>, nonEstablishedNatReg: Array<TreeSpeciesShareFragment>, previousSpecies: Array<TreeSpeciesShareFragment>, pioneerSpecies: Array<TreeSpeciesShareFragment>, reducedTreeMix?: Array<TreeSpeciesShareFragment> | null, remainingSpecies: Array<TreeSpeciesShareFragment> };

export type ScenarioDataFragment = { scenarioId?: string | null, scenarioName?: string | null, inputId: string, baselineInput: ArBaselineDataFragment, growthModelInput: { naturalRegeneration: boolean, planting: boolean, seeding: boolean, projectActivityDescription?: string | null, gameManagement: boolean, groundMaintenance: boolean, forrestManagement: boolean, firePrevention: boolean, projectActivitySupportDescription?: string | null, generalRemarks?: string | null, plannedSpecies: Array<TreeSpeciesShareFragment> } };

export type GetScenarioDataQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetScenarioDataQueryResponse = { getScenarioData: ScenarioDataFragment };

export type ListCertificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCertificationsQueryResponse = { certifications: Array<CertificationPartialFragment> };

export type LoadFullCertificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadFullCertificationsQueryResponse = { loadCertifications: Array<CertificationFragment> };

export type LoadCertificationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LoadCertificationQueryResponse = { certification: CertificationFragment };

export type LoadGroupCertificationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LoadGroupCertificationQueryResponse = { groupCertification: GroupCertificationFragment };

export type CreateCertificationMutationVariables = Exact<{
  input: CreateCertificationInput;
}>;


export type CreateCertificationMutationResponse = { createCertification: CertificationFragment };

export type UpdateCertificationMutationVariables = Exact<{
  input: UpdateCertificationInput;
}>;


export type UpdateCertificationMutationResponse = { updateCertification: CertificationFragment };

export type UpdateAuditDataMutationVariables = Exact<{
  input: UpdateAuditDataInput;
}>;


export type UpdateAuditDataMutationResponse = { updateAuditData: CertificationFragment };

export type UpdateCertificationStatusMutationVariables = Exact<{
  input: UpdateCertificationStatusInput;
}>;


export type UpdateCertificationStatusMutationResponse = { updateCertificationStatus: CertificationFragment };

export type CreateGroupCertificationMutationVariables = Exact<{
  input: CreateGroupCertificationInput;
}>;


export type CreateGroupCertificationMutationResponse = { createGroupCertification: GroupCertificationFragment };

export type UpdateGroupCertificationMutationVariables = Exact<{
  input: UpdateGroupCertificationInput;
}>;


export type UpdateGroupCertificationMutationResponse = { updateGroupCertification: GroupCertificationFragment };

export type UpdateGroupCertificationStatusMutationVariables = Exact<{
  input: UpdateGroupCertificationStatusInput;
}>;


export type UpdateGroupCertificationStatusMutationResponse = { updateGroupCertificationStatus: GroupCertificationFragment };

export type UpdateCertifierMutationVariables = Exact<{
  input: UpdateCertifierInput;
}>;


export type UpdateCertifierMutationResponse = { certification: CertificationFragment };

export type UpdateGroupCertifierMutationVariables = Exact<{
  input: UpdateCertifierInput;
}>;


export type UpdateGroupCertifierMutationResponse = { groupCertification: GroupCertificationFragment };

export type DeleteCertificationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteCertificationMutationResponse = { deleteCertification: CertificationFragment };

export type UpdateIndicationMutationVariables = Exact<{
  input: UpdateIndicationInput;
}>;


export type UpdateIndicationMutationResponse = { updateIndication: (
    { group?: { id: string, status: CertificationStatus, certifications: Array<{ id: string, status: CertificationStatus, indications: Array<IndicationFragment> }>, documents: Array<DocumentSimpleFragment> } | null }
    & CertificationFragment
  ) };

export type UpdateBaselineMutationVariables = Exact<{
  input: UpdateBaselineInput;
  methodInput?: InputMaybe<ArBaselineDataInput>;
}>;


export type UpdateBaselineMutationResponse = { updateBaseline: CertificationFragment };

export type CreateBaselineMutationVariables = Exact<{
  input: CreateBaselineInput;
}>;


export type CreateBaselineMutationResponse = { createBaseline: CertificationFragment };

export type GetBaselineQueryVariables = Exact<{
  method: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;


export type GetBaselineQueryResponse = { baseline: BaselineFragment };

export type DeleteBaselineMutationVariables = Exact<{
  input: UpdateBaselineInput;
}>;


export type DeleteBaselineMutationResponse = { deleteBaseline: CertificationFragment };

export type CreateGrowthModelMutationVariables = Exact<{
  input: CreateGrowthModelInput;
}>;


export type CreateGrowthModelMutationResponse = { createGrowthModel: CertificationFragment };

export type UpdateGrowthModelMutationVariables = Exact<{
  input: UpdateGrowthModelInput;
  methodInput?: InputMaybe<ArGrowthModelInput>;
}>;


export type UpdateGrowthModelMutationResponse = { updateGrowthModel: CertificationFragment };

export type DeleteGrowthModelMutationVariables = Exact<{
  input: UpdateGrowthModelInput;
}>;


export type DeleteGrowthModelMutationResponse = { deleteGrowthModel: CertificationFragment };

export type GetGrowthModelQueryVariables = Exact<{
  method: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;


export type GetGrowthModelQueryResponse = { growthModel: GrowthModelFragment };

export type CreateAuditorRequestMutationVariables = Exact<{
  input: AuditorRequestInput;
}>;


export type CreateAuditorRequestMutationResponse = { createAuditorRequest: (
    { group?: { id: string, status: CertificationStatus, auditorRequests: Array<AuditorRequestFragment>, certifications: Array<{ id: string, status: CertificationStatus, indications: Array<IndicationFragment> }>, documents: Array<DocumentSimpleFragment> } | null }
    & CertificationFragment
  ) };

export type UpdateAuditorRequestMutationVariables = Exact<{
  input: AuditorRequestUpdateInput;
}>;


export type UpdateAuditorRequestMutationResponse = { updateAuditorRequest: (
    { group?: { id: string, status: CertificationStatus, auditorRequests: Array<AuditorRequestFragment>, certifications: Array<{ id: string, status: CertificationStatus, indications: Array<IndicationFragment> }>, documents: Array<DocumentSimpleFragment> } | null }
    & CertificationFragment
  ) };

export type DeleteAuditorRequestMutationVariables = Exact<{
  input: DeleteAuditorRequestInput;
}>;


export type DeleteAuditorRequestMutationResponse = { deleteAuditorRequest: (
    { group?: { id: string, status: CertificationStatus, auditorRequests: Array<AuditorRequestFragment>, certifications: Array<{ id: string, status: CertificationStatus, indications: Array<IndicationFragment> }>, documents: Array<DocumentSimpleFragment> } | null }
    & CertificationFragment
  ) };

export type UpdateAuditorRequestMessageMutationVariables = Exact<{
  input: AuditorRequestMessageInput;
}>;


export type UpdateAuditorRequestMessageMutationResponse = { updateAuditorRequestMessage: (
    { group?: { id: string, status: CertificationStatus, auditorRequests: Array<AuditorRequestFragment>, certifications: Array<{ id: string, status: CertificationStatus, indications: Array<IndicationFragment> }>, documents: Array<DocumentSimpleFragment> } | null }
    & CertificationFragment
  ) };

export type CreateAuditorRequestMessageMutationVariables = Exact<{
  input: AuditorRequestMessageInput;
}>;


export type CreateAuditorRequestMessageMutationResponse = { createAuditorRequestMessage: (
    { group?: { id: string, status: CertificationStatus, auditorRequests: Array<AuditorRequestFragment>, certifications: Array<{ id: string, status: CertificationStatus, indications: Array<IndicationFragment> }>, documents: Array<DocumentSimpleFragment> } | null }
    & CertificationFragment
  ) };

export type UpdateAuditorRequestDocumentsMutationVariables = Exact<{
  input: AuditorRequestDocumentsInput;
}>;


export type UpdateAuditorRequestDocumentsMutationResponse = { updateAuditorRequestDocuments: (
    { group?: { id: string, status: CertificationStatus, auditorRequests: Array<AuditorRequestFragment>, certifications: Array<{ id: string, status: CertificationStatus, indications: Array<IndicationFragment> }>, documents: Array<DocumentSimpleFragment> } | null }
    & CertificationFragment
  ) };

export type HistoryFragment = { id: string, operation: string, createdAt?: any | null, resourceType: string, scope: string, diff?: any | null, user?: { id: string, firstName: string, lastName: string, role: Role, organisation?: { name: string } | null } | null, certification?: { id: string } | null };

export type ListHistoriesQueryVariables = Exact<{
  certificationId: Scalars['String']['input'];
}>;


export type ListHistoriesQueryResponse = { histories: Array<HistoryFragment> };

export type LoadHistoryQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LoadHistoryQueryResponse = { history: HistoryFragment };

export type UserSimpleFragment = { id: string, title?: string | null, firstName: string, lastName: string, email: string, phone?: string | null, role: Role, agbAccepted: boolean, organisation?: OrganisationFragment | null };

export type ScenarioSummaryFragment = { id: string, name: string, inputId?: string | null, certificationId?: string | null, certificationName?: string | null };

export type UserFragment = { id: string, createdAt?: any | null, title?: string | null, firstName: string, lastName: string, email: string, phone?: string | null, role: Role, agbAccepted: boolean, agbVersionAccepted: string, agbAcceptedDate?: any | null, remarks?: string | null, createdBy?: { id: string, firstName: string, lastName: string } | null, organisation?: OrganisationFragment | null, readMessages?: Array<{ id: string }> | null };

export type OrganisationFragment = { id: string, name: string, internalContact?: string | null, description?: string | null, zip?: string | null, city?: string | null, address?: string | null, website?: string | null, contact?: string | null, contactMail?: string | null, contactPhone?: string | null, consultancyId?: string | null, ownerId: string, type: OrganisationType, clients?: Array<{ id: string, name: string, owner?: { id: string, firstName: string, lastName: string } | null }> | null, consultancy?: { id: string } | null, textTemplates?: Array<TextTemplateFragment> | null };

export type TextTemplateFragment = { id: string, name: string, text: string };

export type FileSimpleFragment = { id: string, createdAt?: any | null, fileName: string, fileType: string, fileSize: number, path: string };

export type ImageSimpleFragment = { id: string, name: string, fileName: string, fileSize: number, path: string };

export type DocumentRelationFragment = { id: string, createdAt?: any | null, updatedAt?: any | null, indicationId?: string | null, auditorRequestId?: string | null, baselineId?: string | null, growthModelId?: string | null, createdBy?: { id: string } | null, indication?: { id: string, indicatorId: string } | null, auditorRequest?: { id: string } | null, baseline?: { id: string, name: string } | null, growthModel?: { id: string, name: string } | null };

export type DocumentSimpleFragment = { id: string, category?: DocumentCategory | null, createdAt?: any | null, updatedAt?: any | null, name: string, remarks: string, readonly?: boolean | null, visibility?: DocumentVisibility | null, fileId: string, certificationId?: string | null, groupCertificationId?: string | null, pddAppendix?: boolean | null, createdBy?: UserSimpleFragment | null, file: FileSimpleFragment, project?: { id: string } | null, relations?: Array<DocumentRelationFragment> | null, user?: { id: string } | null };

export type TreeSpeciesFragment = { identifier: string, germanName: string, scientificName: string, pioneer: boolean, baseline?: boolean | null, growthModel?: boolean | null, growthGroup?: TreeFunctionalGroups | null, mdd?: number | null };

export type MessageFragment = { id: string, createdAt?: any | null, updatedAt?: any | null, text: string, verificationStatus?: VerificationStatus | null, parentMessageId?: string | null, createdBy?: UserSimpleFragment | null };

export type AuditorRequestFragment = { id: string, createdAt?: any | null, updatedAt?: any | null, number?: number | null, auditorComment: string, certificationId?: string | null, groupCertificationId?: string | null, indicatorId?: string | null, initialVerificationStatus: VerificationStatus, internalAuditorRemarks: string, reminderCount: number, reviewed: boolean, valid: boolean, sample: boolean, verificationStatus: VerificationStatus, createdBy?: UserSimpleFragment | null, baselines?: Array<{ id: string }> | null, certification?: { id: string } | null, groupCertification?: { id: string } | null, documents?: Array<DocumentSimpleFragment> | null, growthModels?: Array<{ id: string }> | null, indication?: { id: string } | null, messages?: Array<MessageFragment> | null, sites?: Array<{ id: string }> | null };

export type SystemDataFragment = { systemMessages: Array<SystemMessageFragment>, documents: Array<DocumentSimpleFragment> };

export type SystemMessageFragment = { id: string, createdAt?: any | null, title: string, text: string, severity: string, expireDate: any, targetRole?: Role | null, createdBy?: UserSimpleFragment | null };

export type LoadAuthenticatedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadAuthenticatedUserQueryResponse = { authenticatedUser: UserFragment };

export type LoadUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LoadUserQueryResponse = { user: UserFragment };

export type ListUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListUsersQueryResponse = { users: Array<UserSimpleFragment> };

export type GetScenarioSummariesQueryVariables = Exact<{
  id: Scalars['String']['input'];
  scenarioType: Scalars['String']['input'];
}>;


export type GetScenarioSummariesQueryResponse = { scenarioSummaries: Array<ScenarioSummaryFragment> };

export type GetTreeSpeciesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTreeSpeciesQueryResponse = { getTreeSpecies: Array<TreeSpeciesFragment> };

export type LoadSystemDataQueryVariables = Exact<{ [key: string]: never; }>;


export type LoadSystemDataQueryResponse = { getSystemData: SystemDataFragment };

export type CreateSystemMessageMutationVariables = Exact<{
  input: SystemMessageInput;
}>;


export type CreateSystemMessageMutationResponse = { createSystemMessage: SystemMessageFragment };

export type UpdateSystemMessageMutationVariables = Exact<{
  id: Scalars['String']['input'];
  input: SystemMessageInput;
}>;


export type UpdateSystemMessageMutationResponse = { updateSystemMessage: SystemMessageFragment };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutationResponse = { createUser: UserFragment };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutationResponse = { updateUser: UserFragment };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteUserMutationResponse = { deleteUser: UserFragment };

export type UpdateOrganisationMutationVariables = Exact<{
  input: UpdateOrganisationInput;
}>;


export type UpdateOrganisationMutationResponse = { updateOrganisation: OrganisationFragment };

export type CreateDocumentMutationVariables = Exact<{
  name: Scalars['String']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  md5FileSum: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
  projectId?: InputMaybe<Scalars['String']['input']>;
  certificationId?: InputMaybe<Scalars['String']['input']>;
  groupCertificationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  subPath?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<DocumentCategory>;
  index: Scalars['Int']['input'];
  total: Scalars['Int']['input'];
  pddAppendix?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateDocumentMutationResponse = { createdDocument: DocumentSimpleFragment };

export type UpdateDocumentMutationVariables = Exact<{
  input: UpdateDocumentInput;
}>;


export type UpdateDocumentMutationResponse = { updateDocument: DocumentSimpleFragment };

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteDocumentMutationResponse = { deleteDocumentResult: DocumentSimpleFragment };

export type CreateImageMutationVariables = Exact<{
  name: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  projectId: Scalars['String']['input'];
  mainImage: Scalars['Boolean']['input'];
}>;


export type CreateImageMutationResponse = { createdImage: ImageSimpleFragment };

export type UpdateImageMutationVariables = Exact<{
  input: UpdateImageInput;
}>;


export type UpdateImageMutationResponse = { updateImage: ImageSimpleFragment };

export type DeleteImageMutationVariables = Exact<{
  id: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeleteImageMutationResponse = { deleteImageResult: number };

export type ProjectSimpleFragment = { id: string, name: string, projectCode?: string | null, createdAt?: any | null, updatedAt?: any | null, description?: string | null, totalArea?: number | null, mainImage?: string | null, startDate?: any | null, subtitle?: string | null, teaser?: string | null, images?: Array<ImageSimpleFragment> | null, certifications?: Array<CertificationPartialFragment> | null, owner: UserSimpleFragment };

export type ProjectFragment = { id: string, projectCode?: string | null, createdAt?: any | null, updatedAt?: any | null, name: string, subtitle?: string | null, teaser?: string | null, description?: string | null, regioInfo?: string | null, totalArea?: number | null, mainImage?: string | null, startDate?: any | null, createdBy?: UserSimpleFragment | null, updatedBy?: UserSimpleFragment | null, owner: UserSimpleFragment, certifications?: Array<CertificationPartialFragment> | null, images?: Array<ImageSimpleFragment> | null };

export type SubProjectFragment = { id: string, createdAt?: any | null, updatedAt?: any | null, name: string, totalArea?: number | null, owner: UserSimpleFragment };

export type ListProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListProjectsQueryResponse = { projects: Array<ProjectSimpleFragment> };

export type LoadProjectQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LoadProjectQueryResponse = { project: ProjectFragment };

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;


export type CreateProjectMutationResponse = { createProject: ProjectFragment };

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;


export type UpdateProjectMutationResponse = { updateProject: ProjectFragment };

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteProjectMutationResponse = { deleteProject: { id: string } };

export type SiteFragment = { id: string, createdAt?: any | null, updatedAt?: any | null, baselineId?: string | null, description?: string | null, growthModelId?: string | null, label: string, totalArea?: number | null, unstockedArea?: number | null, createdBy?: UserSimpleFragment | null, baseline?: { name: string, co2Storage?: number | null, totalArea?: number | null } | null, certification?: { id: string, name: string, status: CertificationStatus } | null, geometry?: { type: GeometryType, coordinates: any } | null, growthModel?: { name: string, co2Storage?: number | null, totalArea?: number | null } | null, owner: UserSimpleFragment, updatedBy?: UserSimpleFragment | null, region?: { name: string } | null };

export type SiteSimpleFragment = { id: string, createdAt?: any | null, updatedAt?: any | null, totalArea?: number | null, label: string, description?: string | null, unstockedArea?: number | null, createdBy?: UserSimpleFragment | null, updatedBy?: UserSimpleFragment | null, owner: UserSimpleFragment, geometry?: { type: GeometryType, coordinates: any } | null };

export type LoadSiteQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LoadSiteQueryResponse = { site: SiteFragment };

export type CreateSiteMutationVariables = Exact<{
  input: Array<CreateSiteInput> | CreateSiteInput;
}>;


export type CreateSiteMutationResponse = { createSite: CertificationFragment };

export type UpdateSiteMutationVariables = Exact<{
  input: UpdateSiteInput;
}>;


export type UpdateSiteMutationResponse = { updateSite: CertificationFragment };

export type DeleteSiteMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteSiteMutationResponse = { deleteSite: CertificationFragment };

export const TextTemplateFragmentDoc = gql`
    fragment TextTemplate on TextTemplate {
  id
  name
  text
}
    `;
export const OrganisationFragmentDoc = gql`
    fragment Organisation on Organisation {
  id
  name
  internalContact
  description
  zip
  city
  address
  website
  clients {
    id
    name
    owner {
      id
      firstName
      lastName
    }
  }
  contact
  contactMail
  contactPhone
  consultancy {
    id
  }
  consultancyId
  ownerId
  textTemplates {
    ...TextTemplate
  }
  type
}
    ${TextTemplateFragmentDoc}`;
export const UserSimpleFragmentDoc = gql`
    fragment UserSimple on User {
  id
  title
  firstName
  lastName
  email
  phone
  role
  agbAccepted
  organisation {
    ...Organisation
  }
}
    ${OrganisationFragmentDoc}`;
export const FileSimpleFragmentDoc = gql`
    fragment FileSimple on SecureFile {
  id
  createdAt
  fileName
  fileType
  fileSize
  path
}
    `;
export const DocumentRelationFragmentDoc = gql`
    fragment DocumentRelation on DocumentRelation {
  id
  createdAt
  createdBy {
    id
  }
  updatedAt
  indication {
    id
    indicatorId
  }
  indicationId
  auditorRequest {
    id
  }
  auditorRequestId
  baseline {
    id
    name
  }
  baselineId
  growthModel {
    id
    name
  }
  growthModelId
}
    `;
export const DocumentSimpleFragmentDoc = gql`
    fragment DocumentSimple on Document {
  id
  category
  createdAt
  createdBy {
    ...UserSimple
  }
  updatedAt
  name
  remarks
  readonly
  visibility
  file {
    ...FileSimple
  }
  fileId
  project {
    id
  }
  certificationId
  groupCertificationId
  relations {
    ...DocumentRelation
  }
  user {
    id
  }
  pddAppendix
}
    ${UserSimpleFragmentDoc}
${FileSimpleFragmentDoc}
${DocumentRelationFragmentDoc}`;
export const MessageFragmentDoc = gql`
    fragment Message on Message {
  id
  createdAt
  updatedAt
  createdBy {
    ...UserSimple
  }
  text
  verificationStatus
  parentMessageId
}
    ${UserSimpleFragmentDoc}`;
export const AuditorRequestFragmentDoc = gql`
    fragment AuditorRequest on AuditorRequest {
  id
  createdAt
  updatedAt
  createdBy {
    ...UserSimple
  }
  number
  auditorComment
  baselines {
    id
  }
  certification {
    id
  }
  certificationId
  groupCertification {
    id
  }
  groupCertificationId
  documents {
    ...DocumentSimple
  }
  growthModels {
    id
  }
  indication {
    id
  }
  indicatorId
  initialVerificationStatus
  internalAuditorRemarks
  messages {
    ...Message
  }
  reminderCount
  reviewed
  sites {
    id
  }
  valid
  sample
  verificationStatus
}
    ${UserSimpleFragmentDoc}
${DocumentSimpleFragmentDoc}
${MessageFragmentDoc}`;
export const TreeSpeciesFragmentDoc = gql`
    fragment TreeSpecies on ArTreeSpecies {
  identifier
  germanName
  scientificName
  pioneer
  baseline
  growthModel
  growthGroup
  mdd
}
    `;
export const TreeSpeciesShareFragmentDoc = gql`
    fragment TreeSpeciesShare on ArTreeSpeciesShare {
  id
  share
  dryLoss
  naturalRegeneration
  delayedNatReg
  species {
    ...TreeSpecies
  }
}
    ${TreeSpeciesFragmentDoc}`;
export const ArBaselineDataFragmentDoc = gql`
    fragment ArBaselineData on ArBaselineData {
  id
  backWaterInfluence
  closeSpecies {
    ...TreeSpeciesShare
  }
  establishedNatRegArea
  establishedNatReg {
    ...TreeSpeciesShare
  }
  fireRisk
  generalRemarks
  groundVegetation
  groundVegetationRemarks
  groundWaterInfluence
  inclination
  lateFrostRisk
  nfk
  noNatRegReasons
  noNatRegPossibleArea
  nonEstablishedNatRegArea
  nonEstablishedNatReg {
    ...TreeSpeciesShare
  }
  nonEstablishedNatRegGroundVegetation
  previousSpecies {
    ...TreeSpeciesShare
  }
  preExistingBioMass
  pioneerSpecies {
    ...TreeSpeciesShare
  }
  reducedTreeMix {
    ...TreeSpeciesShare
  }
  remainingSpecies {
    ...TreeSpeciesShare
  }
  siteWaterBalance50
  siteWaterBalance
  soilTrophyCategory
  soilType
  waterHoldingCapacity
}
    ${TreeSpeciesShareFragmentDoc}`;
export const CertificationPartialFragmentDoc = gql`
    fragment CertificationPartial on CertificationPartialType {
  id
  certificationStartDate
  certificationCompleteDate
  certificationStartDate
  certifier {
    ...UserSimple
  }
  consultant {
    ...UserSimple
  }
  co2Storage
  co2Avoidance
  createdAt
  createdBy {
    ...UserSimple
  }
  creditingPeriod
  creditingPeriodStart
  groupId
  group {
    id
    name
    code
    status
    certifier {
      ...UserSimple
    }
  }
  growthRegion {
    id
    name
    kwb50
    kwb100
  }
  internalContact
  internalRemarks
  involvedPersons
  location
  method
  name
  ownerId
  owner {
    ...UserSimple
  }
  project {
    id
    name
    projectCode
  }
  sample
  standardVersion
  status
  testCertification
  timeSchedule
  totalArea
  updatedAt
  updatedBy {
    ...UserSimple
  }
}
    ${UserSimpleFragmentDoc}`;
export const BaselineFragmentDoc = gql`
    fragment Baseline on Baseline {
  id
  name
  createdAt
  createdBy {
    firstName
    lastName
  }
  inputId
  input {
    ...ArBaselineData
  }
  certification {
    ...CertificationPartial
  }
  certificationId
  totalArea
  sites {
    id
    label
    totalArea
  }
  co2Storage
  co2Years {
    year
    co2
    yearDiff
    avoidance
  }
  documents {
    ...DocumentSimple
  }
  reviewed
  valid
  verificationStatus
}
    ${ArBaselineDataFragmentDoc}
${CertificationPartialFragmentDoc}
${DocumentSimpleFragmentDoc}`;
export const GrowthModelFragmentDoc = gql`
    fragment GrowthModel on GrowthModel {
  id
  name
  createdAt
  createdBy {
    firstName
    lastName
  }
  inputId
  input {
    plannedSpecies {
      ...TreeSpeciesShare
    }
    naturalRegeneration
    planting
    seeding
    projectActivityDescription
    gameManagement
    groundMaintenance
    forrestManagement
    firePrevention
    projectActivitySupportDescription
    generalRemarks
  }
  certification {
    ...CertificationPartial
  }
  certificationId
  totalArea
  sites {
    id
    label
    totalArea
  }
  co2Storage
  co2Years {
    year
    co2
    yearDiff
    avoidance
  }
  documents {
    ...DocumentSimple
  }
  reviewed
  valid
  verificationStatus
}
    ${TreeSpeciesShareFragmentDoc}
${CertificationPartialFragmentDoc}
${DocumentSimpleFragmentDoc}`;
export const IndicationFragmentDoc = gql`
    fragment Indication on Indication {
  id
  updatedAt
  confirmed
  text
  documents {
    ...DocumentSimple
  }
  messages {
    ...Message
  }
  valid
  reviewed
  indicatorId
  verificationStatus
}
    ${DocumentSimpleFragmentDoc}
${MessageFragmentDoc}`;
export const ImageSimpleFragmentDoc = gql`
    fragment ImageSimple on Image {
  id
  name
  fileName
  fileSize
  path
}
    `;
export const SiteFragmentDoc = gql`
    fragment Site on Site {
  id
  createdAt
  updatedAt
  createdBy {
    ...UserSimple
  }
  baselineId
  baseline {
    name
    co2Storage
    totalArea
  }
  certification {
    id
    name
    status
  }
  description
  geometry {
    type
    coordinates
  }
  growthModelId
  growthModel {
    name
    co2Storage
    totalArea
  }
  label
  owner {
    ...UserSimple
  }
  totalArea
  updatedBy {
    ...UserSimple
  }
  region {
    name
  }
  unstockedArea
}
    ${UserSimpleFragmentDoc}`;
export const CertificationFragmentDoc = gql`
    fragment Certification on Certification {
  id
  auditorRequests {
    ...AuditorRequest
  }
  baselines {
    ...Baseline
  }
  certificationStartDate
  certificationCompleteDate
  certificationStartDate
  certificationConclusion
  certificationOpinion
  certifier {
    ...UserSimple
  }
  consultant {
    ...UserSimple
  }
  co2Years {
    year
    co2
    yearDiff
    avoidance
  }
  co2Storage
  co2Avoidance
  createdAt
  createdBy {
    ...UserSimple
  }
  creditingPeriod
  creditingPeriodStart
  documents {
    ...DocumentSimple
  }
  groupId
  group {
    id
    name
    code
    status
    documents {
      ...DocumentSimple
    }
  }
  growthModels {
    ...GrowthModel
  }
  growthRegion {
    id
    name
    kwb50
    kwb100
  }
  indications {
    ...Indication
  }
  internalContact
  internalRemarks
  involvedPersons
  location
  method
  name
  owner {
    ...UserSimple
  }
  ownerId
  project {
    id
    name
    startDate
    projectCode
    description
    teaser
    images {
      ...ImageSimple
    }
  }
  sample
  sites {
    ...Site
  }
  status
  standardVersion
  testCertification
  timeSchedule
  totalArea
  updatedAt
  updatedBy {
    ...UserSimple
  }
}
    ${AuditorRequestFragmentDoc}
${BaselineFragmentDoc}
${UserSimpleFragmentDoc}
${DocumentSimpleFragmentDoc}
${GrowthModelFragmentDoc}
${IndicationFragmentDoc}
${ImageSimpleFragmentDoc}
${SiteFragmentDoc}`;
export const GroupCertificationFragmentDoc = gql`
    fragment GroupCertification on GroupCertification {
  id
  certifier {
    ...UserSimple
  }
  certifications {
    ...Certification
  }
  code
  name
  owner {
    ...UserSimple
  }
  auditorRequests {
    ...AuditorRequest
  }
  documents {
    ...DocumentSimple
  }
  ownerId
  status
}
    ${UserSimpleFragmentDoc}
${CertificationFragmentDoc}
${AuditorRequestFragmentDoc}
${DocumentSimpleFragmentDoc}`;
export const GroupCertificationPartialFragmentDoc = gql`
    fragment GroupCertificationPartial on GroupCertification {
  id
  certifier {
    ...UserSimple
  }
  certifications {
    id
  }
  code
  name
  owner {
    ...UserSimple
  }
  ownerId
  status
}
    ${UserSimpleFragmentDoc}`;
export const IndicationSimpleFragmentDoc = gql`
    fragment IndicationSimple on Indication {
  id
  valid
  reviewed
  indicatorId
  confirmed
  text
}
    `;
export const ScenarioDataFragmentDoc = gql`
    fragment ScenarioData on ScenarioData {
  scenarioId
  scenarioName
  inputId
  baselineInput {
    ...ArBaselineData
  }
  growthModelInput {
    plannedSpecies {
      ...TreeSpeciesShare
    }
    naturalRegeneration
    planting
    seeding
    projectActivityDescription
    gameManagement
    groundMaintenance
    forrestManagement
    firePrevention
    projectActivitySupportDescription
    generalRemarks
  }
}
    ${ArBaselineDataFragmentDoc}
${TreeSpeciesShareFragmentDoc}`;
export const HistoryFragmentDoc = gql`
    fragment History on History {
  id
  operation
  createdAt
  resourceType
  scope
  diff
  user {
    id
    firstName
    lastName
    role
    organisation {
      name
    }
  }
  certification {
    id
  }
}
    `;
export const ScenarioSummaryFragmentDoc = gql`
    fragment ScenarioSummary on ScenarioSummary {
  id
  name
  inputId
  certificationId
  certificationName
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  createdBy {
    id
    firstName
    lastName
  }
  createdAt
  title
  firstName
  lastName
  email
  organisation {
    ...Organisation
  }
  phone
  role
  agbAccepted
  agbVersionAccepted
  agbAcceptedDate
  remarks
  readMessages {
    id
  }
}
    ${OrganisationFragmentDoc}`;
export const SystemMessageFragmentDoc = gql`
    fragment SystemMessage on SystemMessage {
  id
  createdAt
  createdBy {
    ...UserSimple
  }
  title
  text
  severity
  expireDate
  targetRole
}
    ${UserSimpleFragmentDoc}`;
export const SystemDataFragmentDoc = gql`
    fragment SystemData on SystemData {
  systemMessages {
    ...SystemMessage
  }
  documents {
    ...DocumentSimple
  }
}
    ${SystemMessageFragmentDoc}
${DocumentSimpleFragmentDoc}`;
export const ProjectSimpleFragmentDoc = gql`
    fragment ProjectSimple on Project {
  id
  name
  projectCode
  createdAt
  updatedAt
  description
  totalArea
  mainImage
  images {
    ...ImageSimple
  }
  certifications {
    ...CertificationPartial
  }
  owner {
    ...UserSimple
  }
  startDate
  subtitle
  teaser
}
    ${ImageSimpleFragmentDoc}
${CertificationPartialFragmentDoc}
${UserSimpleFragmentDoc}`;
export const ProjectFragmentDoc = gql`
    fragment Project on Project {
  id
  projectCode
  createdAt
  updatedAt
  createdBy {
    ...UserSimple
  }
  updatedBy {
    ...UserSimple
  }
  owner {
    ...UserSimple
  }
  name
  subtitle
  teaser
  description
  regioInfo
  totalArea
  certifications {
    ...CertificationPartial
  }
  mainImage
  images {
    ...ImageSimple
  }
  startDate
}
    ${UserSimpleFragmentDoc}
${CertificationPartialFragmentDoc}
${ImageSimpleFragmentDoc}`;
export const SubProjectFragmentDoc = gql`
    fragment SubProject on Project {
  id
  createdAt
  updatedAt
  owner {
    ...UserSimple
  }
  name
  totalArea
}
    ${UserSimpleFragmentDoc}`;
export const SiteSimpleFragmentDoc = gql`
    fragment SiteSimple on Site {
  id
  createdAt
  updatedAt
  totalArea
  createdBy {
    ...UserSimple
  }
  updatedBy {
    ...UserSimple
  }
  owner {
    ...UserSimple
  }
  geometry {
    type
    coordinates
  }
  label
  description
  unstockedArea
}
    ${UserSimpleFragmentDoc}`;
export const GetScenarioDataDocument = gql`
    query getScenarioData($id: String!) {
  getScenarioData(id: $id) {
    ...ScenarioData
  }
}
    ${ScenarioDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetScenarioDataGQL extends Apollo.Query<GetScenarioDataQueryResponse, GetScenarioDataQueryVariables> {
    document = GetScenarioDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListCertificationsDocument = gql`
    query listCertifications {
  certifications {
    ...CertificationPartial
  }
}
    ${CertificationPartialFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListCertificationsGQL extends Apollo.Query<ListCertificationsQueryResponse, ListCertificationsQueryVariables> {
    document = ListCertificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadFullCertificationsDocument = gql`
    query loadFullCertifications {
  loadCertifications {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadFullCertificationsGQL extends Apollo.Query<LoadFullCertificationsQueryResponse, LoadFullCertificationsQueryVariables> {
    document = LoadFullCertificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadCertificationDocument = gql`
    query loadCertification($id: String!) {
  certification(id: $id) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadCertificationGQL extends Apollo.Query<LoadCertificationQueryResponse, LoadCertificationQueryVariables> {
    document = LoadCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadGroupCertificationDocument = gql`
    query loadGroupCertification($id: String!) {
  groupCertification(id: $id) {
    ...GroupCertification
  }
}
    ${GroupCertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadGroupCertificationGQL extends Apollo.Query<LoadGroupCertificationQueryResponse, LoadGroupCertificationQueryVariables> {
    document = LoadGroupCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCertificationDocument = gql`
    mutation createCertification($input: CreateCertificationInput!) {
  createCertification(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCertificationGQL extends Apollo.Mutation<CreateCertificationMutationResponse, CreateCertificationMutationVariables> {
    document = CreateCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCertificationDocument = gql`
    mutation updateCertification($input: UpdateCertificationInput!) {
  updateCertification(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCertificationGQL extends Apollo.Mutation<UpdateCertificationMutationResponse, UpdateCertificationMutationVariables> {
    document = UpdateCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAuditDataDocument = gql`
    mutation updateAuditData($input: UpdateAuditDataInput!) {
  updateAuditData(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAuditDataGQL extends Apollo.Mutation<UpdateAuditDataMutationResponse, UpdateAuditDataMutationVariables> {
    document = UpdateAuditDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCertificationStatusDocument = gql`
    mutation updateCertificationStatus($input: UpdateCertificationStatusInput!) {
  updateCertificationStatus(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCertificationStatusGQL extends Apollo.Mutation<UpdateCertificationStatusMutationResponse, UpdateCertificationStatusMutationVariables> {
    document = UpdateCertificationStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGroupCertificationDocument = gql`
    mutation createGroupCertification($input: CreateGroupCertificationInput!) {
  createGroupCertification(input: $input) {
    ...GroupCertification
  }
}
    ${GroupCertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGroupCertificationGQL extends Apollo.Mutation<CreateGroupCertificationMutationResponse, CreateGroupCertificationMutationVariables> {
    document = CreateGroupCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGroupCertificationDocument = gql`
    mutation updateGroupCertification($input: UpdateGroupCertificationInput!) {
  updateGroupCertification(input: $input) {
    ...GroupCertification
  }
}
    ${GroupCertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGroupCertificationGQL extends Apollo.Mutation<UpdateGroupCertificationMutationResponse, UpdateGroupCertificationMutationVariables> {
    document = UpdateGroupCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGroupCertificationStatusDocument = gql`
    mutation updateGroupCertificationStatus($input: UpdateGroupCertificationStatusInput!) {
  updateGroupCertificationStatus(input: $input) {
    ...GroupCertification
  }
}
    ${GroupCertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGroupCertificationStatusGQL extends Apollo.Mutation<UpdateGroupCertificationStatusMutationResponse, UpdateGroupCertificationStatusMutationVariables> {
    document = UpdateGroupCertificationStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCertifierDocument = gql`
    mutation updateCertifier($input: UpdateCertifierInput!) {
  certification(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCertifierGQL extends Apollo.Mutation<UpdateCertifierMutationResponse, UpdateCertifierMutationVariables> {
    document = UpdateCertifierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGroupCertifierDocument = gql`
    mutation updateGroupCertifier($input: UpdateCertifierInput!) {
  groupCertification(input: $input) {
    ...GroupCertification
  }
}
    ${GroupCertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGroupCertifierGQL extends Apollo.Mutation<UpdateGroupCertifierMutationResponse, UpdateGroupCertifierMutationVariables> {
    document = UpdateGroupCertifierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCertificationDocument = gql`
    mutation deleteCertification($id: String!) {
  deleteCertification(id: $id) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCertificationGQL extends Apollo.Mutation<DeleteCertificationMutationResponse, DeleteCertificationMutationVariables> {
    document = DeleteCertificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIndicationDocument = gql`
    mutation updateIndication($input: UpdateIndicationInput!) {
  updateIndication(input: $input) {
    ...Certification
    group {
      id
      status
      certifications {
        id
        status
        indications {
          ...Indication
        }
      }
      documents {
        ...DocumentSimple
      }
    }
  }
}
    ${CertificationFragmentDoc}
${IndicationFragmentDoc}
${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIndicationGQL extends Apollo.Mutation<UpdateIndicationMutationResponse, UpdateIndicationMutationVariables> {
    document = UpdateIndicationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBaselineDocument = gql`
    mutation updateBaseline($input: UpdateBaselineInput!, $methodInput: ArBaselineDataInput) {
  updateBaseline(input: $input, methodInput: $methodInput) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBaselineGQL extends Apollo.Mutation<UpdateBaselineMutationResponse, UpdateBaselineMutationVariables> {
    document = UpdateBaselineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBaselineDocument = gql`
    mutation createBaseline($input: CreateBaselineInput!) {
  createBaseline(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBaselineGQL extends Apollo.Mutation<CreateBaselineMutationResponse, CreateBaselineMutationVariables> {
    document = CreateBaselineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBaselineDocument = gql`
    query getBaseline($method: String!, $id: String!) {
  baseline(method: $method, id: $id) {
    ...Baseline
  }
}
    ${BaselineFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBaselineGQL extends Apollo.Query<GetBaselineQueryResponse, GetBaselineQueryVariables> {
    document = GetBaselineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBaselineDocument = gql`
    mutation deleteBaseline($input: UpdateBaselineInput!) {
  deleteBaseline(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBaselineGQL extends Apollo.Mutation<DeleteBaselineMutationResponse, DeleteBaselineMutationVariables> {
    document = DeleteBaselineDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGrowthModelDocument = gql`
    mutation createGrowthModel($input: CreateGrowthModelInput!) {
  createGrowthModel(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGrowthModelGQL extends Apollo.Mutation<CreateGrowthModelMutationResponse, CreateGrowthModelMutationVariables> {
    document = CreateGrowthModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGrowthModelDocument = gql`
    mutation updateGrowthModel($input: UpdateGrowthModelInput!, $methodInput: ArGrowthModelInput) {
  updateGrowthModel(input: $input, methodInput: $methodInput) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGrowthModelGQL extends Apollo.Mutation<UpdateGrowthModelMutationResponse, UpdateGrowthModelMutationVariables> {
    document = UpdateGrowthModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGrowthModelDocument = gql`
    mutation deleteGrowthModel($input: UpdateGrowthModelInput!) {
  deleteGrowthModel(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGrowthModelGQL extends Apollo.Mutation<DeleteGrowthModelMutationResponse, DeleteGrowthModelMutationVariables> {
    document = DeleteGrowthModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetGrowthModelDocument = gql`
    query getGrowthModel($method: String!, $id: String!) {
  growthModel(method: $method, id: $id) {
    ...GrowthModel
  }
}
    ${GrowthModelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetGrowthModelGQL extends Apollo.Query<GetGrowthModelQueryResponse, GetGrowthModelQueryVariables> {
    document = GetGrowthModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAuditorRequestDocument = gql`
    mutation createAuditorRequest($input: AuditorRequestInput!) {
  createAuditorRequest(input: $input) {
    ...Certification
    group {
      id
      status
      auditorRequests {
        ...AuditorRequest
      }
      certifications {
        id
        status
        indications {
          ...Indication
        }
      }
      documents {
        ...DocumentSimple
      }
    }
  }
}
    ${CertificationFragmentDoc}
${AuditorRequestFragmentDoc}
${IndicationFragmentDoc}
${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAuditorRequestGQL extends Apollo.Mutation<CreateAuditorRequestMutationResponse, CreateAuditorRequestMutationVariables> {
    document = CreateAuditorRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAuditorRequestDocument = gql`
    mutation updateAuditorRequest($input: AuditorRequestUpdateInput!) {
  updateAuditorRequest(input: $input) {
    ...Certification
    group {
      id
      status
      auditorRequests {
        ...AuditorRequest
      }
      certifications {
        id
        status
        indications {
          ...Indication
        }
      }
      documents {
        ...DocumentSimple
      }
    }
  }
}
    ${CertificationFragmentDoc}
${AuditorRequestFragmentDoc}
${IndicationFragmentDoc}
${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAuditorRequestGQL extends Apollo.Mutation<UpdateAuditorRequestMutationResponse, UpdateAuditorRequestMutationVariables> {
    document = UpdateAuditorRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAuditorRequestDocument = gql`
    mutation deleteAuditorRequest($input: DeleteAuditorRequestInput!) {
  deleteAuditorRequest(input: $input) {
    ...Certification
    group {
      id
      status
      auditorRequests {
        ...AuditorRequest
      }
      certifications {
        id
        status
        indications {
          ...Indication
        }
      }
      documents {
        ...DocumentSimple
      }
    }
  }
}
    ${CertificationFragmentDoc}
${AuditorRequestFragmentDoc}
${IndicationFragmentDoc}
${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAuditorRequestGQL extends Apollo.Mutation<DeleteAuditorRequestMutationResponse, DeleteAuditorRequestMutationVariables> {
    document = DeleteAuditorRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAuditorRequestMessageDocument = gql`
    mutation updateAuditorRequestMessage($input: AuditorRequestMessageInput!) {
  updateAuditorRequestMessage(input: $input) {
    ...Certification
    group {
      id
      status
      auditorRequests {
        ...AuditorRequest
      }
      certifications {
        id
        status
        indications {
          ...Indication
        }
      }
      documents {
        ...DocumentSimple
      }
    }
  }
}
    ${CertificationFragmentDoc}
${AuditorRequestFragmentDoc}
${IndicationFragmentDoc}
${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAuditorRequestMessageGQL extends Apollo.Mutation<UpdateAuditorRequestMessageMutationResponse, UpdateAuditorRequestMessageMutationVariables> {
    document = UpdateAuditorRequestMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateAuditorRequestMessageDocument = gql`
    mutation createAuditorRequestMessage($input: AuditorRequestMessageInput!) {
  createAuditorRequestMessage(input: $input) {
    ...Certification
    group {
      id
      status
      auditorRequests {
        ...AuditorRequest
      }
      certifications {
        id
        status
        indications {
          ...Indication
        }
      }
      documents {
        ...DocumentSimple
      }
    }
  }
}
    ${CertificationFragmentDoc}
${AuditorRequestFragmentDoc}
${IndicationFragmentDoc}
${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAuditorRequestMessageGQL extends Apollo.Mutation<CreateAuditorRequestMessageMutationResponse, CreateAuditorRequestMessageMutationVariables> {
    document = CreateAuditorRequestMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAuditorRequestDocumentsDocument = gql`
    mutation updateAuditorRequestDocuments($input: AuditorRequestDocumentsInput!) {
  updateAuditorRequestDocuments(input: $input) {
    ...Certification
    group {
      id
      status
      auditorRequests {
        ...AuditorRequest
      }
      certifications {
        id
        status
        indications {
          ...Indication
        }
      }
      documents {
        ...DocumentSimple
      }
    }
  }
}
    ${CertificationFragmentDoc}
${AuditorRequestFragmentDoc}
${IndicationFragmentDoc}
${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAuditorRequestDocumentsGQL extends Apollo.Mutation<UpdateAuditorRequestDocumentsMutationResponse, UpdateAuditorRequestDocumentsMutationVariables> {
    document = UpdateAuditorRequestDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListHistoriesDocument = gql`
    query listHistories($certificationId: String!) {
  histories(certificationId: $certificationId) {
    ...History
  }
}
    ${HistoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListHistoriesGQL extends Apollo.Query<ListHistoriesQueryResponse, ListHistoriesQueryVariables> {
    document = ListHistoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadHistoryDocument = gql`
    query loadHistory($id: String!) {
  history(id: $id) {
    ...History
  }
}
    ${HistoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadHistoryGQL extends Apollo.Query<LoadHistoryQueryResponse, LoadHistoryQueryVariables> {
    document = LoadHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadAuthenticatedUserDocument = gql`
    query loadAuthenticatedUser {
  authenticatedUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadAuthenticatedUserGQL extends Apollo.Query<LoadAuthenticatedUserQueryResponse, LoadAuthenticatedUserQueryVariables> {
    document = LoadAuthenticatedUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadUserDocument = gql`
    query loadUser($id: String!) {
  user(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadUserGQL extends Apollo.Query<LoadUserQueryResponse, LoadUserQueryVariables> {
    document = LoadUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListUsersDocument = gql`
    query listUsers {
  users {
    ...UserSimple
  }
}
    ${UserSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListUsersGQL extends Apollo.Query<ListUsersQueryResponse, ListUsersQueryVariables> {
    document = ListUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetScenarioSummariesDocument = gql`
    query getScenarioSummaries($id: String!, $scenarioType: String!) {
  scenarioSummaries(id: $id, scenarioType: $scenarioType) {
    ...ScenarioSummary
  }
}
    ${ScenarioSummaryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetScenarioSummariesGQL extends Apollo.Query<GetScenarioSummariesQueryResponse, GetScenarioSummariesQueryVariables> {
    document = GetScenarioSummariesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTreeSpeciesDocument = gql`
    query getTreeSpecies {
  getTreeSpecies {
    ...TreeSpecies
  }
}
    ${TreeSpeciesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTreeSpeciesGQL extends Apollo.Query<GetTreeSpeciesQueryResponse, GetTreeSpeciesQueryVariables> {
    document = GetTreeSpeciesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadSystemDataDocument = gql`
    query loadSystemData {
  getSystemData {
    ...SystemData
  }
}
    ${SystemDataFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadSystemDataGQL extends Apollo.Query<LoadSystemDataQueryResponse, LoadSystemDataQueryVariables> {
    document = LoadSystemDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSystemMessageDocument = gql`
    mutation createSystemMessage($input: SystemMessageInput!) {
  createSystemMessage(input: $input) {
    ...SystemMessage
  }
}
    ${SystemMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSystemMessageGQL extends Apollo.Mutation<CreateSystemMessageMutationResponse, CreateSystemMessageMutationVariables> {
    document = CreateSystemMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSystemMessageDocument = gql`
    mutation updateSystemMessage($id: String!, $input: SystemMessageInput!) {
  updateSystemMessage(id: $id, input: $input) {
    ...SystemMessage
  }
}
    ${SystemMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSystemMessageGQL extends Apollo.Mutation<UpdateSystemMessageMutationResponse, UpdateSystemMessageMutationVariables> {
    document = UpdateSystemMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutationResponse, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutationResponse, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  deleteUser(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutationResponse, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOrganisationDocument = gql`
    mutation updateOrganisation($input: UpdateOrganisationInput!) {
  updateOrganisation(input: $input) {
    ...Organisation
  }
}
    ${OrganisationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganisationGQL extends Apollo.Mutation<UpdateOrganisationMutationResponse, UpdateOrganisationMutationVariables> {
    document = UpdateOrganisationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDocumentDocument = gql`
    mutation createDocument($name: String!, $remarks: String, $visibility: String, $file: Upload!, $md5FileSum: String!, $fileSize: Int!, $projectId: String, $certificationId: String, $groupCertificationId: String, $userId: String, $subPath: String, $category: DocumentCategory, $index: Int!, $total: Int!, $pddAppendix: Boolean) {
  createdDocument(
    name: $name
    remarks: $remarks
    visibility: $visibility
    file: $file
    md5FileSum: $md5FileSum
    fileSize: $fileSize
    projectId: $projectId
    certificationId: $certificationId
    groupCertificationId: $groupCertificationId
    userId: $userId
    subPath: $subPath
    category: $category
    index: $index
    total: $total
    pddAppendix: $pddAppendix
  ) {
    ...DocumentSimple
  }
}
    ${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDocumentGQL extends Apollo.Mutation<CreateDocumentMutationResponse, CreateDocumentMutationVariables> {
    document = CreateDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDocumentDocument = gql`
    mutation updateDocument($input: UpdateDocumentInput!) {
  updateDocument(input: $input) {
    ...DocumentSimple
  }
}
    ${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDocumentGQL extends Apollo.Mutation<UpdateDocumentMutationResponse, UpdateDocumentMutationVariables> {
    document = UpdateDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: String!) {
  deleteDocumentResult(id: $id) {
    ...DocumentSimple
  }
}
    ${DocumentSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDocumentGQL extends Apollo.Mutation<DeleteDocumentMutationResponse, DeleteDocumentMutationVariables> {
    document = DeleteDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateImageDocument = gql`
    mutation createImage($name: String!, $file: Upload!, $projectId: String!, $mainImage: Boolean!) {
  createdImage(
    name: $name
    file: $file
    projectId: $projectId
    mainImage: $mainImage
  ) {
    ...ImageSimple
  }
}
    ${ImageSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateImageGQL extends Apollo.Mutation<CreateImageMutationResponse, CreateImageMutationVariables> {
    document = CreateImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateImageDocument = gql`
    mutation updateImage($input: UpdateImageInput!) {
  updateImage(input: $input) {
    ...ImageSimple
  }
}
    ${ImageSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateImageGQL extends Apollo.Mutation<UpdateImageMutationResponse, UpdateImageMutationVariables> {
    document = UpdateImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteImageDocument = gql`
    mutation deleteImage($id: String!, $projectId: String) {
  deleteImageResult(id: $id, projectId: $projectId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteImageGQL extends Apollo.Mutation<DeleteImageMutationResponse, DeleteImageMutationVariables> {
    document = DeleteImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListProjectsDocument = gql`
    query listProjects {
  projects {
    ...ProjectSimple
  }
}
    ${ProjectSimpleFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListProjectsGQL extends Apollo.Query<ListProjectsQueryResponse, ListProjectsQueryVariables> {
    document = ListProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadProjectDocument = gql`
    query loadProject($id: String!) {
  project(id: $id) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadProjectGQL extends Apollo.Query<LoadProjectQueryResponse, LoadProjectQueryVariables> {
    document = LoadProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProjectDocument = gql`
    mutation createProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectGQL extends Apollo.Mutation<CreateProjectMutationResponse, CreateProjectMutationVariables> {
    document = CreateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectDocument = gql`
    mutation updateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectGQL extends Apollo.Mutation<UpdateProjectMutationResponse, UpdateProjectMutationVariables> {
    document = UpdateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProjectDocument = gql`
    mutation deleteProject($id: String!) {
  deleteProject(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProjectGQL extends Apollo.Mutation<DeleteProjectMutationResponse, DeleteProjectMutationVariables> {
    document = DeleteProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoadSiteDocument = gql`
    query loadSite($id: String!) {
  site(id: $id) {
    ...Site
  }
}
    ${SiteFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoadSiteGQL extends Apollo.Query<LoadSiteQueryResponse, LoadSiteQueryVariables> {
    document = LoadSiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSiteDocument = gql`
    mutation createSite($input: [CreateSiteInput!]!) {
  createSite(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSiteGQL extends Apollo.Mutation<CreateSiteMutationResponse, CreateSiteMutationVariables> {
    document = CreateSiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSiteDocument = gql`
    mutation updateSite($input: UpdateSiteInput!) {
  updateSite(input: $input) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSiteGQL extends Apollo.Mutation<UpdateSiteMutationResponse, UpdateSiteMutationVariables> {
    document = UpdateSiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSiteDocument = gql`
    mutation deleteSite($id: String!) {
  deleteSite(id: $id) {
    ...Certification
  }
}
    ${CertificationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSiteGQL extends Apollo.Mutation<DeleteSiteMutationResponse, DeleteSiteMutationVariables> {
    document = DeleteSiteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    