/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from '@apollo/client';
import {
  IndicatorInterface,
  StandardDefinitionInterface,
} from '@eva/certification/api';
import { AuditorRequest, Certification } from '@eva/data-access/shared';
import { Select } from '@ngxs/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, Subscription } from 'rxjs';
import {
  AuditorRequestService,
  auditorRequestRelation,
} from '../auditor-request.service';

import { AppState } from '../../../../states/app.state';

export interface RelationSelectionDialogData {
  certification: Certification | undefined;
  auditorRequest?: AuditorRequest;
  excludedRelations: auditorRequestRelation[];
}

@Component({
  selector: 'eva-relation-selection',
  templateUrl: './relation-selection.component.html',
  styleUrls: ['./relation-selection.component.scss'],
})
export class RelationSelectionComponent implements OnChanges {
  _subscriptions = new Subscription();

  @Select(AppState.standardDefinitions)
  standardDefs$: Observable<StandardDefinitionInterface[]> | undefined;

  @Input() certification: Certification;
  @Input() auditorRequest: AuditorRequest;

  standardDefinitions: StandardDefinitionInterface[] = [];
  standardDefinition: StandardDefinitionInterface;
  indicators: IndicatorInterface[] = [];

  availableRelations: auditorRequestRelation[] = [];
  filteredRelations: auditorRequestRelation[] = [];

  @Input() excludedRelations: auditorRequestRelation[] = [];

  @Input() selectedRelations: auditorRequestRelation[] = [];
  @Output() selectedRelationsChange = new EventEmitter<
    auditorRequestRelation[]
  >();

  relationTypes = [
    // { label: 'Dokumente', value: 'Document', icon: 'pi pi-file' },
    { label: 'Referenzszenarien', value: 'Baseline', icon: 'pi pi-chart-bar' },
    {
      label: 'Wachstumsmodelle',
      value: 'GrowthModel',
      icon: 'pi pi-chart-line',
    },
    { label: 'Flächen', value: 'Site', icon: 'pi pi-map' },
  ];

  typeFilter: { value: string; label: string }[] = [this.relationTypes[0]];

  sourceFilter = '';
  sourceFilterChange = new Subject<string>();
  set typeFilterSingle(t: { label: string; value: string } | any) {
    this.typeFilter = [t];
  }

  get typeFilterSingle() {
    return this.typeFilter[0];
  }

  includedIn = (arr: { id: string }[]) => (item: { id: string }) =>
    arr.map((i) => i.id).includes(item.id);

  notIncludedIn = (arr: { id: string }[]) => (item: { id: string }) =>
    !arr.map((i) => i.id).includes(item.id);

  constructor(
    public dialogRef: DynamicDialogRef,
    private auditorRequestHelper: AuditorRequestService,
    public config?: DynamicDialogConfig<RelationSelectionDialogData>
  ) {
    if (config?.data?.certification) {
      this.certification = config.data.certification;
    }
    if (config?.data?.auditorRequest) {
      this.auditorRequest = config.data.auditorRequest;
    }
    if (config?.data?.excludedRelations) {
      this.excludedRelations = config.data.excludedRelations;
    }

    this._subscriptions.add(
      this.sourceFilterChange.subscribe((filter) => {
        this.filteredRelations = [...this.availableRelations]
          .filter(
            (r) =>
              !this.excludedRelations
                .map((er) => er.data.id)
                .includes(r.data.id)
          )
          .filter((r) => r.label.indexOf(this.sourceFilter) >= 0)
          .filter((r) =>
            this.typeFilter.map((tf) => tf.value).includes(r.type)
          );
      })
    );

    this._subscriptions.add(
      this.standardDefs$?.subscribe((sd) => {
        this.standardDefinitions = sd;
        if (this.certification) {
          this.init();
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.certification && this.certification) {
      // Falls die Standard-Definition später rein kommen, liste (neu) aufbauen
      if (this.standardDefinitions?.length > 0) {
        this.init();
      }
    }
    if (changes.selectedRelations) {
      this.sourceFilterChange.next('');
    }
  }

  init() {
    const standard = this.standardDefinitions?.find((std) =>
      [std.version, std.revision].includes(this.certification.standardVersion)
    );

    if (this.availableRelations?.length === 0 && standard) {
      this.standardDefinition = standard;
      const sortedData = this.auditorRequestHelper.setupData(
        this.certification,
        this.auditorRequest
      );

      this.selectedRelations.push(...(sortedData?.selectedItems ?? []));
      this.availableRelations.push(...(sortedData?.availableItems ?? []));
      this.sourceFilterChange.next('');
    }
  }

  filterRelationTypes(t: { label: string; value: string } | any) {
    this.typeFilter = [t];
    this.sourceFilterChange.next('');
  }

  onItemAdded(data: auditorRequestRelation[]) {
    data.forEach((item) => {
      this.availableRelations.splice(
        this.availableRelations.findIndex((r) => r.data.id === item.data.id),
        1
      );
      this.selectedRelationsChange.emit(this.selectedRelations);
    });

    this.sourceFilterChange.next('');
  }

  onItemRemoved(data: auditorRequestRelation[]) {
    this.availableRelations.push(...data);

    this.selectedRelationsChange.emit(this.selectedRelations);

    this.sourceFilterChange.next('');
  }

  addItem(data: auditorRequestRelation[]) {
    data.forEach((item) => {
      this.availableRelations.splice(
        this.availableRelations.findIndex((r) => r.data.id === item.data.id),
        1
      );
      this.selectedRelations.push(item);

      this.selectedRelationsChange.emit(this.selectedRelations);
    });

    this.sourceFilterChange.next('');
  }

  removeItem(data: auditorRequestRelation[]) {
    data.forEach((item) => {
      this.selectedRelations.splice(
        this.selectedRelations.findIndex((r) => r.data.id === item.data.id),
        1
      );
      this.availableRelations.push(item);

      this.selectedRelationsChange.emit(this.selectedRelations);
    });
    this.sourceFilterChange.next('');
  }

  closeClicked() {
    this.dialogRef.close(this.selectedRelations);
  }
}
