/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  Input,
  input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DocumentCategoryLocalization,
  IndicatorInterface,
  Role,
  StandardDefinitionInterface,
} from '@eva/certification/api';
import {
  Document,
  DocumentCategory,
  DocumentSimpleFragment,
  DocumentVisibility,
  User,
} from '@eva/data-access/shared';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, throwError } from 'rxjs';
import { FileService } from '../../../services/api/file.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DialogService } from '@eva/ng-base';
import { environment } from '../../../../environments/environment';
import { LoadSystemDataAction } from '../../../actions/systemActions';
import { SEVERITY } from '../../../model/message';
import { AuthorizationService } from '../../../services/authorization.service';
import { MessagingService } from '../../../services/messaging.service';
import { DeleteDocumentAction } from '../../../states/actions/document-actions';
import { AppState } from '../../../states/app.state';
import {
  DocumentEditComponent,
  DocumentEditDialogData,
} from '../document-edit/document-edit.component';
import {
  LinkDocumentDialogData,
  LinkDocumentsDialogComponent,
} from '../link-documents-dialog/link-documents-dialog.component';
import {
  NewDocumentsDialogComponent,
  NewDocumentsDialogData,
} from '../new-documents-dialog/new-documents-dialog.component';

type DocumentWithFake = Document & { fake?: boolean } & {
  indicator?: IndicatorInterface;
  auditorRequestNumber?: number;
  baselineName?: string;
  growthModelName?: string;
};

@Component({
  selector: 'eva-document-group-list',
  templateUrl: './document-group-list.component.html',
  styleUrls: ['./document-group-list.component.scss'],
})
export class DocumentGroupListComponent implements OnInit, OnDestroy {
  @ViewChild('rowExpandButton', { read: ElementRef })
  rowExpandButton: ElementRef;

  @Input() editMode = false;
  @Input() categories: (DocumentCategory | null | undefined)[] | undefined;
  @Input() multiple = true;
  @Input() showGroupHeader = true;
  @Input() showTableHeader = false;
  @Input() showUnlinkButton = false;
  @Input() showAddCategoryDocument = false;
  @Input() showNoDocumentsMessage = true;
  @Input() preDefinedCategories: (DocumentCategory | null)[] | undefined;
  documents = input.required<Document[]>();
  @Input() showZipDownload = false;

  @Output() documentDeleted = new EventEmitter();

  @Output() documentUnLinked = new EventEmitter<Document>();
  @Output() documentsLinked = new EventEmitter<DocumentSimpleFragment[]>();

  allowedCategories: (DocumentCategory | null)[] = [];

  documentVisibilityEnum = DocumentVisibility;

  @Select(AppState.authenticatedUser) authenticatedUser$: Observable<User>;

  @Select(AppState.standardDefinitions) standardDefs$: Observable<
    StandardDefinitionInterface[]
  >;

  loaded = false;
  // needed to cast auditor request number to string
  String = String;

  documentCategoryEnum = DocumentCategory;

  documentList: DocumentWithFake[] = [];
  fakeDocuments: (Document & { fake?: boolean })[] = [
    // {
    //   fake: true,
    //   category: DocumentCategory.ADDITIONALITY,
    // } as DocumentWithFake,
  ];

  get fileCount(): number | undefined {
    return this.loaded
      ? this.documentList?.filter((doc) => !doc.fake).length
      : undefined;
  }

  currentUserRole: Role = Role.ANONYMOUS;

  @Input() certificationId: string | undefined;
  @Input() groupCertificationId: string | undefined;
  @Input() auditorRequestGroupId: string | boolean | undefined;

  progress: { [key: string]: string } = {};

  get documentsExists() {
    return this.documentList?.filter((doc) => !doc.fake).length > 0;
  }

  public mixedDocumentsList = false;

  private subscription: Subscription = new Subscription();

  constructor(
    private fileService: FileService,
    public dialogService: DialogService,
    private messagingService: MessagingService,
    private store: Store,
    private httpClient: HttpClient,
    private authorizationService: AuthorizationService
  ) {
    effect(() => {
      this.documentList = [
        ...this.documents(),
        ...(this.preDefinedCategories ?? []).map(
          (c) =>
            ({
              category: c,
              fake: true,
            } as DocumentWithFake)
        ),
      ]
        .sort((a, b) => {
          return a.createdAt && b.createdAt
            ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            : 0;
        })
        .sort((a) => {
          return a.certificationId ? -1 : 1;
        });

      this.mixedDocumentsList = this.documents().some((d) => d.certificationId);
    });
  }

  distinct<T>(data: T[], compareableFunction: (a: T, b: T) => boolean): T[] {
    return data?.filter((thing, i, arr) => {
      const found = arr.find((t) => compareableFunction(t, thing));
      return found ? arr?.indexOf(found) === i : false;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async ngOnInit() {
    this.currentUserRole = await this.authorizationService.getRole();
    this._updateAllowedCategories();
  }

  getIndicator(
    indicatorId: string | undefined,
    standardDefinition: StandardDefinitionInterface | undefined
  ): IndicatorInterface | undefined {
    const allIndicators = standardDefinition?.principles.flatMap((p) =>
      p.criterias.flatMap((c) => c.indicators)
    );
    const retVal = allIndicators?.find(
      (indicator) => indicator.identifier === indicatorId
    );
    return retVal;
  }

  formatSize(size: number) {
    return this.fileService.formatSize(size);
  }

  getLocalization(category: DocumentCategory) {
    const lValue = DocumentCategoryLocalization[category];
    if (!lValue) {
      return 'Allgemein';
    }
    return lValue;
  }

  getCount(allDocs: DocumentWithFake[], category?: DocumentCategory) {
    return allDocs.filter(
      (doc) =>
        (doc.category === category || category === undefined) && !doc.fake
    ).length;
  }

  getIconClass(document: Document) {
    let iconClass = 'pi-file';
    switch (document?.file?.fileType) {
      case 'pdf':
        iconClass = 'pi-file-pdf';
        break;
      case 'xlsx':
        iconClass = 'pi-file-excel';
        break;
      case 'png':
      case 'jpeq':
      case 'gif':
      case 'jpg':
        iconClass = 'pi-image';
        break;
      default:
        iconClass = 'pi-file';
    }
    return iconClass;
  }

  download(document: Document) {
    if (this.progress[document.id]) {
      return;
    }
    const url =
      this.fileService.getDomain() + environment.publicStorageUrl + document.id;
    const fileName = document.file.fileName;
    this.fileService.download(url, fileName, {
      progress: { id: document.id, progressObject: this.progress },
    });
  }

  linkFileClick() {
    const linkDialog =
      this.dialogService.openDialogWithComponent<LinkDocumentDialogData>(
        LinkDocumentsDialogComponent,
        {
          header: 'Dokumente verknüpfen',
          data: {
            existingRelations: this.documents(),
            auditorRequestGroupId: this.auditorRequestGroupId,
          },
          style: { maxWidth: '60vw', maxHeight: '90vh' },
          width: '60rem',
          height: '60rem',
        }
      );

    linkDialog.onClose.subscribe(
      (linkedDocuments: DocumentSimpleFragment[]) => {
        if (linkedDocuments) {
          this.documentsLinked.emit(linkedDocuments);
        }
      }
    );
  }

  unlinkFileClick(document: Document) {
    this.documentUnLinked.emit(document);
  }

  documentNew(category?: DocumentCategory) {
    const dialogRef =
      this.dialogService.openDialogWithComponent<NewDocumentsDialogData>(
        NewDocumentsDialogComponent,
        {
          header: 'Neue Dokumente hochladen',
          data: {
            category:
              category === undefined
                ? this.categories
                  ? this.categories[0]
                  : undefined
                : category,
            categories: category ? undefined : this.categories,
            certificationId: this.certificationId,
            groupCertificationId: this.groupCertificationId,
            auditorRequestGroupId: this.auditorRequestGroupId,
          },
          contentStyle: { overflow: 'hidden' },
          style: { maxWidth: '60vw', maxHeight: '90vh' },
          width: '60rem',
          height: '60rem',
        }
      );

    dialogRef.onClose.subscribe((result: DocumentSimpleFragment[]) => {
      if (result) {
        this.documentsLinked.emit([...this.documents(), ...result]);
      }
    });
  }

  documentEdit(doc?: Document, category?: DocumentCategory) {
    this.dialogService.openDialogWithComponent<DocumentEditDialogData>(
      DocumentEditComponent,
      {
        header: doc ? 'Dokument bearbeiten' : 'Neues Dokument',
        data: {
          doc,
          category,
          categories: this.categories,
        },
        style: { maxWidth: '40rem', maxHeight: '40rem' },
        width: '450px',
      }
    );
  }

  deleteFile(document: Document) {
    if (document.relations?.length) {
      return;
    }
    this.messagingService.confirm(
      'Bestätigung',
      'Soll das Dokument "' + document.name + '" gelöscht werden?',
      (confirmed) => {
        if (confirmed) {
          this.store
            .dispatch(
              new DeleteDocumentAction(
                {
                  id: document.id,
                  category: document.category ?? undefined,
                },
                (doc) => {
                  if (
                    doc?.category &&
                    [
                      DocumentCategory.AGB_PLATFORM,
                      DocumentCategory.AGB_STANDARD,
                    ].includes(doc?.category)
                  ) {
                    this.store.dispatch(new LoadSystemDataAction());
                  }
                }
              )
            )
            .subscribe({
              next: (value) => {
                this.messagingService.success(
                  '',
                  'Das Dokument "' + document.name + '" wurde gelöscht'
                );
                this.documentDeleted.emit(true);
              },
              error: (err) => {
                this.messagingService.error('Fehler', err.message);
              },
            });
        }
      },
      SEVERITY.DELETE,
      'Löschen',
      'Abbrechen',
      'pi pi-file-pdf'
    );
  }

  downloadZip(certificationId: string) {
    this.httpClient
      .get(
        // environment.apiUrl,
        `${this.fileService.getDomain()}/pdd/${certificationId}/zip`,
        {
          reportProgress: false,
          responseType: 'blob',
          headers: new HttpHeaders({ api: 'true', skipTimeout: 'true' }),
        }
      )
      .subscribe((pdf) => {
        if (pdf) {
          const a = window.document.createElement('a');
          const objectUrl = URL.createObjectURL(pdf);
          a.href = objectUrl;
          a.download = `ZIP-${certificationId}-${new Date().toLocaleDateString(
            'de-DE'
          )}.zip`;
          a.click();
          URL.revokeObjectURL(objectUrl);
        } else {
          throw throwError(() => 'Download Error');
        }
      });
  }

  private _updateAllowedCategories() {
    this.allowedCategories = [
      ...this.fileService.getAllowedCategories(this.currentUserRole),
      null,
    ];
  }
}
