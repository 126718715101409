/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import {
  CreateDocumentInput,
  Document,
  DocumentCategory,
  UpdateDocumentInput,
} from '@eva/data-access/shared';
import { StateContext } from '@ngxs/store';
import {
  insertItem,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { Observable, tap } from 'rxjs';
import { FileService } from '../../services/api/file.service';
import { AppState } from '../app.state';
import { attachAction } from '../attach-actions';

export const loadDocumentActions = (fileService: FileService) => {
  attachAction(
    AppState,
    UploadDocumentAction,
    uploadDocumentAction(fileService)
  );
  attachAction(
    AppState,
    DeleteDocumentAction,
    deleteDocumentAction(fileService)
  );
  attachAction(
    AppState,
    UpdateDocumentAction,
    updateDocumentAction(fileService)
  );
};

export class UploadDocumentAction {
  static readonly type = '[Document] Upload';
  constructor(
    public payload: CreateDocumentInput,
    public uploadedFunc?: (doc: Document) => void
  ) {}
}

const uploadDocumentAction =
  (fileService: FileService) =>
  (
    stateContext: StateContext<AppState>,
    action: UploadDocumentAction
  ): Observable<Document | null> => {
    // only if no explicit certificationId or groupCertificationId is set
    if (
      !action.payload['certificationId'] &&
      !action.payload['groupCertificationId']
    ) {
      // Add certification id to payload if it exists
      action.payload['certificationId'] = action.payload['certificationId']
        ? action.payload['certificationId']
        : stateContext.getState().currentCertification?.id;

      // Add group certification id to payload if it exists
      if (
        stateContext.getState().currentCertification?.groupId ||
        stateContext.getState().currentGroupCertification?.id
      ) {
        action.payload['groupCertificationId'] = action.payload[
          'groupCertificationId'
        ]
          ? action.payload['groupCertificationId']
          : stateContext.getState().currentCertification?.groupId ||
            stateContext.getState().currentGroupCertification?.id;
      }
    }

    return fileService.createDocument(action.payload).pipe(
      tap((data: Document | null) => {
        if (
          action.payload.category &&
          [
            DocumentCategory.AGB_PLATFORM,
            DocumentCategory.AGB_STANDARD,
          ].includes(action.payload.category)
        ) {
          // this.store.dispatch(new LoadSystemDataAction());
        }

        if (data?.certificationId) {
          if (
            stateContext.getState().currentCertification?.id ===
            data.certificationId
          ) {
            stateContext.setState(
              patch({
                currentCertification: patch({
                  documents: insertItem(data),
                }),
              })
            );

            if (stateContext.getState().currentGroupCertification) {
              stateContext.setState(
                patch({
                  currentGroupCertification: patch({
                    certifications: updateItem(
                      (cert) => cert?.id === data.certificationId,
                      patch({
                        documents: insertItem(data),
                      })
                    ),
                  }),
                })
              );
            }
          }
        }

        if (data?.groupCertificationId) {
          if (
            stateContext.getState().currentGroupCertification?.id ===
            data.groupCertificationId
          ) {
            stateContext.setState(
              patch({
                currentGroupCertification: patch({
                  documents: insertItem(data),
                }),
                currentCertification: patch({
                  documents: insertItem(data),
                }),
              })
            );
          }
        }

        if (data && action.uploadedFunc) {
          action.uploadedFunc(data);
        }
      })
    );
  };

export class UpdateDocumentAction {
  static readonly type = '[Document] Update';
  constructor(
    public payload: { input: UpdateDocumentInput },
    public callback?: (doc: Document) => void
  ) {}
}

export const updateDocumentAction =
  (fileService: FileService) =>
  (stateContext: StateContext<AppState>, action: UpdateDocumentAction) => {
    return fileService.updateDocument(action.payload.input).pipe(
      tap((data) => {
        if (
          action.payload.input.category &&
          [
            DocumentCategory.AGB_PLATFORM,
            DocumentCategory.AGB_STANDARD,
          ].includes(action.payload.input.category)
        ) {
          if (data && action.callback) {
            action.callback(data);
          }
        }

        if (data?.certificationId) {
          if (
            stateContext.getState().currentCertification?.id ===
            data.certificationId
          ) {
            stateContext.setState(
              patch({
                currentCertification: patch({
                  documents: updateItem((d) => d.id === data.id, data),
                  auditorRequests: stateContext
                    .getState()
                    .currentCertification.auditorRequests.map((ar) => {
                      const documents = ar.documents?.map((doc) => {
                        if (doc.id === data.id) {
                          return data;
                        }
                        return doc;
                      });

                      return { ...ar, documents };
                    }),
                }),
              })
            );
          }
        }

        if (data?.groupCertificationId) {
          if (
            stateContext.getState().currentGroupCertification?.id ===
            data.groupCertificationId
          ) {
            stateContext.setState(
              patch({
                currentGroupCertification: patch({
                  documents: updateItem((d) => d.id === data.id, data),
                  certifications: stateContext
                    .getState()
                    .currentGroupCertification.certifications.map((c) => {
                      // Update the documents in the documents array of any auditor request
                      const auditorRequests = c.auditorRequests.map((ar) => {
                        const documents = ar.documents?.map((doc) => {
                          if (doc.id === data.id) {
                            return data;
                          }
                          return doc;
                        });

                        return { ...ar, documents };
                      });

                      // update the documents in the documents array of the certification
                      const documents = c.documents.map((doc) => {
                        if (doc.id === data.id) {
                          return data;
                        }
                        return doc;
                      });

                      // TODO update the documents in the documents array of any object where docs are used :-(
                      const involvedIndicationsIds =
                        data.relations
                          ?.filter((rel) => rel.indicationId)
                          .map((rel) => rel.indicationId ?? '')
                          .filter((id) => id) || [];

                      c.indications
                        .filter((indication) =>
                          involvedIndicationsIds.includes(indication.id)
                        )
                        .forEach((indication) => {
                          indication = {
                            ...indication,
                            documents: (indication.documents ?? []).map(
                              (doc) => {
                                if (doc.id === data.id) {
                                  return data;
                                }
                                return doc;
                              }
                            ),
                          };
                        });

                      return { ...c, auditorRequests, documents };
                    }),
                }),
              })
            );

            if (stateContext.getState().currentCertification) {
              stateContext.setState(
                patch({
                  currentCertification: patch({
                    documents: updateItem((d) => d.id === data.id, data),

                    // TODO update the documents in the documents array of any object where docs are used :-(
                    auditorRequests: stateContext
                      .getState()
                      .currentCertification.auditorRequests.map((ar) => {
                        const documents = ar.documents?.map((doc) => {
                          if (doc.id === data.id) {
                            return data;
                          }
                          return doc;
                        });

                        return { ...ar, documents };
                      }),
                    indications: stateContext
                      .getState()
                      .currentCertification.indications.map((indication) => {
                        const documents = indication.documents?.map((doc) => {
                          if (doc.id === data.id) {
                            return data;
                          }
                          return doc;
                        });

                        return {
                          ...indication,
                          documents: documents,
                        };
                      }),
                  }),
                })
              );
            }
          }
        }
      })
    );
  };

export class DeleteDocumentAction {
  static readonly type = '[Document] Delete';
  constructor(
    public payload: {
      id: string;
      category?: DocumentCategory;
    },
    public callback?: (doc: Document | null) => void
  ) {}
}

export const deleteDocumentAction =
  (fileService: FileService) =>
  (stateContext: StateContext<AppState>, action: DeleteDocumentAction) => {
    return fileService.deleteDocument(action.payload).pipe(
      tap((data) => {
        if (
          action.payload.category &&
          [
            DocumentCategory.AGB_PLATFORM,
            DocumentCategory.AGB_STANDARD,
          ].includes(action.payload.category)
        ) {
          // this.store.dispatch(new LoadSystemDataAction());
          if (action.callback) {
            action.callback(data);
          }
        }

        if (data?.certificationId) {
          if (
            stateContext.getState().currentCertification?.id ===
            data.certificationId
          ) {
            stateContext.setState(
              patch({
                currentCertification: patch({
                  documents: removeItem((d) => d.id === data.id),
                }),
              })
            );
          }
        }

        if (data?.groupCertificationId) {
          if (
            stateContext.getState().currentGroupCertification?.id ===
            data.groupCertificationId
          ) {
            stateContext.setState(
              patch({
                currentGroupCertification: patch({
                  documents: removeItem((d) => d.id === data.id),
                }),
              })
            );
            if (stateContext.getState().currentCertification) {
              stateContext.setState(
                patch({
                  currentCertification: patch({
                    documents: removeItem((d) => d.id === data.id),
                  }),
                })
              );
            }
          }
        }
      })
    );
  };
