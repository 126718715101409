/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AuditorRequest } from '@eva/data-access/shared';
import { AuditorRequestService } from '../auditor-request.service';

@Component({
  selector: 'eva-auditor-request-display-status',
  templateUrl: './auditor-request-display-status.component.html',
  styleUrls: ['./auditor-request-display-status.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class AuditorRequestDisplayStatusComponent {
  @Input() auditorRequest: AuditorRequest | undefined;

  constructor(public auditorRequestHelper: AuditorRequestService) {}
}
