<div class="max-text-width">
  <div class="mt-1">
    <div class="card-body">
      <form [formGroup]="userForm" (ngSubmit)="submitForm()">
        <div class="fluid mt-3">
          <p *ngIf="!agbFirstView">
            Wir haben unsere AGB und Nutzungsbedingungen aktualisiert.
          </p>
          <p i18n="@@agb.info1">
            Bitte akzeptieren Sie unsere aktuellen<br />
            <a class="pseudo-link" href="{{ agbDocumentLink }}" target="_blank"
              >Allgemeinen Geschäftsbedingungen</a
            >
            und unsere
            <a class="pseudo-link" href="{{ termsOfUsageLink }}" target="_blank"
              >Nutzungsbedingungen</a
            >
          </p>
          <div class="field">
            <div class="field-checkbox">
              <p-checkbox
                formControlName="agbAccepted"
                [binary]="true"
                inputId="agb"></p-checkbox>
              <label class="text-color" for="agb" i18n="@@agb.label"
                >Ich habe die AGB und die Nutzungsbedingungen gelesen und
                akzeptiere sie</label
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
