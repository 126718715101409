/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Role } from '@eva/certification/api';
import { User } from '@eva/data-access/shared';
import { Select } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';
import { MessagingService } from '../services/messaging.service';
import { AppState } from '../states/app.state';

@Injectable()
export class AuthGuard {
  private role: Role | null = null;

  @Select(AppState.authenticatedUser) user$: Observable<User>;

  constructor(
    private messagingService: MessagingService,
    private authorizationService: AuthorizationService,
    private router: Router
  ) {
    this.authorizationService.getRole().then((role) => (this.role = role));
  }

  canActivateChild(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authorizationService
      .isCurrentUserAuthorized(route?.data?.roles)
      .pipe(
        tap((authorized) => {
          if (!authorized) {
            this.messagingService.error('Fehler', 'Nicht authorisiert!', false);
            this.router.navigate(['unauthorized']);
          }
        })
      );
  }
}
