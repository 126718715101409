/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import {
  CreateGroupCertificationInput,
  GetScenarioSummariesQueryVariables,
  UpdateCertifierInput,
  UpdateGroupCertificationInput,
  UpdateGroupCertificationStatusInput,
} from '@eva/data-access/shared';
import { StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { catchError, of, tap } from 'rxjs';

import { CertificationApiService } from '../../services/api/certification.api.service';

import {
  AppState,
  updateGroupCertificationInAppStateContext,
} from '../app.state';
import { attachAction } from '../attach-actions';

export const loadGroupCertificationActions = (
  certificationService: CertificationApiService
) => {
  attachAction(
    AppState,
    CreateGroupCertificationAction,
    createGroupCertificationAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateGroupCertificationAction,
    updateGroupCertificationAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateGroupCertificationStatusAction,
    updateGroupCertificationStatusAction(certificationService)
  );
  attachAction(
    AppState,
    UpdateGroupCertifierAction,
    updateGroupCertifierAction(certificationService)
  );
  attachAction(
    AppState,
    ListScenarioSummariesAction,
    listScenarioSummariesAction(certificationService)
  );
  attachAction(
    AppState,
    ListScenarioSummariesAction,
    listScenarioSummariesAction(certificationService)
  );
  attachAction(
    AppState,
    UnsetCurrentGroupCertificationAction,
    unsetCurrentGroupCertificationAction()
  );
};

export class ListScenarioSummariesAction {
  static readonly type = '[GroupCertification] ListScenarioSummariesAction';
  constructor(public payload: GetScenarioSummariesQueryVariables) {}
}

export const listScenarioSummariesAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: ListScenarioSummariesAction
  ) => {
    return certificationService
      .getScenarioSummaries(
        action.payload.id,
        action.payload.scenarioType as 'Baseline' | 'GrowthModel'
      )
      .pipe(
        tap((ScenarioSummaries) => {
          if (ScenarioSummaries) {
            if (action.payload.scenarioType === 'Baseline') {
              stateContext.setState(
                patch({
                  baselineScenarios: ScenarioSummaries,
                })
              );
            } else if (action.payload.scenarioType === 'GrowthModel') {
              stateContext.setState(
                patch({
                  growthmodelScenarios: ScenarioSummaries,
                })
              );
            }
          }
        }),
        catchError((error: string) => {
          console.log('catchError', error);

          return of(null);
        })
      );
  };

export class CreateGroupCertificationAction {
  static readonly type = '[GroupCertification] Create';
  constructor(public payload: CreateGroupCertificationInput) {}
}

export const createGroupCertificationAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: CreateGroupCertificationAction
  ) => {
    return certificationService.createGroupCertification(action.payload).pipe(
      tap((newGroupCertification) => {
        if (newGroupCertification?.id) {
          stateContext.setState(
            patch({
              currentGroupCertification: newGroupCertification,
            })
          );
        }
      }),
      catchError((error: string) => {
        console.log('catchError', error);

        return of(null);
      })
    );
  };

export class UpdateGroupCertificationAction {
  static readonly type = '[GroupCertification] Update';
  constructor(public payload: UpdateGroupCertificationInput) {}
}

export const updateGroupCertificationAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: UpdateGroupCertificationAction
  ) => {
    return certificationService.updateGroupCertification(action.payload).pipe(
      tap((newGroupCertification) => {
        if (!action.payload.certificationIds) {
          stateContext.setState(
            patch({
              currentGroupCertification: undefined,
            })
          );
        }

        if (newGroupCertification?.id) {
          stateContext.setState(
            patch({
              currentGroupCertification: newGroupCertification,
            })
          );
        }
      }),
      catchError((error: string) => {
        console.log('catchError', error);

        return of(null);
      })
    );
  };

export class UpdateGroupCertificationStatusAction {
  static readonly type = '[GroupCertification] Update status';
  constructor(public payload: UpdateGroupCertificationStatusInput) {}
}

export const updateGroupCertificationStatusAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: UpdateGroupCertificationStatusAction
  ) => {
    return certificationService
      .updateGroupCertificationStatus(action.payload)
      .pipe(
        tap((updatedGroupCertification) => {
          if (updatedGroupCertification) {
            updateGroupCertificationInAppStateContext(
              stateContext,
              updatedGroupCertification
            );
          }
        }),
        catchError((error: string) => {
          console.log('catchError', error);

          return of(null);
        })
      );
  };

export class UpdateGroupCertifierAction {
  static readonly type = '[GroupCertification] UpdateGroupCertifier';
  constructor(public payload: UpdateCertifierInput) {}
}

export const updateGroupCertifierAction =
  (certificationService: CertificationApiService) =>
  (
    stateContext: StateContext<AppState>,
    action: UpdateGroupCertifierAction
  ) => {
    return certificationService.updateGroupCertifier(action.payload).pipe(
      tap((data) => {
        if (data?.groupCertification) {
          const certification = data.groupCertification;
          updateGroupCertificationInAppStateContext(
            stateContext,
            certification
          );
        }
      })
    );
  };

export class UnsetCurrentGroupCertificationAction {
  static readonly type = '[Certification] UnsetCurrent';
}

export const unsetCurrentGroupCertificationAction =
  () => (stateContext: StateContext<AppState>) => {
    stateContext.patchState({ currentGroupCertification: undefined });
  };
