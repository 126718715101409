/**
 * @copyright
 * Copyright 2024 EVA Service GmbH
 */

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  standalone: true,
  name: 'auditorRequestNumber',
})
export class DisplayAuditorRequestNumber implements PipeTransform {
  transform(value: number | null | undefined, group = false): string {
    if (value) {
      return `#${group ? 'G' : ''}${String(value).padStart(4, '0')}`;
    }
    return '';
  }
}
