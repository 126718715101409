/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { ListHistoriesGQL } from '@eva/data-access/graphql';
import { HistoryFragment } from '@eva/data-access/shared';
import { GraphQLError } from 'graphql';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MessagingService } from '../messaging.service';

@Injectable()
export class HistoryService {
  constructor(
    private listHistories: ListHistoriesGQL,
    private messageService: MessagingService
  ) {}

  /**
   * this error handlers shows an error message via MessagingService
   * and returns an empty array which means dispatched actions and subscribers
   * will not get the error anymore
   *
   * This is mainly for convenience (reducing error handling in components)
   * but there might be use cases where this is not wanted, then this
   * error handler should not be used
   */
  private errorHandler = catchError((err) => {
    if (environment.debug && (err.graphQLErrors || err.networkError)) {
      err.graphQLErrors.forEach((error: GraphQLError) => {
        console.debug('graphQLError', error);
        if (error.message) {
          this.showError('Fehler', error.message);
        }
      });
      if (err.networkError?.statusText) {
        this.showError('Fehler', err.networkError.statusText);
      }
      if (err.networkError?.error?.errors?.length > 0) {
        err.networkError.error.errors.forEach((e: Error) => {
          console.debug('Network Error:' + e.message);
        });
      }
    } else if (environment.debug && err.message) {
      this.showError('Client-Fehler', err.message);
    } else {
      throw Error(
        'Fehler: die gewünschte Aktion konnte nicht durchgeführt werden. Bitte informieren Sie das WKS Sekreteriat!'
      );
    }
    return [];
  });

  private extractData<T>(result: ApolloQueryResult<T> | FetchResult<T>) {
    if (result.data === undefined || result.errors?.length) {
      if (result.errors) {
        this.showError(
          'Fehler: keine Daten verfügbar!',
          result.errors[0].message
        );
      }
      return null;
    } else {
      return result.data;
    }
  }

  private showError(headline: string, message: string) {
    this.messageService.error(headline, message);
  }

  loadHistories(
    certificationId: string
  ): Observable<{ histories: HistoryFragment[] }> {
    return this.listHistories.watch({ certificationId }).valueChanges.pipe(
      map((result) => this.extractData(result)),
      this.errorHandler
    ) as Observable<{ histories: HistoryFragment[] }>;
  }
}
