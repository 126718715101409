/**
 * @copyright
 * Copyright 2022 EVA Service GmbH
 */

import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Actions, Select, Store, ofActionSuccessful } from '@ngxs/store';
import { Observable, from, map, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { PrepareOfflineStatusAction, SetOfflineAction } from '../actions';
import { SEVERITY } from '../model/message';
import { LoadCertificationsAction } from '../states/actions';
import { AppState } from '../states/app.state';
import { AuthenticationService } from './authentication.service';
import { LoadingService } from './loading.service';
import { MessagingService } from './messaging.service';
import { dbService } from './offline-db.service';

@Injectable({ providedIn: 'root' })
export class OfflineService {
  @Select(AppState.getIsOfflineReady) getOfflineReady$: Observable<boolean>;

  constructor(
    private loadingService: LoadingService,
    private authenticationService: AuthenticationService,
    private messagingService: MessagingService,
    private softWareUpdate: SwUpdate,
    private store: Store,
    private action$: Actions
  ) {
    this.action$
      .pipe(ofActionSuccessful(PrepareOfflineStatusAction))
      .subscribe((res) => {
        console.log('ofActionSuccessful: PrepareOfflineStatusAction', res);
        this.store.dispatch(new LoadCertificationsAction());
      });
    this.action$.pipe(ofActionSuccessful(SetOfflineAction)).subscribe((res) => {
      console.log('ofActionSuccessful: SetOfflineAction', res);
      if (res.offline === false) {
        this.messagingService.confirm(
          'Online gehen',
          'Die Anwendung wird nun neu geladen. ' +
            'Bitte achten Sie darauf, dass Sie nun wirklich wieder Online sind sonst könnte es zu Datenverlust kommen',
          (confirmed) => {
            if (confirmed) {
              window.location.reload();
            } else {
              this.store.dispatch(new SetOfflineAction(true));
            }
          }
        );
      } else {
        this.authenticationService.stopAutomaticRefresh();
      }
    });
    this.getOfflineReady$.subscribe((ready) => {
      if (ready) {
        this.loadingService.resetLoadingStatus();
        this.messagingService.clear();
        this.store.dispatch(new SetOfflineAction(true));
        this.messagingService.confirmOnly(
          'Offline Modus',
          'Alle Daten sind nun geladen. ' +
            'Bevor Sie offline gehen empfiehlt es sich vor allem den ' +
            'Reiter "Flächen" (mit den Kartenbildern) und die Übersichtskarte ' +
            'aufzurufen, damit diese Bilder ebenfalls im Cache sind.',
          () => {
            return true;
          },
          SEVERITY.SUCCESS
        );
      }
    });
  }

  /**
   * if online calls Autorization Service initialize()
   * if offline tries to load data from indexeddb
   */
  initialize(): Observable<boolean> {
    return this.checkOnlineStatus();
  }

  /**
   * triggered by UI
   * loads all data in local indexeddb and switches to offline mode
   */
  setOfflineMode(offline: boolean) {
    if (offline) {
      this.messagingService.confirmOnly(
        'Offline Modus',
        'Es werden nun alle benötigten Daten vom Server geladen. ' +
          'Sie werden benachrichtigt wenn der Ladevorgang abgeschlossen ist',
        () => {
          this.messagingService.clear();
        },
        SEVERITY.SUCCESS
      );
      this.store.dispatch(new PrepareOfflineStatusAction());
    } else {
      this.messagingService.confirm(
        'Online gehen',
        'Die Anwendung wird nun neu geladen. ' +
          'Bitte beachten Sie dass es zu Datenverlust kommen kann wenn Sie noch nicht online sind. Sind Sie sicher dass Sie nun wieder online sind?',
        (confirmed) => {
          if (confirmed) {
            this.loadingService.setOfflineStatus(true);
            this.store.dispatch(new SetOfflineAction(false));
          }
        },
        SEVERITY.WARN
      );
    }
  }

  /**
   * unregister old still existing service worker
   */
  private disableServiceWorker(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (const registration of registrations) {
          window.setTimeout(() => {
            registration.unregister();
            console.log('ServiceWorker unregistered');
          }, 1000);
        }
      });
    }
  }

  private checkOnlineStatus(): Observable<boolean> {
    if (
      navigator['standalone' as keyof typeof navigator] ||
      window.matchMedia('(display-mode: standalone)').matches
    ) {
      console.log('Running in PWA');
      return from(this.getOnlineStatus()).pipe(
        switchMap((offline: string) => {
          if (offline === 'OFFLINE') {
            console.log('Is offline!');
            this.loadingService.resetLoadingStatus();
            return this.store.dispatch(new SetOfflineAction(true)).pipe(
              tap((state) => console.log('After dispatch SetOffline', state)),
              map(() => true)
            );
          } else {
            return from(dbService.table('lastState')?.get('lastState')).pipe(
              switchMap((lastState) => {
                if (lastState && lastState['offline']) {
                  console.log('Stay offline like in last state');
                  this.loadingService.resetLoadingStatus();
                  return this.store.dispatch(new SetOfflineAction(true)).pipe(
                    tap((state) =>
                      console.log('After dispatch SetOffline', state)
                    ),
                    map(() => true)
                  );
                } else {
                  console.log('Go online');
                  return this.proceedWithOnlineStatus();
                }
              })
            );
          }
        })
      );
    } else {
      this.disableServiceWorker();
      return this.proceedWithOnlineStatus();
    }
  }

  private proceedWithOnlineStatus() {
    this.softWareUpdate.versionUpdates.subscribe((evt: VersionEvent) => {
      console.log('VersionEvent', evt.type);
      console.log('Software Version', (evt as any)['version']?.hash);
      if (evt.type === 'VERSION_READY') {
        this.messagingService.confirmOnly(
          'Software Update!',
          'Es gab ein Software-Update.\nDie Seite wird nun neu geladen!',
          (confirmed) => {
            if (confirmed) {
              window.location.reload();
            }
          },
          'info'
        );
      }
    });
    return this.authenticationService.initialize();
  }

  private async getOnlineStatus(): Promise<'ONLINE' | 'OFFLINE'> {
    const isReachable = async (url: string) => {
      return fetch(url, { method: 'HEAD', mode: 'no-cors' })
        .then(function (resp) {
          return resp && (resp.ok || resp.type === 'opaque');
        })
        .catch(function (err) {
          console.warn('[conn test failure]:', err);
        });
    };
    if (navigator.onLine) {
      return isReachable(environment.appUrl).then(function (online) {
        if (online) {
          // handle online status
          console.log('online');
          return 'ONLINE';
        } else {
          console.log('no connectivity');
          return 'OFFLINE';
        }
      });
    } else {
      // handle offline status
      console.log('offline');
      return 'OFFLINE';
    }
  }
}
