/**
 * @copyright
 * Copyright 2023 EVA Service GmbH
 */

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentCategoryLocalization } from '@eva/certification/api';
import {
  CreateDocumentInput,
  Document,
  DocumentCategory,
  DocumentVisibility,
  Role,
} from '@eva/data-access/shared';
import { Store } from '@ngxs/store';
import { FileProgressEvent, FileUpload } from 'primeng/fileupload';
import {
  Observable,
  Subject,
  Subscription,
  forkJoin,
  from,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs';
import SparkMD5 from 'spark-md5';
import { LoadSystemDataAction } from '../../../../actions/systemActions';
import { FileProgress } from '../../../../model/file-progress.interface';
import { FileService } from '../../../../services/api/file.service';
import { AuthorizationService } from '../../../../services/authorization.service';
import { MessagingService } from '../../../../services/messaging.service';
import { UploadDocumentAction } from '../../../../states/actions/document-actions';

@Component({
  selector: 'eva-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy, OnChanges {
  @Output() selectionChanged = new EventEmitter<File[]>();
  @Output() uploadFinished = new EventEmitter();
  @Output() deletedEvent = new EventEmitter();

  @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;
  @Input() category: DocumentCategory | null = null;
  @Input() categories: DocumentCategory[] | undefined;
  @Input() disabled = false;
  @Input() defaultVisibilityMode = DocumentVisibility.PUBLIC;
  @Input() multiple = true;
  @Input() editMode = true;
  @Input() certificationId: string | undefined;
  @Input() groupCertificationId: string | undefined;
  @Input() externalControlled = false;
  @Input() useInternalCategorySelection = false;
  visiblilitySelection = true;
  /**
   * holds content from remarks field
   * key is file.lastModified + file.size
   */
  currentFileInfos: {
    [key: string]: {
      name: string;
      remark: string;
      visibility?: DocumentVisibility;
      category?: DocumentCategory | null;
      pddAppendix?: boolean;
      isPDF: boolean;
    };
  } = {};

  allowedCategories: (DocumentCategory | null)[] = [];
  documentCategoryList: ({
    key: DocumentCategory | null;
    value: string;
  } | null)[] = [];

  @Input() chooseLabel = this.multiple
    ? 'Dateien auswählen'
    : 'Neue Datei auswählen';

  @Input() maxFileSize = 1024 * 1024 * 50;

  DocumentVisibility = DocumentVisibility;

  progress: { [key: string]: string } = {};
  allowedMimeTypes = [
    'application/pdf',
    'application/zip',
    'application/vnd.ms-excel',
    'application/msword',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.google-earth.kml+xml',
    'application/geo+json',
    'image/jpg',
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'application/json',
  ];

  currentUserRole: Role = Role.ANONYMOUS;
  _selectedFiles: File[] = [];
  fileProgress: FileProgress[] = [];
  currentFileNames: string[] = [];
  private subscription = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private fileService: FileService,
    private messagingService: MessagingService,
    private store: Store,
    private authorizationService: AuthorizationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categories || changes.indicationId) {
      this.loadAllowedCategories();
      if (this.category) {
        this.handleVisibility(this.category);
      }
    }
  }

  async ngOnInit() {
    this.subscribeToProgress();
    this.currentUserRole = await this.authorizationService.getRole();
    this.loadAllowedCategories();
  }

  loadAllowedCategories() {
    this.allowedCategories = this.fileService.getAllowedCategories(
      this.currentUserRole
    );

    this.documentCategoryList = [
      ...this.allowedCategories.map((c) => ({
        key: c,
        value: c ? DocumentCategoryLocalization[c] : 'Allgemein',
      })),
      // { key: null, value: 'Allgemein' },
    ].filter(
      (c) => this.categories?.includes(c.key as DocumentCategory) ?? true
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public choose() {
    this.fileUpload.basicFileInput?.nativeElement.click();
  }

  public setFile(
    files: {
      file: File;
      fileName?: string;
      remarks?: string;
      visibility?: DocumentVisibility;
      category?: DocumentCategory | null;
      pddAppendix?: boolean;
    }[]
  ) {
    files.forEach((f) => {
      this.currentFileInfos[f.file.name + f.file.lastModified + f.file.size] = {
        name: f.fileName ?? f.file.name,
        remark: f.remarks ?? '',
        visibility: f.visibility ?? this.defaultVisibilityMode,
        category: f.category ?? this.category ?? null,
        pddAppendix: f.pddAppendix ?? false,
        isPDF: this.isPDFFile(f.file),
      };
    });
    this.fileUpload.files = files.map((f) => f.file);
  }

  public async startUpload(
    useGroupCertificationId = false
  ): Promise<Observable<Document[]>> {
    return await this.uploadFileTask(
      { files: this.fileUpload.files },
      this.fileUpload,
      useGroupCertificationId
    );
  }

  subscribeToProgress() {
    this.ngZone.runOutsideAngular(() => {
      this.subscription.add(
        this.fileService._progressList.asObservable().subscribe({
          next: (list) => {
            if (list?.length) {
              this.fileProgress = list;
              const found = list.filter((e) =>
                this.currentFileNames?.includes(e.name)
              );

              if (!found?.length) {
                return;
              }

              let totalProgress = 0;

              if (found.length === 1) {
                totalProgress = found[0].progress;
              } else {
                let total = 0;
                let loaded = 0;

                for (const foundItem of found) {
                  total = total + foundItem.total;
                  loaded = loaded + foundItem.loaded;
                }
                totalProgress = Number(((loaded / total) * 100).toFixed(0));
              }
              // not available in cypress e2e Test??
              this.fileUpload?.onProgress.emit({
                progress: totalProgress,
              } as FileProgressEvent);
              this.changeDetectorRef.detectChanges();
            }
          },
        })
      );
    });
  }

  async uploadFile(event: any, fileUpload: FileUpload) {
    (await this.uploadFileTask(event, fileUpload)).subscribe(
      (documentInputs) => {
        this.uploadFinished.emit();
        fileUpload.clear();
        this.currentFileNames = [];
        this.currentFileInfos = {};
      }
    );
  }

  async uploadFileTask(
    event: any,
    fileUpload: FileUpload,
    useGroupCertificationId = false
  ): Promise<Observable<Document[]>> {
    const files: { file: File; originalSize: number }[] = [];
    for (const f of event.files) {
      if (f.type.startsWith('image/')) {
        files.push({
          file: await this.fileService.compress(f),
          originalSize: f.size,
        });
      } else {
        files.push({
          file: f,
          originalSize: f.size,
        });
      }
    }

    this.currentFileNames = files.map((e) => e.file.name);
    const total = files.length;

    const waitForDocumentId = new Subject<Document>();
    const md5Sums = forkJoin(
      files.map((f, index) =>
        from(this.computeMd5FileSum(f.file)).pipe(
          map((md5) => ({ md5, f })),
          map((data) => {
            const currentFile =
              this.currentFileInfos[
                data.f.file.name +
                  data.f.file.lastModified +
                  data.f.originalSize
              ];
            return {
              file: data.f.file,
              fileSize: data.f.file.size,
              md5FileSum: data.md5,
              name: currentFile?.name ?? data.f.file.name,
              remarks: currentFile?.remark ?? '',
              certificationId:
                useGroupCertificationId === false
                  ? this.certificationId
                  : undefined,
              groupCertificationId: useGroupCertificationId
                ? this.groupCertificationId
                : undefined,
              visibility: currentFile?.visibility ?? DocumentVisibility.PRIVATE,
              category: currentFile?.category ?? this.category,
              index,
              total,
              pddAppendix: currentFile?.pddAppendix ?? false,
            } as CreateDocumentInput;
          }),
          tap((documentInput) =>
            this.store.dispatch(
              // insert an callback function to get the documentId
              new UploadDocumentAction(documentInput, (doc) => {
                // feed the observable to complete uploadFileTask only after  callback is called
                waitForDocumentId.next(doc);
                if (
                  doc.category &&
                  [
                    DocumentCategory.AGB_PLATFORM,
                    DocumentCategory.AGB_STANDARD,
                  ].includes(doc.category)
                ) {
                  this.store.dispatch(new LoadSystemDataAction());
                }
              })
            )
          ),
          // wait for the documentId to be available
          switchMap(() => waitForDocumentId.pipe(take(1)))
        )
      )
    );

    return md5Sums;
  }

  isPDFFile(file: File): boolean {
    return (file?.type?.toLowerCase().indexOf('pdf') ?? -1) > -1;
  }

  computeMd5FileSum(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const chunkSize = 2097152; // Read in chunks of 2MB
      const spark = new SparkMD5.ArrayBuffer();
      const fileReader = new FileReader();
      let uploadedSize = 0;

      let cursor = 0; // current cursor in file

      fileReader.onerror = function (): void {
        reject('MD5 computation failed - error reading the file');
      };

      // read chunk starting at `cursor` into memory
      function processChunk(chunk_start: number): void {
        const chunk_end = Math.min(file.size, chunk_start + chunkSize);
        fileReader.readAsArrayBuffer(file.slice(chunk_start, chunk_end));
      }

      // when it's available in memory, process it
      // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
      // of `any` for `e` variable, otherwise stick with `any`
      // See https://github.com/Microsoft/TypeScript/issues/25510
      fileReader.onload = function (e: ProgressEvent<FileReader>): void {
        if (e.target && e.target.result) {
          spark.append(e.target.result as ArrayBuffer); // Accumulate chunk to md5 computation
          cursor += chunkSize; // Move past this chunk
          uploadedSize += parseInt((e.target.result as any)['byteLength']);
          console.log('Uploaded: ' + uploadedSize / 1000 + ' kB');
          if (cursor < file.size) {
            // Enqueue next chunk to be accumulated
            processChunk(cursor);
          } else {
            // Computation ended, last chunk has been processed. Return as Promise value.
            // This returns the base64 encoded md5 hash, which is what
            // Rails ActiveStorage or cloud services expect
            // resolve(btoa(spark.end(true)));

            // If you prefer the hexdigest form (looking like
            // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
            resolve(spark.end());
          }
        }
      };

      processChunk(0);
    });
  }

  formatSize(size: number) {
    return this.fileService.formatSize(size);
  }

  getFileProcess(name: string) {
    if (this.fileProgress?.find((e) => e.name === name && e.progress !== 100)) {
      return this.fileProgress?.find((e) => e.name === name)?.progress + ' %';
    } else {
      return '';
    }
  }

  categoryChanged(selectedCategory: DocumentCategory) {
    this.handleVisibility(selectedCategory);
  }

  handleVisibility(selectedCategory: DocumentCategory) {
    if (selectedCategory) {
      const defaultVisibility =
        this.fileService.getDefaultVisibility(selectedCategory);

      this.visiblilitySelection = !defaultVisibility;
      this.defaultVisibilityMode =
        defaultVisibility ?? DocumentVisibility.PUBLIC;

      if (this.currentFileInfos) {
        Object.keys(this.currentFileInfos).forEach((key) => {
          this.currentFileInfos[key].visibility = this.defaultVisibilityMode;
        });
      }
    }
  }

  onSelect(evt: { files: File[] }, fileUpload: any) {
    const invalidFiles: any[] = [];
    const newFiles = Array.from(evt.files) as File[];
    newFiles.forEach((f: File) => {
      if (
        this._selectedFiles.find(
          (existingFile: File) => f.name === existingFile.name
        )
      ) {
        this.messagingService.warn(
          'Datei doppelt gewählt',
          'Diese Datei wurde bereits ausgewählt!'
        );
      } else {
        this.currentFileInfos[f.name + f.lastModified + f.size] = {
          name: f.name,
          remark: '',
          visibility: this.defaultVisibilityMode,
          isPDF: this.isPDFFile(f),
        };
      }
      if (!this.allowedMimeTypes.includes(f.type)) {
        invalidFiles.push(f);
        fileUpload._files = fileUpload._files.filter((f2: File) => {
          return f2.name !== f.name;
        });
      }
    });
    this._selectedFiles = [...fileUpload._files];
    if (invalidFiles.length > 0) {
      this.messagingService.warn(
        'Datei nicht erlaubt!',
        'Die Datei(en) ' +
          invalidFiles.reduce(
            (previousValue, currentValue) =>
              previousValue + '"' + currentValue.name + '",\n',
            '\n'
          ) +
          ' sind nicht erlaubt. Es sind nur Bilder, PDF, Word- und Excel-Dateien sowie zip-Archive erlaubt'
      );
    } else {
      this.selectionChanged.emit(fileUpload._files);
    }
  }

  selectionChange(_evt: unknown, fileUpload: FileUpload) {
    this.selectionChanged.emit(fileUpload._files);
    this._selectedFiles = [...fileUpload._files];
  }

  selectionCleared(_evt: unknown, fileUpload: FileUpload) {
    this._selectedFiles = [];
    this.selectionChanged.emit(fileUpload._files);
  }

  fileRemoved(_evt: unknown, fileUpload: FileUpload) {
    if (fileUpload._files.length === 1) {
      // event is triggered before file is removed!
      this._selectedFiles = [];
    }
    this.selectionChanged.emit(fileUpload._files);
  }

  onProgress(evt: unknown) {
    this.fileUpload.progress = evt as number;
  }
}
