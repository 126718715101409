/**
 * @copyright
 * Copyright 2021 EVA Service GmbH
 */

import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'eva-form-buttons',
  template: `
    <div class="buttons" [ngClass]="classes">
      <eva-button
        *ngIf="form.dirty && !saveButtonOnly"
        [icon]="{ name: 'pi pi-times' }"
        i18n-label="@@cancel"
        label="Abbrechen"
        [nativeType]="'reset'"
        [type]="'outlined'"
        (click)="onFormCanceled()"
        >Abbrechen</eva-button
      >

      <eva-button
        *ngIf="!form.dirty && !saveButtonOnly && showCloseButton"
        [icon]="{ name: 'pi pi-times' }"
        i18n-label="@@close"
        label="Schließen"
        [nativeType]="'reset'"
        [type]="'outlined'"
        (click)="onFormClose()"
        >Schließen</eva-button
      >

      <eva-button
        evaLoading
        [icon]="{ name: 'pi pi-check' }"
        [keys]="loadingKey!"
        [nativeType]="
          (form.invalid || form.pristine) === true ? 'button' : 'submit'
        "
        i18n-label="@@save"
        label="Speichern"
        [disabled]="form.invalid || form.pristine"
        class="ml-2"
        [attr.data-cy]="'submit'"
        >Speichern</eva-button
      >
    </div>
  `,
})
export class ButtonComponent {
  @Input() form: FormGroup;
  @Input() saveButtonOnly: boolean;
  @Input() loadingKey?: string;
  @Input() classes?: string[] = ['text-right'];
  @Input() closeButtonLabel? = 'Schließen';
  @Input() showCloseButton? = true;

  @Output() formClosed = new EventEmitter();
  @Output() formCanceled = new EventEmitter();

  constructor(public location: Location) {}

  onFormClose() {
    console.log('onFormClose');
    this.formClosed.emit();
  }

  onFormCanceled() {
    console.log('onFormCanceled');
    this.formCanceled.emit();
  }
}
